import React from 'react';
import { connect } from 'react-redux';
import AccountDetails from './AccountDetails';
import AccountDetailsConfirmation from './AccountDetailsConfirmation';

export const steps = {
  ACCOUNT_DETAILS: 'ACCOUNT_DETAILS',
  ACCOUNT_DETAILS_CONFIRMATION: 'ACCOUNT_DETAILS_CONFIRMATION',
};

const Gocardless = (props) => {
  const { payment } = props;

  // Get date suffix
  const nth = n => ['st', 'nd', 'rd'][((((n + 90) % 100) - 10) % 10) - 1] || 'th';

  switch (payment.step) {
    case steps.ACCOUNT_DETAILS_CONFIRMATION:
      // Spread props to pass all to AccountDetailsConfirmation
      return <AccountDetailsConfirmation nth={nth} {...props} />;
    default:
      // Spread props to pass all to AccountDetails
      return <AccountDetails nth={nth} {...props} />;
  }
};

export default connect(({ payment }) => ({ payment }))(Gocardless);
