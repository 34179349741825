import React, { useEffect } from 'react';
import ReactSVG from 'react-svg';
import { connect } from 'react-redux';

import SvgSprite from '@comicrelief/storybook/src/components/SVG/spritesheet.svg';

import ThankYou from './ThankYou/ThankYou';
import ThankYouMembership from './ThankYou/Membership/ThankYouMembership';
import MarketingPreferences from './MarketingPreferences/MarketingPreferences';
import { GIVING_TYPE_MONTHLY } from '../DonationForm/GivingTypeSelector/GivingTypeSelector';

import CartService from '../../Service/Cart.service';

import FullHeightSingleImage from '../../Component/FullHeightSingleImage/FullHeightSingleImage';

/**
 * Success Component
 */
const Success = ({ application, history, payment, donationForm, successSection }) => {
  useEffect(() => {
    if (payment.paymentIsComplete === false || (payment.transactionId === null && payment.subscriptionId === null)) {
      history.push({ pathname: '/' });
    }

    // Set the page title
    document.title = `Success${application.page.titlePostfix}`;
  }, [application.page.titlePostfix, history, payment.paymentIsComplete, payment.subscriptionId, payment.transactionId]);

  const thankYouPage = application.client === 'the_fix' ? <ThankYouMembership /> : <ThankYou />;

  const cart = new CartService(application.cartId);
  const { desktop, mobile, alt, successDesktop, successMobile, successAlt } = cart.config.header[donationForm.givingType];
  const isFinished = successSection.formSubmitted === true;

  // Switch images depending on whether the user has actually finished,
  // and if the cart actually has an optional 'success' image
  const thisDesktopImg = isFinished && successDesktop ? successDesktop : desktop;
  const thisMobileImg = isFinished && successMobile ? successMobile : mobile;
  const thisAltText = isFinished && successAlt ? successAlt : alt;
  const isMonthly = donationForm.givingType === GIVING_TYPE_MONTHLY;

  return (
    <main role="main">
      <ReactSVG
        path={SvgSprite}
        className="visually-hidden"
      />
      <section className={`paragraph--full-height-single-image-single-copy ${isMonthly ? 'njaons' : 'bg--blue-2023'}`}>
        <FullHeightSingleImage
          alt={thisAltText}
          desktop={thisDesktopImg}
          mobile={thisMobileImg}
        />
        {isFinished ? thankYouPage : <MarketingPreferences />}
      </section>
    </main>
  );
};

export default connect(
  ({ application, donationForm, payment, successSection }) => (
    { application, donationForm, payment, successSection }),
)(Success);
