export const ROUTES = {
  global: {
    configuration: 'provider',
    cancel: 'form/giftaid',
  },
  payIn: {
    cancel: 'form/payin/payment-method',
    paymentMethod: 'form/payin/payment-method',
    activity: 'form/payin/activity',
    audience: 'form/payin/audience',
    details: 'form/payin/details',
    postalAddress: 'form/payin/postal-address',
    billingAddress: 'form/payin/billing-address',
    cardPayment: 'form/payin/payment',
    failure: 'form/payin/failure',
    success: 'form/payin/success',
    endpoint: 'payin',
  },
  payment: {
    fetch: 'payment/fetch',
  },
  provider: {
    braintree: {
      generateToken: 'provider/braintree/token/generate',
      create: 'provider/braintree/create',
      createSubscription: 'provider/braintree/create-subscription',
      execute: 'provider/braintree/execute',
      executeAsync: 'provider/braintree/execute-async',
      executeSubscription: 'provider/braintree/execute-subscription',
    },
    goCardless: {
      execute: 'provider/go-cardless/execute',
      verifyBank: 'provider/go-cardless/verify-bank',
    },
    paypal: {
      create: 'provider/paypal/create',
      execute: 'provider/paypal/execute',
    },
    stripe: {
      create: 'provider/stripe/create',
      execute: 'provider/stripe/execute',
    },
  },
  transaction: {
    cancel: 'transaction/cancel',
    updateMarketingPreferences: 'transaction/update-marketing-preferences',
  },
};

/**
 * PaymentServiceRouter class
 */
export default class PaymentServiceRouter {
  /**
   * Get Route
   * @param route
   * @return {*}
   */
  static get(route) {
    return process.env.REACT_APP_PAYMENTSURL + route;
  }

  /**
   * Post Request
   * @param  {String} route
   * @param  {Object} body
   * @return {Promise}
   */
  static postRequest(route, body) {
    return fetch(PaymentServiceRouter.get(route), {
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(body),
    });
  }
}
