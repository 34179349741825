import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import FullHeightSingleImage from '../../Component/FullHeightSingleImage/FullHeightSingleImage';
import PayInSchoolsLookup from './SchoolsLookup/PayInSchoolsLookup';
import PayInPostcodeLookup from './PostcodeLookup/PayInPostcodeLookup';
import BackButton from '../../Component/BackButton/BackButton';
import AmountAndGivingTypeHeader from '../../Component/AmountAndGivingTypeHeader/AmountAndGivingTypeHeader';
import audienceImages from './data/audienceImages';
import * as payInFormActions from '../../Actions/payInFormActions';
import { ROUTES } from '../../Service/PaymentServiceRouter.service';

/**
 * PayInPostalAddress Component
 */
const PayInPostalAddress = ({ application, payInForm, history, updateCurrentPayInStep, updatePayInForceFormErrors, isMobile }) => {
  const isSchool = payInForm.payInAudienceType === 'school';

  useEffect(() => {
    const prevStep = payInForm.currentPayInStep;
    const thisStep = 3;

    if (prevStep > thisStep + 1 || prevStep < thisStep - 1 || prevStep === null) {
      history.push({ pathname: '/' });
    }

    document.title = `Pay-In: Postal Address${application.page.titlePostfix}`;
    // Update form progress
    updateCurrentPayInStep(thisStep);
  }, [application.page.titlePostfix, history, payInForm.currentPayInStep, updateCurrentPayInStep]);
  /**
   * handleSubmit
   * @param e
   */
  const handleSubmit = (e) => {
    e.preventDefault();
    const { isPayInAddressValid, isPayInSchoolAddressValid } = payInForm.allAddressValidity;
    // Check the appropriate validation object, force error state (passed to Lookups via props) if invalid..
    if ((isSchool && !isPayInSchoolAddressValid) || (!isSchool && !isPayInAddressValid)) {
      updatePayInForceFormErrors(true);
    } else {
      // Else, we're valid, so continue:
      updatePayInForceFormErrors(false);
      // Successful submission, onto the next page
      history.push({ pathname: `/${ROUTES.payIn.paymentMethod}` });
    }
  };
  /**
   * goBack
   * @param event
   */
  const goBack = (event = null) => {
    if (event) event.preventDefault();
    history.push({ pathname: `/${ROUTES.payIn.details}` });
  };

  const formIsInvalid = (isSchool && !payInForm.allAddressValidity.isPayInSchoolAddressValid) || (!isSchool && !payInForm.allAddressValidity.isPayInAddressValid);
  /**
   * PayInPostalAddress render
   * @return {*}
   */
  return (
    <main role="main">
      <section className="paragraph--full-height-single-image-single-copy bg--blue-2023 paragraph--payin">
        {!isMobile && (
          <FullHeightSingleImage
            alt={audienceImages[payInForm.payInAudienceType]}
            desktop={audienceImages[payInForm.payInAudienceType]}
          />
        )}
        <div className="form__step fhdisc__text-wrapper fhsisc__text-wrapper bg--transparent">
          <form
            name="comicrelief_payinbundle_payment"
            method="post"
            onSubmit={handleSubmit}
            noValidate="novalidate"
            className="has-validation-callback fhdisc__text-3"
          >
            <div className="form__header">
              <BackButton id="postal-address" handleClick={goBack} ariaLabel="Back to your details" />
              <AmountAndGivingTypeHeader />
            </div>

            <div className="form__row form__row--your--address">
              <h1 className="form__subtitle">Postal address</h1>
              {/* Pass 'show error' flags to lookup wrappers via state */}
              {isSchool ? <PayInSchoolsLookup showError={!payInForm.allAddressValidity.isPayInSchoolAddressValid} /> : <PayInPostcodeLookup showError={!payInForm.allAddressValidity.isPayInAddressValid} />}
            </div>

            <div className="form__field--wrapper form__submit form__field--submit form__payin-postal-submit submit-btn-wrapper">
              <button
                type="submit"
                id="comicrelief_payinbundle_address_submit"
                className={`btn form__next cta${formIsInvalid ? ' disabled' : ''}`}
                name="comicrelief_payinbundle_address[submit]"
              >
                Continue to payment method
              </button>
            </div>
          </form>
        </div>
      </section>
    </main>
  );
};

export default connect(
  ({ application, payInForm }) => ({ application, payInForm }),
  dispatch => bindActionCreators(
    Object.assign({}, payInFormActions),
    dispatch,
  ),
)(PayInPostalAddress);
