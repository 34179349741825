import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Marquee from 'react-smooth-marquee';

import CartService from '../../Service/Cart.service';
import './SocialTicker.scss';

function SocialTicker({ application, payInForm }) {
  const cart = new CartService(application.cartId);

  if (cart.config.social_ticker_status === false
    || application.recentDonors.length === 0
    || (application.currentPageStep !== 0 || payInForm.currentPayInStep !== false)
    || typeof application.dependencies.ticker === 'undefined'
    || application.dependencies.ticker === false
  ) {
    return null;
  }

  return (
    <div className="social-ticker-container">
      <div className="explainer-block">
        Latest Donations
      </div>
      <Marquee velocity="0.05">{ application.recentDonors.map((item, key) => (
        <span key={`${key + item.first_name}`} className="marquee-need-message">
          <strong>{ item.first_name }</strong> just donated <strong>£{ item.amount }</strong>
        </span>
      )) }</Marquee>
    </div>
  );
}

export default connect(
  ({ application, payInForm }) => ({ application, payInForm }),
  dispatch => bindActionCreators(
    {},
    dispatch,
  ),
)(SocialTicker);

