import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as applicationActions from '../../Actions/applicationActions';
import * as addressActions from '../../Actions/addressActions';
import * as donationFormActions from '../../Actions/donationFormActions';
import * as paymentActions from '../../Actions/paymentActions';
import PaymentServiceRouter, { ROUTES } from '../../Service/PaymentServiceRouter.service';
import { cardProviders, paymentProviders } from '../../Service/PaymentConfiguration.service';
import Stripe from '../../Component/Provider/Stripe/Stripe';
import Braintree from '../../Component/Provider/Braintree/Braintree';
import { GIVING_TYPE_SINGLE } from '../DonationForm/GivingTypeSelector/GivingTypeSelector';
import GooglePayButton from '../../Component/Provider/GooglePay/GooglePay';
import ApplePayButton from '../../Component/Provider/ApplePay/ApplePay';
import Recaptcha from '../../Component/Recaptcha/Recaptcha';
import store from '../../Store/store';
import { getNonCardProviders, nonCardLoadingStatusUpdate } from '../../Component/Provider/_utils/nonCardProviderHelpers';

/**
 * PaymentForm Component:
 *
 * This component is used exclusively for direct payments
 * (on the Prize platform, for one), and isn't part of the
 * Donate/Payin journey at all.
 */
class PaymentForm extends Component {
  constructor() {
    super();

    this.state = {
      fetchedConfig: false,
      nonCardLoadingStatuses: {},
    };
  }

  async componentWillMount() {
    const {
      updateAmount,
      updateCurrency,
      updateClient,
      updateGivingType,
      updateTransactionId,
      updateAddressValue,
      updateSuccessURL,
      updateFailureURL,
      updatePaymentPageStatus,
      resetApplicationState,
      payment,
    } = this.props;

    // Reset entire application state if there has been a previous payment
    // As existing payments prevent merchant providers from getting reloaded
    if (payment.paymentIsComplete === true || payment.paymentIsFailed === true) {
      resetApplicationState();
    }
    updatePaymentPageStatus(true);

    try {
      // Grabs transactionID from URL
      const transactionId = this.props.match.params.transactionId;

      // Queries the PSL using the transactionID
      const paymentResponse = await fetch(PaymentServiceRouter.get(ROUTES.payment.fetch), {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ transactionId }),
      });

      // If no match, fallback to Sorry page
      if (paymentResponse.status !== 200) {
        this.props.history.push({ pathname: '/sorry' });

        return null;
      }

      // Else, destructure response to grab the data
      const { data } = await paymentResponse.json();

      // Updates 'payment' reducer
      updateTransactionId(transactionId);

      // Updates 'donationForm' reducer
      updateAmount(data.amount.toString());
      updateCurrency(data.currency);

      // Updates 'addressSection' reducer
      updateAddressValue('firstName', data.firstName);
      updateAddressValue('lastName', data.lastName);
      updateAddressValue('town', data.town);
      updateAddressValue('postcode', data.postcode);
      updateAddressValue('address1', data.address1);
      updateAddressValue('address2', data.address2);
      updateAddressValue('address3', data.address3);
      updateAddressValue('email', data.email);

      // Updates 'application' reducer
      updateClient(data.client);
      updateGivingType(GIVING_TYPE_SINGLE);

      // Updates 'application' reducer
      const successURL = data.successUrl;
      const successURLParamJoin = successURL.includes('?') ? '&' : '?';
      updateSuccessURL(`${successURL}${successURLParamJoin}transaction_id=${transactionId}`);

      // Updates 'application' reducer
      const failureURL = data.failureUrl;
      const failureURLParamJoin = failureURL.includes('?') ? '&' : '?';
      updateFailureURL(`${failureURL}${failureURLParamJoin}transaction_id=${transactionId}`);

      // fetchedConfig change -being part of state- will now trigger a re-render
      this.setState({ fetchedConfig: true });

      return null;
    } catch (e) {
      console.log('error', e);

      this.props.history.push({ pathname: '/sorry' });

      return null;
    }
  }

  /**
   * On payment failure
   */
  onPaymentFailure() {
    const {
      application: {
        callbackUrls: { failureUrl },
      },
    } = this.props;

    window.location.href = failureUrl;
  }

  /**
   * On payment success
   */
  onPaymentSuccess() {
    const {
      application: {
        callbackUrls: { successUrl },
      },
    } = this.props;

    window.location.href = successUrl;
  }

  /**
   * Fetch the active card provider
   * @return {*}
   */
  getCardProvider() {
    const { providers } = this.props;
    // If the config has not yet been fetched, then return null as we don't want providers from old sessions.
    if (this.state.fetchedConfig === false) {
      return null;
    }

    let cardProvider = null;

    // Find card providers in the returned available providers
    providers.availableProviders.forEach((provider) => {
      if (typeof cardProviders[provider] !== 'undefined') {
        cardProvider = cardProviders[provider];
      }
    });

    return cardProvider;
  }

  /**
   * nonCardLoadingStatusUpdateWrapper
   *
   * Allow us to pass 'this' to our shared function
   * @param thisProviderName string
   * @param thisProviderStatus string
   */
  nonCardLoadingStatusUpdateWrapper(thisProviderName, thisProviderStatus) {
    nonCardLoadingStatusUpdate(thisProviderName, thisProviderStatus, this);
  }

  /**
   * Render non-card providers
   *
   * Renders ALL available non-card options,
   * not purely the first available (as per renderCardProviders)
   * @return {*}
   */
  renderNonCardProviders() {
    const { providers: { availableProviders } } = this.props;

    // If the config has not yet been fetched, then return null as we don't want providers from old sessions.
    if (this.state.fetchedConfig === false) {
      return null;
    }

    return (
      <Fragment>
        { availableProviders.indexOf(paymentProviders.BRAINTREE_GOOGLEPAY) !== -1 &&
          <GooglePayButton
            onError={() => this.onPaymentFailure()}
            onSuccess={() => this.onPaymentSuccess()}
            nonCardLoadingStatusUpdate={(name, status) => this.nonCardLoadingStatusUpdateWrapper(name, status)}
          />
        }

        { availableProviders.indexOf(paymentProviders.BRAINTREE_APPLEPAY) !== -1 &&
        <ApplePayButton
          onError={() => this.onPaymentFailure()}
          onSuccess={() => this.onPaymentSuccess()}
          nonCardLoadingStatusUpdate={(name, status) => this.nonCardLoadingStatusUpdateWrapper(name, status)}
        />
        }
      </Fragment>
    );
  }

  /**
   * Render card providers
   * @return {*}
   */
  renderCardProviders() {
    const { providers: { availableProviders } } = this.props;

    if (availableProviders.indexOf(paymentProviders.STRIPE) !== -1) {
      return (<Stripe
        onSuccess={() => this.onPaymentSuccess()}
        onFailure={() => this.onPaymentFailure()}
      />);
    }

    if (availableProviders.indexOf(paymentProviders.BRAINTREE) !== -1) {
      return (<Braintree
        onSuccess={() => this.onPaymentSuccess()}
        onFailure={() => this.onPaymentFailure()}
      />);
    }

    return null;
  }

  /**
   * On PaymentForm render
   * @return {*}
   */
  render() {
    const { application, recaptcha, providers, payment } = this.props;
    const { nonCardLoadingStatuses } = this.state;
    const cardProvider = this.getCardProvider();

    // Make sure we've got at least one non-card provider available to use
    const currentNonCardProviders = getNonCardProviders(this.props.providers, paymentProviders);
    const nonCardProvidersAreAvailable = currentNonCardProviders !== null && currentNonCardProviders.length > 0;

    // Cache all of the non-card loading statuses
    const anyLoading = Object.values(nonCardLoadingStatuses).indexOf('loading') > -1;
    const providerStatusesAreEmpty = Object.values(nonCardLoadingStatuses).length < 1;

    // Only show the loader if we've definitely got SOME noncard providers enabled, but also when
    // we have a 'loading' flag OR we're still waiting on any status updates from the child components
    const showLoader = nonCardProvidersAreAvailable && (anyLoading || providerStatusesAreEmpty);

    return (
      <main role="main">
        <section className="paragraph--full-height-single-image-single-copy payment-page">
          <div
            className="form__step form__step--payment fhsisc__text-wrapper bg--transparent form__light--bg"
            style={{ minHeight: '50vh' }}
          >

            {/* Prompt user to choose a payment method */}
            {(!payment.userHasChosenCardPayment) &&
              <div>
                <h1 className="form__subtitle">Choose a payment method:</h1>

                <Recaptcha action="payment_mode" />

                { ((cardProviders !== null && recaptcha.tokenV3) || (application.dependencies.reCaptcha === false && application.dependencies.reCaptcha_frontend === false && providers.client)) &&
                  <button
                    id="comicrelief_payinbundle_payment_card"
                    className={`btn form__next cta ${application.isPaymentPage && 'button-black'} `}
                    type="submit"
                    aria-label="Pay by card"
                    onClick={() => store.dispatch(paymentActions.setUserHasChosenCardPayment(true))}
                  >Pay by card
                  </button>
                }

                {/* Render non-card providers once ready */}
                { (nonCardProvidersAreAvailable) &&
                  this.renderNonCardProviders()
                }


                {/* While the above is rendering, use the state (set via nonCardLoadingStatusUpdate callback in provider components) to determine if anything's loading */}
                { ((!recaptcha.tokenV3 && providers.client === null) || showLoader) &&
                <div className="loader-container">
                  <span className="loader" />
                </div>
                }

              </div>
            }

            {/* Only render the card provider once user has made this choice */}
            { (payment.userHasChosenCardPayment) &&
            <form
              name="comicrelief_payinbundle_payment"
              onSubmit={(e) => { this.onSubmit(e); }}
              method="post"
              noValidate="novalidate"
              className="has-validation-callback"
            >
              { cardProvider === null &&
              <div className="loader-container">
                <p>Fetching payment data</p>
                <span className="loader" />
              </div>
              }
              { cardProvider !== null &&
              <Fragment>
                <h1 className="form__subtitle">Please enter your payment details</h1>

                {/* add this condition to avoid displaying 2 recaptcha
                components when the transaction failed
                */}
                {!payment.paymentIsFailed &&
                <Recaptcha action="payment_mode" />
                }

                {this.renderCardProviders()}
              </Fragment>
              }
            </form>
            }
          </div>
        </section>
      </main>
    );
  }
}

export default connect(
  ({ application, donationForm, payment, providers, recaptcha }) => ({ application, recaptcha, donationForm, payment, providers }),
  dispatch => bindActionCreators(
    Object.assign({}, addressActions, applicationActions, donationFormActions, paymentActions),
    dispatch,
  ),
)(PaymentForm);
