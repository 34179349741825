import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import InputField from '@comicrelief/storybook/src/components/InputField/InputField';
import CheckboxField from '@comicrelief/storybook/src/components/CheckboxField/CheckboxField';
import SelectField from '@comicrelief/storybook/src/components/SelectField/SelectField';
import JustInTime from '@comicrelief/storybook/src/components/JustInTime/JustInTime';
import directDebitLogo from '../../../../public/images/direct-debit-logo--white.svg';
import goCardlessLogo from '../../../../public/images/gocardless-logo--white.svg';
import * as paymentActions from '../../../Actions/paymentActions';
import ErrorDisplayService from '../../../Service/ErrorDisplay.service';
import { steps } from './Gocardless';
import PaymentServiceRouter, { ROUTES } from '../../../Service/PaymentServiceRouter.service';
import { amountFormatter } from '../../../Helpers/_Helpers';

import './GoCardless.scss';

/**
 * AccountDetails component
 */
class AccountDetails extends Component {
  /**
   * AccountDetails constructor
   */
  constructor(props) {
    super(props);
    this.errorDisplay = new ErrorDisplayService();
    this.state = {
      errorMessage: '',
      loading: false,
      showErrors: false,
    };
    this.componentIsMounted = true;
  }

  /**
   * AccountDetails componentWillMount
   */
  componentWillMount() {
    const { updateFieldsValidation, updateData, payment: { data: { accountHolder }, validation } } = this.props;
    if (typeof validation.sortCode === 'undefined' || typeof validation.accountNumber === 'undefined'
      || typeof validation.billingDayOfMonth === 'undefined') {
      updateFieldsValidation({
        sortCode: {
          valid: null,
          message: '',
        },
        accountNumber: {
          valid: null,
          message: '',
        },
        billingDayOfMonth: {
          valid: null,
          message: '',
        },
      });
    }
    if (typeof accountHolder === 'undefined') {
      updateData('accountHolder', true);
    }
  }

  /**
   * AccountDetails componentDidMount
   */
  componentDidMount() {
    this.componentIsMounted = true;
  }

  /**
   * AccountDetails componentWillUnmount
   */
  componentWillUnmount() {
    this.componentIsMounted = false;
  }

  /**
   * On button click
   * @param {Object} event
   */
  async onButtonClick(event) {
    event.preventDefault();
    const { application, updateStep, updateData, payment: { isValid, data: { sortCode, accountNumber } } } = this.props;

    if (isValid === false && this.componentIsMounted === true) {
      return this.setState({ showErrors: true }, () => this.errorDisplay.setScrollToErrorTimeout());
    }

    if (this.componentIsMounted === true) {
      this.setState({
        errorMessage: '',
        loading: true,
      });
    }

    const response = await fetch(PaymentServiceRouter.get(ROUTES.provider.goCardless.verifyBank), {
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        client: application.client,
        sortCode,
        accountNumber,
      }),
    });

    if (response.status !== 200 && this.componentIsMounted === true) {
      this.setState({
        errorMessage: 'Your bank account could not be validated, please check the details and try again.',
        loading: false,
      });

      return false;
    }

    const responseData = await response.json();

    updateData('bankName', responseData.data.bank);
    updateStep(steps.ACCOUNT_DETAILS_CONFIRMATION);
    return true;
  }

  /**
   * Updates state with value and validity from child.
   * @param valid
   * @param name
   */
  setValidity(valid, name) {
    const { payment, updateData, updateFieldValidation } = this.props;
    if (payment.data[name] !== valid.value || payment.validation[name].message !== valid.message) {
      updateData(name, valid.value);
      updateFieldValidation(name, {
        valid: valid.valid,
        message: valid.message,
        showErrorMessage: valid.showErrorMessage,
      });
    }
  }

  /**
   * Change account holder state
   * @param accountHolder
   */
  changeAccountHolder({ target: { checked: accountHolder } }) {
    const { updateFieldValidation, updateData } = this.props;
    const validation = accountHolder ? undefined : { valid: null, message: '' };
    const accountHolderName = accountHolder ? undefined : '';

    if (this.componentIsMounted === true) {
      this.setState({ showErrors: false });
    }

    updateFieldValidation('accountHolderName', validation);
    updateData('accountHolder', accountHolder);
    updateData('accountHolderName', accountHolderName);
  }

  renderInputs() {
    const { showErrors } = this.state;
    const { payment: { data, validation } } = this.props;
    const inputs = [
      {
        id: 'accountHolderName',
        label: 'Account holder name',
        type: 'text',
        shouldRender: !data.accountHolder,
      },
      { id: 'sortCode', label: 'Sort code', type: 'text' },
      { id: 'accountNumber', label: 'Account number', type: 'text' },
    ];
    return inputs
      .filter(item => item.shouldRender !== false)
      .map(item => (
        <InputField
          key={item.id}
          label={item.label}
          name={item.id}
          id={item.id}
          required
          type={item.type}
          placeholder={item.placeholder}
          ref={element => this.errorDisplay.setRef(element)}
          showErrorMessage={showErrors}
          isValid={(valid, name) => { this.setValidity(valid, name); }}
          fieldValue={{ ...validation[item.id], showErrorMessage: showErrors, value: data[item.id] || '' }}
        />
      ));
  }

  renderSelect() {
    const { showErrors } = this.state;
    const { payment: { data } } = this.props;

    // Our prescribed days of the month, see https://comicrelief.atlassian.net/browse/ENG-3423
    const dateOptions = [1, 5, 15, 20, 28];
    // Start with the 1st of the month:
    let currentDateIndex = 0;

    // Convert one day to milliseconds to use for MATHS
    const oneDay = 24 * 60 * 60 * 1000;

    // Set current date (using epoch time)
    const currentDate = Date.now();

    // Set default full date, based on NEXT month:
    const defaultDate = new Date(new Date().getFullYear(), new Date().getMonth() + 1, dateOptions[currentDateIndex]);

    // Calc the difference between these 2 dates:
    const diffInDays = Math.round(Math.abs((currentDate - defaultDate.getTime()) / (oneDay)));

    // If the default billing date is in LESS than 3 days time from now, use the
    // next option in the array (looping around if we're already at the end):
    if (diffInDays < 3) {
      currentDateIndex = (currentDateIndex < dateOptions.length - 1 ? currentDateIndex + 1 : 0);
    }

    const optionsArray = [
      {
        label: 'Please select',
        value: '',
        selected: typeof dateOptions[currentDateIndex] === 'undefined' && data.billingDayOfMonth === '',
      },
    ];

    for (let i = 0; i < dateOptions.length; i += 1) {
      const date = dateOptions[i].toString();
      const label = `${date}${this.props.nth(dateOptions[i])}`;

      if (date === dateOptions[currentDateIndex].toString() && (typeof data.billingDayOfMonth === 'undefined'
        || data.billingDayOfMonth === '')) {
        // Select default date
        optionsArray.push({ label, value: date, selected: true });
      } else { // Or select selected date
        optionsArray.push({ label, value: date, selected: data.billingDayOfMonth === date });
      }
    }

    return (
      <SelectField
        id="billingDayOfMonth"
        name="billingDayOfMonth"
        label="Select Billing Day of Month"
        showErrorMessage={showErrors}
        required
        options={optionsArray}
        isValid={(valid, name) => { this.setValidity(valid, name); }}
      />
    );
  }

  /**
   * AccountDetails component render
   * @return {*}
   */
  render() {
    if (this.state.loading === true) {
      return (
        <div className="loader-container">
          <span className="loader" />
        </div>
      );
    }

    const { donationForm: { amount, currency: { symbol } }, payment: { isValid, data: { accountHolder } } } = this.props;

    const buttonCopy = `Donate ${symbol}${amountFormatter(amount)} a month`;

    return (
      <div>
        <div className="form__row form__row__full_width form__row--your--account">

          <div className="form__fieldset">
            <h1 className="form__subtitle">Your account details</h1>

            {this.state.errorMessage !== '' &&
              <span className="help-block form-error">
                {this.state.errorMessage}
              </span>
            }

            <CheckboxField
              type="checkbox"
              id="accountHolder"
              name="accountHolder"
              label="I'm the account holder"
              additionalText=""
              setBackgroundColor={false}
              checked={accountHolder || false}
              value={accountHolder ? 1 : 0}
              handleCheckboxChange={e => this.changeAccountHolder(e)}
            />
            {this.renderInputs()}
            {this.renderSelect()}

          </div>
        </div>
        <JustInTime linkText="This payment is safeguarded by the Direct Debit Guarantee" className="just-in-time--dd-text">
          <p>
            The Direct Debit guarantee is offered by all Banks and Building Societies that take part in the Direct Debit Scheme.
            The efficiency and security of the scheme is monitored and protected by your own Bank or Building Society.
            If the amounts to be paid or the payment dates change, <strong>GC re Comic Relief</strong> will notify you ten working days in advance of your account being debited or as otherwise agreed.
            If an error is made by <strong>GC re Comic Relief</strong> or your Bank or Building Society, you are guaranteed a full and immediate refund from your branch of the amount paid.
            You can cancel a Direct Debit at any time by writing to your Bank or Building Society.
            Please also send a copy of your letter to us.
          </p>
        </JustInTime>
        <div className="form__row form__row__full_width">
          <div className="form__fieldset">
            <p>
              By continuing I confirm I am the authorised signatory on my nominated bank or building society account.
            </p>
          </div>
        </div>
        <div className="form__row form__row__full_width">
          <div className="form__fieldset">
            <div className="form__field--wrapper form__submit form__field--submit">
              <button
                type="submit"
                className={`btn cta ${!isValid ? 'invalid' : ''}`}
                onClick={event => this.onButtonClick(event)}
                id="comicrelief_payinbundle_payment_submit"
                name="comicrelief_payinbundle_payment[submit]"
              >
                {buttonCopy}
              </button>
            </div>
          </div>
        </div>
        <div className="form__row form__row__full_width form__row--recurring-payment-logos">
          <div className="recurring-payment-logos-wrapper">
            <img className="recurring-payment-logo direct-debit-logo" alt="direct debit logo" src={directDebitLogo} />
            <img className="recurring-payment-logo gocardless-logo" alt="go cardless logo" src={goCardlessLogo} />
            <p className="p__gocardless_strap_line">Payments powered by GoCardless</p>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  ({ application, payment, donationForm }) => ({ application, payment, donationForm }),
  dispatch => bindActionCreators(Object.assign({}, paymentActions), dispatch),
)(AccountDetails);
