import React, { useState, useEffect } from 'react';
import confettiAnimation from './confettiAnimation';
/**
 * Confetti component
 */
const Confetti = () => {
  const [animate, setAnimate] = useState(true);

  useEffect(() => {
    // Run confetti animation if animate is true
    if (animate) {
      confettiAnimation();
    }

    // Set a timer to stop the animation after 5 seconds
    const timer = setTimeout(() => {
      if (animate) {
        setAnimate(false);
      }
    }, 5000);

    // Always return a cleanup function to clear the timeout
    // This will ensure consistent return value for the arrow function in useEffect
    return () => clearTimeout(timer);
  }, [animate]); // Dependency array includes animate to re-run the effect if it changes

  return (
    <div>
      {animate && <canvas id="confetti" className="is-animating" />}
    </div>
  );
};

export default Confetti;
