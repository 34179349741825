/* eslint-disable no-trailing-spaces */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as payInFormActions from '../../Actions/payInFormActions';

import FullHeightSingleImage from '../../Component/FullHeightSingleImage/FullHeightSingleImage';
import ErrorMessage from '../../Component/ErrorMessage/ErrorMessage';

import BackButton from '../../Component/BackButton/BackButton';
import AmountAndGivingTypeHeader from '../../Component/AmountAndGivingTypeHeader/AmountAndGivingTypeHeader';

import CartService from '../../Service/Cart.service';
import { ROUTES } from '../../Service/PaymentServiceRouter.service';

import ErrorDisplayService from '../../Service/ErrorDisplay.service';

import audienceData from './data/audienceData';
import SiteService from '../../Service/Site.service';

export const MYSELF_FRIENDS_OR_FAMILY = 'public';
export const NURSERY_OR_SCHOOL = 'school';
export const ORGANISATION_OR_WORKPLACE = 'work';
export const YOUTH_GROUP_OR_UNIVERSITY = 'youth';

/**
 * PayInAudience Component
 */
class PayInAudience extends Component {
  /**
   * PayInAudience constructor
   */
  constructor(props) {
    super(props);
    this.errorDisplay = new ErrorDisplayService();
    this.siteService = new SiteService();
    this.state = {
      showError: false,
    };
  }

  /**
   * PayInAudience componentDidMount
   */
  componentDidMount() {
    // Ensure user is following the journey linearly
    const prevStep = this.props.payInForm.currentPayInStep;
    const thisStep = 0;

    if (prevStep > thisStep + 1 || prevStep < thisStep - 1 || prevStep === null) {
      return this.props.history.push({ pathname: '/' });
    }

    document.title = `Pay-In: Audience${this.props.application.page.titlePostfix}`;

    // Else, Update form progress, zero-index style
    return this.props.updateCurrentPayInStep(thisStep);
  }

  /**
   * onSubmit handle erroring state or page change on submission
   * @param event
   * @param payInAudienceType
   */
  onSubmit(e, payInAudienceType) {
    e.preventDefault();

    if (payInAudienceType !== null) {
      // If we've selected a type, next page
      return this.props.history.push({ pathname: `/${ROUTES.payIn.activity}` });
    }

    // Else, show the form error and scroll to top of fieldset
    this.setState({ showError: true });
    return this.errorDisplay.setScrollToErrorTimeoutMarkII();
  }

  /**
   * goBack to previous page
   * @param event
   */
  goBack(event = null) {
    if (event) event.preventDefault();
    this.props.history.push({ pathname: '/' });
  }

  /**
   * Handle Option Changes
   * @param changeEvent
   */
  handleOptionChange(changeEvent) {
    this.setState({ showError: false });
    this.props.updatePayInAudienceType(changeEvent.target.value);
    // Reset any associated activities to avoid an audience-activity mismatch if navigating back and forth
    this.props.resetPayInActivities();
  }

  /**
   * On PayInAudience render
   * @return {*}
   */
  render() {
    const {
      payInForm: { payInAudienceType },
      application,
      isMobile,
      donationForm: { givingType },
    } = this.props;

    const cart = new CartService(application.cartId);

    const { desktop, alt } = cart.config.header[givingType];

    const numberOfAudienceTypes = Object.keys(audienceData).length;

    return (
      <main role="main">
        <section
          className="paragraph--full-height-single-image-single-copy bg--blue-2023 activity-preload paragraph--payin"
          ref={element => this.errorDisplay.setRef(element)}
        >
          {!isMobile && <FullHeightSingleImage
            alt={alt}
            desktop={desktop}
          /> }

          <div className="form__step fhsisc__text-wrapper">
            <form
              name="comicrelief_payinbundle_audience"
              onSubmit={(e) => { this.onSubmit(e, payInAudienceType); }}
              method="post"
              noValidate="novalidate"
              className="has-validation-callback"
            >
              <div className="form__header">
                <BackButton id="audience" handleClick={(e) => { this.goBack(e); }} ariaLabel="Back to donation type" />
                <AmountAndGivingTypeHeader />
              </div>

              <div className="form__step form__step--audience">
                <div className="form__row form__row--audience">
                  <div className="form__fieldset">
                    <h1 className="form__subtitle select-label text-align-center">
                      Who are you paying in for?
                    </h1>
                  </div>
                </div>

                <fieldset
                  id="payin_audience_selector"
                  className={(this.state.showError ? 'error' : '') + (payInAudienceType !== null ? ' js-selected' : '')}
                >
                  <legend className="visuallyhidden">Who are you paying in for?</legend>
                  <div className={'form__payin_audience form__payin_audience--' + numberOfAudienceTypes}>
                    {/* Create radio button for each audience */}
                    {Object.keys(audienceData).map(audienceType => (
                      <div
                        id={audienceData[audienceType].id + '--wrapper'}
                        className={'form__field--wrapper ' + (payInAudienceType === audienceType ? 'js-checked' : '')}
                        key={audienceType}
                      >
                        <label htmlFor={audienceData[audienceType].id}>
                          <input
                            id={audienceData[audienceType].id}
                            name="payin_audience_selector"
                            type="radio"
                            value={audienceType}
                            checked={payInAudienceType === audienceType}
                            onChange={(e) => { this.handleOptionChange(e); }}
                          />
                          <span>{audienceData[audienceType].label}</span>
                        </label>
                      </div>
                    ))}
                  </div>

                  {this.state.showError === true &&
                    <ErrorMessage id="audience" copy="Please choose who you are paying in for" />
                  }
                </fieldset>

                <div className="form__fieldset form__payin-audience-submit submit-btn-wrapper">
                  <button
                    id="comicrelief_payinbundle_audience_submit"
                    type="submit"
                    name="comicrelief_payinbundle_audience[submit]"
                    className={'btn form__next cta ' + (payInAudienceType === null ? 'disabled' : '')}
                  >
                    Continue to activities
                  </button>
                </div>

              </div>
            </form>
          </div>
        </section>
      </main>
    );
  }
}

export default connect(
  ({ application, payInForm, donationForm }) => ({ application, payInForm, donationForm }),
  dispatch => bindActionCreators(
    Object.assign({}, payInFormActions),
    dispatch,
  ),
)(PayInAudience);
