import UrlService from './Url.service';
import PaymentServiceRouter, { ROUTES } from './PaymentServiceRouter.service';

export const paymentProviders = {
  BRAINTREE: 'BRAINTREE',
  BRAINTREE_ASYNC: 'BRAINTREE_ASYNC',
  BRAINTREE_GOOGLEPAY: 'BRAINTREE_GOOGLEPAY',
  BRAINTREE_APPLEPAY: 'BRAINTREE_APPLEPAY',
  GOCARDLESS: 'GOCARDLESS',
  PAYPAL: 'PAYPAL',
  STRIPE: 'STRIPE',
};

export const cardProviders = {
  BRAINTREE: 'BRAINTREE',
  BRAINTREE_ASYNC: 'BRAINTREE_ASYNC',
  STRIPE: 'STRIPE',
};

/**
 * PaymentConfigurationService class
 */
export default class PaymentConfigurationService {
  /**
   * Get the application fallback client
   * @return {*|process.env.REACT_APP_CLIENT|string}
   */
  static getFallbackClient() {
    const clientOverride = UrlService('clientOverride', null);
    return clientOverride !== null ? clientOverride : process.env.REACT_APP_CLIENT;
  }

  /**
   * PaymentConfigurationService constructor
   */
  constructor(client = null) {
    this.client = client;

    if (this.client === null) {
      this.client = PaymentConfigurationService.getFallbackClient();
    }
  }

  /**
   * Get Configuration
   * @return {Promise<*>}
   */
  async get() {
    const providersOverride = UrlService('providers');

    if (providersOverride) {
      return this.overrideProviders(providersOverride);
    }

    const responseData = await this.getConfiguration();

    const availableProviders = {};
    const providersConfiguration = {};

    Object.keys(responseData.provider).forEach((donationType) => {
      if (typeof availableProviders[donationType] === 'undefined') {
        availableProviders[donationType] = [];
        providersConfiguration[donationType] = {};
      }

      Object.keys(responseData.provider[donationType]).forEach((providerKey) => {
        availableProviders[donationType].push(providerKey);
        providersConfiguration[donationType][providerKey] = responseData.provider[donationType][providerKey];
      });
    });

    const redirect = responseData.redirect;

    PaymentConfigurationService.logProviders(availableProviders);

    return {
      recentDonors: responseData.recent_donors,
      providers: availableProviders,
      providersConfiguration,
      redirect,
      client: this.client,
      dependencies: typeof responseData.dependencies !== 'undefined' ? responseData.dependencies : {},
    };
  }

  /**
   * Get the application client
   * @return {*|process.env.REACT_APP_CLIENT|string}
   */
  getClient() {
    return this.client;
  }

  /**
   * Get configuration from payment service
   * @param getAllProviders
   * @return {Promise<any>}
   */
  async getConfiguration(getAllProviders = false) {
    const allProviders = getAllProviders === true ? 1 : 0;
    const response = await fetch(`${PaymentServiceRouter.get(ROUTES.global.configuration)}?client=${this.client}&all-providers=${allProviders}`);
    const json = await response.json();
    return json.data;
  }

  /**
   * Manually override providers
   * @param providersOverride
   */
  async overrideProviders(providersOverride) {
    const overriddenProviders = providersOverride.toUpperCase().split(',');

    const allProvidersConfiguration = await this.getConfiguration(true);

    const availableProviders = {
      single: [],
      monthly: [],
    };
    const availableProvidersConfiguration = {
      single: {},
      monthly: {},
    };

    overriddenProviders.forEach((provider) => {
      if (typeof allProvidersConfiguration.provider.single[provider] !== 'undefined') {
        availableProvidersConfiguration.single[provider] = allProvidersConfiguration.provider.single[provider];
        availableProviders.single.push(provider);
      }
      if (allProvidersConfiguration.provider.monthly && typeof allProvidersConfiguration.provider.monthly[provider] !== 'undefined') {
        availableProvidersConfiguration.monthly[provider] = allProvidersConfiguration.provider.monthly[provider];
        availableProviders.monthly.push(provider);
      }
    });

    PaymentConfigurationService.logProviders(availableProviders);

    return {
      providers: availableProviders,
      providersConfiguration: availableProvidersConfiguration,
      redirect: allProvidersConfiguration.redirect,
      client: this.client,
      dependencies: allProvidersConfiguration.dependencies,
      recentDonors: [],
    };
  }

  /**
   * Log available providers to console
   * @param providers
   */
  static logProviders(providers) {
    console.log('Available Providers', providers);
  }
}
