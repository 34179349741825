export const LOADED_PROVIDERS = 'LOADED_PROVIDERS';
export const LOADED_PROVIDER_SCRIPT = 'LOADED_PROVIDER_SCRIPT';
export const LOADED_PROVIDER_DATA = 'LOADED_PROVIDER_DATA';
export const LOADING_PROVIDER_DATA_STATUS = 'LOADING_PROVIDER_DATA_STATUS';
export const ADD_LOADING_SCRIPT = 'ADD_LOADING_SCRIPT';
export const ADD_LOADED_SCRIPT = 'ADD_LOADED_SCRIPT';
export const RESET_PROVIDERS_STATE = 'RESET_PROVIDERS_STATE';
export const REMOVE_LOADING_SCRIPT = 'REMOVE_LOADING_SCRIPT';

/**
 * Resets the payment state
 * @return {{type: string}}
 */
export function resetProvidersState() {
  return {
    type: RESET_PROVIDERS_STATE,
  };
}

/**
 * Add loading provider scripts
 * @param provider
 * @returns {{type: string, provider: *}}
 */
export function addLoadingScript(provider) {
  return {
    type: ADD_LOADING_SCRIPT,
    provider,
  };
}

/**
 * Add loaded provider scripts
 * @param provider
 * @returns {{type: string, provider: *}}
 */
export function addLoadedScript(provider) {
  return {
    type: ADD_LOADED_SCRIPT,
    provider,
  };
}

/**
 * Update Loaded Providers
 * @param providers
 * @param client
 * @return {{type: string, providers: *, client: *}}
 */
export function loadedProviders(providers, client) {
  return {
    type: LOADED_PROVIDERS,
    providers,
    client,
  };
}

/**
 * Update Provider Script
 * @param provider
 * @return {{type: string, provider: *}}
 */
export function loadedProviderScript(provider) {
  return {
    type: LOADED_PROVIDER_SCRIPT,
    provider,
  };
}

/**
 * Update loaded provider data
 * @param provider
 * @param key
 * @param data
 * @return {{type: string, provider: *, key: *, data: *}}
 */
export function loadedProviderData(provider, key, data) {
  return {
    type: LOADED_PROVIDER_DATA,
    provider,
    key,
    data,
  };
}

/**
 * Update loading provider
 * @param provider
 * @param key
 * @param status
 * @return {{type: string, provider: *, key: *, status: boolean}}
 */
export function loadingProviderData(provider, key, status = true) {
  return {
    type: LOADING_PROVIDER_DATA_STATUS,
    provider,
    key,
    status,
  };
}

/**
 * Remove loading provider script
 * @param provider
 * @returns {{type: string, provider: *}}
 */
export function removeLoadingScript(provider) {
  return {
    type: REMOVE_LOADING_SCRIPT,
    provider,
  };
}
