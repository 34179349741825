import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as applicationActions from '../../Actions/applicationActions';
import * as payInFormActions from '../../Actions/payInFormActions';
import { ROUTES } from '../../Service/PaymentServiceRouter.service';
import FullHeightSingleImage from '../../Component/FullHeightSingleImage/FullHeightSingleImage';
import PaymentButtons from '../../Component/PaymentButtons/PaymentButtons';
import BackButton from '../../Component/BackButton/BackButton';
import AmountAndGivingTypeHeader from '../../Component/AmountAndGivingTypeHeader/AmountAndGivingTypeHeader';
import audienceImages from './data/audienceImages';

/**
 * PayInPaymentMethod class
 */
class PayInPaymentMethod extends Component {
  /**
   * PayInPaymentMethod constructor
   * @param props
   */
  constructor(props) {
    super(props);

    this.state = {
      errorResponse: false,
    };
  }

  /**
   * PayInPayment componentWillMount
   */
  componentDidMount() {
    const {
      updateSuccessURL,
      updateFailureURL,
      payment: {
        paymentIsFailed,
      },
    } = this.props;


    // Ensure user is following the journey linearly
    const prevStep = this.props.payInForm.currentPayInStep;
    const thisStep = 4;

    // Allow step-check to include user being bounced back here following an unsuccessful payment
    if ((paymentIsFailed === false) &&
      (prevStep > thisStep + 1 || prevStep < thisStep - 1 || prevStep === null)) {
      return this.props.history.push({ pathname: '/' });
    }

    document.title = `Pay-In: Payment Method${this.props.application.page.titlePostfix}`;

    // Update form progress
    this.props.updateCurrentPayInStep(thisStep);

    // But also update the Donate 'current page' step, as required by loadDataProviders in application saga
    // this.props.updateCurrentPageStep(1);

    // Update application URLS to PayIn-specific page URLs
    const baseUrl = window.location.protocol + '//' + window.location.host + '/';
    updateSuccessURL(`${baseUrl}${ROUTES.payIn.success}`);
    updateFailureURL(`${baseUrl}${ROUTES.payIn.failure}`);

    return null;
  }

  /**
   * Go back to previous page
   * @param event
   */
  goBack(event = null) {
    if (event) event.preventDefault();

    const siteUrl = this.props.application.siteUrl;

    // Redirect users to where they come from.
    if (siteUrl !== null) {
      sessionStorage.clear();
      window.location.href = siteUrl;
    } else this.props.history.push({ pathname: `/${ROUTES.payIn.postalAddress}` });
  }

  /**
   * Update error message
   * Used by paypal and apple pay
   * @param message
   */
  updateErrorMessage(message) {
    return this.setState({
      errorResponse: message,
    }, (() => {
        window.scrollTo(0, 0);
      }));
  }

  /**
   * PayInPayment render
   * @return {*}
   */
  render() {
    if (this.props.application.providers === null) {
      return null;
    }

    const { isMobile, payInForm: { payInAudienceType },
    } = this.props;

    return (
      <main role="main">
        <section className="paragraph--full-height-single-image-single-copy bg--blue-2023 paragraph--payin">

          {!isMobile && <FullHeightSingleImage
            alt={audienceImages[payInAudienceType]}
            desktop={audienceImages[payInAudienceType]}
          /> }

          <div className="form__step form__step--payment  fhsisc__text-wrapper bg--transparent">
            <form
              name="comicrelief_payinbundle_payment"
              method="post"
              onSubmit={(e) => { e.preventDefault(); }}
              noValidate="novalidate"
              className="has-validation-callback"
            >
              <div className="form__header">
                <BackButton id="postal-address" handleClick={(e) => { this.goBack(e); }} ariaLabel="Back to your postal address" />
                <AmountAndGivingTypeHeader />
              </div>

              <h1 className="form__subtitle">Payment method</h1>

              <PaymentButtons history={this.props.history} updateErrorMessage={(message) => { this.updateErrorMessage(message); }} />
            </form>
          </div>
        </section>
      </main>
    );
  }
}

function mapStateToProps(state) {
  return {
    application: state.application,
    payment: state.payment,
    payInForm: state.payInForm,
  };
}

function mapDispachToProps(dispatch) {
  return bindActionCreators(Object.assign({}, applicationActions, payInFormActions), dispatch);
}

export default connect(mapStateToProps, mapDispachToProps)(PayInPaymentMethod);
