import { v4 as uuidv4 } from 'uuid';

import {
  RESET_PAYIN_FORM_STATE,
  UPDATE_PAYIN_AUDIENCE_TYPE,
  UPDATE_PAYIN_ACTIVITY_VALIDITY,
  UPDATE_IS_MODAL_OPEN,
  ADD_ACTIVITY,
  REMOVE_ACTIVITY,
  UPDATE_PAYIN_DETAILS,
  UPDATE_PAYIN_YOUR_DETAILS_VALIDITY,
  UPDATE_PAYIN_ALL_ADDRESS_VALIDITY,
  UPDATE_USE_POSTAL_ADDRESS_AS_BILLING_ADDRESS,
  UPDATE_ALL_PAYIN_ADDRESSES,
  UPDATE_SHOW_BILLING_ADDRESS_ERROR,
  OVERWRITE_BILLING_ADDRESS,
  RESET_PAYIN_MARKETING_PREFS_STATE,
  UPDATE_PAYIN_PERMISSION_EMAIL,
  UPDATE_PAYIN_PERMISSION_POST,
  UPDATE_PAYIN_PERMISSION_PHONE,
  UPDATE_PAYIN_PERMISSION_SMS,
  RESET_PAYIN_PERMISSION_SMS,
  UPDATE_PAYIN_MARKETING_ERROR_MESSAGE,
  UPDATE_PAYIN_MARKETING_VALIDITY,

  PAYIN_MARKETING_CONSENT_COMPLETE,
  PAYIN_MARKETING_CONSENT_NOT_COMPLETE,
  PAYIN_MARKETING_CONSENT_SUBMIT_DATA,
  UPDATE_PAYIN_MARKETING_CONSENT_PHONE_NUMBERS,

  UPDATE_PAYIN_FORCE_FORM_ERRORS,
  RESET_PAYIN_ACTIVITIES,
  SET_ACTIVITY_ERROR_MESSAGE,
  UPDATE_CURRENT_PAYIN_STEP,
  UPDATE_IS_SUGGESTED_ORG_NAME,
} from '../Actions/payInFormActions';

const defaultState = {
  currentPayInStep: null,
  payInAudienceType: null,
  isActivityFormValid: false,
  showActivityError: null,
  activityErrorMessage: null,
  isModalOpen: false,
  showBillingAddressError: false,
  payInActivities: {},
  isPayInYourDetailsValid: false,
  payInDetailsValidation: {
    firstName: { valid: '', message: '', value: '', showErrorMessage: false },
    lastName: { valid: '', message: '', value: '', showErrorMessage: false },
    email: { valid: '', message: '', value: '', showErrorMessage: false },
  },
  usePostalAddressAsBillingAddress: false,
  allAddressValidity: {
    isPayInAddressValid: false,
    isPayInBillingAddressValid: false,
    isPayInSchoolAddressValid: false,
  },
  allAddressValidation: {
    payInAddressValidation: {
      postcode: { valid: null, message: '', value: '', showErrorMessage: false },
      address1: { valid: null, message: '', value: '', showErrorMessage: false },
      address2: { valid: true, message: '', value: '', showErrorMessage: false },
      address3: { valid: true, message: '', value: '', showErrorMessage: false },
      town: { valid: null, message: '', value: '', showErrorMessage: false },
      country: { valid: true, message: '', value: 'GB', showErrorMessage: false },
      // Both only used for organisation/workplace
      organisationName: { valid: null, message: 'This field is required', value: '', showErrorMessage: false, required: true },
      jobTitle: { valid: true, message: '', value: '', showErrorMessage: false, required: false },

    },
    payInBillingAddressValidation: {
      postcode: { valid: null, message: '', value: '', showErrorMessage: false },
      address1: { valid: null, message: '', value: '', showErrorMessage: false },
      address2: { valid: true, message: '', value: '', showErrorMessage: false },
      address3: { valid: true, message: '', value: '', showErrorMessage: false },
      town: { valid: null, message: '', value: '', showErrorMessage: false },
      country: { valid: true, message: '', value: 'GB', showErrorMessage: false },
    },
    payInSchoolAddressValidation: {
      establishmentName: { valid: null, message: '', value: '', showErrorMessage: false, required: true },
      address1: { valid: null, message: '', value: '', showErrorMessage: false, required: true },
      address2: { valid: null, message: '', value: '', showErrorMessage: false, required: false },
      address3: { valid: null, message: '', value: '', showErrorMessage: false, required: false },
      town: { valid: null, message: '', value: '', showErrorMessage: false, required: true },
      postcode: { valid: null, message: '', value: '', showErrorMessage: false, required: true },
      country: { valid: true, message: '', value: 'GB', showErrorMessage: false, required: true },
      establishmentType: { valid: true, message: '', value: '', showErrorMessage: false, required: true },
      jobTitle: { valid: true, message: '', value: '', showErrorMessage: false, required: true },
      // These fields are simplified as they're only filled programmatically, so don't require normal validation
      establishmentId: { value: '' },
      selectedEstablishment: { value: {} },
    },
  },
  marketingPrefs: {
    validating: false,
    formComplete: false,
    submitData: false,
    formValidity: false,
    showErrorMessages: false,
    permissionEmail: {
      value: null,
      valid: false,
    },
    permissionPost: {
      value: null,
      valid: false,
    },
    permissionPhone: {
      isFieldsHidden: false,
      value: null,
      valid: false,
      fieldValidation: false,
    },
    permissionSMS: {
      isFieldsHidden: false,
      value: null,
      valid: false,
      fieldValidation: false,
    },
    errorMessage: '',
    phoneNumber: '',
    smsNumber: '',
  },
  forceFormErrors: false,
  isSuggestedOrgName: false,
  uuid: uuidv4(),
};

/**
 * PayIn Form Reducer
 * @param state
 * @param action
 * @return {*}
 */
export default function payInForm(state = defaultState, action) {
  switch (action.type) {
    case RESET_PAYIN_FORM_STATE:
      return defaultState;
    case UPDATE_PAYIN_AUDIENCE_TYPE:
      return {
        ...state,
        payInAudienceType: action.payInAudienceType,
      };
    case UPDATE_PAYIN_ACTIVITY_VALIDITY:
      return {
        ...state,
        isActivityFormValid: action.isActivityFormValid,
        showActivityError: action.showActivityError,
      };
    case UPDATE_IS_MODAL_OPEN:
      return {
        ...state,
        isModalOpen: action.isModalOpen,
      };
    case ADD_ACTIVITY: {
      return {
        ...state,
        payInActivities: {
          ...state.payInActivities,
          [action.activityName]: action.activityValue,
        },
      };
    }
    case REMOVE_ACTIVITY: {
      const { [action.activityName]: value, ...remainingActivities } = state.payInActivities;
      return {
        ...state,
        payInActivities: remainingActivities,
      };
    }
    case UPDATE_PAYIN_DETAILS: {
      return {
        ...state,
        payInDetailsValidation: {
          ...state.payInDetailsValidation,
          [action.name]: action.valid,
        },
      };
    }
    case UPDATE_PAYIN_YOUR_DETAILS_VALIDITY:
      return {
        ...state,
        isPayInYourDetailsValid: action.isPayInYourDetailsValid,
      };
    case UPDATE_PAYIN_ALL_ADDRESS_VALIDITY:
      return {
        ...state,
        allAddressValidity: {
          ...state.allAddressValidity,
          [action.name]: action.valid,
        },
      };
    case UPDATE_USE_POSTAL_ADDRESS_AS_BILLING_ADDRESS:
      return {
        ...state,
        usePostalAddressAsBillingAddress: action.usePostalAddressAsBillingAddress,
      };
    case UPDATE_ALL_PAYIN_ADDRESSES:
      return {
        ...state,
        allAddressValidation: {
          ...state.allAddressValidation,
          [action.whichAddress]: {
            ...state.allAddressValidation[action.whichAddress],
            [action.name]: action.valid,
          },
        },
      };
    case UPDATE_SHOW_BILLING_ADDRESS_ERROR:
      return {
        ...state,
        showBillingAddressError: action.showBillingAddressError,
      };
    case OVERWRITE_BILLING_ADDRESS : {
      // Resets to default state if no function parameter is passed
      const thisObj = action.overwriteObject ? action.overwriteObject : defaultState.allAddressValidation.payInBillingAddressValidation;
      return {
        ...state,
        allAddressValidation: {
          ...state.allAddressValidation,
          payInBillingAddressValidation: thisObj,
        },
      };
    }
    case RESET_PAYIN_MARKETING_PREFS_STATE:
      return {
        ...state,
        marketingPrefs: defaultState.marketingPrefs,
      };

    case RESET_PAYIN_PERMISSION_SMS:
      return {
        ...state,
        marketingPrefs: {
          ...state.marketingPrefs,
          permissionSMS: defaultState.permissionSMS,
        },
      };

    case PAYIN_MARKETING_CONSENT_COMPLETE: {
      return {
        ...state,
        marketingPrefs: {
          ...state.marketingPrefs,
          formComplete: true,
        },
      };
    }

    case PAYIN_MARKETING_CONSENT_NOT_COMPLETE: {
      return {
        ...state,
        marketingPrefs: {
          ...state.marketingPrefs,
          formComplete: false,
        },
      };
    }

    case PAYIN_MARKETING_CONSENT_SUBMIT_DATA: {
      return {
        ...state,
        marketingPrefs: {
          ...state.marketingPrefs,
          submitData: true,
        },
      };
    }

    case UPDATE_PAYIN_PERMISSION_EMAIL:
      return {
        ...state,
        marketingPrefs: {
          ...state.marketingPrefs,
          permissionEmail: {
            ...state.permissionEmail,
            checked: action.permissionEmail.checked,
            value: action.permissionEmail.value,
            valid: action.permissionEmail.valid,
            validation: action.permissionEmail.validation,
          },
        },
      };
    case UPDATE_PAYIN_PERMISSION_POST:
      return {
        ...state,
        marketingPrefs: {
          ...state.marketingPrefs,
          permissionPost: {
            ...state.permissionPost,
            checked: action.permissionPost.checked,
            value: action.permissionPost.value,
            valid: action.permissionPost.valid,
            validation: action.permissionPost.validation,
          },
        },
      };
    case UPDATE_PAYIN_PERMISSION_PHONE:
      return {
        ...state,
        marketingPrefs: {
          ...state.marketingPrefs,
          permissionPhone: {
            ...state.permissionPhone,
            checked: action.permissionPhone.checked,
            value: action.permissionPhone.value,
            valid: action.permissionPhone.valid,
            validation: action.permissionPhone.validation,
          },
        },
      };
    case UPDATE_PAYIN_PERMISSION_SMS:
      return {
        ...state,
        marketingPrefs: {
          ...state.marketingPrefs,
          permissionSMS: {
            ...state.permissionSMS,
            checked: action.permissionSMS.checked,
            value: action.permissionSMS.value,
            valid: action.permissionSMS.valid,
            validation: action.permissionSMS.validation,
          },
        },
      };
    case UPDATE_PAYIN_MARKETING_ERROR_MESSAGE:
      return {
        ...state,
        marketingPrefs: {
          ...state.marketingPrefs,
          errorMessage: action.error,
        },
      };
    case UPDATE_PAYIN_MARKETING_VALIDITY:
      return {
        ...state,
        marketingPrefs: {
          ...state.marketingPrefs,
          validating: !action.status === true,
          formValidity: action.status === true,
          showErrorMessages: !action.status === true,
        },
      };
    case UPDATE_PAYIN_FORCE_FORM_ERRORS:
      return {
        ...state,
        forceFormErrors: action.forceFormErrors,
      };
    case RESET_PAYIN_ACTIVITIES:
      return {
        ...state,
        isActivityFormValid: defaultState.isActivityFormValid,
        showActivityError: defaultState.isActivityFormValid,
        payInActivities: defaultState.payInActivities,
      };
    case SET_ACTIVITY_ERROR_MESSAGE:
      return {
        ...state,
        activityErrorMessage: action.activityErrorMessage,
      };
    case UPDATE_CURRENT_PAYIN_STEP:
      return {
        ...state,
        currentPayInStep: action.currentPayInStep,
      };
    case UPDATE_PAYIN_MARKETING_CONSENT_PHONE_NUMBERS: {
      return {
        ...state,
        marketingPrefs: {
          ...state.marketingPrefs,
          phoneNumber: action.phoneNumber,
          smsNumber: action.smsNumber,
        },
      };
    }
    case UPDATE_IS_SUGGESTED_ORG_NAME:
      return {
        ...state,
        isSuggestedOrgName: action.isSuggestedOrgName,
      };
    default:
      return state;
  }
}
