import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as donationFormActions from '../../../Actions/donationFormActions';
import * as applicationActions from '../../../Actions/applicationActions';

import CartService from '../../../Service/Cart.service';
import SiteService from '../../../Service/Site.service';
import PaymentConfigurationService from '../../../Service/PaymentConfiguration.service';

export const GIVING_TYPE_MONTHLY = 'MONTHLY';
export const GIVING_TYPE_SINGLE = 'SINGLE';
export const GIVING_TYPE_PAYIN = 'PAYIN';

const { transType: defaultTransType, transSource: defaultTransSource } = new SiteService().get('site_campaign');

/**
 * GivingTypeSelector Component
 */
const GivingTypeSelector = ({ application, donationForm, updateGivingType, updateClient, updateTransType, updateTransSource }) => {
  /**
   * Handle Option Changes
   * @param changeEvent
   */
  const handleOptionChange = (changeEvent) => {
    const { client } = application;
    updateGivingType(changeEvent.target.value);
    let newTransType = defaultTransType;
    let newTransSource = defaultTransSource;
    if (changeEvent.target.value === 'PAYIN') {
      newTransType = 'payin';
      newTransSource = 'payin';
      if (client !== 'payin') {
        updateClient('payin');
      }
    } else if (client === 'payin') {
      updateClient(PaymentConfigurationService.getFallbackClient());
    }
    updateTransType(newTransType);
    updateTransSource(newTransSource);
  };

  const { providers, cartId, isClientUpdated } = application;
  const { givingType } = donationForm;

  const cart = new CartService(cartId);

  const hasSingle = cart.get('moneybuys')[GIVING_TYPE_SINGLE] !== undefined;
  const hasMonthly = cart.get('moneybuys')[GIVING_TYPE_MONTHLY] !== undefined;
  const hasPayin = cart.get('moneybuys')[GIVING_TYPE_PAYIN] !== undefined;
  const multipleCarts = Object.keys(cart.get('moneybuys')).length > 1;

  // hide selector if there are no multiple types of providers or no providers at all, unless it's a client update
  // where either providers are being reloaded, or a type (e.g. monthly) is missing in a client (e.g. payin client)
  // but that does not mean fallback client does not have that type.
  // based on the assumption that client won't be updated unless we had providers of multiple types in the first place.
  if ((isClientUpdated === false &&
    (providers === null ||
    typeof providers.monthly === 'undefined' ||
    typeof providers.single === 'undefined')) ||
      !multipleCarts) {
    return null;
  }

  return (
    <fieldset id="giving_type_selector">
      <legend className="visuallyhidden">Would you like to donate monthly, make a one off donation or pay in your fundraising money?</legend>
      <div className="form__giving-type">
        {hasSingle && (
          <div className={`form__field--wrapper ${givingType === GIVING_TYPE_SINGLE ? 'selected' : ''}`}>
            <input
              id="one_off_selector"
              name="giving_type_selector"
              type="radio"
              value={GIVING_TYPE_SINGLE}
              checked={givingType === GIVING_TYPE_SINGLE}
              onChange={handleOptionChange}
            />
            <label htmlFor="one_off_selector">Single donation</label>
          </div>
        )}

        {hasMonthly && (
          <div className={`form__field--wrapper ${givingType === GIVING_TYPE_MONTHLY ? 'selected' : ''}`}>
            <input
              id="monthly_giving_selector"
              name="giving_type_selector"
              type="radio"
              value={GIVING_TYPE_MONTHLY}
              checked={givingType === GIVING_TYPE_MONTHLY}
              onChange={handleOptionChange}
            />
            <label htmlFor="monthly_giving_selector">Monthly donation</label>
          </div>
        )}

        {hasPayin && (
          <div className={`payin-wrapper form__field--wrapper ${givingType === GIVING_TYPE_PAYIN ? 'selected' : ''}`}>
            <input
              id="payin_giving_selector"
              name="giving_type_selector"
              type="radio"
              value={GIVING_TYPE_PAYIN}
              checked={givingType === GIVING_TYPE_PAYIN}
              onChange={handleOptionChange}
            />
            <label htmlFor="payin_giving_selector">Pay in fundraising</label>
          </div>
        )}
      </div>
    </fieldset>
  );
};

const mapStateToProps = ({ application, donationForm }) => ({ application, donationForm });

const mapDispatchToProps = dispatch => bindActionCreators(Object.assign({}, applicationActions, donationFormActions), dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(GivingTypeSelector);
