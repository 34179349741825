export const getUrlCartID = () => {
  if (window.location.pathname.indexOf('/cart/') !== -1) {
    return window.location.pathname.replace('/cart/', '');
  }

  return null;
};


/**
 * URL Service
 * @param parameterName string
 * @param result        string|null
 * @return {*}
 */
export default (parameterName, result = null) => {
  let tmp = [];
  let response = result;
  window.location.search
    .substr(1)
    .split('&')
    .forEach((item) => {
      tmp = item.split('=');
      if (tmp[0] === parameterName) response = decodeURIComponent(tmp[1]);
    });
  return response;
};
