import React from 'react';
import { GIVING_TYPE_PAYIN } from '../../../DonationForm/GivingTypeSelector/GivingTypeSelector';

const SocialLinks = ({ donationForm, application, prepopulatedSocialShareMessage, ctaForSocialShareIcons }) => {
/**
 * Handle onclick to show popup to allow users to share donation to twitter or facebook
 * @param {id} id
 * @param {event} event
 */
  const openShareWindow = (id, event) => {
    event.preventDefault();

    // for testing:
    // const thisDomain = 'https://donation.comicrelief.com';
    // for production:
    const thisDomain = document.location.origin;

    const thisCartId = application.cartId;
    const socialLinkBackDomain = `${thisDomain}/?cartId=${thisCartId}`;
    const socialShareMessage = prepopulatedSocialShareMessage || 'I donated to Comic Relief! You can support Comic Relief too by donating here:%0a';


    const isPayin = donationForm.givingType === GIVING_TYPE_PAYIN;
    const thisAmount = `${donationForm.currency.symbol}${donationForm.amount}`;
    const shareMessage = isPayin ? `I fundraised ${thisAmount} for Comic Relief! You can support Comic Relief too by donating here:%0a` : `${socialShareMessage}`;

    const targetUrl = id === 'facebook'
      ? `https://www.facebook.com/sharer/sharer.php?u=${socialLinkBackDomain}&quote=${shareMessage}&u=`
      : `https://twitter.com/share?text=${shareMessage}&url=`;

    window.open(targetUrl + socialLinkBackDomain, `${id}-popup`, 'height=350,width=600');
  };

  const isPayin = donationForm.givingType === GIVING_TYPE_PAYIN;

  const fbIcon = isPayin ? 'icon-facebook--payin' : 'icon-facebook';
  const twIcon = isPayin ? 'icon-twitter--payin' : 'icon-twitter';
  const igIcon = isPayin ? 'icon-instagram--payin' : 'icon-instagram';

  const socialIconMessage = ctaForSocialShareIcons || 'Let the world know!';

  return (
    <div className="success-social-links">
      {!isPayin && <span>{socialIconMessage}</span> }
      <ul>
        <li>
          <a
            href="#facebook-share"
            aria-label="Share on Facebook. (Opens in a new window)."
            target="_blank"
            className="icon__fb"
            onClick={(e) => { openShareWindow('facebook', e); }}
          >
            <svg className="icon">
              <use xlinkHref={'#' + fbIcon} />
            </svg>
          </a>
        </li>
        <li>
          <a
            href="#twitter-share"
            aria-label="Share on Twitter. (Opens in a new window)."
            target="_blank"
            className="icon__twitter"
            onClick={(e) => { openShareWindow('twitter', e); }}
          >
            <svg className="icon">
              <use xlinkHref={'#' + twIcon} />
            </svg>
          </a>
        </li>
        {isPayin &&
        <li>
          <a
            href="https://www.instagram.com"
            aria-label="Share on Instagram. (Opens in a new window)."
            target="_blank"
            className="icon__instagram"
            rel="noopener noreferrer"
          >
            <svg className="icon">
              <use xlinkHref={'#' + igIcon} />
            </svg>
          </a>
        </li>
        }
      </ul>
    </div>
  );
};

export default SocialLinks;
