import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Typeahead } from 'react-typeahead';
import { bindActionCreators } from 'redux';

import PostcodeLookup from '@comicrelief/storybook/src/components/PostcodeLookup/PostcodeLookup';
import InputField from '@comicrelief/storybook/src/components/InputField/InputField';

import ErrorMessage from '../../../Component/ErrorMessage/ErrorMessage';
import AutoSuggestedOrgNames from '../data/AutoSuggestedOrgNames';
import { regex, regexError, regexJob, regexJobError } from '../../../data/regex';

import * as applicationActions from '../../../Actions/applicationActions';
import * as payInFormActions from '../../../Actions/payInFormActions';

import ErrorDisplayService from '../../../Service/ErrorDisplay.service';
import SiteService from '../../../Service/Site.service';

/**
 * PayInPostcodeLookup Component
 */
class PayInPostcodeLookup extends Component {
  constructor(props) {
    super(props);
    this.site = new SiteService();
    this.suggestedOrgNames = null;
    this.errorDisplay = new ErrorDisplayService();
    this.setRef = (element) => { this.typeahead = element; };
    this.handleOrgNameUpdate = this.handleOrgNameTypeaheadUpdate.bind(this);
  }

  componentDidMount() {
    // Populate the autosuggest Typeahead
    this.suggestedOrgNames = AutoSuggestedOrgNames;
  }

  componentDidUpdate() {
    const {
      updatePayInForceFormErrors,
      payInForm: { forceFormErrors, allAddressValidation: { payInAddressValidation: { organisationName } } },
    } = this.props;

    // If the 'Address' parent component has changed this flag via an attempted submission:
    if (forceFormErrors) {
      // Reset flag
      updatePayInForceFormErrors(false);
      // Force validation on this field specifically (PCLU takes care of itself)
      this.handleOrgNameTypeaheadUpdate(organisationName.value);

      // Scroll to first error
      this.errorDisplay.setScrollToErrorTimeoutMarkII();
    }
  }

  /**
   * Callback for Typeahead user events
   * @param value string
   */
  handleOrgNameTypeaheadUpdate(value) {
    const { updateIsSuggestedOrgName, payInForm: { allAddressValidation: { payInAddressValidation } } } = this.props;

    // Update this flag used at the Payin data submission stage
    updateIsSuggestedOrgName(this.suggestedOrgNames.includes(value));

    // Copy current validation state for this specific field
    const thisFieldValidationObj = Object.assign({}, payInAddressValidation.organisationName);
    thisFieldValidationObj.value = value;

    const isRequired = thisFieldValidationObj.required;
    const isNotEmpty = thisFieldValidationObj.value !== '';
    const isRegexMatch = thisFieldValidationObj.value.match(regex) !== null;

    thisFieldValidationObj.message = !isNotEmpty && isRequired ? 'This field is required' : '';
    thisFieldValidationObj.message = isNotEmpty && !isRegexMatch ? regexError : thisFieldValidationObj.message; // Additional regex check
    thisFieldValidationObj.valid = (isNotEmpty && isRegexMatch);
    thisFieldValidationObj.showErrorMessage = !thisFieldValidationObj.valid;

    // Pass updated object to common validation function
    this.handleValidation(thisFieldValidationObj, 'organisationName');
  }

  /**
   * checkValues helper function
   * @return {{isValid: Boolean: *}}
   */
  checkValues() {
    const { payInForm: { payInAudienceType, allAddressValidation: { payInAddressValidation } } } = this.props;

    const isOrgWorkplace = payInAudienceType === 'work';
    const isUniOrYouthGroup = payInAudienceType === 'youth';
    const isOrgWorkplaceUniversityOrYouthGroup = isOrgWorkplace || isUniOrYouthGroup;
    let isValid = true;

    // Check all values in validation obj for any falsy values
    Object.keys(payInAddressValidation).forEach((fieldName) => {
      // Skip non-audience fields
      if ((!isOrgWorkplaceUniversityOrYouthGroup && fieldName === 'organisationName') || (!isOrgWorkplace && fieldName === 'jobTitle')) {
        // console.log('Skipping:', fieldName, 'as it doesnt exist here');
      } else if (payInAddressValidation[fieldName].valid === false || payInAddressValidation[fieldName].valid === null) {
        isValid = false;
      }
    });

    return isValid;
  }

  /**
   * handleValidation function
   * @param validityObj Object
   * @param name string
   */
  handleValidation(validityObj, fieldName) {
    const { payInForm: { allAddressValidation: { payInAddressValidation }, allAddressValidity: { isPayInAddressValid } } } = this.props;

    // Only run both update functions once something's changed, to prevent black holes
    if (payInAddressValidation[fieldName].value !== validityObj.value || payInAddressValidation[fieldName].valid !== validityObj.valid) {
      this.props.updateAllPayInAddresses('payInAddressValidation', fieldName, validityObj);
    }

    const areAllFieldsValid = this.checkValues();

    // Update the relevant validity flag
    if (areAllFieldsValid !== isPayInAddressValid) {
      this.props.updatePayInAllAddressValidity('isPayInAddressValid', areAllFieldsValid);
    }
  }

  /**
   * Render PostcodeLookUp
   */
  render() {
    const { payInForm: { payInAudienceType, allAddressValidation: { payInAddressValidation }, forceFormErrors } } = this.props;

    // Break out PCLU validation to its own object, as required by the PCLU component
    const postCodeLookupValidation = {
      postcode: payInAddressValidation.postcode,
      address1: payInAddressValidation.address1,
      address2: payInAddressValidation.address2,
      address3: payInAddressValidation.address3,
      town: payInAddressValidation.town,
      country: payInAddressValidation.country,
    };

    const orgNameObject = payInAddressValidation.organisationName;
    const jobTitleObject = payInAddressValidation.jobTitle;
    const isYouthGroup = payInAudienceType === 'youth';
    const isOrgOrWorkplace = payInAudienceType === 'work';

    return (
      <div>
        {(isYouthGroup || isOrgOrWorkplace) &&
        <div
          id="field-wrapper--organisation-name"
          className={'form__field--wrapper form__field--organisation-name form__field-wrapper--text ' + (orgNameObject.showErrorMessage ? 'error form__field-error-wrapper' : '')}
        >

          { isOrgOrWorkplace &&
          <div>
            {/* Bespoke label as Typeahead doesn't provide one */}
            <label
              id="field-label--organisation-name"
              htmlFor="field-input--organisation-name"
              className="form__field-label required"
            >
              Organisation name
            </label>
            <Typeahead
              ref={this.typeahead}
              placeholder="Organisation name"
              options={this.suggestedOrgNames}
              onKeyUp={(e) => { this.handleOrgNameTypeaheadUpdate(e.target.value); }}
              onOptionSelected={(item) => { this.handleOrgNameTypeaheadUpdate(item); }}
              customClasses={{ input: 'form__field form__field--text' }}
              initialValue={orgNameObject.value}
              inputProps={{ id: 'field-input--organisation-name', autoComplete: 'off' }}
            />

            { (orgNameObject.showErrorMessage || forceFormErrors ? <ErrorMessage id="organisation-name" copy={payInAddressValidation.organisationName.message} /> : null)}

            <InputField
              type="text"
              label="Job title"
              id="field-input--job-title"
              name="jobTitle"
              className="form__field form__field--text field-input--job-title"
              placeholder="Job title"
              aria-describedby="field-label--payin-job-title field-error--payin-job-title"
              isValid={(valid, name) => this.handleValidation(valid, name)}
              showErrorMessage={jobTitleObject.showErrorMessage || forceFormErrors}
              required={jobTitleObject.required}
              fieldValue={jobTitleObject}
              autoComplete="off"
              pattern={regexJob}
              invalidErrorText={regexJobError}
            />

          </div>
          }

          { /* Non-typeahead OrgName field for Youth Groups */}
          { isYouthGroup &&
            <InputField
              type="text"
              label="Organisation name"
              id="field-input--organisation-name"
              name="organisationName"
              className="form__field form__field--text field-input--organisation-name"
              required
              placeholder="Organisation name"
              aria-describedby="field-label--payin-organisation-name field-error--payin-organisation-name"
              isValid={(valid, name) => this.handleValidation(valid, name)}
              showErrorMessage={orgNameObject.showErrorMessage || forceFormErrors}
              fieldValue={orgNameObject}
              autoComplete="off"
              pattern={regex}
              invalidErrorText={regexError}
            />
          }
        </div>
        }

        <PostcodeLookup
          ref={element => this.errorDisplay.setRef(element)}
          label="Postcode"
          buttonText="Find my address"
          placeholder="Postcode"
          valuesFromParent={postCodeLookupValidation}
          showErrorMessages={forceFormErrors}
          forceManualInput={forceFormErrors}
          isAddressValid={
            (validation) => {
              Object.keys(validation).map(key => this.handleValidation(validation[key], key));
            }
          }
        />
      </div>
    );
  }
}

export default connect(
  ({ application, payInForm }) => ({ application, payInForm }),
  dispatch => bindActionCreators(
    Object.assign({}, applicationActions, payInFormActions),
    dispatch,
  ),
)(PayInPostcodeLookup);
