import { select, takeEvery } from 'redux-saga/effects';
import store from '../../Store/store';

import { getSuccessSection } from '../Selectors';
import {
  changePermissions,
  resetPermissionSMS,
  UPDATE_PERMISSION_PHONE,
  UPDATE_PERMISSION_SMS,
} from '../../Actions/successActions';

/**
 * Checks if phone field contains a mobile
 * number and updates the phone and mobile values
 *
 */
function* overridesMobileField(action) {
  // Get phone number value
  const phoneNumber = action.permissionPhone.validation.fieldValidation.phone.value;
  const success = yield select(getSuccessSection);
  const { permissionSMS } = success;

  // check if phone number is a mobile number
  if (phoneNumber.startsWith('07')) {
    // clone the permission SMS object
    const clonePermissionSMS = Object.assign({}, permissionSMS);

    // show the hidden input field
    clonePermissionSMS.validation.isFieldsHidden = false;

    // assigns the values from the phone field to the mobile field
    clonePermissionSMS.validation.fieldValidation.mobile.valid = action.permissionPhone.validation.fieldValidation.phone.valid;
    clonePermissionSMS.validation.fieldValidation.mobile.value = action.permissionPhone.validation.fieldValidation.phone.value;
    clonePermissionSMS.validation.fieldValidation.mobile.showErrorMessage = action.permissionPhone.validation.fieldValidation.phone.showErrorMessage;

    // updates the permissionSMS field
    store.dispatch(changePermissions('permissionSMS', clonePermissionSMS.validation));
  }
}
/**
 * Resets the mobile field if phone number
 * is changed to a non-mobile number
 */
function* resetMobileField(action) {
  const success = yield select(getSuccessSection);
  const { permissionPhone, permissionSMS } = success;
  // Get phone number value
  const phoneNumber = permissionPhone.validation.fieldValidation.phone.value;

  const prevMobileNumber = permissionSMS.validation.fieldValidation.mobile.value;
  if (action.permissionSMS.checked === false && phoneNumber.startsWith('07') === false && prevMobileNumber !== '') {
    // clone the permission SMS object
    const clonePermissionSMS = Object.assign({}, action.permissionSMS);

    // reset validation
    clonePermissionSMS.validation.isFieldsHidden = true;
    clonePermissionSMS.validation.fieldValidation.mobile.value = '';
    clonePermissionSMS.validation.fieldValidation.mobile.message = '';
    clonePermissionSMS.validation.fieldValidation.mobile.showErrorMessage = false;

    // resets the permissionSMS field
    store.dispatch(resetPermissionSMS());
  }
}

export default function* sagas() {
  yield takeEvery(UPDATE_PERMISSION_PHONE, overridesMobileField);
  yield takeEvery(UPDATE_PERMISSION_SMS, resetMobileField);
}
