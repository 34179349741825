import React, { Component } from 'react';
import braintreeClient from 'braintree-web/client';
import braintreeGooglePayment from 'braintree-web/google-payment';
import braintreeThreeDSecure from 'braintree-web/three-d-secure';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Raven from 'raven-js';

import PaymentServiceRouter, { ROUTES } from '../../../Service/PaymentServiceRouter.service';
import { paymentProviders } from '../../../Service/PaymentConfiguration.service';
import * as paymentActions from '../../../Actions/paymentActions';
import { GIVING_TYPE_PAYIN } from '../../../Pages/DonationForm/GivingTypeSelector/GivingTypeSelector';
import { timeOutDuration } from '../_utils/nonCardProviderHelpers';

import './GooglePay.scss';
import Recaptcha from '../../Recaptcha/Recaptcha';
import { canSubmitRecaptcha, getRecaptchaTokenAndVersion, refreshRecaptchaToken } from '../../../Service/Recaptcha.service';

class GooglePay extends Component {
  constructor(props) {
    super(props);
    this.componentIsMounted = false;
    this.timer = null;
    this.state = {
      showButton: false,
      showPane: false,
      firstName: '',
      lastName: '',
      email: '',
      reCaptchaToken: null,
      timedOut: false,
    };
  }


  componentDidMount() {
    this.componentIsMounted = true;
    const { providers } = this.props;
    if (providers.loadedScriptsProviders.includes(paymentProviders.BRAINTREE_GOOGLEPAY)) {
      this.canMakePayments(providers);
      // Assign 'timeout' timer
      this.timer = setTimeout(() => {
        // To make future debugging easier
        console.error('GOOGLEPAY TIMEOUT');
        // Inform parent form that we are no longer trying to load GooglePay
        this.setLoadedStatus('failed');
        // Update our flag after the duration has elapsed
        this.setState({ timedOut: true });
      }, timeOutDuration);
    }
  }

  componentDidUpdate(prevProps) {
    const { recaptcha, application } = this.props;
    const { showButton, timedOut } = this.state;
    const provider = paymentProviders.BRAINTREE_GOOGLEPAY;
    if ((this.props.providers !== prevProps.providers) && this.props.providers !== null) {
      if (!showButton && !this.paymentsClient && prevProps.providers.loadedScriptsProviders.includes(provider) && timedOut !== true) {
        this.canMakePayments(this.props.providers);
      }
    }
    // Handling GooglePay controlled button, outside of React
    if (document.getElementsByClassName('payment-button')[0]) {
      document.getElementsByClassName('payment-button')[0].disabled = !canSubmitRecaptcha(recaptcha, application);
    }
  }

  componentWillUnmount() {
    this.componentIsMounted = false;
  }

  /**
   * Show Google Pay payment modal when Google Pay payment button is clicked
   */
  async onGooglePaymentButtonClicked() {
    if (this.componentIsMounted === true) {
      this.setState({ showPane: true });
      this.setState({ isSubmittingForm: true });
    }

    const paymentDataRequest = this.googlePaymentInstance.createPaymentDataRequest(this.getGooglePaymentDataRequest());

    try {
      const paymentData = await this.paymentsClient.loadPaymentData(paymentDataRequest);

      const [transactionData, googlePaymentData] = await Promise.all([
        this.createTransaction(paymentData),
        this.googlePaymentInstance.parseResponse(paymentData),
      ]);

      // 3D Secure handling
      const threeDSecureData = await this.handle3DS(transactionData, googlePaymentData, paymentData);

      await this.executeTransaction(transactionData, googlePaymentData, threeDSecureData);
    } catch (err) {
      console.error(err);
      if (err.statusCode === 'CANCELED') {
        if (this.componentIsMounted === true) {
          this.setState({ showPane: false });
        }
        // Only calls the callback function if it's been passed;
        if (typeof this.props.onCancel === 'function') {
          this.props.onCancel();
        }
      } else {
        // Unknown GooglePay error
        if (typeof Raven !== 'undefined' && this.props.application.dependencies.sentry !== false) {
          Raven.captureException(new Error('GOOGLEPAY ERROR', { cause: err }));
        }
        // Redirect to failure page
        this.props.onError(err);
      }
    }
  }

  /**
   * Resuable wrapper function to check prop callback function exists before using it
   */
  setLoadedStatus(status) {
    const { nonCardLoadingStatusUpdate } = this.props;
    if (typeof nonCardLoadingStatusUpdate === 'function') {
      nonCardLoadingStatusUpdate(paymentProviders.BRAINTREE_GOOGLEPAY, status);
    }
  }

  /**
   * Configure support for the Google Pay API
   *
   * NOTE: The `braintree-web` library uses GooglePay v1 API by default.
   * Payloads will not match GooglePay documentation (showing v2 structure) unless/until we upgrade
   * https://braintree.github.io/braintree-web/3.97.1/google-payment_google-payment.js.html
   *
   * Example structure (as logged from this.googlePaymentInstance.createPaymentDataRequest()):
   * {
   *     "environment": "TEST",
   *     "allowedPaymentMethods": [
   *         "CARD",
   *         "TOKENIZED_CARD"
   *     ],
   *     "paymentMethodTokenizationParameters": {
   *         "tokenizationType": "PAYMENT_GATEWAY",
   *         "parameters": {
   *             "gateway": "braintree",
   *             "braintree:merchantId": "<AUTO-SET-BY-BRAINTREE>",
   *             "braintree:apiVersion": "v1",
   *             "braintree:sdkVersion": "3.97.1",
   *             "braintree:metadata": "{\"source\":\"client\",\"integration\":\"custom\",\"sessionId\":\"<AUTO-SET-BY-BRAINTREE>\",\"version\":\"3.97.1\",\"platform\":\"web\"}",
   *             "braintree:authorizationFingerprint": "<AUTO-SET-BY-BRAINTREE>"
   *         }
   *     },
   *     "cardRequirements": {
   *         "allowedCardNetworks": [
   *             "VISA",
   *             "MASTERCARD"
   *         ]
   *     },
   *     "apiVersion": 1,
   *     "transactionInfo": {
   *         "totalPriceStatus": "FINAL",
   *         "totalPrice": 25,
   *         "currencyCode": "GBP"
   *     },
   *     "emailRequired": true,
   *     "shippingAddressRequired": true,
   *     "i": {
   *         "googleTransactionId": "<AUTO-SET-BY-BRAINTREE>",
   *         "usingPayJs": true
   *     }
   * }
   *
   * @returns {object} PaymentDataRequest fields
   */
  getGooglePaymentDataRequest() {
    const { donationForm: { amount, currency: { name: currencyCode } } } = this.props;

    return {
      transactionInfo: {
        totalPriceStatus: 'FINAL',
        totalPrice: amount,
        currencyCode,
      },
      merchantInfo: {
        // @todo a merchant ID is available for a production environment after approval by Google
        // See {@link https://developers.google.com/pay/api/web/guides/test-and-deploy/integration-checklist|Integration checklist}
        // merchantId: '01234567890123456789',
        merchantName: 'Example Merchant',
      },
      emailRequired: true,
      shippingAddressRequired: true,
      cardRequirements: {
        allowedCardNetworks: [
          // 'AMEX', // Disabled while AMEX is not working on our Braintree account
          'VISA',
          'MASTERCARD',
        ],
      },
    };
  }

  /**
   * Set an active PaymentsClient (Google Pay API client)
   * @see {@link https://developers.google.com/pay/api/web/reference/client#PaymentsClient|PaymentsClient constructor}
   * @param mode string
   */
  setPaymentsClient(mode) {
    const environment = mode === 'live' ? 'PRODUCTION' : 'TEST';

    this.paymentsClient = new google.payments.api.PaymentsClient({ environment });
  }

  /**
   * Prefetch payment data to improve performance
   *
   * @see {@link https://developers.google.com/pay/api/web/reference/client#prefetchPaymentData|prefetchPaymentData()}
   */
  prefetchGooglePaymentData() {
    const googlePaymentData = this.getGooglePaymentDataRequest();
    const paymentDataRequest = this.googlePaymentInstance.createPaymentDataRequest(googlePaymentData);
    this.paymentsClient.prefetchPaymentData(paymentDataRequest);
  }

  /**
   * Initialize Google PaymentsClient after Google-hosted JavaScript has loaded
   *
   * Display a Google Pay payment button after confirmation of the viewer's
   * ability to pay.
   * @param {Object} providers
   */
  canMakePayments(providers) {
    const { application } = this.props;
    const provider = paymentProviders.BRAINTREE_GOOGLEPAY;

    // Inform the parent form that GooglePay is loading as soon as possible
    this.setLoadedStatus('loading');

    if (typeof providers.loadedDataProviders[provider] === 'undefined' ||
      application.providersConfiguration === null ||
      typeof application.providersConfiguration.single === 'undefined' ||
      typeof application.providersConfiguration.single[provider] === 'undefined') {
      return;
    }

    const paymentCreateData = {
      client: providers.loadedDataProviders[provider].client,
    };

    if (typeof application.providersConfiguration.single[provider].google_merchant_id !== 'undefined') {
      paymentCreateData.googleMerchantId = application.providersConfiguration.single[provider].google_merchant_id;
    }

    // NOTE: Set `paymentCreateData.googlePayVersion = 2` to use GooglePay v2 API
    // This will require a refactor of field structures used in this file

    this.setPaymentsClient(application.providersConfiguration.single[provider].mode);

    braintreeGooglePayment.create(paymentCreateData)
      .then((googlePaymentInstance) => {
        this.googlePaymentInstance = googlePaymentInstance;

        /**
         * Configure your site's support for payment methods supported by the Google Pay
         * API.
         *
         * Each member of allowedPaymentMethods should contain only the required fields,
         * allowing reuse of this base request when determining a viewer's ability
         * to pay and later requesting a supported payment method
         */
        const isReadyToPayRequest = Object.assign({}, this.state.baseRequest, {
          allowedPaymentMethods: this.googlePaymentInstance.createPaymentDataRequest().allowedPaymentMethods,
        });
        this.paymentsClient.isReadyToPay(isReadyToPayRequest)
          .then((response) => {
            if (response.result && this.buttonContainer) {
              /**
               * Add a Google Pay purchase button
               *
               * @see {@link https://developers.google.com/pay/api/web/reference/object#ButtonOptions|Button options}
               * @see {@link https://developers.google.com/pay/api/web/guides/brand-guidelines|Google Pay brand guidelines}
               */
              if (this.componentIsMounted === true) {
                this.setState({ showButton: true });

                // Inform parent that GooglePay is ready
                this.setLoadedStatus('loaded');

                // Cancel the timeout counter
                clearTimeout(this.timer);
              }

              const thisBtnColour = (application.isPaymentPage ? 'black' : 'white');
              const generatedButton = this.paymentsClient.createButton({
                buttonType: 'pay',
                buttonColor: thisBtnColour,
                onClick: () => this.onGooglePaymentButtonClicked(),
              });

              const button = generatedButton.getElementsByTagName('button')[0];
              button.className = `${button.className} payment-button`;
              button.type = 'button';
              this.buttonContainer.appendChild(generatedButton);
              // prefetch payment data to improve performance after confirming site functionality
              this.prefetchGooglePaymentData();
            }
          })
          .catch((err) => {
            // Inform parent of error
            this.setLoadedStatus('failed');
            this.props.onError(err);
            // Cancel the timeout counter
            clearTimeout(this.timer);
          });
      })
      .catch((err) => {
        // Inform parent of error
        this.setLoadedStatus('failed');
        this.props.onError(err);
        // Cancel the timeout counter
        clearTimeout(this.timer);
      });
  }

  /**
   * Process payment data returned by the Google Pay API
   * Create braintree transaction
   *
   * @param {object} paymentData response from Google Pay API after user approves payment
   * @see {@link https://developers.google.com/pay/api/web/reference/object#PaymentData|PaymentData object reference}
   */
  async createTransaction(paymentData) {
    const {
      cardInfo,
      email,
      shippingAddress: {
        name,
        address1,
        address2,
        address3,
        locality,
        postalCode,
        countryCode,
      },
    } = paymentData;

    const firstName = name.split(' ')[0];
    const lastName = name.replace(`${firstName} `, '');
    const { application, recaptcha, giftaidSection, donationForm, payment } = this.props;

    await refreshRecaptchaToken(application, 'braintree_load_googlepay');
    const { token, version } = getRecaptchaTokenAndVersion(application, recaptcha);

    if (this.componentIsMounted === true) {
      this.setState({
        firstName,
        lastName,
        email,
      });
    }

    // As per https://github.com/comicrelief/react-donation/issues/710
    const thisGiftAid = donationForm.givingType === GIVING_TYPE_PAYIN ? null : giftaidSection.giftaid;

    const createParams = {
      successUrl: application.callbackUrls.successUrl,
      failureUrl: application.callbackUrls.failureUrl,
      recoverUrl: application.callbackUrls.recoverUrl,
      campaign: application.campaign,
      transSource: application.transSource,
      transSourceUrl: application.callbackUrls.transSourceUrl,
      transType: application.transType,
      affiliate: application.affiliate,
      cartId: application.cartId,
      order_reference: donationForm.orderReference,
      client: application.client,
      amount: donationForm.amount,
      currency: donationForm.currency.name,
      giftaid: thisGiftAid,
      firstName,
      lastName,
      email,
      address1,
      address2,
      address3,
      postcode: postalCode,
      town: locality,
      country: countryCode,
      recaptcha_token: token,
      recaptcha_version: version,
      paymentMethod: cardInfo,
      paymentType: 'GOOGLEPAY',
      ...(application.isPaymentPage && {
        transactionId: payment.transactionId,
      }),
    };

    return PaymentServiceRouter.postRequest(ROUTES.provider.braintree.create, createParams).then(createResponse => createResponse.json());
  }

  /**
   * Handle 3D Secure operations for non-tokenised (i.e. PAN_ONLY) cards
   * See: https://developer.paypal.com/braintree/docs/guides/3d-secure/client-side/javascript/v3/#using-3d-secure-with-google-pay
   *
   * @param {object} transactionData response from our payments service after transaction is created
   * @param {object} googlePaymentData generated nonce
   * @param {object} paymentData response from Google Pay API after user approves payment
   */
  async handle3DS(transactionData, googlePaymentData, paymentData) {
    const { donationForm: { amount } } = this.props;
    const { firstName: givenName, lastName: surname, email } = this.state;
    const { nonce, details: { isNetworkTokenized, bin } } = googlePaymentData;
    const { data: { token: clientToken } } = transactionData;
    const { shippingAddress: { address1: streetAddress, locality, postalCode, countryCode: countryCodeAlpha2 } } = paymentData;

    // For tokenised cards (i.e. CRYPTOGRAM_3DS), 3DS check should be skipped (as per Braintree/GooglePay docs)
    if (isNetworkTokenized === true) {
      return null;
    }

    const client = await braintreeClient.create({
      authorization: clientToken,
    });

    const threeDSecureInstance = await braintreeThreeDSecure.create({
      version: 2, // Will use 3DS2 whenever possible
      client,
    });

    const threeDSecureParameters = {
      nonce,
      bin,
      collectDeviceData: true,
      amount,
      email,
      billingAddress: {
        givenName,
        surname,
        streetAddress,
        locality,
        postalCode,
        countryCodeAlpha2,
      },
      additionalInformation: {
        shippingGivenName: givenName,
        shippingSurname: surname,
        shippingAddress: {
          streetAddress,
          locality,
          postalCode,
          countryCodeAlpha2,
        },
      },
      onLookupComplete(data, next) {
        next();
      },
    };

    return threeDSecureInstance.verifyCard(threeDSecureParameters);
  }

  /**
   * Process payment data returned by the Google Pay API
   * Execute braintree transaction
   *
   * @param {object} transactionData response from Our server after transaction is created
   * @param {object} googlePaymentData generated nonce
   * @see {@link https://developers.google.com/pay/api/web/reference/object#PaymentData|PaymentData object reference}
   */
  executeTransaction(transactionData, googlePaymentData, threeDSecureData = null) {
    const { application, donationForm: { amount, currency: { name: currencyCode } } } = this.props;
    const { firstName, lastName, email } = this.state;

    this.props.updateTransactionId(transactionData.data.transactionId);

    // Use nonce from 3DS (if exists), otherwise use gPay nonce
    const nonce = (threeDSecureData === null) ? googlePaymentData.nonce : threeDSecureData.nonce;

    const body = {
      paymentType: 'GOOGLEPAY',
      amount,
      currency: currencyCode,
      client: application.client,
      account_identifier: transactionData.data.account_identifier,
      nonce,
      transactionId: transactionData.data.transactionId,
      firstName,
      lastName,
      email,
    };

    const executeRoute = ROUTES.provider.braintree.execute;

    let executeStatus;

    PaymentServiceRouter.postRequest(executeRoute, body)
      .then((executeResponse) => {
        executeStatus = executeResponse.status;

        return executeResponse.json();
      })
      .then((executeResponseJson) => {
        if (executeStatus === 200) {
          this.props.updatePaymentProvider(paymentProviders.BRAINTREE_GOOGLEPAY);
          this.props.setPaymentAsComplete();

          this.props.onSuccess(
            {
              transactionId: body.transactionId,
            },
            {
              hidePaypal: true,
              hideApplePay: true,
            },
          );
        } else {
          this.props.onError(executeResponseJson);
        }
        return null;
      });
  }

  render() {
    const { application } = this.props;

    return (
      <div>
        {/* Give the user some feedback that more options are coming */}
        { (!this.state.showButton && this.props.hasLoader) &&
        <div className="loader-container">
          <span className="loader" />
        </div>
        }
        {(application.dependencies.reCaptcha === true || application.dependencies.reCaptcha_frontend === true)
        && application.reCaptchaReady === true &&
          <div>
            <Recaptcha action="braintree_load_googlepay" />
          </div>
        }
        {/* Only render these GooglePay components if we haven't timed out */}
        {this.state.timedOut !== true &&
          <div>
            <div className={this.state.showPane === true ? 'modal-overlay' : ''} />
            <div ref={node => this.buttonContainer = node} />
          </div>
        }

      </div>
    );
  }
}

export default connect(
  ({ application, providers, donationForm, recaptcha, giftaidSection, payment }) => ({
    application,
    providers,
    recaptcha,
    donationForm,
    giftaidSection,
    payment,
  }),
  dispatch => bindActionCreators(Object.assign({}, paymentActions), dispatch),
)(GooglePay);
