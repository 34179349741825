import React from 'react';

const labelText = (site, type) => {
  if (type === 'MONTHLY') {
    return 'Yes, I would like Comic Relief to claim Gift Aid on my monthly donations, any donations I make in the future and any I have made in the past 4 years. *';
  } return 'Yes, I would like Comic Relief to claim Gift Aid on my donation, any donations I make in the future and any I have made in the past 4 years.*';
};

const giftaidInfo = donationForm => (
  <p>
    <span className="font--family-bold">Boost your donation by {donationForm.currency.symbol}{(parseFloat(donationForm.amount) * 0.25).toFixed(2)}</span>
    &nbsp;at no extra cost with Gift Aid. All it takes is one click to make your donation go 25% further.
  </p>
);

// To allow for lots of goofy customisation:
const ProgressBarCopy = (thisCartId, isMonthly) => {
  const thisCopy = {};

  if (isMonthly) {
    // Handle customisations based on cart IDs, but use a new default for ALL monthly giving types:
    switch (thisCartId) {
      case 'CR24-THEFLING':
        thisCopy.title = "Here to see what we can offer? Well, we'll definitely show you a good time!";
        thisCopy.subtitle = "Ok, we know it's not the most exciting start, but we've got to cover those all-important basics - will you help your love and monthly gifts go that little bit further?";
        break;
      case 'CR24-GOINGSTEADY':
        thisCopy.title = "We love that you're ready to take the next step and commit to a relationship!";
        thisCopy.subtitle = "Ok, we know it's not the most exciting start, but we've got to cover those all-important basics - will you help your love and monthly gifts go that little bit further?";
        break;
      case 'CR24-LOVEISINTHEAIR':
        thisCopy.title = "We couldn't be happier! Here's to a future of fun, giggles, and changing the world together.";
        thisCopy.subtitle = "Ok, we know it's not the most exciting start, but we've got to cover those all-important basics - will you help your love and monthly gifts go that little bit further?";
        break;
      case 'CR24-NOTJUSTAONENIGHTSTAND':
        thisCopy.title = "We couldn't be happier! Here's to a future of fun, giggles, and changing the world together.";
        thisCopy.subtitle = "Ok, we know it's not the most exciting start, but we've got to cover those all-important basics - will you help your love and monthly gifts go that little bit further?";
        break;
      case 'CR24-RGBUTTON':
        thisCopy.title = "We couldn't be happier! Here's to a future of fun, giggles, and changing the world together.";
        thisCopy.subtitle = "Ok, we know it's not the most exciting start, but we've got to cover those all-important basics - will you help your love and monthly gifts go that little bit further?";
        break;
        // *Global* update as per NJAONS specifications
      default:
        thisCopy.title = "We couldn't be happier! Here's to a future of fun, giggles, and changing the world together.";
        thisCopy.subtitle = "Ok, we know it's not the most exciting start, but we've got to cover those all-important basics - will you help your love and monthly gifts go that little bit further?";
    }
  } else {
    // Else, use the standard copy for non-monthly giving types:
    thisCopy.title = "Let's get started!";
    thisCopy.subtitle = 'Make your donation go further';
  }

  return thisCopy;
};

export { labelText, giftaidInfo, ProgressBarCopy };
