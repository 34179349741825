import { select, takeEvery } from 'redux-saga/effects';

import { getPayment } from '../Selectors';
import PaymentServiceRouter, { ROUTES } from '../../Service/PaymentServiceRouter.service';
import store from '../../Store/store';
import {
  CANCEL_TRANSACTION,
  setPreviousTransactionIdToNull,
  UPDATE_TRANSACTION_ID,
  updateTransactionId,
} from '../../Actions/paymentActions';
import SiteService, { SITE_TYPES } from '../../Service/Site.service';

const isPaymentSite = new SiteService().get('type') === SITE_TYPES.PAYMENT;

/**
 * Cancel current transactions
 * @return {null}
 */
function* cancelTransaction() {
  const payment = yield select(getPayment);

  if (payment.transactionId === null || isPaymentSite === true) {
    return null;
  }

  fetch(PaymentServiceRouter.get(ROUTES.transaction.cancel), {
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      transactionId: payment.transactionId,
    }),
  });

  store.dispatch(setPreviousTransactionIdToNull());
  store.dispatch(updateTransactionId(null));

  return null;
}

/**
 * Cancel legacy transactions when a new transaction id is created
 * @return {null}
 */
function* cancelStaleTransaction(actionData) {
  const payment = yield select(getPayment);

  // If there is no previous transaction id or if the previous is the same as the current, then do not continue.
  if (payment.previousTransactionId === null ||
    actionData.transactionId === null ||
    payment.transactionId === payment.previousTransactionId ||
    isPaymentSite === true
  ) {
    return null;
  }

  if (payment.paymentIsFailed) {
    return null;
  }

  fetch(PaymentServiceRouter.get(ROUTES.transaction.cancel), {
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      transactionId: payment.previousTransactionId,
    }),
  });

  store.dispatch(setPreviousTransactionIdToNull());

  return null;
}

/**
 * Register Transaction Sagas
 */
export default function* sagas() {
  yield takeEvery(CANCEL_TRANSACTION, cancelTransaction);
  yield takeEvery(UPDATE_TRANSACTION_ID, cancelStaleTransaction);
}

