import React, { useEffect } from 'react';
import { connect } from 'react-redux';
/**
 * Sorry component
 */
const Sorry = ({ application }) => {
  useEffect(() => {
    document.title = `Sorry${application.page.titlePostfix}`;
  }, [application.page.titlePostfix]);

  return (
    <main role="main" className="bg--blue-2023">
      <section className="inner-content sorry" style={{ margin: '0 auto 4em', padding: '2em 0' }}>
        <h1>Something went wrong</h1>
        <p>We’re having some trouble taking your payment right now. </p>
        <p>Our technical team have been notified and will resolve the issue as soon as possible.</p>
        <p>
          Please try again in a few minutes and if the issue persists feel free to <a href="https://www.comicrelief.com/contact-us" className="link">contact us</a>.
        </p>
      </section>
    </main>
  );
};

export default connect(
  ({ application, payment }) => ({ application, payment }),
)(Sorry);
