import { takeLatest, select, call } from 'redux-saga/effects';
import { getPayInForm, getPayment } from '../Selectors';
import PaymentServiceRouter, { ROUTES } from '../../Service/PaymentServiceRouter.service';
import store from '../../Store/store';
import { PAYIN_MARKETING_CONSENT_SUBMIT_DATA, payInMarketingConsentComplete, payInMarketingConsentNotComplete, updatePayInMarketingErrorMessage } from '../../Actions/payInFormActions';

function* submitPayInUserData() {
  const endpoint = PaymentServiceRouter.get(ROUTES.payIn.endpoint);
  const payInForm = yield select(getPayInForm);
  const payment = yield select(getPayment);
  const timestampNow = Date.now();

  // Marketing Preference data
  const marketingPrefs = payInForm.marketingPrefs;

  // get phone number
  const phoneNumber = typeof marketingPrefs.permissionPhone.validation.fieldValidation !== 'undefined' &&
      marketingPrefs.permissionPhone.validation.fieldValidation.phone.value !== ''
      && marketingPrefs.permissionPhone.validation.fieldValidation.phone.value !== null
    ? marketingPrefs.permissionPhone.validation.fieldValidation.phone.value : null;

  // send mobile value if permissionSMS is set
  const smsNumber = marketingPrefs.permissionSMS.value !== null && marketingPrefs.permissionSMS.value !== ''
      && typeof marketingPrefs.permissionSMS.validation.fieldValidation !== 'undefined'
        && marketingPrefs.permissionSMS.validation.fieldValidation.mobile.value !== ''
    ? marketingPrefs.permissionSMS.validation.fieldValidation.mobile.value : null;

  // Audience data
  const isWorkplaceOrOrg = payInForm.payInAudienceType === 'work';
  const isSchool = payInForm.payInAudienceType === 'school';
  const isYouthClubOrUni = payInForm.payInAudienceType === 'youth';

  // Adress data
  const thisAddress = isSchool ? payInForm.allAddressValidation.payInSchoolAddressValidation : payInForm.allAddressValidation.payInAddressValidation;

  // School-specific logic, ensuring we're passing required null values rather than any default empty string value
  const hasEstablishmentID = payInForm.allAddressValidation.payInSchoolAddressValidation.establishmentId.value !== '';
  const establishmentID = isSchool && hasEstablishmentID ? payInForm.allAddressValidation.payInSchoolAddressValidation.establishmentId.value.toString() : null;
  const establishmentType = isSchool ? payInForm.allAddressValidation.payInSchoolAddressValidation.establishmentType.value : null;
  const establishmentName = isSchool ? payInForm.allAddressValidation.payInSchoolAddressValidation.establishmentName.value : null;

  // Workplace data
  const thisJobTitle = (isWorkplaceOrOrg || isSchool ? thisAddress.jobTitle.value : null);
  let organisationName = (isWorkplaceOrOrg || isYouthClubOrUni ? thisAddress.organisationName.value : null);

  let organisationNameManualEntry = null;

  // Switch the values if the the user has used one of the suggested organisation names
  if (!payInForm.isSuggestedOrgName && organisationName !== null) {
    organisationNameManualEntry = organisationName;
    organisationName = null;
  }

  // Convert activities obj to key array, removing any 'other' activity as it's covered below
  let allActivities = Object.keys(payInForm.payInActivities);
  allActivities = allActivities.filter(activity => activity !== 'other');
  allActivities = allActivities.join('|').replace(/\s+/g, '-').toLowerCase();

  const otherActivity = payInForm.payInActivities.other !== undefined ? payInForm.payInActivities.other : null;

  const createParams = {
    id: payInForm.uuid,
    transactionId: payment.transactionId,
    created: timestampNow,
    updated: timestampNow,
    address1: thisAddress.address1.value,
    address2: (thisAddress.address2.value !== undefined && thisAddress.address2.value !== '' ? thisAddress.address2.value : null),
    address3: (thisAddress.address3.value !== undefined && thisAddress.address3.value !== '' ? thisAddress.address3.value : null),
    town: thisAddress.town.value,
    country: thisAddress.country.value,
    postcode: thisAddress.postcode.value,
    audience: payInForm.payInAudienceType,
    WhatYouDid: otherActivity,
    permissionEmail: marketingPrefs.permissionEmail.value,
    permissionPhone: marketingPrefs.permissionPhone.value,
    permissionPost: marketingPrefs.permissionPost.value,
    permissionSMS: marketingPrefs.permissionSMS.value,
    phone: phoneNumber,
    mobile: smsNumber,
    establishmentId: establishmentID,
    jobTitle: thisJobTitle,
    schoolType: establishmentType,
    establishment_name: establishmentName,
    organisation: organisationName,
    organisationFreetext: organisationNameManualEntry,
    fundraising_activity: (allActivities !== '' ? allActivities : null),
    // To keep parity with previous Payin data-contract
    Event: null,
    ukActiveMember: null,
    certificate: null,
    permission: null,
  };

  const submitObj = {
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(createParams),
  };

  // Adding this in, even though FetchInterceptor.js is catching this..
  try {
    const response = yield call(() => fetch(endpoint, submitObj));
    const responseData = yield response.json();

    if (typeof response !== 'undefined' && response.status === 200) {
      // Update flag to show Success page and trigger
      store.dispatch(payInMarketingConsentComplete());
    } else {
      // Else, set the flag to false and show error
      store.dispatch(payInMarketingConsentNotComplete());
      store.dispatch(updatePayInMarketingErrorMessage(responseData));
    }
  } catch (error) {
    // Catch any submission errors and update the error
    store.dispatch(updatePayInMarketingErrorMessage(error));
    console.log('error', error);
  }
}

export default function* watchPayInPaymentCompletion() {
  yield takeLatest(PAYIN_MARKETING_CONSENT_SUBMIT_DATA, submitPayInUserData);
}
