import React from 'react';
import { injectStripe, CardNumberElement, CardExpiryElement, CardCVCElement } from 'react-stripe-elements';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import PaymentServiceRouter, { ROUTES } from '../../../../Service/PaymentServiceRouter.service';
import * as paymentActions from '../../../../Actions/paymentActions';
import { GIVING_TYPE_PAYIN } from '../../../../Pages/DonationForm/GivingTypeSelector/GivingTypeSelector';
import { amountFormatter } from '../../../../Helpers/_Helpers';
import Recaptcha from '../../../Recaptcha/Recaptcha';
import { canSubmitRecaptcha, refreshRecaptchaToken } from '../../../../Service/Recaptcha.service';
import { setRecaptchaTokenV2, setRecaptchaVersion } from '../../../../Actions/recaptchaActions';
import store from '../../../../Store/store';

/**
 * StripeElements Component
 */
class StripeElements extends React.Component {
  /**
   * StripeElements constructor
   */
  constructor() {
    super();
    this.state = {
      loading: false,
      tokenError: null,
      validation: {
        cardNumber: false,
        cardExpiry: false,
        cardCvc: false,
        formValid: false,
      },
      showFormErrors: false,
      errorResponse: false,
      isSubmittingForm: false,
    };
    this.componentIsMounted = false;
  }

  /**
   * StripeElements componentWillMount
   * @return {Promise<void>}
   */
  componentDidMount() {
    this.componentIsMounted = true;
  }

  /**
   * StripeElements componentWillUnmount
   */
  componentWillUnmount() {
    this.componentIsMounted = false;
  }

  /**
   * On stripe field change update validation
   * @param changeObject
   */
  onFieldChange(changeObject) {
    const validation = this.state.validation;
    validation[changeObject.elementType] = changeObject.complete;
    validation.formValid = validation.cardNumber === true && validation.cardExpiry === true && validation.cardCvc === true;

    if (this.componentIsMounted === true) {
      this.setState({
        validation,
      });
    }
  }

  /**
   * Handle payment execution
   * @param paymentMethod
   * @return {Promise<Promise<null|*|undefined>|void|*>}
   */
  async handlePayment(paymentMethod = null) {
    const {
      accountIdentifier,
      application,
      donationForm,
      payment,
      stripe,
    } = this.props;

    const executeResponse = await fetch(PaymentServiceRouter.get(ROUTES.provider.stripe.execute), {
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        transactionId: payment.transactionId,
        account_identifier: accountIdentifier,
        client: application.client,
        payment_method_id: paymentMethod.paymentMethod.id,
        amount: donationForm.amount,
        currency: donationForm.currency.name,
      }),
    });

    const responseData = await executeResponse.json();

    if (executeResponse.status !== 200) {
      return this.handlePaymentFailure(responseData);
    }

    // If Stripe execute has "requires_action" we must handle this with payment intents
    // This is usually triggered with 3DS / SCA actions prompts need to be responded to by the user
    // https://stripe.com/docs/payments/3d-secure/authentication-flow?locale=en-GB
    if (responseData.data.requires_action === true) {
      let nextAction;

      try {
        nextAction = await stripe.handleNextAction({
          clientSecret: responseData.data.payment_intent_client_secret,
        });
      } catch (e) {
        console.error(e);
        return this.props.onFailure();
      }

      if (nextAction.error && nextAction.error.code) {
        switch (nextAction.error.code) {
          case 'payment_intent_authentication_failure':
            this.setState({
              loading: false,
              isSubmittingForm: false,
              errorResponse: 'We are unable to authenticate your card.',
            });

            return null;

          default:
            return this.props.onFailure();
        }
      }

      // We expect a payment intent ID - we must go to failure if we don't have it
      if (!nextAction.paymentIntent || !nextAction.paymentIntent.id) {
        return this.props.onFailure();
      }

      const executeIntentResponse = await fetch(PaymentServiceRouter.get(ROUTES.provider.stripe.execute), {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          transactionId: payment.transactionId,
          account_identifier: accountIdentifier,
          client: application.client,
          payment_intent_id: nextAction.paymentIntent.id,
        }),
      });

      if (executeIntentResponse.status !== 200) {
        return this.handlePaymentFailure(await executeIntentResponse.json());
      }
    }

    this.props.setPaymentAsComplete();

    return this.props.onSuccess();
  }

  /**
   * Handle payment failure
   * @param responseData
   * @return {Promise<null|void|*>}
   */
  async handlePaymentFailure(responseData) {
    const { application } = this.props;

    try {
      if (responseData.data.type !== 'undefined' && responseData.data.type === 'cardError') {
        this.setState({
          loading: false,
          isSubmittingForm: false,
          errorResponse: responseData.message,
        });

        // to reset recpatchatokenv2 if the first create failed
        store.dispatch(setRecaptchaTokenV2(''));

        await refreshRecaptchaToken(application, 'refresh_after_decline');

        store.dispatch(paymentActions.setPaymentAsFailed());
        store.dispatch(setRecaptchaVersion(2));

        return null;
      }
      return this.props.onFailure();
    } catch (e) {
      return this.props.onFailure();
    }
  }

  /**
   * Handle stripe submission
   * @param event
   * @return {Promise<void>}
   */
  async handleSubmit(event) {
    event.preventDefault();

    if (this.componentIsMounted === true) {
      if (this.state.validation.formValid !== true) {
        return this.setState({
          showFormErrors: true,
        });
      }

      const { addressSection, donationForm, payInForm: { payInDetailsValidation, allAddressValidation: { payInBillingAddressValidation } } } = this.props;

      const thisAddressObject = donationForm.givingType === GIVING_TYPE_PAYIN ?
        { ...payInDetailsValidation, ...payInBillingAddressValidation }
        : addressSection;

      this.setState({
        isSubmittingForm: true,
      });

      // Generate the Stripe token
      const tokenResponse = await this.props.stripe.createToken({
        name: `${thisAddressObject.firstName.value} ${thisAddressObject.lastName.value}`,
        address_line1: thisAddressObject.address1.value,
        address_line2: thisAddressObject.address2.value,
        address_city: thisAddressObject.town.value,
        address_zip: thisAddressObject.postcode.value,
        address_country: thisAddressObject.country.value,
      });

      if (tokenResponse.error) {
        return this.setState({
          tokenError: tokenResponse.error.message,
        });
      }

      this.setState({
        loading: true,
      });

      // Create the Stripe payment method
      const paymentMethod = await this.props.stripe.createPaymentMethod('card', {
        card: {
          token: tokenResponse.token.id,
        },
        billing_details: {
          name: `${thisAddressObject.firstName.value} ${thisAddressObject.lastName.value}`,
          email: thisAddressObject.email.value,
          address: {
            line1: thisAddressObject.address1.value,
            line2: thisAddressObject.address2.value,
            city: thisAddressObject.town.value,
            country: thisAddressObject.country.value,
            postal_code: thisAddressObject.postcode.value,
          },
        },
      });

      if (paymentMethod.error) {
        return this.setState({
          tokenError: paymentMethod.error.message,
          loading: false,
        });
      }

      return this.handlePayment(paymentMethod);
    }

    return null;
  }

  /**
   * StripeElements render
   * @return {*}
   */
  render() {
    const { application, recaptcha, donationForm } = this.props;

    const buttonText = application.isPaymentPage ? 'Complete Payment' : `Donate ${donationForm.currency.symbol}${amountFormatter(donationForm.amount)} now`;

    if (this.state.loading === true) {
      return (
        <div className="loader-container">
          <span className="loader" />
        </div>
      );
    }

    return (
      <div>
        {this.state.tokenError !== null ? (
          <div className="error" style={{ color: 'red' }}>
            { this.state.tokenError }
          </div>
        ) : ''}

        {this.state.errorResponse !== false &&
        <React.Fragment>
          <div className="inner-content failure bg--transparent">
            <span style={{ marginBottom: '1em' }} className="help-block form-error">
              {this.state.errorResponse} The payment didn’t go through and you haven&apos;t been charged.
            </span>
          </div>

          <br />
          <Recaptcha action="transaction_fail_mode" />
          <br />

          <div className="form__field--wrapper form__submit form__field--submit submit-btn-wrapper">
            <button
              className={`btn form__next cta ${!canSubmitRecaptcha(recaptcha, application) ? 'invalid' : ''}`}
              disabled={!canSubmitRecaptcha(recaptcha, application)}
              onClick={() => {
                this.setState({
                  errorResponse: false,
                });
                this.props.create('fail');
              }
              }
            >Try Again</button>
          </div>
        </React.Fragment>
        }

        {this.state.errorResponse === false &&
        <React.Fragment>
          <label htmlFor="card-number-element" className="required card-number">
            Card number
            <CardNumberElement onChange={this.onFieldChange.bind(this)} />
            {this.state.validation.cardNumber === false && this.state.showFormErrors === true &&
            <span className="help-block form-error">Card number is not valid</span>
            }
          </label>

          <label htmlFor="expiration-date-element" className="required expiration-date">
            Expiration date
            <CardExpiryElement onChange={this.onFieldChange.bind(this)} />
            {this.state.validation.cardExpiry === false && this.state.showFormErrors === true &&
            <span className="help-block form-error">Card expiration date is not valid</span>
            }
          </label>

          <label htmlFor="card-cvc-element" className="required cvc">
            CVC
            <CardCVCElement onChange={this.onFieldChange.bind(this)} />
            {this.state.validation.cardCvc === false && this.state.showFormErrors === true &&
            <span className="help-block form-error">Card CVC is not valid</span>
            }
          </label>

          <div className="form__field--wrapper form__submit form__field--submit submit-btn-wrapper">
            <button
              id="comicrelief_payinbundle_payment_amount_submit"
              style={{ marginTop: '1em' }}
              type="submit"
              name="comicrelief_payinbundle_payment[submit]"
              className={`form__next cta ${!this.state.validation.formValid ? 'invalid' : ''}`}
              onClick={(e) => { this.handleSubmit(e); }}
              disabled={this.state.isSubmittingForm}
            >{ buttonText }
            </button>
          </div>
        </React.Fragment>
        }
      </div>
    );
  }
}

export default connect(
  ({ addressSection, application, recaptcha, donationForm, giftaidSection, payment, providers, payInForm }) => ({
    addressSection,
    application,
    donationForm,
    giftaidSection,
    recaptcha,
    payment,
    providers,
    payInForm,
  }),
  dispatch => bindActionCreators(Object.assign({}, paymentActions), dispatch),
)(injectStripe(StripeElements));
