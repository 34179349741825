import thunkMiddleware from 'redux-thunk';
import { createStore, applyMiddleware } from 'redux';
import throttle from 'lodash.throttle';
import createSagaMiddleware from 'redux-saga';

import { saveState, loadState } from '../Component/PersistentState/PersistentState';

// import root reducer
import rootReducer from '../Reducers/index';
import { defaultState as providersDefaultState } from '../Reducers/providers';
import sagas from '../Sagas';

// load state from sessionStorage
const persistedState = loadState();

// create object for default state
const defaultState = typeof persistedState !== 'undefined' ? {
  addressSection: persistedState.addressSection,
  application: persistedState.application,
  donationForm: persistedState.donationForm,
  giftaidSection: persistedState.giftaidSection,
  payment: persistedState.payment,
  providers: persistedState.providers,
  successSection: persistedState.successSection,
  payInForm: persistedState.payInForm,
} : {};

// create the saga middleware
const sagaMiddleware = createSagaMiddleware();

// create store which takes 3 arguments: rootReducer, defaultState and middlewares
// default state will override the initial state specified by the reducers
const store = createStore(rootReducer, defaultState, applyMiddleware(thunkMiddleware, sagaMiddleware));

// called every time the storage state changes
// use throttle so we're only writing to sessionStorage once a second
store.subscribe(throttle(() => {
  const {
    addressSection,
    application,
    donationForm,
    giftaidSection,
    payInForm,
    payment,
    successSection,
    recaptcha,
    providers: { availableProviders, client },
  } = store.getState();
  saveState({
    addressSection,
    application,
    donationForm,
    payInForm,
    recaptcha,
    giftaidSection,
    payment,
    successSection,
    providers: { ...providersDefaultState, availableProviders, client },
  });
}, 1000));

sagaMiddleware.run(sagas);

export default store;
