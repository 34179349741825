export const RESET_ADDRESS_STATE = 'RESET_ADDRESS_STATE';
export const UPDATE_FIRST_NAME = 'UPDATE_FIRST_NAME';
export const UPDATE_LAST_NAME = 'UPDATE_LAST_NAME';
export const UPDATE_EMAIL = 'UPDATE_EMAIL';
export const UPDATE_CONFIRM_EMAIL = 'UPDATE_CONFIRM_EMAIL';
export const UPDATE_POSTCODE = 'UPDATE_POSTCODE';
export const UPDATE_ADDRESS_1 = 'UPDATE_ADDRESS_1';
export const UPDATE_ADDRESS_2 = 'UPDATE_ADDRESS_2';
export const UPDATE_ADDRESS_3 = 'UPDATE_ADDRESS_3';
export const UPDATE_TOWN = 'UPDATE_TOWN';
export const UPDATE_COUNTRY = 'UPDATE_COUNTRY';
export const UPDATE_VALUE = 'UPDATE_VALUE';

/**
 * Resets the state of the address section
 * @return {{type: string}}
 */
export function resetAddresstate() {
  return {
    type: RESET_ADDRESS_STATE,
  };
}

export function updateAddressValue(key, value) {
  return {
    type: UPDATE_VALUE,
    key,
    value,
  };
}

/**
 * updateDetails Actions
 * @param name  string
 * @param valid object
 * @return {*}
 */
export function updateDetails(name, valid) {
  switch (name) {
    case 'firstName':
      return {
        type: UPDATE_FIRST_NAME,
        firstName: {
          valid: valid.valid,
          value: valid.value,
          message: valid.message,
          showErrorMessage: valid.showErrorMessage,
        },
      };
    case 'lastName':
      return {
        type: UPDATE_LAST_NAME,
        lastName: {
          valid: valid.valid,
          value: valid.value,
          message: valid.message,
          showErrorMessage: valid.showErrorMessage,
        },
      };
    case 'email':
      return {
        type: UPDATE_EMAIL,
        email: {
          valid: valid.valid,
          value: valid.value,
          message: valid.message,
          showErrorMessage: valid.showErrorMessage,
        },
      };
    case 'confirmEmail':
      return {
        type: UPDATE_CONFIRM_EMAIL,
        confirmEmail: {
          valid: valid.valid,
          value: valid.value,
          message: valid.message,
          showErrorMessage: valid.showErrorMessage,
        },
      };
    case 'postcode':
      return {
        type: UPDATE_POSTCODE,
        postcode: {
          valid: valid.valid,
          value: valid.value,
          message: valid.message,
          showErrorMessage: valid.showErrorMessage,
        },
      };
    case 'address1':
      return {
        type: UPDATE_ADDRESS_1,
        address1: {
          valid: valid.valid,
          value: valid.value,
          message: valid.message,
          showErrorMessage: valid.showErrorMessage,
        },
      };
    case 'address2':
      return {
        type: UPDATE_ADDRESS_2,
        address2: {
          valid: valid.valid,
          value: valid.value,
          message: valid.message,
          showErrorMessage: valid.showErrorMessage,
        },
      };
    case 'address3':
      return {
        type: UPDATE_ADDRESS_3,
        address3: {
          valid: valid.valid,
          value: valid.value,
          message: valid.message,
          showErrorMessage: valid.showErrorMessage,
        },
      };
    case 'town':
      return {
        type: UPDATE_TOWN,
        town: {
          valid: valid.valid,
          value: valid.value,
          message: valid.message,
          showErrorMessage: valid.showErrorMessage,
        },
      };
    case 'country':
      return {
        type: UPDATE_COUNTRY,
        country: {
          valid: valid.valid,
          value: valid.value,
          message: valid.message,
          showErrorMessage: valid.showErrorMessage,
        },
      };
    default:
      return null;
  }
}
