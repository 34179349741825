import React from 'react';
import { connect } from 'react-redux';
import SocialLinks from '../SocialLinks/SocialLinks';
import ScrollToTopOnMount from '../../../../Component/App/ScrollToTop/ScrollToTopOnMount';
import MembershipCard from './MembershipCard';
import './ThankYouMembership.scss';
import { amountFormatter } from '../../../../Helpers/_Helpers';

const getDirectDebitDate = () => {
  const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
  const date = new Date();
  const month = months[date.getMonth() + 1];
  const currentDayOfMonth = date.getDate();
  const day = currentDayOfMonth > 28 ? 28 : currentDayOfMonth;
  return day + ' ' + month;
};

/**
 * ThankYouMembership Component
 */
const ThankYouMembership = ({ donationForm, addressSection, payment }) => {
  let thankYouHeader;
  if (addressSection.firstName.value) {
    thankYouHeader = `Thank you, ${addressSection.firstName.value}!`;
  } else {
    thankYouHeader = 'Thank you!';
  }

  const subHeader = `Your ${donationForm.currency.symbol}${amountFormatter(donationForm.amount)} a month goes a long way`;

  return (
    <div>
      <div className="inner-content inner-content--success">
        <div className=" inner-content--success-membership">
          <ScrollToTopOnMount />
          <h1>{thankYouHeader}</h1>
          <h2>{subHeader}</h2>
          <p className="success-transactionsid">{`Subscription ID: ${payment.subscriptionId}`}</p>
          <SocialLinks {...{ donationForm, addressSection, payment }} />
          <div>
            <h3>Your work is done</h3>
            <p>We’ve begun the process of setting up your Direct Debit. </p>
            <p>Your first donation will come out of your account on {getDirectDebitDate()} and on the same date every month thereafter.</p>
            <p>{`We’ve sent a confirmation email to ${addressSection.email.value}`}</p>
          </div>
          <div className="success-info-wrapper">
            <h3 className="text--label">Any problems?</h3>
            <p>If you experience any problems then please contact our Supporter care team</p>
            <p className="font--large">
              <a className="link inline" href="mailto:info@comicrelief.com?Subject=Membership%20subscription%20issue">
                info@comicrelief.com
              </a>
            </p>
            <p className="font--large">
              <a className="link inline" href="tel:0044207820080">
                +44(0)20 7820 2080
              </a>
            </p>
            <p>Phone lines are open Monday to Friday, 9:30–17:30</p>
          </div>
        </div>
      </div>
      <MembershipCard header="See how our supporters have helped" />
    </div>
  );
};

export default connect(
  ({ application, addressSection, donationForm, payment }) => ({
    application, addressSection, donationForm, payment,
  }),
)(ThankYouMembership);
