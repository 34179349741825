import React, { useState, useEffect } from 'react';
import { Picture } from 'react-responsive-picture';

const FullHeightSingleImage = ({ alt, desktop, mobile, hideOnMobile = false }) => {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const updateWindowDimensions = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener('resize', updateWindowDimensions);
    // Cleanup listener on component unmount
    return () => {
      window.removeEventListener('resize', updateWindowDimensions);
    };
  }, []);

  const style = {};
  if (width < 740 && hideOnMobile) {
    style.display = 'none';
  }

  return (
    <div className="fhsisc__image-wrapper objectfit bg--transparent" style={style}>
      <Picture
        alt={alt}
        sources={[
          {
            srcSet: `${mobile}`,
            media: '(max-width: 740px)',
          },
          {
            srcSet: `${desktop}`,
          },
          {
            srcSet: `${mobile}`,
            type: 'image/webp',
          },
        ]}
      />
    </div>
  );
};

export default FullHeightSingleImage;
