export const RECAPTCHA_VERSION = 'RECAPTCHA_VERSION';
export const RECAPTCHA_TOKEN_V3 = 'RECAPTCHA_TOKEN_V3';
export const RECAPTCHA_TOKEN_V2 = 'RECAPTCHA_TOKEN_V2';

/**
 * Set the recaptcha token state V2
 * @param tokenV3
 * @return {{type: string, tokenV3: string}}
 */
export function setRecaptchaTokenV3(tokenV3) {
  return {
    type: RECAPTCHA_TOKEN_V3,
    tokenV3,
  };
}

/**
 * Set the recaptcha token state V2
 * @param tokenV2
 * @return {{type: string, tokenV2: *}}
 */
export function setRecaptchaTokenV2(tokenV2) {
  return {
    type: RECAPTCHA_TOKEN_V2,
    tokenV2,
  };
}

/**
 * Update version
 * @param version
 * @return {{type: string, version: number}}
 */
export function setRecaptchaVersion(version) {
  return {
    type: RECAPTCHA_VERSION,
    version,
  };
}
