import React from 'react';

export default (props) => {
  const header = typeof props.header !== 'undefined' ? props.header : '';
  const customSuccessMessage = typeof props.customSuccessMessage !== 'undefined' ? props.customSuccessMessage : null;

  const customButtonURL = props.customButtonURL ? props.customButtonURL : 'https://www.comicrelief.com/what-your-money-does/';
  const customButtonText = props.customButtonText ? props.customButtonText : 'Where does my money go?';

  const customMessage = (
    <React.Fragment>
      <p>{customSuccessMessage}</p>
    </React.Fragment>
  );
  const defaultMessage = (
    <React.Fragment>
      <p>Your donation today will help people who are facing the toughest times of their lives, both here in the UK and across the world, and help them to break free from poverty.</p>
      <p>Thank you.</p>
    </React.Fragment>
  );

  return (
    <div className="form__row--upsell">
      <div className="cards cards--three-grid-centre cards--three-grid-unit">
        <div className="cards-header">
          <h2>{header}</h2>
          {customSuccessMessage !== null ? customMessage : defaultMessage}
        </div>
        <a
          href={customButtonURL}
          aria-label={customButtonText}
          className="btn btn--deep-violet"
        >
          {customButtonText}
        </a>
      </div>
    </div>
  );
};
