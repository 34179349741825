import { v4 as uuidv4 } from 'uuid';
import CartService from '../Service/Cart.service';

import {
  DONATION_FORM_LOAD,
  RESET_DONATION_FORM_STATE,
  SHOW_AMOUNT_ERROR,
  UPDATE_AMOUNT,
  UPDATE_CURRENCY,
  UPDATE_GIVING_TYPE,
  UPDATE_POPUP_SHOWN,
  UPDATE_HAS_SET_DEFAULT_AMOUNT,
  UPDATE_HAS_CHANGED_GIVING_TYPE,
  UPDATE_PREVIOUS_MONEYBUY_ADDED_FOR_GA,
} from '../Actions/donationFormActions';

import { GIVING_TYPE_SINGLE, GIVING_TYPE_MONTHLY, GIVING_TYPE_PAYIN } from '../Pages/DonationForm/GivingTypeSelector/GivingTypeSelector';

const cartService = new CartService();

// Introduce 3rd givingtype
const newGivingType = (function (givingType) {
  switch (givingType) {
    case 'single':
      return GIVING_TYPE_SINGLE;
    case 'regular':
      return GIVING_TYPE_MONTHLY;
    default:
      return GIVING_TYPE_PAYIN; // Defaults to Payin
  }
}(cartService.get('giving_type')));


const defaultState = {
  amount: '',
  previousAmount: null,
  currency: {
    name: 'GBP',
    previousName: 'GBP',
    symbol: '£',
  },
  formLoadAt: new Date().getTime(),
  givingType: newGivingType,
  previousGivingType: null,
  moneyBuy: {
    index: null,
    name: '',
    previousName: null,
    previousGivingTypeGA: null,
    previousIndex: null,
  },
  orderReference: uuidv4(),
  showAmountError: false,
  wasPopupShown: null,
  hasSetDefaultAmount: false,
  hasChangedGivingType: false,
  // To let us track changes for GA
  moneybuysForGA: {
    storedCurrentMoneybuy: null,
    storedPreviousMoneybuy: null,
  },
};

/**
 * Donation Form Reducer
 * @param state
 * @param action
 * @return {*}
 */
export default function donationForm(state = defaultState, action) {
  switch (action.type) {
    case DONATION_FORM_LOAD:
      return {
        ...state,
        formLoadAt: action.formLoadAt,
      };
    case RESET_DONATION_FORM_STATE:
      return defaultState;
    case SHOW_AMOUNT_ERROR:
      return {
        ...state,
        showAmountError: action.showAmountError === true,
      };
    case UPDATE_AMOUNT:
      return {
        ...state,
        amount: action.amount === ''.toString() ? action.amount : parseFloat(action.amount, 10),
        previousAmount: state.amount,
        showAmountError: action.amount < 1 || action.amount > 25000 || action.amount.match(/^[1-9][0-9]*\.?\d*$/gm) === null,
        moneyBuy: {
          ...state.moneyBuy,
          index: action.moneyBuyIndex,
          name: action.moneyBuyIndex !== null ? 'moneybuy-' + action.amount : 'manual-entry',
          previousName: state.moneyBuy.name,
          previousGivingTypeGA: state.givingType !== state.previousGivingType ? state.previousGivingType : state.givingType,
          previousIndex: state.moneyBuy.index,
        },
        previousGivingType: state.givingType,
      };
    case UPDATE_CURRENCY:
      return {
        ...state,
        currency: {
          name: action.currency.name,
          previousName: state.currency.name,
          symbol: action.currency.symbol,
        },
      };
    case UPDATE_GIVING_TYPE:
      return {
        ...state,
        givingType: action.givingType,
        previousGivingType: state.givingType,
      };
    case UPDATE_POPUP_SHOWN:
      return {
        ...state,
        wasPopupShown: action.wasPopupShown,
      };
    case UPDATE_HAS_SET_DEFAULT_AMOUNT:
      return {
        ...state,
        hasSetDefaultAmount: action.hasSetDefaultAmount,
      };
    case UPDATE_HAS_CHANGED_GIVING_TYPE:
      return {
        ...state,
        hasChangedGivingType: action.hasChangedGivingType,
      };
    case UPDATE_PREVIOUS_MONEYBUY_ADDED_FOR_GA:
      return {
        ...state,
        moneybuysForGA: action.moneybuysForGA,
      };
    default:
      return state;
  }
}
