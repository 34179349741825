import React from 'react';
import { connect } from 'react-redux';
import { GIVING_TYPE_MONTHLY } from '../../DonationForm/GivingTypeSelector/GivingTypeSelector';
import CartService from '../../../Service/Cart.service';
import SocialLinks from './SocialLinks/SocialLinks';
import Card from './Card/Card';
import ScrollToTopOnMount from '../../../Component/App/ScrollToTop/ScrollToTopOnMount';
import { amountFormatter } from '../../../Helpers/_Helpers';

/**
 * ThankYou Component
 */
const ThankYou = ({ application, donationForm, addressSection, payment }) => {
  const cart = new CartService(application.cartId);
  let transactionsIdCopy = null;
  let cardsHeader = null;
  let thankYouHeader;
  const isMonthly = donationForm.givingType === GIVING_TYPE_MONTHLY;

  switch (application.site) {
    default: {
      transactionsIdCopy = `Transaction ID: ${payment.transactionId}`;
      cardsHeader = `Your ${donationForm.currency.symbol}${amountFormatter(donationForm.amount)} goes a long way`;
      break;
    }
  }

  if (payment.isAsyncTransaction === true) {
    thankYouHeader = 'Your payment is being processed';
  } else if (addressSection.firstName.value) {
    thankYouHeader = `Thank you, ${addressSection.firstName.value}!`;
  } else {
    thankYouHeader = 'Thank you!';
  }


  let customSuccessMsg = (cart.config.custom_success_message && cart.config.custom_success_message[donationForm.givingType]) ? cart.config.custom_success_message[donationForm.givingType] : null;
  // *Global* update as per NJAONS specifications:
  // Having to handle this here rather than via the CMS as it calls for over 256
  // chars AND a linebreak; both of which the customSuccessMsg field don't support.
  if (!customSuccessMsg && isMonthly) {
    customSuccessMsg = (
      <React.Fragment>
        <p>You&apos;re making us blush as red as our Red Nose! Because we&apos;re so happy you&apos;ve joined Not Just a One Night Stand. Your generous monthly donations really could be life-changing, helping to support people both here in the UK and across the world, who are facing the harm of poverty.</p>
        <p>We feel so honoured to have you by our side and hope this new kind of relationship with you will turn out to be a long-lasting one. Gosh, we&apos;re smitten!</p>
      </React.Fragment>
    );
  }

  const ctaForSocialShareIcons = cart.config.cta_for_social_share_icons && cart.config.cta_for_social_share_icons[donationForm.givingType] ? cart.config.cta_for_social_share_icons[donationForm.givingType] : null;
  const prepopulatedSocialShareMessage = cart.config.prepopulated_social_share_message && cart.config.prepopulated_social_share_message[donationForm.givingType] ? cart.config.prepopulated_social_share_message[donationForm.givingType] : null;
  const customButtonURL = cart.config.custom_success_button_url ? cart.config.custom_success_button_url : 'https://www.comicrelief.com/what-your-money-does/';
  const customButtonText = cart.config.custom_success_button_text ? cart.config.custom_success_button_text : 'Where does my money go?';
  const downloadClassName = typeof cart.config.success_download_file !== 'undefined' ? 'success-download-file' : '';

  if (donationForm.givingType === GIVING_TYPE_MONTHLY) {
    thankYouHeader = `${addressSection.firstName.value}, we're head over heels for you already!`;
    transactionsIdCopy = `Subscription ID: ${payment.subscriptionId}`;
    cardsHeader = `Your ${donationForm.currency.symbol}${amountFormatter(donationForm.amount)} a month will go a long way - thank you'`;
  }

  return (
    <div className="fhsisc__text-wrapper fhsisc__thank-you-text">
      <ScrollToTopOnMount />
      <div className={`inner-content inner-content--success ${downloadClassName}`}>
        {payment.isAsyncTransaction === true ?
          <h1>Thank You!<br /> {thankYouHeader}</h1>
          :
          <h1>{thankYouHeader}</h1>
        }

        {/* conditional text based on donation amount */}
        { (typeof cart.config.success_conditional_text !== 'undefined' &&
        donationForm.currency.name === 'GBP' &&
        donationForm.amount >= cart.config.success_conditional_min_amount) &&
        <p>{cart.config.success_conditional_text}</p>
        }

        <p className="success-transactionsid">{transactionsIdCopy}</p>
        <SocialLinks
          application={application}
          donationForm={donationForm}
          addressSection={addressSection}
          payment={payment}
          ctaForSocialShareIcons={ctaForSocialShareIcons}
          prepopulatedSocialShareMessage={prepopulatedSocialShareMessage}
        />
      </div>
      <Card
        header={cardsHeader}
        customSuccessMessage={customSuccessMsg}
        customButtonURL={customButtonURL}
        customButtonText={customButtonText}
      />
    </div>
  );
};

export default connect(state => ({
  application: state.application,
  addressSection: state.addressSection,
  donationForm: state.donationForm,
  payment: state.payment,
}))(ThankYou);
