export const CANCEL_TRANSACTION = 'CANCEL_TRANSACTION';
export const RESET_PAYMENT_STATE = 'RESET_PAYMENT_STATE';
export const SET_PAYMENT_COMPLETE = 'SET_PAYMENT_COMPLETE';
export const SET_PAYMENT_FAILED = 'SET_PAYMENT_FAILED';
export const SET_PREVIOUS_TRANSACTION_ID_TO_NULL = 'SET_PREVIOUS_TRANSACTION_ID_TO_NULL';
export const UPDATE_ASYNC_TRANSACTION_STATUS = 'UPDATE_ASYNC_TRANSACTION_STATUS';
export const UPDATE_DATA = 'UPDATE_DATA';
export const UPDATE_FIELD_VALIDATION = 'UPDATE_FIELD_VALIDATION';
export const UPDATE_FIELDS_VALIDATION = 'UPDATE_FIELDS_VALIDATION';
export const UPDATE_PAYMENT_PROVIDER = 'UPDATE_PAYMENT_PROVIDER';
export const UPDATE_STEP = 'UPDATE_STEP';
export const UPDATE_SUBSCRIPTION_ID = 'UPDATE_SUBSCRIPTION_ID';
export const UPDATE_TRANSACTION_ID = 'UPDATE_TRANSACTION_ID';
export const SET_USER_CHOSEN_CARD_PAYMENT = 'SET_USER_CHOSEN_CARD_PAYMENT';

/**
 * Cancels a transaction
 * @return {{type: string}}
 */
export function cancelTransaction() {
  return {
    type: CANCEL_TRANSACTION,
  };
}

/**
 * Resets the payment state
 * @return {{type: string}}
 */
export function resetPaymentState() {
  return {
    type: RESET_PAYMENT_STATE,
  };
}


/**
 * Set a payment as complete
 * @return {{type: string, paymentIsComplete: boolean}}
 */
export function setPaymentAsComplete() {
  return {
    type: SET_PAYMENT_COMPLETE,
    paymentIsComplete: true,
  };
}


/**
 * Set a payment as failed
 * @returns {{type: string, paymentIsComplete: boolean}}
 */
export function setPaymentAsFailed() {
  return {
    type: SET_PAYMENT_FAILED,
    paymentIsFailed: true,
  };
}

/**
 * Sets the previous transaction id to null
 * @return {{type: string}}
 */
export function setPreviousTransactionIdToNull() {
  return {
    type: SET_PREVIOUS_TRANSACTION_ID_TO_NULL,
  };
}

/**
 * Set whether the transaction is ASYNC or not
 *
 * @param isAsync boolean
 * @return {{type: string, isAsyncTransaction: boolean}}
 */
export function updateAsyncTransactionStatus(isAsync) {
  return {
    type: UPDATE_ASYNC_TRANSACTION_STATUS,
    isAsyncTransaction: isAsync === true,
  };
}

/**
 * Update Payment Data
 *
 * @param field
 * @param value
 * @return {{type: string, field: *, value: *}}
 */
export function updateData(field, value) {
  return {
    type: UPDATE_DATA,
    field,
    value,
  };
}

/**
 * Update field validation
 *
 * @param field
 * @param validation
 * @return {{type: string, field: *, validation: *}}
 */
export function updateFieldValidation(field, validation) {
  return {
    type: UPDATE_FIELD_VALIDATION,
    field,
    validation,
  };
}

/**
 * Update Fields Validation
 *
 * @param validation
 * @return {{type: string, validation: *}}
 */
export function updateFieldsValidation(validation) {
  return {
    type: UPDATE_FIELDS_VALIDATION,
    validation,
  };
}

/**
 * Update payment provider
 *
 * @param paymentProvider
 * @return {{type: string, paymentProvider: *}}
 */
export function updatePaymentProvider(paymentProvider) {
  return {
    type: UPDATE_PAYMENT_PROVIDER,
    paymentProvider,
  };
}

/**
 * Update Payment Step
 *
 * @param step
 * @return {{type: string, step: *}}
 */
export function updateStep(step) {
  return {
    type: UPDATE_STEP,
    step,
  };
}

/**
 * Update Subscription ID
 *
 * @param subscriptionId string
 * @return {{type: string, subscriptionId: *}}
 */
export function updateSubscriptionId(subscriptionId) {
  return {
    type: UPDATE_SUBSCRIPTION_ID,
    subscriptionId,
  };
}

/**
 * Update Transaction ID
 *
 * @param transactionId string
 * @return {{type: string, transactionId: *}}
 */
export function updateTransactionId(transactionId) {
  return {
    type: UPDATE_TRANSACTION_ID,
    transactionId,
  };
}


/**
 * set userHasChosenCardPayment
 *
 * @param userHasChosenCardPayment boolean
 * @return {{type: boolean, userHasChosenCardPayment: *}}
 */
export function setUserHasChosenCardPayment(userHasChosenCardPayment) {
  return {
    type: SET_USER_CHOSEN_CARD_PAYMENT,
    userHasChosenCardPayment,
  };
}
