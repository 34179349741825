import React from 'react';
import cardImg1 from '../../../../../public/images/farhad-story.jpg';

export default (props) => {
  const header = typeof props.header !== 'undefined' ? props.header : '';
  return (
    <div className="membership-success form__row--upsell">
      <section className="cards cards--three-grid-centre">
        <h3 className="cards__title">{header}</h3>
        <div className="cards__wrapper">
          <div className="card">
            <div className="card__image">
              <img src={cardImg1} alt="Farhad" />
            </div>
            <div className="card__content-wrapper">
              <div className="card__content">
                <div className="card__content">
                  <h4>Farhad’s story</h4>
                  <p>
                    Farhad’s father fled Taliban control in Afghanistan as a young man, settling in Iran where he raised his family.  After growing pressure against Afghans by the Iranian government, the young family was forced to move again.
                  </p>
                  <p>
                    <a className="link inline" href="https://www.comicrelief.com/what-your-money-does/safe-places/Farhad">Read more</a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
