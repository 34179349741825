import {
  ADD_LOADING_SCRIPT,
  ADD_LOADED_SCRIPT,
  LOADED_PROVIDERS,
  LOADED_PROVIDER_SCRIPT,
  LOADED_PROVIDER_DATA,
  LOADING_PROVIDER_DATA_STATUS,
  REMOVE_LOADING_SCRIPT,
  RESET_PROVIDERS_STATE,
} from '../Actions/providersActions';

export const defaultState = {
  client: null,
  availableProviders: [],
  loadedScriptsProviders: [],
  loadingScriptsProviders: [],
  loadedDataProviders: {},
  loadingDataProvidersStatus: {},
};

/**
 * Providers Reducer
 * @param state
 * @param action
 * @return {*}
 */
export default function providers(state = defaultState, action) {
  switch (action.type) {
    case ADD_LOADING_SCRIPT: {
      const loadingScriptsProviders = state.loadingScriptsProviders;
      loadingScriptsProviders.push(action.provider);

      return {
        ...state,
        loadingScriptsProviders,
      };
    }
    case ADD_LOADED_SCRIPT: {
      const loadingScriptsProviders = state.loadingScriptsProviders;
      const loadedScriptsProviders = state.loadedScriptsProviders;

      loadingScriptsProviders.splice(action.provider);
      loadedScriptsProviders.push(action.provider);

      return {
        ...state,
        loadingScriptsProviders,
        loadedScriptsProviders,
      };
    }
    case LOADED_PROVIDERS:
      return {
        ...state,
        availableProviders: action.providers,
        client: action.client,
      };
    case LOADED_PROVIDER_SCRIPT: {
      const scriptsArray = state.loadedScriptsProviders;
      scriptsArray.push(action.provider);

      return {
        ...state,
        loadedScriptsProviders: scriptsArray,
      };
    }
    case LOADED_PROVIDER_DATA: {
      const newState = {
        ...state,
        loadedDataProviders: { ...state.loadedDataProviders },
      };
      if (!newState.loadedDataProviders[action.provider]) {
        newState.loadedDataProviders[action.provider] = {};
      }

      newState.loadedDataProviders[action.provider][action.key] = action.data;
      delete newState.loadingDataProvidersStatus[action.provider];
      return newState;
    }
    case LOADING_PROVIDER_DATA_STATUS: {
      const newState = {
        ...state,
        loadingDataProvidersStatus: { ...state.loadingDataProvidersStatus },
      };
      if (!newState.loadingDataProvidersStatus[action.provider]) {
        newState.loadingDataProvidersStatus[action.provider] = {};
      }
      newState.loadingDataProvidersStatus[action.provider][action.key] = action.status;
      return newState;
    }
    case REMOVE_LOADING_SCRIPT: {
      const loadingScriptsProviders = state.loadingScriptsProviders;

      loadingScriptsProviders.splice(action.provider);

      return {
        ...state,
        loadingScriptsProviders,
      };
    }
    case RESET_PROVIDERS_STATE: {
      return defaultState;
    }
    default:
      return state;
  }
}

