import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';

import CartService from '../../../Service/Cart.service';
import * as donationFormActions from '../../../Actions/donationFormActions';

/**
 * MoneyBuyCarousel Component
 */
const MoneyBuyCarousel = ({ application, donationForm }) => {
  const cart = new CartService(application.cartId);
  const moneyBuys = cart.get('moneybuys')[donationForm.givingType];
  const num = Object.keys(moneyBuys).length;

  // Internet Explorer 6-11
  const isIE = /* @cc_on!@ */false || !!document.documentMode;

  if (isIE) {
    return (
      <ul className="carousel-fallback">
        {Object.keys(moneyBuys).map(key => (
          <li key={key}>{moneyBuys[key].description}</li>
        ))}
      </ul>
    );
  }

  return (
    <div>
      <CarouselProvider
        naturalSlideWidth={50}
        naturalSlideHeight={20}
        totalSlides={num}
        isPlaying
        interval={4500}
        infinite
      >
        <Slider
          classNameAnimation="moneybuy--slider"
        >
          {Object.keys(moneyBuys).map((key, index) => (
            <Slide index={index} key={key}>{moneyBuys[key].description}</Slide>
          ))}
        </Slider>
        <ButtonBack>Back</ButtonBack>
        <ButtonNext>Next</ButtonNext>
      </CarouselProvider>
    </div>
  );
};

export default connect(
  state => ({
    application: state.application,
    donationForm: state.donationForm,
  }),
  dispatch => bindActionCreators(donationFormActions, dispatch),
)(MoneyBuyCarousel);
