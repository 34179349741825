import {
  RESET_PAYMENT_STATE,
  SET_PAYMENT_COMPLETE,
  SET_PAYMENT_FAILED,
  SET_PREVIOUS_TRANSACTION_ID_TO_NULL,
  UPDATE_STEP,
  UPDATE_DATA,
  UPDATE_FIELD_VALIDATION,
  UPDATE_FIELDS_VALIDATION,
  UPDATE_ASYNC_TRANSACTION_STATUS,
  UPDATE_PAYMENT_PROVIDER,
  UPDATE_SUBSCRIPTION_ID,
  UPDATE_TRANSACTION_ID,
  SET_USER_CHOSEN_CARD_PAYMENT,
} from '../Actions/paymentActions';

const defaultState = {
  data: {},
  isAsyncTransaction: false,
  isValid: false,
  paymentIsComplete: false,
  paymentIsFailed: false,
  paymentProvider: null,
  step: '',
  subscriptionId: null,
  transactionId: null,
  previousTransactionId: null,
  validation: {},
  userHasChosenCardPayment: false,
};

/**
 * Update validation and isValid keys in state
 * @param  {Object}  state
 * @param  {Object}  action
 * @param  {Boolean} isSingle
 * @return {Object}  new state object
 */
const updateValidation = (state, action, isSingle = false) => {
  let validation;
  if (isSingle === true) {
    validation = { ...state.validation, [action.field]: action.validation };
  } else {
    validation = { ...state.validation, ...action.validation };
  }
  const newState = {
    ...state,
    validation,
  };
  // get first invalid field
  // values can be null or empty strings, so check for not true
  const invalidField = Object.keys(newState.validation)
    .find(field => newState.validation[field] && newState.validation[field].valid !== true);
  newState.isValid = invalidField === undefined;
  return newState;
};

/**
 * Payment Reducer
 * @param state
 * @param action
 * @return {*}
 */
export default function payment(state = defaultState, action) {
  switch (action.type) {
    case RESET_PAYMENT_STATE:
      return defaultState;

    case SET_PAYMENT_COMPLETE:
      return {
        ...state,
        paymentIsComplete: true,
      };

    case SET_PAYMENT_FAILED:
      return {
        ...state,
        paymentIsFailed: true,
      };

    case SET_PREVIOUS_TRANSACTION_ID_TO_NULL:
      return {
        ...state,
        previousTransactionId: null,
      };

    case UPDATE_ASYNC_TRANSACTION_STATUS:
      return {
        ...state,
        isAsyncTransaction: action.isAsyncTransaction,
      };

    case UPDATE_DATA:
      return {
        ...state,
        data: { ...state.data, [action.field]: action.value },
      };

    case UPDATE_FIELD_VALIDATION:
      return updateValidation(state, action, true);

    case UPDATE_FIELDS_VALIDATION:
      return updateValidation(state, action);

    case UPDATE_STEP:
      return { ...state, step: action.step };

    case UPDATE_SUBSCRIPTION_ID:
      return {
        ...state,
        subscriptionId: action.subscriptionId,
      };

    case UPDATE_TRANSACTION_ID:
      return {
        ...state,
        transactionId: action.transactionId,
        previousTransactionId: state.transactionId,
      };

    case UPDATE_PAYMENT_PROVIDER:
      return {
        ...state,
        paymentProvider: action.paymentProvider,
      };

    case SET_USER_CHOSEN_CARD_PAYMENT:
      return {
        ...state,
        userHasChosenCardPayment: action.userHasChosenCardPayment,
      };

    default:
      return state;
  }
}

