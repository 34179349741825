const FooterCopy = {
  SRDONATE: `Sport Relief is an initiative of Comic Relief. Comic Relief is the operating name of Charity Projects, 
  a registered charity in England and Wales (326568) and Scotland (SC039730), which is a company limited by
      guarantee registered in England and Wales (01806414). Registered address: 6th Floor, The White Chapel Building, 10 Whitechapel High Street, London, E1 8QS.`,
  CRDONATE: `Comic Relief is the operating name of Charity Projects, a registered charity in England and Wales 
  (326568) and Scotland (SC039730), which is a company limited by guarantee registered in England and Wales 
  (01806414). Registered address: 6th Floor, The White Chapel Building, 10 Whitechapel High Street, London, E1 8QS.`,
  ADDITIONAL: `<div class="cr-body">
  <p>Last year, from every £1 donation to Comic Relief: 
  <ul><li>76 pence helped thousands of vital projects supporting vulnerable people in the UK and internationally</li>
  <li>7.2 pence ensured we allocated our funding as effectively as possible and monitored how it was spent</li>
  <li>1.8 pence helped raise awareness of the work we support to tackle poverty and injustice</li>
  <li>15 pence helped raise even more money!</li></ul></p>
  </div>`,
};

export default FooterCopy;
