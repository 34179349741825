import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import CartService from '../../../Service/Cart.service';
import * as donationFormActions from '../../../Actions/donationFormActions';

/**
 * MoneyBuys Component
 */
class MoneyBuys extends Component {
  componentDidMount() {
    if (!this.props.donationForm.hasSetDefaultAmount) {
      this.setDefaultMoneybuy();

      this.props.updateHasSetDefaultAmount(true);
    }
  }

  componentDidUpdate(prevProps) {
    if (
      !this.props.donationForm.hasChangedGivingType &&
      this.props.donationForm.givingType !== prevProps.donationForm.givingType
    ) {
      this.setDefaultMoneybuy();

      this.props.updateHasChangedGivingType(true);
    }
  }

  /**
  * Function to set the default selected money, pulled from CMS.
  */
  setDefaultMoneybuy() {
    const moneyBuys = this.getMoneyBuys();

    const defaultIndex = this.getDefaultIndex(moneyBuys);

    this.props.updateAmount(
      moneyBuys[defaultIndex].amount.toString(),
      defaultIndex,
    );
  }

  /**
   * Function to get and limit defaut moneybuy index
   *
   * @param moneybuys
   */
  getDefaultIndex(moneyBuys) {
    // CMS indexes from 1 for UX
    let defaultIndex = this.props.defaultIndex - 1;

    if (defaultIndex > moneyBuys.length) defaultIndex = moneyBuys.length;

    return defaultIndex;
  }

  /**
   * Function to get moneyBuys per givingType
   */
  getMoneyBuys() {
    const cart = new CartService(this.props.application.cartId);
    const moneyBuys = cart.get('moneybuys')[this.props.donationForm.givingType];

    return moneyBuys;
  }

  /**
   * Function to change amount
   * @param event
   * @param amount
   * @param index
   */
  updateAmount(event, amount, index) {
    event.preventDefault();
    this.props.updateAmount(amount, index);
  }

  /**
   * MoneyBuys render
   * @return {*}
   */
  render() {
    const {
      donationForm,
    } = this.props;

    const moneyBuys = this.getMoneyBuys();

    return (
      <div>
        {Object.keys(moneyBuys).map(index => (
          <button
            key={moneyBuys[index].amount}
            className={parseFloat(donationForm.amount).toFixed(2) === parseFloat(moneyBuys[index].amount).toFixed(2) ? 'select-amount-btn selected' : 'select-amount-btn'}
            onClick={(event) => {
              this.updateAmount(event, moneyBuys[index].amount.toString(), index);
            }}
            aria-label={`${donationForm.currency.symbol}${moneyBuys[index].amount} could pay for ${moneyBuys[index].description}`}
          >
            <span className="select-amount">
              <span className="amount-text">{donationForm.currency.symbol}</span>
              <span className="amount-text amount-text-value">{moneyBuys[index].amount}</span>
            </span>
            <span className="select-amount--context text--body-small">{moneyBuys[index].description}</span>
          </button>
        ))}
      </div>
    );
  }
}

export default connect(
  state => ({
    application: state.application,
    donationForm: state.donationForm,
  }),
  dispatch => bindActionCreators(donationFormActions, dispatch),
)(MoneyBuys);
