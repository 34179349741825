const activityData = {
  // 'School, Nursery Or Youth Group'
  school: {
    fancy_dress: 'Fancy dress',
    baking: 'Baking',
    sponsored_challenge: 'Sponsored challenge',
    quiz_games: 'Quiz / games',
    talent_show: 'Talent show',
    sport_activity: 'Sport activity',
    other: 'Something else',
  },

  // 'Workplace'
  work: {
    fancy_dress: 'Fancy dress',
    baking: 'Baking',
    sponsored_challenge: 'Sponsored challenge',
    quiz_games: 'Quiz / games',
    sport_activity: 'Sport activity',
    other: 'Something else',
  },

  // Historic data label, aka 'Myself Or A Group'
  public: {
    fancy_dress: 'Fancy dress',
    baking: 'Baking',
    sponsored_challenge: 'Sponsored challenge',
    quiz_games: 'Quiz / games',
    sport_activity: 'Sport activity',
    other: 'Something else',
  },
  maxActivities: 8,

};

export default activityData;
