import React from 'react';
import { connect } from 'react-redux';
import { MYSELF_FRIENDS_OR_FAMILY, NURSERY_OR_SCHOOL, ORGANISATION_OR_WORKPLACE } from '../../Pages/PayIn/PayInAudience';
/**
 * AmountAndGivingTypeHeader component
 */
const AmountAndGivingTypeHeader = ({ donationForm: { amount, currency: { symbol } }, payInForm: { payInAudienceType, currentPayInStep } }) => {
  const audienceCopy = (type) => {
    switch (type) {
      case MYSELF_FRIENDS_OR_FAMILY:
        return 'for myself or a group';
      case NURSERY_OR_SCHOOL:
        return 'for a school, nursery or youth group';
      case ORGANISATION_OR_WORKPLACE:
        return 'for a workplace';
      default:
        return 'for a youth group or university';
    }
  };

  // Only add the audience detail when appropriate
  const additionalAudienceCopy = (payInAudienceType && currentPayInStep > 0 ? audienceCopy(payInAudienceType) : null);

  return (
    <p id="amount-giving-type-header">
      Paying in {symbol}{amount} {additionalAudienceCopy && <strong>{additionalAudienceCopy}</strong>}
    </p>
  );
};

// Connect the functional component to Redux store
export default connect(
  ({ donationForm, payInForm }) => ({ donationForm, payInForm }),
)(AmountAndGivingTypeHeader);

