import React, { Component } from 'react';
import { connect } from 'react-redux';

import PostcodeLookup from '@comicrelief/storybook/src/components/PostcodeLookup/PostcodeLookup';
import CheckboxField from '@comicrelief/storybook/src/components/CheckboxField/CheckboxField';

import { bindActionCreators } from 'redux';
import * as payInFormActions from '../../Actions/payInFormActions';

import FullHeightSingleImage from '../../Component/FullHeightSingleImage/FullHeightSingleImage';
import ErrorMessage from '../../Component/ErrorMessage/ErrorMessage';

import { ROUTES } from '../../Service/PaymentServiceRouter.service';
import ErrorDisplayService from '../../Service/ErrorDisplay.service';
import BackButton from '../../Component/BackButton/BackButton';
import AmountAndGivingTypeHeader from '../../Component/AmountAndGivingTypeHeader/AmountAndGivingTypeHeader';
import audienceImages from './data/audienceImages';
import Recaptcha from '../../Component/Recaptcha/Recaptcha';
import { canSubmitRecaptcha } from '../../Service/Recaptcha.service';

/**
 * PayInBillingAddress Component
 */
class PayInBillingAddress extends Component {
  constructor(props) {
    super(props);
    this.isSchool = this.props.payInForm.payInAudienceType === 'school';
    this.errorDisplay = new ErrorDisplayService();
  }

  /**
   * PayInBillingAddress componentWillMount
   */
  componentDidMount() {
    // Ensure user is following the journey linearly
    const prevStep = this.props.payInForm.currentPayInStep;
    const thisStep = 5;

    if (prevStep > thisStep + 1 || prevStep < thisStep - 1 || prevStep === null) {
      return this.props.history.push({ pathname: '/' });
    }

    document.title = `Pay-In: Billing Address${this.props.application.page.titlePostfix}`;

    // Update form progress
    return this.props.updateCurrentPayInStep(thisStep);
  }

  /**
   * handleSubmit
   * @param e
   */
  handleSubmit(e) {
    const { history, updateShowBillingAddressError, payInForm: { usePostalAddressAsBillingAddress, allAddressValidity: { isPayInBillingAddressValid } } } = this.props;

    e.preventDefault();

    // If we're not using the postal address, and the supplied billing address isn't valid, show the error
    if (!usePostalAddressAsBillingAddress && !isPayInBillingAddressValid) {
      updateShowBillingAddressError(true);
      this.errorDisplay.setScrollToErrorTimeoutMarkII();
      return null;
    }

    // Else, successfully submit as the entered Billing address is valid, or because copyied-over Postal address has already been validated
    return history.push({ pathname: `/${ROUTES.payIn.cardPayment}` });
  }

  /**
   * goBack event
   * @param e
   */
  goBack(event = null) {
    const { history } = this.props;
    if (event) event.preventDefault();
    history.push({ pathname: `/${ROUTES.payIn.paymentMethod}` });
  }

  /**
   * handleUsePostalAddress checkbox logic
   */
  handleUsePostalAddress() {
    const {
      updateUsePostalAddressAsBillingAddress,
      overwriteBillingAddress,
      updatePayInAllAddressValidity,
      payInForm: {
        usePostalAddressAsBillingAddress,
        allAddressValidation: { payInAddressValidation, payInSchoolAddressValidation },
      },
    } = this.props;

    // Check current value before switching over
    if (usePostalAddressAsBillingAddress) {
      // Function will fallback to default state values with no parameter supplied
      overwriteBillingAddress();
    } else {
      // Copy over relevant already-validated address to billing address, and update the 'is valid' flag for good measure
      const thisAddress = this.isSchool ? payInSchoolAddressValidation : payInAddressValidation;
      overwriteBillingAddress(thisAddress);
      updatePayInAllAddressValidity('isPayInBillingAddressValid', true);
    }
    // Flip value
    updateUsePostalAddressAsBillingAddress(!usePostalAddressAsBillingAddress);
  }

  /**
   * 'checkValues' helper function
   * @return {{isValid: Boolean: *}}
   */
  checkValues() {
    const { payInForm: { allAddressValidation } } = this.props;
    let isValid = true;

    // Check all values in validation obj for any falsy values
    Object.keys(allAddressValidation.payInBillingAddressValidation).forEach((field) => {
      const thisValid = allAddressValidation.payInBillingAddressValidation[field].valid;
      if (thisValid === false || thisValid === null || thisValid === '') {
        isValid = false;
      }
    });

    return isValid;
  }

  /**
   * handleValidation function
   * @param valid Boolean
   * @param name string
   */
  handleValidation(valid, name) {
    const { payInForm: { allAddressValidity, allAddressValidation }, updateAllPayInAddresses, updatePayInAllAddressValidity } = this.props;

    // Only run both update functions once something's changed, to prevent black holes
    if (allAddressValidation.payInBillingAddressValidation[name].value !== valid.value) {
      updateAllPayInAddresses('payInBillingAddressValidation', name, valid);
    }

    const areAllFieldsValid = this.checkValues();
    const currentValidity = allAddressValidity.isPayInBillingAddressValid;

    // Update the relevant validity flag
    if (areAllFieldsValid !== currentValidity) updatePayInAllAddressValidity('isPayInBillingAddressValid', areAllFieldsValid);
  }

  /**
   * Render Postcode
   */
  renderPostcodeLookupInputs() {
    const {
      application,
      payInForm: { usePostalAddressAsBillingAddress, allAddressValidation, allAddressValidity, showBillingAddressError },
    } = this.props;

    const postCodeLookupValidation = {
      postcode: allAddressValidation.payInBillingAddressValidation.postcode,
      address1: allAddressValidation.payInBillingAddressValidation.address1,
      address2: allAddressValidation.payInBillingAddressValidation.address2,
      address3: allAddressValidation.payInBillingAddressValidation.address3,
      town: allAddressValidation.payInBillingAddressValidation.town,
      country: allAddressValidation.payInBillingAddressValidation.country,
    };

    return (
      <PostcodeLookup
        ref={element => this.errorDisplay.setRef(element)}
        label="Billing address"
        buttonText="Find my address"
        valuesFromParent={postCodeLookupValidation}
        forceManualInput={application.dependencies.plus === false}
        showErrorMessages={!usePostalAddressAsBillingAddress && showBillingAddressError && !allAddressValidity.isPayInBillingAddressValid}
        isAddressValid={
          (validation) => {
            Object.keys(validation).map(key => this.handleValidation(validation[key], key));
          }
        }
      />
    );
  }

  /**
   * PayInDetails render
   * @return {*}
   */
  render() {
    const {
      payInForm: { payInAudienceType, usePostalAddressAsBillingAddress, allAddressValidity: { isPayInBillingAddressValid }, showBillingAddressError },
      isMobile,
      application,
      recaptcha,
    } = this.props;

    const generalFormError = "Please enter your billing address, or select 'Same as postal address'";

    const formIsInvalid = !usePostalAddressAsBillingAddress && !isPayInBillingAddressValid;

    return (
      <main role="main">
        <section className="paragraph--full-height-single-image-single-copy bg--blue-2023 paragraph--payin">

          {!isMobile && <FullHeightSingleImage
            alt={audienceImages[payInAudienceType]}
            desktop={audienceImages[payInAudienceType]}
          /> }

          <div className="form__step fhdisc__text-wrapper fhsisc__text-wrapper bg--transparent">
            <form
              name="comicrelief_payinbundle_payment"
              method="post"
              onSubmit={(e) => { this.handleSubmit(e); }}
              noValidate="novalidate"
              className={'has-validation-callback fhdisc__text-3 ' + (!usePostalAddressAsBillingAddress && !isPayInBillingAddressValid ? 'error' : '')}

            >

              <div className="form__header">
                <BackButton id="billing-address" handleClick={(e) => { this.goBack(e); }} ariaLabel="Back to payment type" />
                <AmountAndGivingTypeHeader />
              </div>

              <div className="form__row form__row--your--address">
                <h1> Billing address </h1>

                <CheckboxField
                  type="checkbox"
                  id="use-postal-address"
                  name="use-postal-address-checkbox"
                  label="Same as postal address"
                  setBackgroundColor
                  checked={usePostalAddressAsBillingAddress}
                  value={usePostalAddressAsBillingAddress ? 1 : 0}
                  handleCheckboxChange={() => { this.handleUsePostalAddress(); }}
                />

                {/* Only show PCLU if the user wants to enter a different billing address */}
                {!usePostalAddressAsBillingAddress && this.renderPostcodeLookupInputs()}

                {/* Only show general error  */}
                {!usePostalAddressAsBillingAddress && showBillingAddressError && !isPayInBillingAddressValid &&
                  <ErrorMessage copy={generalFormError} id="billing-address" />
                }

              </div>

              <Recaptcha action="payin_mode" />

              <div className="form__field--wrapper form__submit form__field--submit form__payin-billing-submit submit-btn-wrapper">
                <button
                  type="submit"
                  id="comicrelief_payinbundle_address_submit"
                  disabled={!canSubmitRecaptcha(recaptcha, application)}
                  className={'btn form__next cta' + (formIsInvalid || !canSubmitRecaptcha(recaptcha, application) ? ' disabled' : '')}
                  name="comicrelief_payinbundle_address[submit]"
                > Continue to payment method </button>
              </div>
            </form>
          </div>
        </section>
      </main>
    );
  }
}

function mapStateToProps(state) {
  return {
    application: state.application,
    donationForm: state.donationForm,
    addressSection: state.addressSection,
    payment: state.payment,
    payInForm: state.payInForm,
    recaptcha: state.recaptcha,
  };
}

function mapDispachToProps(dispatch) {
  return bindActionCreators(Object.assign({}, payInFormActions), dispatch);
}

export default connect(mapStateToProps, mapDispachToProps)(PayInBillingAddress);
