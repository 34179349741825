export const RESET_PAYIN_FORM_STATE = 'RESET_PAYIN_FORM_STATE';
// Audience
export const UPDATE_PAYIN_AUDIENCE_TYPE = 'UPDATE_PAYIN_AUDIENCE_TYPE';
export const UPDATE_PAYIN_ACTIVITY_VALIDITY = 'UPDATE_PAYIN_ACTIVITY_VALIDITY';
// Activity
export const UPDATE_OTHER_ACTIVITY = 'UPDATE_OTHER_ACTIVITY';
export const UPDATE_IS_MODAL_OPEN = 'UPDATE_IS_MODAL_OPEN';
export const ADD_ACTIVITY = 'ADD_ACTIVITY';
export const REMOVE_ACTIVITY = 'REMOVE_ACTIVITY';
export const SET_ACTIVITY_ERROR_MESSAGE = 'SET_ACTIVITY_ERROR_MESSAGE';
// Your Details
export const UPDATE_PAYIN_DETAILS = 'UPDATE_PAYIN_DETAILS';
export const UPDATE_PAYIN_YOUR_DETAILS_VALIDITY = 'UPDATE_PAYIN_YOUR_DETAILS_VALIDITY';
// Flag to choose address
export const UPDATE_USE_POSTAL_ADDRESS_AS_BILLING_ADDRESS = 'UPDATE_USE_POSTAL_ADDRESS_AS_BILLING_ADDRESS';
// Billing address
export const UPDATE_SHOW_BILLING_ADDRESS_ERROR = 'UPDATE_SHOW_BILLING_ADDRESS_ERROR';
// Handle all addresses dynamically
export const UPDATE_ALL_PAYIN_ADDRESSES = 'UPDATE_ALL_PAYIN_ADDRESSES';
export const UPDATE_PAYIN_ALL_ADDRESS_VALIDITY = 'UPDATE_PAYIN_ALL_ADDRESS_VALIDITY';
export const OVERWRITE_BILLING_ADDRESS = 'OVERWRITE_BILLING_ADDRESS';
// Reproducing existing marketing prefs state funcs
export const RESET_PAYIN_MARKETING_PREFS_STATE = 'RESET_PAYIN_MARKETING_PREFS_STATE';
export const UPDATE_PAYIN_PERMISSION_EMAIL = 'UPDATE_PAYIN_PERMISSION_EMAIL';
export const UPDATE_PAYIN_PERMISSION_POST = 'UPDATE_PAYIN_PERMISSION_POST';
export const UPDATE_PAYIN_PERMISSION_PHONE = 'UPDATE_PAYIN_PERMISSION_PHONE';
export const UPDATE_PAYIN_PERMISSION_SMS = 'UPDATE_PAYIN_PERMISSION_SMS';
export const RESET_PAYIN_PERMISSION_SMS = 'RESET_PAYIN_PERMISSION_SMS';
export const UPDATE_PAYIN_MARKETING_ERROR_MESSAGE = 'UPDATE_PAYIN_MARKETING_ERROR_MESSAGE';
export const UPDATE_PAYIN_MARKETING_VALIDITY = 'UPDATE_PAYIN_MARKETING_VALIDITY';
export const PAYIN_MARKETING_CONSENT_COMPLETE = 'PAYIN_MARKETING_CONSENT_COMPLETE';
export const PAYIN_MARKETING_CONSENT_NOT_COMPLETE = 'PAYIN_MARKETING_CONSENT_NOT_COMPLETE';
export const PAYIN_MARKETING_CONSENT_SUBMIT_DATA = 'PAYIN_MARKETING_CONSENT_SUBMIT_DATA';

export const UPDATE_PAYIN_MARKETING_CONSENT_PHONE_NUMBERS = 'UPDATE_PAYIN_MARKETING_CONSENT_PHONE_NUMBERS';

export const UPDATE_PAYIN_FORCE_FORM_ERRORS = 'UPDATE_PAYIN_FORCE_FORM_ERRORS';
export const RESET_PAYIN_ACTIVITIES = 'RESET_PAYIN_ACTIVITIES';
export const UPDATE_PAYIN_JOURNEY_START = 'UPDATE_PAYIN_JOURNEY_START';
export const UPDATE_IS_SUGGESTED_ORG_NAME = 'UPDATE_IS_SUGGESTED_ORG_NAME';
export const UPDATE_CURRENT_PAYIN_STEP = 'UPDATE_CURRENT_PAYIN_STEP';

/**
 * Update PayIn Audience Type
 * @param payInAudienceType string
 * @return {{type: string, payInAudienceType: string}}
 */
export function updatePayInAudienceType(payInAudienceType) {
  return {
    type: UPDATE_PAYIN_AUDIENCE_TYPE,
    payInAudienceType,
  };
}

/**
 * Update PayIn Activity Form Validity
 * @param isActivityFormValid Boolean
 * @param showActivityError Boolean
 * @return {{type: string, isActivityFormValid: Boolean, showActivityError: Boolean}}
 */
export function updatePayInActivityValidity(isActivityFormValid, showActivityError) {
  return {
    type: UPDATE_PAYIN_ACTIVITY_VALIDITY,
    isActivityFormValid,
    showActivityError,
  };
}

/**
 * Resets the payin form state
 * @return {{type: string}}
 */
export function resetPayInFormState() {
  return {
    type: RESET_PAYIN_FORM_STATE,
  };
}

/**
 * Updates 'Modal Open' state
 * @param isModalOpen Boolean
 * @return {{type: string, isModalOpen: Boolean}}
 */
export function updateIsModalOpen(isModalOpen) {
  return {
    type: UPDATE_IS_MODAL_OPEN,
    isModalOpen,
  };
}

/**
 * Add Activity
 * @param activityName string
 * @param activityValue string
 * @return {{type: string, activityName: string, activityValue: string}}
 */
export function addActivity(activityName, activityValue) {
  return {
    type: ADD_ACTIVITY,
    activityName,
    activityValue,
  };
}

/**
 * Remove Activity
 * @param activityName string
 * @return {{type: string, activityName: string}}
 */
export function removeActivity(activityName) {
  return {
    type: REMOVE_ACTIVITY,
    activityName,
  };
}

/**
 * Update PayIn Detail Field/s
 * @param name string
 * @param valid Object
 * @return {{type: string, name: string, valid: Object}}
 */
export function updatePayInDetails(name, valid) {
  return {
    type: UPDATE_PAYIN_DETAILS,
    name,
    valid,
  };
}

/**
 * Update PayIn 'Your Details' Form Validity
 * @param isPayInYourDetailsValid Boolean
 * @return {{type: string, isPayInYourDetailsValid: Boolean}}
 */
export function updatePayInYourDetailsValidity(isPayInYourDetailsValid) {
  return {
    type: UPDATE_PAYIN_YOUR_DETAILS_VALIDITY,
    isPayInYourDetailsValid,
  };
}

/**
 * Update PayIn Validity Object For All Types Of Address
 * @param name string
 * @param valid Object
 * @return {{type: string, name: string, valid: Object}}
 */
export function updatePayInAllAddressValidity(name, valid) {
  return {
    type: UPDATE_PAYIN_ALL_ADDRESS_VALIDITY,
    name,
    valid,
  };
}

/**
 * Update PayIn 'Use Postal Address' Flag
 * @param updateUsePostalAddressAsBillingAddress Boolean
 * @return {{type: string, updateUsePostalAddressAsBillingAddress: Boolean}}
 */
export function updateUsePostalAddressAsBillingAddress(usePostalAddressAsBillingAddress) {
  return {
    type: UPDATE_USE_POSTAL_ADDRESS_AS_BILLING_ADDRESS,
    usePostalAddressAsBillingAddress,
  };
}

/**
 * Update All Payin Addresses
 * @param whichAddress string
 * @param name string
 * @param valid Object
 * @return {{type: string, whichAddress: string, name: string, valid: Object}}
 */
export function updateAllPayInAddresses(whichAddress, name, valid) {
  return {
    type: UPDATE_ALL_PAYIN_ADDRESSES,
    whichAddress,
    name,
    valid,
  };
}

/**
 * Update All Payin Addresses
 * @param showBillingAddressError Boolean
 * @return {{type: string, showBillingAddressError: Boolean}}
 */
export function updateShowBillingAddressError(showBillingAddressError) {
  return {
    type: UPDATE_SHOW_BILLING_ADDRESS_ERROR,
    showBillingAddressError,
  };
}

/**
 * Update All Payin Addresses
 * @param overwriteObject Object
 * @return {{type: string, overwriteObject: Object}}
 */
export function overwriteBillingAddress(overwriteObject) {
  return {
    type: OVERWRITE_BILLING_ADDRESS,
    overwriteObject,
  };
}

/**
 * Resets the state of the Marketingprefs section
 * @return {{type: string}}
 */
export function resetPayInMarketingPrefsState() {
  return {
    type: RESET_PAYIN_MARKETING_PREFS_STATE,
  };
}

/**
 * Resets the state of the permission SMS
 * @return {{type: string}}
 */
export function resetPayInPermissionSMS() {
  return {
    type: RESET_PAYIN_PERMISSION_SMS,
  };
}


/**
 * Update Marketing preferences form state
 * @return {{type: string}}
 */
export function payInMarketingConsentComplete() {
  return {
    type: PAYIN_MARKETING_CONSENT_COMPLETE,
  };
}

/**
 * Update Marketing preferences form state
 * @return {{type: string}}
 */
export function payInMarketingConsentNotComplete() {
  return {
    type: PAYIN_MARKETING_CONSENT_NOT_COMPLETE,
  };
}

/**
 * Update Marketing preferences form data submit flag
 * @return {{type: string}}
 */
export function payInMarketingConsentSubmitData() {
  return {
    type: PAYIN_MARKETING_CONSENT_SUBMIT_DATA,
  };
}

/**
 * Changes field permission states
 * @param checkbox string
 * @param validation Object
 * @return {{type: string, *: Object}}
 */
export function changePayInPermissions(checkbox, validation) {
  let value = validation.value;
  let checked = false;

  if (value !== null) {
    value = value === 'yes' ? 1 : 0;
    checked = true;
  }

  // set validity for fields
  const fieldValidity = (checked === true && validation.valid === true)
    || (checked === false && validation.value === null);

  const phoneValidity = (checked === false
    && typeof validation.fieldValidation.phone !== 'undefined'
    && validation.fieldValidation.phone.value === '')
    || (checked === true && typeof validation.fieldValidation.phone !== 'undefined' && validation.fieldValidation.phone.valid === true);

  const mobileValidity = (checked === false
    && typeof validation.fieldValidation.mobile !== 'undefined'
    && validation.fieldValidation.mobile.value === '')
    || (typeof validation.fieldValidation.mobile !== 'undefined' && validation.fieldValidation.mobile.valid === true);

  switch (checkbox) {
    case 'permissionEmail':
      return {
        type: UPDATE_PAYIN_PERMISSION_EMAIL,
        permissionEmail: {
          checked,
          value,
          valid: fieldValidity,
          validation,
        },
      };
    case 'permissionPost':
      return {
        type: UPDATE_PAYIN_PERMISSION_POST,
        permissionPost: {
          checked,
          value,
          valid: fieldValidity,
          validation,
        },
      };
    case 'permissionPhone':
      return {
        type: UPDATE_PAYIN_PERMISSION_PHONE,
        permissionPhone: {
          checked,
          value,
          valid: phoneValidity,
          validation,
        },
      };
    case 'permissionSMS':
      return {
        type: UPDATE_PAYIN_PERMISSION_SMS,
        permissionSMS: {
          checked,
          value,
          valid: mobileValidity,
          validation,
        },
      };
    default:
      return null;
  }
}

/**
 * Update PayIn Marketing Error Message
 * @param error string
 * @return {{type: string, error: string}}
 */
export function updatePayInMarketingErrorMessage(error) {
  return {
    type: UPDATE_PAYIN_MARKETING_ERROR_MESSAGE,
    error,
  };
}

/**
 * Update PayIn Marketing Form Validity
 * @param status Boolean
 * @return {{type: string, status: Boolean}}
 */
export function updatePayInMarketingValidity(status) {
  return {
    type: UPDATE_PAYIN_MARKETING_VALIDITY,
    status,
  };
}

/**
 * Force form errors to show for address lookups
 * @param forceFormErrors Boolean
 * @return {{type: string, forceFormErrors: Boolean}}
 */
export function updatePayInForceFormErrors(forceFormErrors) {
  return {
    type: UPDATE_PAYIN_FORCE_FORM_ERRORS,
    forceFormErrors,
  };
}

/**
 * Reset Payin activites on 'audience' change
 * @return {{type: string}}
 */
export function resetPayInActivities() {
  return {
    type: RESET_PAYIN_ACTIVITIES,
  };
}

/**
 * Force form errors to show for address lookups
 * @param activityErrorMessage string
 * @return {{type: string, activityErrorMessage: string}}
 */
export function setActivityErrorMessage(activityErrorMessage) {
  return {
    type: SET_ACTIVITY_ERROR_MESSAGE,
    activityErrorMessage,
  };
}

/**
 * Update current form step
 * @param currentPayInStep number
 * @return {{type: string, currentPayInStep: number}}
 */
export function updateCurrentPayInStep(currentPayInStep) {
  return {
    type: UPDATE_CURRENT_PAYIN_STEP,
    currentPayInStep,
  };
}

/**
 * Update phone number state values used in Marketing Prefs form
 * @param phoneNumber string
 * @param smsNumber string
 * @return {{type: string, phoneNumber: string, smsNumber: string}}
 */
export function updatePayInMarketingContentPhoneNumbers(phoneNumber, smsNumber) {
  return {
    type: UPDATE_PAYIN_MARKETING_CONSENT_PHONE_NUMBERS,
    phoneNumber,
    smsNumber,
  };
}

/**
 * Update flag if user selects a suggested (partner) org name
 * @param isSuggestedOrgName Boolean
 * @return {{type: string, isSuggestedOrgName: boolean}}
 */
export function updateIsSuggestedOrgName(isSuggestedOrgName) {
  return {
    type: UPDATE_IS_SUGGESTED_ORG_NAME,
    isSuggestedOrgName,
  };
}
