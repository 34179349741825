import { useRef } from 'react';
import { withRouter } from 'react-router-dom';

/**
 * ScrollToTop component
 */
const ScrollToTop = () => {
  const isMounted = useRef(false);

  if (isMounted.current) {
    const elmnt = document.getElementById('root');
    try {
      elmnt.scrollIntoView(true);
    } catch (err) {
      if (err instanceof TypeError) {
        window.scrollTo(0, 0);
      } else {
        throw err;
      }
    }
  } else {
    // This marks the component as mounted on the first effect run
    isMounted.current = true;
  }

  return null;
};

export default withRouter(ScrollToTop);
