import {
  RESET_SUCCESS_STATE,
  SUBMIT_MARKETING_CONSENT_FORM,
  UPDATE_PERMISSION_EMAIL,
  UPDATE_PERMISSION_POST,
  UPDATE_PERMISSION_PHONE,
  UPDATE_PERMISSION_SMS,
  RESET_PERMISSION_SMS,
  UPDATE_ERROR_MESSAGE,
  UPDATE_VALIDITY,
} from '../Actions/successActions';

const defaultState = {
  validating: false,
  formSubmitted: false,
  formValidity: false,
  showErrorMessages: false,
  permissionEmail: {
    value: null,
    valid: false,
  },
  permissionPost: {
    value: null,
    valid: false,
  },
  permissionPhone: {
    isFieldsHidden: false,
    value: null,
    valid: false,
    fieldValidation: false,
  },
  permissionSMS: {
    isFieldsHidden: false,
    value: null,
    valid: false,
    fieldValidation: false,
  },
  errorMessage: '',
};

/**
 * Success Reducer
 * @param state
 * @param action
 * @return {*}
 */
export default function success(state = defaultState, action) {
  switch (action.type) {
    case RESET_SUCCESS_STATE:
      return defaultState;

    case RESET_PERMISSION_SMS:
      return {
        ...state,
        permissionSMS: defaultState.permissionSMS,
      };

    case SUBMIT_MARKETING_CONSENT_FORM:
      return {
        ...state,
        formSubmitted: action.formSubmitted === true,
      };
    case UPDATE_PERMISSION_EMAIL:
      return {
        ...state,
        permissionEmail: {
          ...state.permissionEmail,
          checked: action.permissionEmail.checked,
          value: action.permissionEmail.value,
          valid: action.permissionEmail.valid,
          validation: action.permissionEmail.validation,
        },
      };
    case UPDATE_PERMISSION_POST:
      return {
        ...state,
        permissionPost: {
          ...state.permissionPost,
          checked: action.permissionPost.checked,
          value: action.permissionPost.value,
          valid: action.permissionPost.valid,
          validation: action.permissionPost.validation,
        },
      };
    case UPDATE_PERMISSION_PHONE:
      return {
        ...state,
        permissionPhone: {
          ...state.permissionPhone,
          checked: action.permissionPhone.checked,
          value: action.permissionPhone.value,
          valid: action.permissionPhone.valid,
          validation: action.permissionPhone.validation,
        },
      };
    case UPDATE_PERMISSION_SMS:
      return {
        ...state,
        permissionSMS: {
          ...state.permissionSMS,
          checked: action.permissionSMS.checked,
          value: action.permissionSMS.value,
          valid: action.permissionSMS.valid,
          validation: action.permissionSMS.validation,
        },
      };
    case UPDATE_ERROR_MESSAGE:
      return {
        ...state,
        errorMessage: action.error,
      };
    case UPDATE_VALIDITY:
      return {
        ...state,
        validating: !action.status === true,
        formValidity: action.status === true,
        showErrorMessages: !action.status === true,
      };
    default:
      return state;
  }
}
