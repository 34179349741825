import React, { Component } from 'react';
import { connect } from 'react-redux';
import DropIn from 'braintree-web-drop-in-react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';

import PaymentServiceRouter, { ROUTES } from '../../../Service/PaymentServiceRouter.service';
import { paymentProviders } from '../../../Service/PaymentConfiguration.service';
import { GIVING_TYPE_MONTHLY, GIVING_TYPE_PAYIN } from '../../../Pages/DonationForm/GivingTypeSelector/GivingTypeSelector';
import * as paymentActions from '../../../Actions/paymentActions';

import './Braintree.scss';
import { canSubmitRecaptcha, getRecaptchaTokenAndVersion, handleRecaptchaResponse, refreshRecaptchaToken } from '../../../Service/Recaptcha.service';
import store from '../../../Store/store';
import Recaptcha from '../../Recaptcha/Recaptcha';
import { setRecaptchaTokenV2, setRecaptchaVersion } from '../../../Actions/recaptchaActions';

/**
 * Braintree class
 */
class Braintree extends Component {
  /**
   * Braintree constructor
   */
  constructor() {
    super();
    this.instance = null;
    this.state = {
      loading: true,
      clientToken: null,
      accountIdentifier: null,
      errorResponse: false,
      isSubmittingForm: false,
      failTransaction: false,
    };
    this.componentIsMounted = false;
  }

  /**
   * Braintree componentDidMount
   * @return {Promise<void>}
   */
  async componentDidMount() {
    const { application } = this.props;

    await refreshRecaptchaToken(application, 'refresh_generated_token');

    this.create();
  }

  /**
   * Braintree componentWillUnmount
   */
  componentWillUnmount() {
    this.componentIsMounted = false;
  }


  async create(hasFailed = false) {
    let createParams = {};
    this.componentIsMounted = true;
    const {
      addressSection,
      application,
      donationForm,
      giftaidSection,
      payment,
      updatePaymentProvider,
      updateSubscriptionId,
      updateTransactionId,
      recaptcha,
      history,
      onFailure,
      payInForm: { payInDetailsValidation, allAddressValidation: { payInBillingAddressValidation } },
    } = this.props;

    await refreshRecaptchaToken(application, 'refresh_after_decline');

    const { token, version } = getRecaptchaTokenAndVersion(application, recaptcha);

    const route = donationForm.givingType === GIVING_TYPE_MONTHLY
      ? ROUTES.provider.braintree.createSubscription
      : ROUTES.provider.braintree.create;

    if (application.isPaymentPage) {
      createParams = {
        client: application.client,
        transactionId: payment.transactionId,
        recaptcha_token: token,
        recaptcha_version: version,
      };
    } else {
      // As per https://github.com/comicrelief/react-donation/issues/710
      const thisGiftAid = donationForm.givingType === GIVING_TYPE_PAYIN ? null : giftaidSection.giftaid;

      // Construct new object from separate Payin fields for ease of assignment
      const thisAddressObject = donationForm.givingType === GIVING_TYPE_PAYIN ?
        { ...payInDetailsValidation, ...payInBillingAddressValidation } : addressSection;

      createParams = {
        successUrl: application.callbackUrls.successUrl,
        failureUrl: application.callbackUrls.failureUrl,
        recoverUrl: application.callbackUrls.recoverUrl,
        campaign: application.campaign,
        transSource: application.transSource,
        transSourceUrl: application.callbackUrls.transSourceUrl,
        transType: application.transType,
        affiliate: application.affiliate,
        cartId: application.cartId,
        client: application.client,
        order_reference: donationForm.orderReference,
        amount: donationForm.amount,
        currency: donationForm.currency.name,
        giftaid: thisGiftAid,
        firstName: thisAddressObject.firstName.value,
        lastName: thisAddressObject.lastName.value,
        email: thisAddressObject.email.value,
        postcode: thisAddressObject.postcode.value,
        address1: thisAddressObject.address1.value,
        address2: thisAddressObject.address2.value,
        address3: thisAddressObject.address3.value,
        town: thisAddressObject.town.value,
        country: thisAddressObject.country.value,
        recaptcha_token: token,
        recaptcha_version: version,
      };
    }

    // Get a client token for authorization from your server
    const response = await fetch(PaymentServiceRouter.get(route), {
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(createParams),
    });

    const responseData = await response.json();

    if (handleRecaptchaResponse(response.status, responseData)) {
      this.setState({
        loading: false,
      });

      if (hasFailed) {
        this.setState({
          failTransaction: true,
        });
        return null;
      } else if (application.transType === 'payin') {
        return history.push({ pathname: '/form/payin/billing-address' });
      } else if (!application.isPaymentPage) {
        return history.push({ pathname: '/form/address' });
      }

      // PaymentForm:userHasChosenCardPayment state to false
      store.dispatch(paymentActions.setUserHasChosenCardPayment(false));

      return false;
    }

    if (response.status !== 200) {
      return onFailure();
    }

    // Update the payment provider
    updatePaymentProvider(paymentProviders.BRAINTREE);

    // Set the transaction id / subscription id
    if (donationForm.givingType === GIVING_TYPE_MONTHLY) {
      updateSubscriptionId(responseData.data.subscriptionId);
    } else {
      updateTransactionId(responseData.data.transactionId);
    }

    this.setState({
      loading: false,
      clientToken: responseData.data.token,
      accountIdentifier: responseData.data.account_identifier,
    });

    return null;
  }

  /**
   * On Purchase confirmation
   * @param event
   * @return {Promise<void>}
   */
  async buy(event) {
    event.preventDefault();

    let route;
    const {
      application,
      addressSection,
      donationForm,
      giftaidSection,
      onFailure,
      onSuccess,
      payment,
      setPaymentAsComplete,
      updateAsyncTransactionStatus,
      payInForm: { payInDetailsValidation, allAddressValidation: { payInBillingAddressValidation } },
    } = this.props;

    this.setState({
      isSubmittingForm: true,
    });

    // Switch source of user details (no address here) based on payin type for ease of assignment
    const thisAddressObject = donationForm.givingType === GIVING_TYPE_PAYIN ?
      { ...payInDetailsValidation, ...payInBillingAddressValidation } : addressSection;

    const body = {
      amount: donationForm.amount,
      currency: donationForm.currency.name,
      client: application.client,
      account_identifier: this.state.accountIdentifier,
      firstName: thisAddressObject.firstName.value,
      lastName: thisAddressObject.lastName.value,
      email: thisAddressObject.email.value,
    };

    const threeDSecureParameters = {
      amount: donationForm.amount,
      email: thisAddressObject.email.value,
      billingAddress: {
        givenName: thisAddressObject.firstName.value,
        surname: thisAddressObject.lastName.value,
        streetAddress: thisAddressObject.address1.value,
        extendedAddress: thisAddressObject.address2.value,
        locality: thisAddressObject.town.value,
        postalCode: thisAddressObject.postcode.value,
        countryCodeAlpha2: thisAddressObject.country.value,
      },
    };

    try {
      const payload = await this.instance.requestPaymentMethod({
        threeDSecure: threeDSecureParameters,
      });
      body.nonce = payload.nonce;
    } catch (e) {
      this.setState({
        isSubmittingForm: false,
      });

      return null;
    }

    this.setState({
      loading: true,
    });

    if (donationForm.givingType === GIVING_TYPE_MONTHLY) {
      route = ROUTES.provider.braintree.executeSubscription;
      body.subscriptionId = payment.subscriptionId;
    } else {
      body.transactionId = payment.transactionId;

      if (giftaidSection.paymentType === paymentProviders.BRAINTREE_ASYNC) {
        route = ROUTES.provider.braintree.executeAsync;
        updateAsyncTransactionStatus(true);
      } else {
        route = ROUTES.provider.braintree.execute;
      }
    }

    const execution = await fetch(PaymentServiceRouter.get(route), {
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(body),
    });

    if (execution.status === 200) {
      setPaymentAsComplete();

      return onSuccess();
    }

    try {
      const responseData = await execution.json();

      if (responseData.data.type !== 'undefined' && responseData.data.type === 'cardError' && this.componentIsMounted === true) {
        this.setState({
          loading: false,
          isSubmittingForm: false,
          errorResponse: responseData.message,
        });

        // to reset recpatchatokenv2 if the first create failed
        store.dispatch(setRecaptchaTokenV2(''));

        await refreshRecaptchaToken(application, 'refresh_after_decline');

        store.dispatch(paymentActions.setPaymentAsFailed());
        store.dispatch(setRecaptchaVersion(2));

        return null;
      }

      return onFailure();
    } catch (e) {
      return onFailure();
    }
  }

  /**
   * Render the payment form
   * @return {*}
   */
  paymentFormRender() {
    const { application, recaptcha, donationForm: { amount, currency: { symbol }, givingType }, options } = this.props;


    let buttonText;

    if (application.isPaymentPage) {
      buttonText = 'Complete Payment';
    } else if (givingType === GIVING_TYPE_MONTHLY) {
      buttonText = `Donate ${symbol}${amount} a month`;
    } else {
      buttonText = 'Complete Donation';
    }

    return (
      <div>
        {this.state.errorResponse !== false || this.state.failTransaction ?
          <React.Fragment>
            <div className="inner-content failure bg--transparent">
              <span className="help-block form-error">The payment did not go through and you haven&apos;t been charged.</span>
            </div>

            <br />
            <Recaptcha action="transaction_fail_mode" />
            <br />

            <div className="form__field--wrapper form__submit form__field--submit submit-btn-wrapper">
              <button
                className={`btn form__next cta ${!canSubmitRecaptcha(recaptcha, application) ? 'invalid' : ''}`}
                disabled={!canSubmitRecaptcha(recaptcha, application)}
                onClick={() => {
                  this.setState({
                    errorResponse: false,
                    loading: true,
                    failTransaction: false,
                  });
                  this.create('fail');
                }
                }
              >Try Again</button>
            </div>
          </React.Fragment> : ''
        }

        {this.state.errorResponse === false && !this.state.failTransaction &&
        <React.Fragment>
          <DropIn
            options={Object.assign({}, { authorization: this.state.clientToken, threeDSecure: true }, options)}
            onInstance={instance => (this.instance = instance)}
          />
          <div className="form__field--wrapper form__submit form__field--submit submit-btn-wrapper">
            <button
              id="comicrelief_payinbundle_payment_amount_submit"
              type="submit"
              name="comicrelief_payinbundle_payment[submit]"
              onClick={(e) => {
                this.buy(e);
              }}
              disabled={this.state.isSubmittingForm}
              className="form__next cta"
            >{buttonText}
            </button>
          </div>
        </React.Fragment>
        }

      </div>
    );
  }

  /**
   * Render the loader
   * @return {*}
   */
  renderLoader() {
    const { application } = this.props;

    if (application.isPaymentPage) {
      return (
        <div className="loader-container">
          <p>Creating payment session with Braintree</p>
          <span className="loader" />
        </div>
      );
    }

    return (
      <div className="loader-container">
        <span className="loader" />
      </div>
    );
  }

  /**
   * Braintree render
   * @return {*}
   */
  render() {
    return (
      <div>
        {this.state.loading === true ||
        this.componentIsMounted === false ?
          (
            this.renderLoader()
          ) : (
            this.paymentFormRender()
          )}
      </div>
    );
  }
}

Braintree.defaultProps = {
  options: {},
};

Braintree.propTypes = {
  options: PropTypes.object,
  onSuccess: PropTypes.func.isRequired,
  onFailure: PropTypes.func.isRequired,
};

export default connect(
  ({ addressSection, application, recaptcha, donationForm, giftaidSection, payment, payInForm }) => ({
    addressSection,
    application,
    donationForm,
    recaptcha,
    giftaidSection,
    payment,
    payInForm,
  }),
  dispatch => bindActionCreators(Object.assign({}, paymentActions), dispatch),
)(Braintree);
