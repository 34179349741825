import React, { Component } from 'react';

import { connect } from 'react-redux';

import MarketingConsent from '@comicrelief/storybook/src/components/MarketingConsent/MarketingConsent';
import JustInTime from '@comicrelief/storybook/src/components/JustInTime/JustInTime';

import { bindActionCreators } from 'redux';

import * as successActions from '../../../Actions/successActions';

import marketingConsentData from './marketingConsentData.json';
import membershipMarketingConsentData from './membershipMarketingConsentData.json';
import PaymentServiceRouter, { ROUTES } from '../../../Service/PaymentServiceRouter.service';
import { GIVING_TYPE_MONTHLY } from '../../DonationForm/GivingTypeSelector/GivingTypeSelector';
import ProgressBar from '../../../Component/ProgressBar/ProgressBar';

/**
 * MarketingPreferences Component
 */
class MarketingPreferences extends Component {
  constructor(props) {
    super(props);
    this.scrollTimeout = null;
  }
  /**
   * Deals with component update after pressing submit button
   */
  componentDidUpdate() {
    const { successSection } = this.props;
    if (successSection.showErrorMessages && !successSection.formValidity && successSection.validating) {
      // timeout needed for error class names to appear
      this.scrollTimeout = setTimeout(() => { this.scrollToError(); }, 500);
    }
  }
  /**
   * Submit the marketing preferences form
   *
   */
  async onSubmitCard() {
    try {
      const { payment, successSection } = this.props;

      // get phone number
      const phoneNumber = typeof successSection.permissionPhone.validation.fieldValidation !== 'undefined' &&
      successSection.permissionPhone.validation.fieldValidation.phone.value !== ''
      && successSection.permissionPhone.validation.fieldValidation.phone.value !== null
        ? successSection.permissionPhone.validation.fieldValidation.phone.value : null;

      // send mobile value if permissionSMS is set
      const mobileNumber = successSection.permissionSMS.value !== null && successSection.permissionSMS.value !== ''
      && typeof successSection.permissionSMS.validation.fieldValidation !== 'undefined'
        && successSection.permissionSMS.validation.fieldValidation.mobile.value !== ''
        ? successSection.permissionSMS.validation.fieldValidation.mobile.value : null;

      const response = await fetch(PaymentServiceRouter.get(ROUTES.transaction.updateMarketingPreferences), {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          transactionId: payment.transactionId,
          subscriptionId: payment.subscriptionId,
          permissionEmail: successSection.permissionEmail.value,
          permissionPost: successSection.permissionPost.value,
          permissionPhone: successSection.permissionPhone.value,
          permissionSMS: successSection.permissionSMS.value,
          phone: phoneNumber,
          mobile: mobileNumber,
        }),

      });
      const responseData = await response.json();
      if (typeof response !== 'undefined' && response.status === 200) {
        this.props.submitMarketingConsentForm(true);
      } else {
        this.props.submitMarketingConsentForm(false);
        this.props.updateErrorMessage(responseData);
      }
    } catch (e) {
      this.props.updateErrorMessage(e);
    }
  }

  /**
   * Check if any permission is set
   * @returns {boolean}
   */
  isPermissionSet() {
    const { successSection } = this.props;
    return (successSection.permissionEmail.value !== null
      && successSection.permissionEmail.value !== '')
    || (successSection.permissionPost.value !== null
        && successSection.permissionPost.value !== '')
      || (successSection.permissionPhone.value !== null
        && successSection.permissionPhone.value !== '')
      || (successSection.permissionSMS.value !== null
        && successSection.permissionSMS.value !== '');
  }

  /**
   * Checks if any field is invalid.
   * If invalid fields: shows error sets state to show errorMessages.
   * If all fields valid: sets form validity to true
   * @param e
   */
  validateForm(e) {
    e.preventDefault();
    const { successSection } = this.props;
    // Post form values only if permission is set
    if (this.isPermissionSet()) {
      let validity = true;
      const validation = {
        permissionEmail: successSection.permissionEmail,
        permissionPost: successSection.permissionPost,
        permissionPhone: successSection.permissionPhone,
        permissionSMS: successSection.permissionSMS,
      };

      Object.keys(validation).map((key) => {
        const item = validation[key];
        if (item.valid !== true) {
          validity = false;
        }
        return true;
      });

      // update state accordingly
      if (validity !== true) {
        this.props.updateValidity(false);
      } else {
        this.props.updateValidity(true);
        this.onSubmitCard();
      }
    } else {
      this.props.submitMarketingConsentForm(true);
    }
  }

  /**
   * Goes through field refs, gets the first erroring field and focuses on it,
   * uses additional to checks to suit specifc compnents
   */
  scrollToError() {
    // Scroll to the first erroring field and focus on its input field
    const errorWrapper = document.querySelectorAll('.form__field--erroring')[0];
    const errorField = document.querySelectorAll('.form__field--erroring > div input')[0];

    if (typeof errorWrapper !== 'undefined') {
      errorWrapper.scrollIntoView('smooth');
      errorField.focus();
    }
    clearTimeout(this.scrollTimeout);
  }

  render() {
    const { successSection, application, donationForm } = this.props;
    const props = {};

    // get marketing consent data based on client
    const consentData = application.client !== 'the_fix' ? marketingConsentData : membershipMarketingConsentData;

    const isMonthly = donationForm.givingType === GIVING_TYPE_MONTHLY;

    // As the step counter doesn't actually get increased AFTER payment,
    // we're having to simulate it here for the Progress Bar component;
    // the Single giving type has 3 steps before this, Monthly has 4.
    const currentStep = isMonthly ? 5 : 4;

    return (
      <div className="form__step form__step--payment fhsisc__text-wrapper bg--transparent">
        <form
          name="comicrelief_payinbundle_marketing-pref"
          method="post"
          onSubmit={(e) => { e.preventDefault(); }}
          noValidate="novalidate"
          className="has-validation-callback marketing-pref-form"
        >
          <div>
            <ProgressBar currentStep={currentStep} isMonthly={isMonthly} text="Just one last thing..." />
            <div className="form__row form__row--marketing-consent">
              <p className="thankyou-subheading">
                {isMonthly ? 'We’ve successfully received your generous monthly donation, but just before we show you the details, would you like to...'
                  : 'We’ve successfully received your generous donation, but just before we show you the details, would you like to...'}
              </p>

              <h2 className="text-align-center marketing-prefs-heading">Stay up to date with Comic Relief?</h2>
            </div>
            <MarketingConsent
              getValidation={(validation) => {
                Object.keys(validation).forEach(key => this.props.changePermissions(key, validation[key]));
              }}
              itemData={consentData}
              valueFromParent={
                {
                  permissionEmail: successSection.permissionEmail.validation,
                  permissionPost: successSection.permissionPost.validation,
                  permissionPhone: successSection.permissionPhone.validation,
                  permissionSMS: successSection.permissionSMS.validation,
                }
              }
              showErrorMessages={successSection.showErrorMessages}
              {...props}
            />
            <div className="form__step--marketing-pref--bottom">
              <button
                className="btn btn--red cta"
                type="submit"
                aria-label="Submit"
                onClick={e => this.validateForm(e)}
              > Show me my donation details
              </button>

              <JustInTime linkText="Why do we collect this info?">
                <p>
                  <strong>Name, surname, email and billing address:</strong> we need these to process your payment,
                  create a receipt and send it to you. We’ll also use it to claim Gift Aid, if you’ve told us to.
                </p>
                <p>
                  Your details will be kept safe and never shared with other organisations; see our <a href="https://www.comicrelief.com/privacy-policy" rel="noopener noreferrer" target="_blank" className="link link-dark--grey">privacy policy <span className="visuallyhidden">(opens in a new window)</span></a>.
                </p>
              </JustInTime>

            </div>
          </div>

        </form>
      </div>
    );
  }
}

export default connect(
  ({ addressSection, application, donationForm, giftaidSection, payment, successSection }) => ({
    addressSection, application, donationForm, giftaidSection, payment, successSection,
  }),
  dispatch => bindActionCreators(Object.assign({}, successActions), dispatch),
)(MarketingPreferences);
