export const APPLICATION_LOAD = 'APPLICATION_LOAD';
export const GTM_INSTANTIATED = 'GTM_INSTANTIATED';
export const RESET_APPLICATION_STATE = 'RESET_APPLICATION_STATE';
export const UPDATE_CART_ID = 'UPDATE_CART_ID';
export const UPDATE_CLIENT = 'UPDATE_CLIENT';
export const UPDATE_COMPLETED_PAGE_STEP = 'UPDATE_COMPLETED_PAGE_STEP';
export const UPDATE_CURRENT_PAGE_STEP = 'UPDATE_CURRENT_PAGE_STEP';
export const UPDATE_DEPENDENCIES = 'UPDATE_DEPENDENCIES';
export const UPDATE_PROVIDERS = 'UPDATE_PROVIDERS';
export const UPDATE_PROVIDERS_CONFIGURATION = 'UPDATE_PROVIDERS_CONFIGURATION';
export const UPDATE_SUCCESS_URL = 'UPDATE_SUCCESS_URL';
export const UPDATE_FAILURE_URL = 'UPDATE_FAILURE_URL';
export const UPDATE_PAYMENT_PAGE_STATUS = 'UPDATE_PAYMENT_PAGE_STATUS';
export const UPDATE_RECENT_DONORS = 'UPDATE_RECENT_DONORS';
export const SET_RECAPTCHA_AS_READY = 'SET_RECAPTCHA_AS_READY';
export const GET_REDIRECT_URL = 'GET_REDIRECT_URL';
export const GET_AFFILIATE = 'GET_AFFILIATE';
export const UPDATE_TRANSTYPE = 'UPDATE_TRANSTYPE';
export const UPDATE_TRANSSOURCE = 'UPDATE_TRANSSOURCE';
export const CLOSE_CART_INFO_BANNER = 'CLOSE_CART_INFO_BANNER';
export const UPDATE_CART_CUSTOMISATION = 'UPDATE_CART_CUSTOMISATION';

/**
 * Set the Application Load time
 */
export function applicationLoad() {
  return {
    type: APPLICATION_LOAD,
    applicationLoadAt: new Date().getTime(),
  };
}

/**
 * Resets the state of the entire application
 * @return {{type: string}}
 */
export function resetApplicationState() {
  return {
    type: RESET_APPLICATION_STATE,
  };
}

/**
 * Updates the cart id
 * @param cartId
 * @return {{cartId: *, type: string}}
 */
export function updateCartId(cartId) {
  return {
    type: UPDATE_CART_ID,
    cartId,
  };
}

/**
 * Set GTM as instantiated
 * @return {{type: string, instantiated: boolean}}
 */
export function setGTMAsInstantiated() {
  return {
    type: GTM_INSTANTIATED,
    instantiated: true,
  };
}

/**
 * Update the completed page step
 * @param completedPageStep
 * @return {{type: string, completedPageStep: *}}
 */
export function updateCompletedPageStep(completedPageStep) {
  return {
    type: UPDATE_COMPLETED_PAGE_STEP,
    completedPageStep,
  };
}


/**
 * Update the current page step
 * @param currentPageStep
 * @return {{type: string, currentPageStep: *}}
 */
export function updateCurrentPageStep(currentPageStep) {
  return {
    type: UPDATE_CURRENT_PAGE_STEP,
    currentPageStep,
  };
}

/**
 * Update the available providers
 * @param providers
 * @return {{type: string, providers: *}}
 */
export function updateProviders(providers) {
  return {
    type: UPDATE_PROVIDERS,
    providers,
  };
}

/**
 * Update the available providers configuration
 * @param providersConfiguration
 * @return {{type: string, providersConfiguration: *}}
 */
export function updateProvidersConfiguration(providersConfiguration) {
  return {
    type: UPDATE_PROVIDERS_CONFIGURATION,
    providersConfiguration,
  };
}

/**
 * Update the current client
 * @param client
 * @return {{type: string, providersConfiguration: *}}
 */
export function updateClient(client) {
  return {
    type: UPDATE_CLIENT,
    client,
  };
}

/**
 * Update active dependencies
 * @param dependencies
 * @return {{type: string, dependencies: *}}
 */
export function updateDependencies(dependencies) {
  return {
    type: UPDATE_DEPENDENCIES,
    dependencies,
  };
}

/**
 * Update the recent donor list
 * @param recentDonors
 * @return {{recentDonors: *, type: string}}
 */
export function updateRecentDonors(recentDonors) {
  return {
    type: UPDATE_RECENT_DONORS,
    recentDonors,
  };
}

export function setRecaptchaAsReady(status = true) {
  return {
    type: SET_RECAPTCHA_AS_READY,
    reCaptchaReady: status,
  };
}


/**
 * Update the redirect url
 * @param url
 * @return {{type: string, url: *}}
 */
export function updateSiteUrl(url) {
  return {
    type: GET_REDIRECT_URL,
    url,
  };
}

/**
 * Return affiliate value
 * @param affiliate
 * @return {{type: string, affiliate: *}}
 */
export function getAffiliate(affiliate) {
  return {
    type: GET_AFFILIATE,
    affiliate,
  };
}

/**
 * Update the successURL
 * @param successURL
 * @return {{successURL: *, type: string}}
 */
export function updateSuccessURL(successURL) {
  return {
    type: UPDATE_SUCCESS_URL,
    successURL,
  };
}

/**
 * Update the failure url
 * @param failureURL
 * @return {{type: string, failureURL: *}}
 */
export function updateFailureURL(failureURL) {
  return {
    type: UPDATE_FAILURE_URL,
    failureURL,
  };
}

/**
 * Set the payment page status
 * @param isPaymentPage
 * @return {{isPaymentPage: *, type: string}}
 */
export function updatePaymentPageStatus(isPaymentPage) {
  return {
    type: UPDATE_PAYMENT_PAGE_STATUS,
    isPaymentPage,
  };
}

/**
 * Update the transType value
 * @param transType
 * @return {{type: string, transType: *}}
 */
export function updateTransType(transType) {
  return {
    type: UPDATE_TRANSTYPE,
    transType,
  };
}

/**
 * Update the transSource value
 * @param transSource
 * @return {{type: string, transSource: *}}
 */
export function updateTransSource(transSource) {
  return {
    type: UPDATE_TRANSSOURCE,
    transSource,
  };
}

/**
 * Update the 'close banner' status
 * @param closeStatus
 * @return {{type: boolean, closeStatus: *}}
 */
export function closeCartInfoBanner(isCartInfoBannerClosed) {
  return {
    type: CLOSE_CART_INFO_BANNER,
    isCartInfoBannerClosed,
  };
}

/**
 * Updates cart-based customisation value/flag
 * @param cartCustomisation
 * @return {{type: string, cartCustomisation}}
 */
export function updateCartCustomisation(cartCustomisation) {
  return {
    type: UPDATE_CART_CUSTOMISATION,
    cartCustomisation,
  };
}
