import CartConfig from '../Config/cart.json';
import UrlService, { getUrlCartID } from './Url.service';
import SiteService from './Site.service';

/**
 * CartService class
 */
export default class CartService {
  /**
   * CartService Constructor
   * @param cartId
   */
  constructor(cartId = null) {
    this.cartId = cartId;
    this.urlCartId = getUrlCartID();
    this.setCart();
  }

  /**
   * Get a config variable
   * @param param
   * @return {null}
   */
  get(param = null) {
    if (param === null) {
      return this.config;
    }

    return typeof this.config[param] !== 'undefined' ? this.config[param] : null;
  }

  /**
   * Get the cartID
   * @return {*}
   */
  getCartId() {
    return this.cartId;
  }

  /**
   * Get the cart campaign value
   * @return {*}
   */
  getCartCampaign() {
    return this.config.financial_attribution.campaign.fields.campaignCode;
  }

  /**
   * Get the cart financial attribution data
   * @return {*}
   */
  getCartFinancialAttribution() {
    return this.config.financial_attribution;
  }

  /**
   * Determine the client from the cart
   * @return {*}
   */
  determineClient() {
    // if giving_type is expected ('single' or 'regular') then keep default client
    if (this.config.giving_type === 'single' || this.config.giving_type === 'regular') {
      return null;
    }

    // default to 'payin' otherwise
    return 'payin';
  }

  /**
   * Fetch the default cart id
   * @return {*}
   */
  static getDefaultCartId() {
    return new SiteService().get('default_cart');
  }

  /**
   * Set configuration
   * @return {*}
   * @private
   */
  setCart() {
    if (this.urlCartId !== null) {
      Object.keys(CartConfig).forEach((cartKey) => {
        const { id } = CartConfig[cartKey];

        if (id === this.urlCartId) {
          this.cartId = cartKey;
        }
      });
    }

    if (this.cartId === null) {
      this.cartId = UrlService('cartId', null);
    }

    if (this.cartId === null) {
      this.cartId = UrlService('cartid', null);
    }

    if (this.cartId === null) {
      this.cartId = UrlService('cart-id', null);
    }

    // Attempt to get the cart id from previous state if not in url
    if (this.cartId === null) {
      this.cartId = CartService.getDefaultCartId();
    }

    if (this.cartId !== null && typeof this.cartId !== 'undefined') {
      this.cartId = this.cartId.toUpperCase();
    }

    // This will be true if either the cart doesn't exist OR is not published,
    // in which case, set the globally-used ID to the default for this site,
    // preventing incorrect/old cart IDs being added to the application state,
    // and ultimately being associated with this payment.
    if (typeof CartConfig[this.cartId] === 'undefined') {
      this.cartId = CartService.getDefaultCartId();
    }

    if (typeof CartConfig[this.cartId] !== 'undefined') {
      return this.config = CartConfig[this.cartId];
    }

    return this.config = CartConfig[CartService.getDefaultCartId()];
  }
}
