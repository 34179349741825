import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import InputField from '@comicrelief/storybook/src/components/InputField/InputField';
import PostcodeLookup from '@comicrelief/storybook/src/components/PostcodeLookup/PostcodeLookup';

import { GIVING_TYPE_MONTHLY } from '../DonationForm/GivingTypeSelector/GivingTypeSelector';
import ErrorDisplayService from '../../Service/ErrorDisplay.service';
import * as applicationActions from '../../Actions/applicationActions';
import * as paymentActions from '../../Actions/paymentActions';
import * as addressActions from '../../Actions/addressActions';
import CartService from '../../Service/Cart.service';
import FullHeightSingleImage from '../../Component/FullHeightSingleImage/FullHeightSingleImage';
import ProgressBar from '../../Component/ProgressBar/ProgressBar';
import Recaptcha from '../../Component/Recaptcha/Recaptcha';
import { canSubmitRecaptcha, refreshRecaptchaToken } from '../../Service/Recaptcha.service';
import { emailRegex, nameRegex, nameErrorText } from '../../data/regex';

/**
 * Address component
 */
class Address extends Component {
  /**
   * getDerivedStateFromProps
   *
   * React's own post-componentDidUpdate
   * function, letting us easily set state
   * without causing infinite loops
   *
   * @param props
   * @param state
   */
  static getDerivedStateFromProps(props, state) {
    const { addressSection } = props;
    let isFormValid = true;

    // Put field validation into new array to check for invalid fields
    const fields = [];
    Object.keys(addressSection).map(key =>
      fields.push(addressSection[key].valid),
    );

    // Values can be null or empty strings, so specifically check for not 'true'
    // If we fine one, do scrolly things and update flag variable we'll pass to state
    if (fields.some(element => element !== true) === true) {
      isFormValid = false;
      if (state.submissionAttempt) {
        state.errorDisplay.setScrollToErrorTimeout();
      }
    }

    // Update the state with this validity,
    // resetting the submission flag
    return {
      isFormValid,
      submissionAttempt: false,
    };
  }

  /**
   * Address constructor
   */
  constructor(props) {
    super(props);
    this.state = {
      showErrors: false,
      showMessage: false,
      isFormValid: false,
      submissionAttempt: false,
      // Stored in state so accessible by the static getDerivedStateFromProps
      errorDisplay: new ErrorDisplayService(),
    };
  }

  /**
   * Updates state with location state info if available
   */
  componentDidMount() {
    const { application, history, payment, updateCurrentPageStep } = this.props;

    document.title = `Your Details${application.page.titlePostfix}`;

    // If the user has not completed the previous form step, then push them back to the homepage
    if (application.completedPageStep < 1 || payment.paymentIsComplete === true) {
      return history.push({ pathname: '/' });
    }

    // Update the current page step
    updateCurrentPageStep(2);

    return null;
  }

  /**
   * On form submission event
   * @param event
   */
  async onSubmit(event) {
    const { application, updateStep, updateCompletedPageStep, history } = this.props;
    event.preventDefault();

    await refreshRecaptchaToken(application, 'donation_mode');

    // Update our flag to, so if getDerivedStateFromProps
    // finds an invalid field, it'll do scroll-error things
    this.setState({
      submissionAttempt: true,
    });

    // Only continue the submission when appropriate
    if (this.state.isFormValid) {
      // Reset the payment page pay step
      updateStep('');

      // Reset the completed page step
      updateCompletedPageStep(2);

      return history.push({ pathname: '/form/payment' });
    }

    // Otherwise, show any underlying errors a
    this.setState({
      showErrors: true,
    });

    return null;
  }

  /**
   * Updates state with value and validity from child.
   * Deals with email matching and sets state accordingly.
   * (Email fields use state for invalidErrorText and showErrorMessage props and listen for changes in these props)
   * @param name
   * @param valid
   */
  validateAndUpdateField(valid, name) {
    const { addressSection, updateDetails } = this.props;

    if (addressSection[name] && (addressSection[name].value === null || addressSection[name].value !== valid.value) && valid.valid !== null) {
      if (name === 'confirmEmail' || (name === 'email' && addressSection.confirmEmail !== '')) {
        const confirmEmailState = Object.assign({}, addressSection.confirmEmail);

        if (name === 'confirmEmail') {
          confirmEmailState.value = valid.value;
          confirmEmailState.valid = addressSection.email.value === valid.value;
          confirmEmailState.showErrorMessage = addressSection.email.value !== valid.value;
          confirmEmailState.message = addressSection.email.value !== valid.value ? 'Email does not match' : '';
        } else {
          confirmEmailState.valid = addressSection.confirmEmail.value === valid.value;
          confirmEmailState.showErrorMessage = addressSection.confirmEmail.value !== valid.value;
          confirmEmailState.message = addressSection.confirmEmail.value !== valid.value ? 'Email does not match' : '';
          updateDetails(name, valid);
        }

        return updateDetails('confirmEmail', confirmEmailState);
      }

      return updateDetails(name, valid);
    }

    return null;
  }

  /**
   * On step back event
   * @param event
   */
  goBack(event = null) {
    if (event) {
      event.preventDefault();
    }

    this.props.history.push({ pathname: '/form/giftaid' });
  }

  /**
   * Render the personal details inputs
   * @returns {Array}
   */
  renderPersonalDetailsInputs() {
    const { addressSection } = this.props;

    const inputs = [
      {
        id: 'firstName',
        label: 'First name',
        type: 'text',
        required: true,
        pattern: nameRegex,
        invalidErrorText: nameErrorText,
      },
      {
        id: 'lastName',
        label: 'Last name',
        type: 'text',
        required: true,
        pattern: nameRegex,
        invalidErrorText: nameErrorText,
      },
      {
        id: 'email',
        label: 'Email',
        type: 'email',
        placeholder: 'example@example.com',
        pattern: emailRegex,
      },
      {
        id: 'confirmEmail',
        label: 'Confirm email',
        type: 'email',
        placeholder: 'example@example.com',
        emptyFieldErrorText: 'Please confirm your email address',
        invalidErrorText: addressSection.confirmEmail.message,
        showErrorMessage: addressSection.confirmEmail.showErrorMessage,
        pattern: emailRegex,
      },
    ];

    const personalDetailsValidation = {
      firstName: addressSection.firstName,
      lastName: addressSection.lastName,
      email: addressSection.email,
      confirmEmail: addressSection.confirmEmail,
    };

    return inputs
      .map(item => (
        <InputField
          key={item.id}
          label={item.label}
          name={item.id}
          id={item.id}
          required
          pattern={item.pattern}
          type={item.type}
          placeholder={item.placeholder}
          ref={element => this.state.errorDisplay.setRef(element)}
          showErrorMessage={this.state.showErrors === true ? true : item.showErrorMessage}
          emptyFieldErrorText={item.emptyFieldErrorText}
          invalidErrorText={item.invalidErrorText}
          isValid={(valid, name) => { this.validateAndUpdateField(valid, name); }}
          fieldValue={personalDetailsValidation[item.id]}
        />
      ));
  }

  /**
   * Render Postcode
   */
  renderPostcodeLookupInputs() {
    const { addressSection, application } = this.props;

    const postCodeLookupValidation = {
      postcode: addressSection.postcode,
      address1: addressSection.address1,
      address2: addressSection.address2,
      address3: addressSection.address3,
      town: addressSection.town,
      country: addressSection.country,
    };

    return (
      <PostcodeLookup
        ref={element => this.state.errorDisplay.setRef(element)}
        label="Postcode"
        buttonText="Find my address (UK only)"
        showErrorMessages={this.state.showErrors}
        valuesFromParent={postCodeLookupValidation}
        forceManualInput={application.dependencies.plus === false}
        isAddressValid={
          (validation) => {
            Object.keys(validation).map(key => this.validateAndUpdateField(validation[key], key));
          }
        }
      />
    );
  }

  /**
   * Address component render
   * @return {*}
   */
  render() {
    const { application, recaptcha, donationForm, application: { currentPageStep } } = this.props;
    const cart = new CartService(application.cartId);
    const { desktop, mobile, alt } = cart.config.header[donationForm.givingType];
    const isMonthly = this.props.donationForm.givingType === GIVING_TYPE_MONTHLY;
    // *Global* update as per NJAONS specifications
    const progressBarText = isMonthly ? "Time to get to know each other, and first, it's all about you…" : 'We just need some info...';
    return (
      <main role="main">
        <section className={`paragraph--full-height-single-image-single-copy ${isMonthly ? 'njaons' : 'bg--blue-2023'}`}>
          <FullHeightSingleImage
            hideOnMobile
            alt={alt}
            desktop={desktop}
            mobile={mobile}
          />
          <div className="form__step form__step--payment fhdisc__text-wrapper fhsisc__text-wrapper bg--transparent">
            <form
              name="comicrelief_payinbundle_payment"
              method="post"
              onSubmit={(e) => { this.onSubmit(e); }}
              noValidate="novalidate"
              className="has-validation-callback fhdisc__text-3"
            >

              <div className="form__row form__row--your-detail">
                <a
                  id="js-back-details"
                  className="form__back"
                  href="/"
                  role="button"
                  onClick={(e) => { this.goBack(e); }}
                  aria-label="Back to Giftaid claim"
                >
                  Back
                </a>

                <ProgressBar currentStep={currentPageStep} isMonthly={isMonthly} text={progressBarText} />

                <div className="form__fieldset">
                  <h1 className="form__subtitle text--subtitle--small no-margin">Tell us a little bit about yourself</h1>

                  { isMonthly ? (<p className="privacy-link">Find out how we use your information in our <a
                    href="https://www.comicrelief.com/privacy-notice"
                    className="link inline"
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label="Find out how we use your information on our privacy notice (opens in a new window)"
                  >privacy policy</a> - and don&apos;t worry, we&apos;ve got you covered if anyone asks how we met…</p>)
                    : (
                      <p className="privacy-link">Find out how we use your information on our <a
                        href="https://www.comicrelief.com/privacy-notice"
                        className="link inline"
                        target="_blank"
                        rel="noopener noreferrer"
                        aria-label="Find out how we use your information on our privacy notice (opens in a new window)"
                      >privacy notice</a>.</p>
                    )
                  }

                  { this.renderPersonalDetailsInputs() }
                </div>
              </div>
              <div className="form__row form__row--your--address">
                { this.renderPostcodeLookupInputs() }
              </div>

              <Recaptcha action="donation_mode" />

              <div className="form__row">
                <div className="form__fieldset">
                  <div className="form__field--wrapper form__submit form__field--submit">
                    <button
                      type="submit"
                      id="comicrelief_payinbundle_payment_submit"
                      className={`btn form__next cta ${!this.state.isFormValid ? 'invalid' : ''}`}
                      disabled={!canSubmitRecaptcha(recaptcha, application)}
                      name="comicrelief_payinbundle_payment[submit]"
                    >
                      Continue
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </section>
      </main>
    );
  }
}

export default connect(
  ({ addressSection, application, recaptcha, donationForm, giftaidSection, payment }) => ({
    addressSection,
    application,
    recaptcha,
    donationForm,
    giftaidSection,
    payment,
  }),
  dispatch => bindActionCreators(
    Object.assign({}, applicationActions, paymentActions, addressActions),
    dispatch,
  ),
)(Address);
