import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { ROUTES } from '../../Service/PaymentServiceRouter.service';
import audienceImages from './data/audienceImages';
import FullHeightSingleImage from '../../Component/FullHeightSingleImage/FullHeightSingleImage';

/**
 * PayInFailure component
 */
const PayInFailure = ({ application, payment, payInForm, history, isMobile }) => {
  useEffect(() => {
    // If the payment is not complete then return the user to the homepage
    if (payment.paymentIsFailed === false) {
      history.push({ pathname: '/' });
    }

    document.title = `Failure${application.page.titlePostfix}`;
  }, [payment.paymentIsFailed, application.page.titlePostfix, history]);

  return (
    <main role="main">
      <section className="paragraph--full-height-single-image-single-copy bg--blue-2023 paragraph--payin">
        {!isMobile && <FullHeightSingleImage
          alt={audienceImages[payInForm.payInAudienceType]}
          desktop={audienceImages[payInForm.payInAudienceType]}
        />}
        <div className="inner-content failure fhsisc__text-wrapper bg--transparent">
          <h1>Oops, your payment <br /> didn&apos;t go through</h1>
          <h2>Your transaction failed and you&apos;ve not been charged</h2>

          <a
            id="payin-failure--try-again"
            className="btn cta"
            href={'/' + ROUTES.payIn.paymentMethod}
          >
            Try again
          </a>
          <p>
            If you continue to encounter this problem please <a style={{ color: 'white' }} className="hyperlink" href="https://www.comicrelief.com/contact-us"><strong>contact the support team</strong></a>
          </p>
        </div>
      </section>
    </main>
  );
};

export default connect(
  ({ application, payment, payInForm }) => ({ application, payment, payInForm }),
)(PayInFailure);
