import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import applePay from 'braintree-web/apple-pay';

import PaymentServiceRouter, { ROUTES } from '../../../Service/PaymentServiceRouter.service';
import { paymentProviders } from '../../../Service/PaymentConfiguration.service';
import { timeOutDuration } from '../_utils/nonCardProviderHelpers';

import * as paymentActions from '../../../Actions/paymentActions';

import './ApplePay.scss';
import { canSubmitRecaptcha, getRecaptchaTokenAndVersion, refreshRecaptchaToken } from '../../../Service/Recaptcha.service';
import Recaptcha from '../../Recaptcha/Recaptcha';

/**
 * ApplePay Component
 */
class ApplePay extends Component {
  /**
   * ApplePay Constructor
   * @param props
   */
  constructor(props) {
    super(props);
    this.timer = null;
    this.timeOutDuration = timeOutDuration;
    this.state = {
      loadedStatus: null,
      timedOut: false,
    };
  }

  /**
   * ApplePay componentDidMount
   */
  componentDidMount() {
    if (this.state.timedOut !== true) {
      this.isApplePaySupported();
    }
  }

  componentDidUpdate(prevProps) {
    if ((this.props.providers !== prevProps.providers) && this.props.providers !== null && this.state.timedOut !== true) {
      this.isApplePaySupported();
    }
  }

  /**
   * @return {Object} The payment object to pass into an `ApplePaySession` constructor.
   * See: https://developer.apple.com/documentation/apple_pay_on_the_web/applepaypaymentrequest
   * Also: https://developer.apple.com/documentation/apple_pay_on_the_web/applepaypaymentrequest/1916122-supportednetworks
   */
  getPaymentRequest() {
    const { donationForm } = this.props;

    return {
      countryCode: 'GB',
      currencyCode: donationForm.currency.name,
      total: {
        amount: donationForm.amount,
        type: 'final',
        label: 'Donation',
      },
      requiredBillingContactFields: ['name', 'postalAddress'],
      requiredShippingContactFields: ['email'],
      supportedNetworks: [
        // 'amex', // Disabled while AMEX is not working on our Braintree account
        'electron', // visa sub-brand
        'maestro', // mastercard sub-brand
        'masterCard',
        'visa',
        'vPay', // visa sub-brand
      ],
    };
  }

  /**
   * Resuable wrapper function to check prop callback function exists before using it
   */
  setLoadedStatus(status) {
    const { nonCardLoadingStatusUpdate } = this.props;

    // Update our local flag used for render logic
    this.setState({ loadedStatus: status });

    if (typeof nonCardLoadingStatusUpdate === 'function') {
      nonCardLoadingStatusUpdate(paymentProviders.BRAINTREE_APPLEPAY, status);
    }
  }

  /**
   * Calls braintree internal Validate Merchant method to prove the server's identity, then calls the last step in
   * the Apple JS SDK validate process with the returned merchantSession.
   *
   * @param {Object} event onvalidatemerchant Apple Pay JS event, containing a 'validationURL' property
   */
  async validateMerchant({ validationURL }) {
    try {
      const merchantSession = await this.applePayInstance.performValidation({
        validationURL,
        displayName: 'Comic Relief',
      });
      this.session.completeMerchantValidation(merchantSession);
    } catch (validationError) {
      this.session.abort();
      this.props.onError(validationError);
    }
  }

  /**
   * Process payment data returned by the Apple Pay API
   * Create braintree transaction
   *
   * @param {object} thisPayment payment data provided by Apple Pay
   * @see https://developer.apple.com/documentation/apple_pay_on_the_web/applepaypayment
   */
  async createTransaction(thisPayment) {
    const {
      billingContact: {
        addressLines,
        givenName: firstName,
        familyName: lastName,
        postalCode: postcode,
        locality: town,
        countryCode: country,
      },
      shippingContact: {
        emailAddress: email,
      },
      token: {
        paymentMethod,
      },
    } = thisPayment;

    const {
      application,
      application: {
        callbackUrls: {
          successUrl,
          failureUrl,
          recoverUrl,
          transSourceUrl,
        },
        campaign,
        transSource,
        transType,
        affiliate,
        cartId,
        client,
      },
      giftaidSection: {
        giftaid,
      },
      donationForm: {
        orderReference,
        amount,
        currency: {
          name: currency,
        },
      },
      payment,
      recaptcha,
    } = this.props;

    await refreshRecaptchaToken(application, 'braintree_load_applepay');
    const { token, version } = getRecaptchaTokenAndVersion(application, recaptcha);

    const createParams = {
      successUrl,
      failureUrl,
      recoverUrl,
      campaign,
      transSource,
      transSourceUrl,
      transType,
      affiliate,
      cartId,
      order_reference: orderReference,
      client,
      amount,
      currency,
      giftaid,
      firstName,
      lastName,
      email,
      address1: addressLines[0],
      address2: addressLines[1] || null,
      address3: addressLines[2] || null,
      postcode,
      town,
      country,
      paymentMethod,
      recaptcha_token: token,
      recaptcha_version: version,
      paymentType: 'APPLEPAY',
      ...(application.isPaymentPage && {
        transactionId: payment.transactionId,
      }),
    };

    const response = await PaymentServiceRouter.postRequest(ROUTES.provider.braintree.create, createParams);
    const json = await response.json();

    return { json, status: response.status };
  }

  /**
   * Process payment data returned by the Apple Pay API
   * Execute braintree transaction
   *
   * @param {object} thisPayment payment data provided by Apple Pay
   * @param {object} transaction response from Our server after transaction is created
   * @param {object} nonce generated nonce
   */
  async executeTransaction(thisPayment, transaction, nonce) {
    const {
      billingContact: {
        givenName: firstName,
        familyName: lastName,
      },
      shippingContact: {
        emailAddress: email,
      },
    } = thisPayment;

    const {
      data: {
        account_identifier: accountIdentifier,
        transactionId,
      },
    } = transaction;

    const {
      application,
      donationForm: {
        amount,
        currency: {
          name: currency,
        },
      },
    } = this.props;

    const response = await PaymentServiceRouter.postRequest(ROUTES.provider.braintree.execute, {
      paymentType: 'APPLEPAY',
      amount,
      currency,
      client: application.client,
      account_identifier: accountIdentifier,
      nonce,
      transactionId,
      firstName,
      lastName,
      email,
    });

    const json = await response.json();

    return { json, status: response.status };
  }
  /**
   * Asynctimer, to allow us to use a setTimeout within
   * the async contexts that this provider uses
   */
  async asyncTimer() {
    // Removing any previously running timer
    clearTimeout(this.timer);
    // Assign timer
    this.timer = setTimeout(() => {
      // To make future debugging easier
      console.error('APPLEPAY TIMEOUT');
      // Update our flag after the duration has elapsed
      this.setState({ timedOut: true });
      // Inform parent form that we are no longer trying to load ApplePay, updating render logic flag too
      this.setLoadedStatus('failed');
    }, this.timeOutDuration);
  }
  /**
   * Calls the CR payment 'process from nonce' endpoint,
   * with data that includes the nonce generated by braintree from the authorised Apple Pay token.
   *
   * @param {Object} event onpaymentauthorized Apple Pay JS event, containing a 'payment' property.
   */
  async processPayment({ payment: thisPayment }) {
    if (!this.session) {
      return;
    }

    const { billingContact: contact, token } = thisPayment;
    const fieldErrors = [];

    // Validate the users name
    if (typeof contact.givenName === 'undefined' || contact.givenName < 1 ||
      typeof contact.familyName === 'undefined' || contact.familyName < 1) {
      fieldErrors.push(new ApplePayError('billingContactInvalid', 'name', 'Name is not valid'));
    }

    // Validate the users address details
    if (typeof contact.locality === 'undefined' || contact.locality.length < 1) {
      fieldErrors.push(new ApplePayError('billingContactInvalid', 'locality', 'Town is not valid'));
    }

    if (typeof contact.postalCode === 'undefined' || contact.postalCode.length < 1) {
      fieldErrors.push(new ApplePayError('billingContactInvalid', 'postalCode', 'Postal Code is not valid'));
    }

    if (typeof contact.addressLines === 'undefined' ||
      typeof contact.addressLines[0] === 'undefined' ||
      contact.addressLines[0].length < 1) {
      fieldErrors.push(new ApplePayError('billingContactInvalid', 'addressLines', 'Address is not valid'));
    }

    // If the errors array has fields, then kill the function and throw an apple pay error
    if (fieldErrors.length >= 1) {
      this.session.completePayment({
        status: ApplePaySession.STATUS_FAILURE,
        errors: fieldErrors,
      });

      return;
    }

    const { nonce } = await this.applePayInstance.tokenize({ token });

    const responseErrors = [];
    const getError = message => new ApplePayError('unknown', 'name', message);

    try {
      const { json: transaction, status: creationStatus } = await this.createTransaction(thisPayment);
      let execution;
      let executionStatus;
      let error;
      if (creationStatus === 200) {
        ({ json: execution, status: executionStatus } = await this.executeTransaction(thisPayment, transaction, nonce));
      } else {
        error = transaction;
        responseErrors.push(getError(transaction.message));
      }
      if (executionStatus !== 200) {
        error = execution;
        responseErrors.push(getError(execution.message));
      }
      const authResult = {
        status: (executionStatus === 200)
          ? ApplePaySession.STATUS_SUCCESS
          : ApplePaySession.STATUS_FAILURE,
        errors: responseErrors,
      };
      this.session.completePayment(authResult);
      if (executionStatus === 200 && transaction.data && transaction.data.transactionId) {
        this.props.updatePaymentProvider(paymentProviders.BRAINTREE_APPLEPAY);
        this.props.updateTransactionId(transaction.data.transactionId);
        this.props.setPaymentAsComplete();

        this.props.onSuccess(transaction.data, {
          hidePaypal: true,
          hideGooglePay: true,
        });
      } else {
        this.props.onError(error);
      }
    } catch (error) {
      this.session.completePayment({
        status: ApplePaySession.STATUS_FAILURE,
        errors: [getError(error)],
      });
      this.props.onError(error);
    }
  }

  beginPayment() {
    const paymentRequest = this.applePayInstance.createPaymentRequest(this.getPaymentRequest());
    this.session = new ApplePaySession(3, paymentRequest);
    this.session.onvalidatemerchant = (event) => { this.validateMerchant(event); };
    this.session.onpaymentauthorized = (event) => { this.processPayment(event); };
    this.session.oncancel = () => {
      // Clear session, to avoid triggering any processes while cancel is clicked
      this.session = null;

      // Only calls the callback function if it's been passed;
      if (typeof this.props.onCancel === 'function') {
        this.props.onCancel();
      }
    };

    this.session.begin();
  }

  /**
   * Set state whether Apple Pay v3 is supported in this browser
   */
  async isApplePaySupported() {
    const { application, providers } = this.props;
    // Inform the parent form that GooglePay is loading as soon as possible
    this.setLoadedStatus('loading');
    const provider = paymentProviders.BRAINTREE_APPLEPAY;

    const isApplePayNotSupported = !(window.ApplePaySession && ApplePaySession.supportsVersion(3) && ApplePaySession.canMakePayments());
    const isClientNotLoaded = !(providers.loadedDataProviders
      && providers.loadedDataProviders[provider]
      && application.providersConfiguration
      && application.providersConfiguration.single
      && application.providersConfiguration.single[provider]
    );
    if (isApplePayNotSupported || isClientNotLoaded) {
      // Inform the parent that ApplePay ISN'T supported on the user's browser/device, and/or enabled in general
      this.setLoadedStatus('failed');
      return;
    }

    // Start the async timer
    this.asyncTimer();

    const { client } = providers.loadedDataProviders[provider];

    try {
      this.applePayInstance = await applePay.create({ client });
      // Inform the parent that ApplePay has loaded, updating additional render logic flag accordingly
      this.setLoadedStatus('loaded');
      // Cancel the timeout counter
      clearTimeout(this.timer);
    } catch (error) {
      // If there's any issues with loading ApplePay here, cancel the
      // timeout, and report the error and update flags accordingly
      this.props.onError(error);
      this.setLoadedStatus('failed');
      clearTimeout(this.timer);
    }
  }

  render() {
    const { application, recaptcha } = this.props;
    const thisBtnClass = application.isPaymentPage ? 'apple-pay-button-black' : 'apple-pay-button-white';

    return (
      <div>
        {/* Only render button once isApplePaySupported has approved this device and ApplePay is loaded */}
        { this.state.loadedStatus === 'loaded' && (
          <React.Fragment>

            <Recaptcha action="braintree_load_applepay" />

            <button
              onClick={() => { this.beginPayment(); }}
              type="button"
              className={'apple-pay-button ' + thisBtnClass + ' payment-button'}
              disabled={!canSubmitRecaptcha(recaptcha, application)}
            />
          </React.Fragment>

        )}
      </div>

    );
  }
}

export default connect(
  ({ application, donationForm, recaptcha, giftaidSection, providers, payment }) => ({
    application,
    donationForm,
    recaptcha,
    giftaidSection,
    providers,
    payment,
  }),
  dispatch => bindActionCreators(Object.assign({}, paymentActions), dispatch),
)(ApplePay);
