import React from 'react';
import { connect } from 'react-redux';
import SiteService from '../../Service/Site.service';
import CartService from '../../Service/Cart.service';

const Header = ({ isPaymentPage, application: { cartId, currentPageStep }, donationForm: { givingType }, payInForm: { currentPayInStep } }) => {
  const siteService = new SiteService();
  const site = siteService.getSite().toLowerCase();
  const thisCartID = cartId.toLowerCase();

  const thisTitle = 'Home';
  const thisLogo = siteService.get('logo');
  const thisLink = siteService.get('home_url');

  const cart = new CartService(cartId);
  const additionalLogo = cart.config.additionalLogoImage && cart.config.additionalLogoImage.fields;
  const additionalLogoUrl = cart.config.additionalLogoUrl;
  const isPayinJourney = currentPayInStep !== false;
  const isFrontPage = (!isPayinJourney && currentPageStep < 1);

  return (
    <header
      className={`cart--${thisCartID} header givingtype--${givingType.toLowerCase()} header--${site} ${(additionalLogo && !isPaymentPage) && ' header--additional-logo'} ${isPayinJourney ? ' header--payin' : ''} ${isFrontPage ? ' header--front-page' : ''}`}
      role="banner"
    >
      <div className="header__inner-wrapper">
        <div id="block-branding" className="block block-branding">
          <a
            href={thisLink}
            title={thisTitle}
            rel="home noopener noreferrer"
            className={`site-logo site-logo--${site}`}
            target="_blank"
          >
            <img src={thisLogo} alt={thisTitle} />
          </a>
        </div>
        {(additionalLogo && !isPaymentPage) && (
          <div id="additional-logo" className="block block-branding">

            {additionalLogoUrl && (
              <a
                href={additionalLogoUrl}
                title={additionalLogo.description}
                rel="home noopener noreferrer"
                className={`site-logo site-logo--${site} site-additional-logo`}
                target="_blank"
              >
                <img src={additionalLogo.file.url} alt={additionalLogo.description} />
              </a>
            )}

            {!additionalLogoUrl && (
              <div className="additional-logo--no-url">
                <img src={additionalLogo.file.url} alt={additionalLogo.description} />
              </div>
            )}

          </div>
        )}
      </div>
    </header>
  );
};

export default connect(
  ({ application, donationForm, payInForm }) => ({ application, donationForm, payInForm }),
)(Header);
