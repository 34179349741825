import { useEffect } from 'react';
import { withRouter } from 'react-router-dom';

/**
 * ScrollToTopOnMount function
 */
const ScrollToTopOnMount = () => {
  useEffect(() => {
    const elmnt = document.getElementById('root');
    try {
      // window.scrollTo({ top: 400, behavior: 'smooth' });
      // elmnt.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' });
      elmnt.scrollIntoView(true);
    } catch (err) {
      if (err instanceof TypeError) {
        window.scrollTo(0, 0);
      } else {
        throw err;
      }
    }
  }, []); // Empty array means this effect runs only once, similar to componentDidMount

  return null; // Functional components must return something, even if it's null
};

export default withRouter(ScrollToTopOnMount);
