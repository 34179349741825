import {
  RESET_GIFTAID_STATE,
  UPDATE_GIFTAID,
  UPDATE_PAYMENT_TYPE,
} from '../Actions/giftAidActions';

const defaultState = {
  giftaid: 0,
  paymentType: null,
};

/**
 * Giftaid Reducer
 * @param state
 * @param action
 * @return {*}
 */
export default function giftaid(state = defaultState, action) {
  switch (action.type) {
    case RESET_GIFTAID_STATE:
      return defaultState;

    case UPDATE_GIFTAID:
      return {
        ...state,
        giftaid: action.giftaid,
      };
    case UPDATE_PAYMENT_TYPE:
      return {
        ...state,
        paymentType: action.paymentType,
      };
    default:
      return state;
  }
}

