/* eslint no-undef: 0 */
/* eslint react/jsx-no-undef: 0 */

import React, { Component } from 'react';
import { StripeProvider, Elements } from 'react-stripe-elements';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import StripeElements from './StripeElements/StripeElements';
import PaymentServiceRouter, { ROUTES } from '../../../Service/PaymentServiceRouter.service';
import { paymentProviders } from '../../../Service/PaymentConfiguration.service';
import { GIVING_TYPE_PAYIN } from '../../../Pages/DonationForm/GivingTypeSelector/GivingTypeSelector';

import * as paymentActions from '../../../Actions/paymentActions';

import './stripe.scss';
import { getRecaptchaTokenAndVersion, handleRecaptchaResponse, refreshRecaptchaToken } from '../../../Service/Recaptcha.service';
import store from '../../../Store/store';

/**
 * Stripe class
 */
class Stripe extends Component {
  /**
   * Stripe constructor
   */
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      public_key: null,
      account_identifier: null,
    };
    this.componentIsMounted = false;
  }

  /**
   * Stripe componentDidMount
   * @return {Promise<void>}
   */
  async componentDidMount() {
    const { application } = this.props;

    this.componentIsMounted = true;

    await refreshRecaptchaToken(application, 'refresh_generated_token');

    this.create();
  }

  /**
   * Stripe componentWillUnmount
   */
  componentWillUnmount() {
    this.componentIsMounted = false;
  }


  async create(hasFailed = false) {
    const { addressSection, application, onFailure, recaptcha, history, donationForm, giftaidSection, payment, payInForm: { payInDetailsValidation, allAddressValidation: { payInBillingAddressValidation } } } = this.props;
    let createParams = {};
    const { token, version } = getRecaptchaTokenAndVersion(application, recaptcha);

    if (application.isPaymentPage) {
      createParams = {
        client: application.client,
        transactionId: payment.transactionId,
        recaptcha_token: token,
        recaptcha_version: version,
      };
    } else {
      // As per https://github.com/comicrelief/react-donation/issues/710
      const thisGiftAid = donationForm.givingType === GIVING_TYPE_PAYIN ? null : giftaidSection.giftaid;

      // Construct new object from separate Payin fields for ease of assignment
      const thisAddressObject = donationForm.givingType === GIVING_TYPE_PAYIN ?
        { ...payInDetailsValidation, ...payInBillingAddressValidation } : addressSection;

      createParams = {
        successUrl: application.callbackUrls.successUrl,
        failureUrl: application.callbackUrls.failureUrl,
        recoverUrl: application.callbackUrls.recoverUrl,
        campaign: application.campaign,
        transSource: application.transSource,
        transSourceUrl: application.callbackUrls.transSourceUrl,
        transType: application.transType,
        affiliate: application.affiliate,
        cartId: application.cartId,
        client: application.client,
        order_reference: donationForm.orderReference,
        amount: donationForm.amount,
        currency: donationForm.currency.name,
        giftaid: thisGiftAid,
        firstName: thisAddressObject.firstName.value,
        lastName: thisAddressObject.lastName.value,
        email: thisAddressObject.email.value,
        postcode: thisAddressObject.postcode.value,
        address1: thisAddressObject.address1.value,
        address2: thisAddressObject.address2.value,
        address3: thisAddressObject.address3.value,
        town: thisAddressObject.town.value,
        country: thisAddressObject.country.value,
        recaptcha_token: token,
        recaptcha_version: version,
      };
    }

    const create = await fetch(PaymentServiceRouter.get(ROUTES.provider.stripe.create), {
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(createParams),
    });

    const responseData = await create.json();

    if (handleRecaptchaResponse(create.status, responseData)) {
      this.setState({
        loading: false,
      });

      if (application.transType === 'payin') {
        return history.push({ pathname: '/form/payin/billing-address' });
      } else if (!application.isPaymentPage) {
        return history.push({ pathname: '/form/address' });
      }

      // PaymentForm:userHasChosenCardPayment state to false
      store.dispatch(paymentActions.setUserHasChosenCardPayment(false));

      return false;
    }

    if (this.componentIsMounted === true || hasFailed) {
      if (create.status !== 200) {
        return onFailure();
      }

      this.props.updatePaymentProvider(paymentProviders.STRIPE);
      this.props.updateTransactionId(responseData.data.transactionId);
      this.setState({
        ...this.state,
        loading: false,
        public_key: responseData.data.public_key,
        account_identifier: responseData.data.account_identifier,
      });


      return null;
    }

    return null;
  }

  /**
   * Stripe render
   * @return {*}
   */
  render() {
    const { application, providers } = this.props;

    if (this.state.loading === true ||
      this.state.public_key === null ||
      providers.loadedScriptsProviders.indexOf(paymentProviders.STRIPE) === -1 ||
      this.componentIsMounted === false
    ) {
      if (application.isPaymentPage) {
        return (
          <div className="loader-container">
            <p>Creating payment session with Stripe</p>
            <span className="loader" />
          </div>
        );
      }

      return (
        <div className="loader-container">
          <span className="loader" />
        </div>
      );
    }

    return (
      <StripeProvider apiKey={this.state.public_key}>
        <Elements>
          <StripeElements
            onSuccess={this.props ? this.props.onSuccess : props.onSuccess}
            onFailure={this.props ? this.props.onFailure : props.onFailure}
            create={failedValue => this.create(failedValue)}
            accountIdentifier={this.state.account_identifier}
          />
        </Elements>
      </StripeProvider>
    );
  }
}

export default connect(
  ({ addressSection, application, donationForm, recaptcha, giftaidSection, payment, providers, payInForm }) => ({
    addressSection,
    application,
    donationForm,
    recaptcha,
    giftaidSection,
    payment,
    providers,
    payInForm,
  }),
  dispatch => bindActionCreators(Object.assign({}, paymentActions), dispatch),
)(Stripe);
