import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as donationFormActions from '../../../Actions/donationFormActions';

/**
 * CurrencySelector Component
 */
const CurrencySelector = ({ donationForm, updateCurrency }) => {
  // Ensure the currency is updated to GBP if the current currency is not GBP
  // This is especially relevant for cases where the giving type might change
  // outside this component and GBP is the only supported currency for the new type.
  useEffect(() => {
    if (donationForm.givingType === 'SINGLE' && donationForm.currency.name !== 'GBP') {
      updateCurrency('GBP');
    }
  }, [donationForm.givingType, donationForm.currency.name, updateCurrency]);

  const getAvailableCurrencies = () => [
    {
      currency: 'GBP',
      symbol: '£',
    },
  ];

  const changeCurrency = (event) => {
    event.preventDefault();
    updateCurrency(event.target.value);
  };

  const error = donationForm.showAmountError === true ? 'form__field--error-outline' : '';

  return (
    <div className="form__field--wrapper form__choice form__field--currency">
      <label
        className="form__field form__field--currency form__choice required"
        htmlFor="comicrelief_payinbundle_payment_currency"
      > Currency
      </label>
      <select
        id="comicrelief_payinbundle_payment_currency"
        name="comicrelief_payinbundle_payment[currency]"
        value={donationForm.currency.name}
        onChange={changeCurrency}
        className={`form__field form__field--currency ${error}`}
      >
        <optgroup label="currency">
          {getAvailableCurrencies().map(currency => (
            <option key={currency.currency} value={currency.currency}>
              {currency.symbol} {currency.currency}
            </option>
          ))}
        </optgroup>
      </select>
    </div>
  );
};

const mapStateToProps = ({ donationForm }) => ({ donationForm });

const mapDispatchToProps = dispatch => bindActionCreators(donationFormActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CurrencySelector);
