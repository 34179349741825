import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import InputField from '@comicrelief/storybook/src/components/InputField/InputField';
import FullHeightSingleImage from '../../Component/FullHeightSingleImage/FullHeightSingleImage';
import BackButton from '../../Component/BackButton/BackButton';
import AmountAndGivingTypeHeader from '../../Component/AmountAndGivingTypeHeader/AmountAndGivingTypeHeader';
import { emailRegex, nameRegex, nameErrorText } from '../../data/regex';
import * as payInFormActions from '../../Actions/payInFormActions';
import { ROUTES } from '../../Service/PaymentServiceRouter.service';
import ErrorDisplayService from '../../Service/ErrorDisplay.service';

import audienceImages from './data/audienceImages';

/**
 * PayInDetails Component
 */
const PayInDetails = ({ application, payInForm, history, updateCurrentPayInStep, updatePayInDetails, updatePayInYourDetailsValidity, isMobile }) => {
  const [showAllErrors, setShowAllErrors] = useState(false);
  const errorDisplay = new ErrorDisplayService();

  useEffect(() => {
    // Ensure user is following the journey linearly
    const prevStep = payInForm.currentPayInStep;
    const thisStep = 2;
    if (prevStep > thisStep + 1 || prevStep < thisStep - 1 || prevStep === null) {
      history.push({ pathname: '/' });
    }
    document.title = `Pay-In: Your Details${application.page.titlePostfix}`;
    // Update form progress
    updateCurrentPayInStep(thisStep);
  }, [application.page.titlePostfix, history, payInForm.currentPayInStep, updateCurrentPayInStep]);

  /**
   * handleSubmit
   * @param e
   */
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!payInForm.isPayInYourDetailsValid) {
      // Update a local validation flag to force all errors to be shown
      setShowAllErrors(!payInForm.isPayInYourDetailsValid);
      errorDisplay.setScrollToErrorTimeoutMarkII();
    } else {
    // Else, forward to the next step
      history.push({ pathname: `/${ROUTES.payIn.postalAddress}` });
    }
  };

  const goBack = (event = null) => {
    if (event) event.preventDefault();
    history.push({ pathname: `/${ROUTES.payIn.activity}` });
  };
  /**
   * checkValues helper function
   * @return {{isValid: Boolean: *}}
   */
  const checkValues = () => {
    let isValid = true;

    // Check all values in validation obj for any falsy values
    Object.keys(payInForm.payInDetailsValidation).forEach((field) => {
      if (payInForm.payInDetailsValidation[field].valid === false || payInForm.payInDetailsValidation[field].valid === '') isValid = false;
    });
    return isValid;
  };

  /**
   * handleValidation function
   * @param valid Boolean
   * @param name string
   */
  const handleValidation = (valid, name) => {
    // Only run both update functions once something's changed, to prevent black holes
    if (payInForm.payInDetailsValidation[name].value !== valid.value) {
      updatePayInDetails(name, valid);
    }
    const allFieldValidity = checkValues(payInForm.payInDetailsValidation);

    if (allFieldValidity !== payInForm.isPayInYourDetailsValid) updatePayInYourDetailsValidity(allFieldValidity);
  };

  /**
   * Render Details input fields
   */
  const renderInputs = () => {
    const { firstName, lastName, email } = payInForm.payInDetailsValidation;
    return (
      <div className="form__payin_details">
        <div id="field-wrapper--payin-firstname">
          <div className="form__field--wrapper form__field-wrapper--text">
            <div className="form__field--payin-firstname">
              <InputField
                type="text"
                label="First name"
                id="field-input--payin-firstname"
                name="firstName"
                className="form__field form__field--text"
                required
                placeholder=""
                aria-describedby="field-label--payin-firstname field-error--payin-firstname"
                fieldValue={firstName}
                isValid={(valid, name) => handleValidation(valid, name)}
                pattern={nameRegex}
                invalidErrorText={nameErrorText}
                ref={element => errorDisplay.setRef(element)}
                showErrorMessage={firstName.showErrorMessage || showAllErrors}
              />
            </div>
          </div>
        </div>

        <div id="field-wrapper--payin-lastname">
          <div className="form__field--wrapper form__field-wrapper--text">
            <div className="form__field--payin-lastname">
              <InputField
                type="text"
                label="Last name"
                id="field-input--payin-lastname"
                name="lastName"
                className="form__field form__field--text"
                required
                placeholder=""
                aria-describedby="field-label--payin-lastname field-error--payin-lastname"
                fieldValue={lastName}
                pattern={nameRegex}
                invalidErrorText={nameErrorText}
                ref={element => errorDisplay.setRef(element)}
                isValid={(valid, name) => handleValidation(valid, name)}
                showErrorMessage={lastName.showErrorMessage || showAllErrors}
              />
            </div>
          </div>
        </div>
        <div id="field-wrapper--payin-email">
          <div className="form__field--wrapper form__field-wrapper--text">
            <div className="form__field--payin-email">
              <InputField
                type="email"
                label="Email"
                id="field-input--payin-email"
                name="email"
                className="form__field form__field--text"
                required
                aria-describedby="field-label--payin-email field-error--payin-email"
                fieldValue={email}
                isValid={(valid, name) => handleValidation(valid, name)}
                placeholder="example@example.com"
                ref={element => errorDisplay.setRef(element)}
                showErrorMessage={email.showErrorMessage || showAllErrors}
                pattern={emailRegex}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  /**
   * PayInDetails render
   * @return {*}
   */
  return (
    <main role="main">
      <section className="paragraph--full-height-single-image-single-copy bg--blue-2023 paragraph--payin">
        {!isMobile && <FullHeightSingleImage
          alt={audienceImages[payInForm.payInAudienceType]}
          desktop={audienceImages[payInForm.payInAudienceType]}
        />}
        <div className="form__step fhsisc__text-wrapper">
          <form
            name="comicrelief_payinbundle_details"
            onSubmit={handleSubmit}
            noValidate="novalidate"
            className="has-validation-callback"
          >
            <div className="form__header">
              <BackButton id="details" handleClick={goBack} ariaLabel="Back to activity select" />
              <AmountAndGivingTypeHeader />
            </div>

            <div className="form__step form__step--details">
              <h1 className="form__subtitle no-margin">Tell us a little bit about yourself</h1>
              <p className="privacy-link">
                Find out how we use your information on our <a
                  href="https://www.comicrelief.com/privacy-notice"
                  className="link inline"
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="Find out how we use your information on our privacy notice (opens in a new window)"
                >
                  privacy notice
                </a>.
              </p>

              {renderInputs()}

              <div className="form__payin-details-submit submit-btn-wrapper">
                <button
                  id="comicrelief_payinbundle_details_submit"
                  type="submit"
                  name="comicrelief_payinbundle_details[submit]"
                  className={'btn form__next cta ' + (!payInForm.isPayInYourDetailsValid ? 'disabled' : '')}
                >
                  Continue to address
                </button>
              </div>
            </div>
          </form>
        </div>
      </section>
    </main>
  );
};

const mapStateToProps = ({ application, payInForm }) => ({ application, payInForm });

const mapDispatchToProps = dispatch => bindActionCreators({ ...payInFormActions }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(PayInDetails);
