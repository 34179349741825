import { select, takeEvery } from 'redux-saga/effects';
import axios from 'axios';

import { getDonationForm, getPayment } from '../Selectors';
import { GIVING_TYPE_SINGLE, GIVING_TYPE_MONTHLY, GIVING_TYPE_PAYIN } from '../../Pages/DonationForm/GivingTypeSelector/GivingTypeSelector';
import { SET_PAYMENT_COMPLETE } from '../../Actions/paymentActions';

/**
   * getCookie helper function, covers both _very specific_ scenarios;
   * directly getting the '_fbp' cookie value, or the 'CRCC4' subvalue
   * of the 'OptanonConsent' cookie, associated with marketing opt-in/outs
   * on our .comicrelief.com subdomains.
   *
   * @param {string} name=
   * @param {string} subValue
   * @returns {(string|undefined)}: either a string value associated with this cookie, or undefined if no match
   */
function getCookie(name, subValue = null) {
  // Decode for ease of filtering
  const decodedCookies = decodeURIComponent(document.cookie);
  let cookieVal;

  // Ensure cookie is present
  if (decodedCookies.includes(name)) {
    // Return our desired cookie from the decodedCookies string
    cookieVal = decodedCookies.split(name + '=')[1];
    // Trim off any content after it
    cookieVal = cookieVal.split(';')[0];

    // If we're after a specific subvalue of this cookie, check it exists first
    if (subValue && cookieVal.includes(subValue)) {
      // Grab the value following the subValue label
      cookieVal = cookieVal.split(subValue + ':')[1];
      // As this subValue will only ever be a single 'boolean'-style string value, just grab that one character and parse
      cookieVal = cookieVal.substring(0, 1);
    } else if (subValue && !cookieVal.includes(subValue)) {
      // Else, if the requested subvalue isn't present, reset
      cookieVal = undefined;
    }
  }
  return cookieVal;
}

function* submitAnalytics() {
  const isProd = process.env.REACT_APP_ENVIRONMENT === 'production';
  const submit = process.env.REACT_APP_ANALYTICS_SUBMIT === 'true';

  // Always run on prod, but also gives us the ability to test on other envs by temporarily updating the env var
  if (isProd || submit) {
    const donationForm = yield select(getDonationForm);
    const payment = yield select(getPayment);

    const givingTypes = {
      [GIVING_TYPE_SINGLE]: 'single',
      [GIVING_TYPE_MONTHLY]: 'monthly',
      [GIVING_TYPE_PAYIN]: 'payin',
    };

    const fbpValue = getCookie('_fbp');
    const isOptingIn = parseInt(getCookie('OptanonConsent', 'CRCC4'), 10);

    const capiFields = {
      'action-source': 'website',
      'content-category': givingTypes[donationForm.givingType],
      'content-type': 'product',
      currency: donationForm.currency.name,
      'order-id': payment.transactionId === null ? payment.subscriptionId : payment.transactionId,
      'number-of-items': 1,
      'event-source-url': location.href,
      'opt-out': !isOptingIn,
      // As per limitiations on total 'event-names'; Hiral's happy to use the URL to determine the platform
      'event-name': isProd ? 'Donate' : 'TestEvent',
      'fbp-browser-id': fbpValue,
      value: donationForm.amount,
    };

    axios.post(process.env.REACT_APP_ANALYTICS_URL, capiFields).catch((error) => {
      // Swallowing any CAPI submission error, as to not break user journey
      if (typeof Sentry !== 'undefined') {
        // eslint-disable-next-line no-undef
        Sentry.captureException(error);
      }
    });
  }
}

/**
 * Register FacebookCAPI Sagas
 */
export default function* sagas() {
  yield takeEvery(SET_PAYMENT_COMPLETE, submitAnalytics);
}
