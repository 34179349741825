import {
  APPLICATION_LOAD,
  GTM_INSTANTIATED,
  RESET_APPLICATION_STATE,
  UPDATE_CLIENT,
  GET_REDIRECT_URL,
  UPDATE_COMPLETED_PAGE_STEP,
  UPDATE_CURRENT_PAGE_STEP,
  UPDATE_DEPENDENCIES,
  UPDATE_PROVIDERS,
  UPDATE_PROVIDERS_CONFIGURATION,
  SET_RECAPTCHA_AS_READY,
  UPDATE_CART_ID,
  UPDATE_FAILURE_URL,
  UPDATE_SUCCESS_URL,
  UPDATE_PAYMENT_PAGE_STATUS,
  UPDATE_RECENT_DONORS,
  UPDATE_TRANSTYPE,
  UPDATE_TRANSSOURCE,
  CLOSE_CART_INFO_BANNER,
  UPDATE_CART_CUSTOMISATION,
} from '../Actions/applicationActions';
import UrlService from '../Service/Url.service';
import CartService from '../Service/Cart.service';
import SiteService from '../Service/Site.service';
import PaymentConfigurationService from '../Service/PaymentConfiguration.service';

const site = new SiteService();
const cart = new CartService();
const baseUrl = window.location.protocol + '//' + window.location.host + '/';

const defaultState = {
  affiliate: UrlService('affiliate'),
  applicationLoadAt: '',
  callbackUrls: {
    successUrl: `${baseUrl}success`,
    failureUrl: `${baseUrl}failure`,
    recoverUrl: `${baseUrl}recover`,
    transSourceUrl: window.location.hostname === 'localhost' ? 'http://local.donation.comicrelief.com' : baseUrl,
  },
  campaign: cart.getCartCampaign(),
  cartId: cart.getCartId(),
  client: new PaymentConfigurationService(cart.determineClient()).getClient(),
  completedPageStep: null,
  currentPageStep: 0,
  dependencies: {
    sentry: false,
    plus: false,
    gtm: false,
    reCaptcha: false,
    reCaptcha_frontend: false,
  },
  financialAttribution: cart.getCartFinancialAttribution(),
  gtm: {
    instantiated: false,
  },
  isPaymentPage: false,
  page: {
    titlePostfix: site.get('title_postfix'),
  },
  providers: null,
  providersConfiguration: null,
  reCaptchaReady: false,
  recentDonors: [],
  site: site.getSite(),
  transSource: site.get('site_campaign').transSource,
  transType: site.get('site_campaign').transType,
  siteUrl: null,
  moneybuy: UrlService('moneybuy'),
  rowId: UrlService('rowID'),
  clientOverride: UrlService('clientOverride'),
  isClientUpdated: false,
  isCartInfoBannerClosed: false,
  cartCustomisation: null,
};

/**
 * Application Reducer
 * @param state
 * @param action
 * @return {*}
 */
export default function application(state = defaultState, action) {
  switch (action.type) {
    case APPLICATION_LOAD:
      return {
        ...state,
        applicationLoadAt: action.applicationLoadAt,
      };
    case GTM_INSTANTIATED:
      return {
        ...state,
        gtm: {
          ...state.gtm,
          instantiated: action.instantiated === true,
        },
      };
    case RESET_APPLICATION_STATE:
      return defaultState;
    case UPDATE_CART_ID:
      return {
        ...state,
        cartId: action.cartId,
      };
    case UPDATE_COMPLETED_PAGE_STEP:
      return {
        ...state,
        completedPageStep: action.completedPageStep,
      };
    case UPDATE_CURRENT_PAGE_STEP:
      return {
        ...state,
        currentPageStep: action.currentPageStep,
      };
    case UPDATE_PROVIDERS:
      return {
        ...state,
        providers: action.providers,
      };
    case UPDATE_PROVIDERS_CONFIGURATION:
      return {
        ...state,
        providersConfiguration: action.providersConfiguration,
      };
    case UPDATE_FAILURE_URL:
      return {
        ...state,
        callbackUrls: {
          ...state.callbackUrls,
          failureUrl: action.failureURL,
        },
      };
    case UPDATE_RECENT_DONORS:
      return {
        ...state,
        recentDonors: action.recentDonors,
      };
    case UPDATE_SUCCESS_URL:
      return {
        ...state,
        callbackUrls: {
          ...state.callbackUrls,
          successUrl: action.successURL,
        },
      };
    case UPDATE_CLIENT:
      return {
        ...state,
        client: action.client,
        isClientUpdated: true,
      };
    case GET_REDIRECT_URL:
      return {
        ...state,
        siteUrl: action.url,
      };
    case UPDATE_DEPENDENCIES:
      return {
        ...state,
        dependencies: action.dependencies,
      };
    case UPDATE_PAYMENT_PAGE_STATUS:
      return {
        ...state,
        isPaymentPage: action.isPaymentPage,
      };
    case SET_RECAPTCHA_AS_READY:
      return {
        ...state,
        reCaptchaReady: action.reCaptchaReady,
      };
    case UPDATE_TRANSTYPE:
      return {
        ...state,
        transType: action.transType,
      };
    case UPDATE_TRANSSOURCE:
      return {
        ...state,
        transSource: action.transSource,
      };
    case CLOSE_CART_INFO_BANNER:
      return {
        ...state,
        isCartInfoBannerClosed: action.isCartInfoBannerClosed,
      };
    case UPDATE_CART_CUSTOMISATION:
      return {
        ...state,
        cartCustomisation: action.cartCustomisation,
      };
    default:
      return state;
  }
}
