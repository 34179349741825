import React from 'react';
import PropTypes from 'prop-types';

/**
 * BackButton component
 */
const BackButton = ({ handleClick, id, ariaLabel }) => (
  <a
    id={`js-back-${id}`}
    className="form__back"
    href="/"
    role="button"
    onClick={handleClick}
    aria-label={ariaLabel}
    title={ariaLabel}
  >
    Back
  </a>
);

BackButton.propTypes = {
  handleClick: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  ariaLabel: PropTypes.string.isRequired,
};

export default BackButton;
