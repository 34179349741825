import React from 'react';
import { connect } from 'react-redux';
import { paymentProviders } from '../../Service/PaymentConfiguration.service';

/**
 * DataAttributeWrapper Component
 */
const DataAttributeWrapper = ({ application, children }) => {
  const { providers } = application;
  let providerAvailability = {};

  if (typeof providers !== 'undefined' && providers !== null) {
    providerAvailability = {
      applepay: typeof providers.single !== 'undefined' && providers.single.indexOf(paymentProviders.BRAINTREE_APPLEPAY) !== -1,
      braintree: typeof providers.single !== 'undefined'
      && (providers.single.indexOf(paymentProviders.BRAINTREE) !== -1 || providers.single.indexOf(paymentProviders.BRAINTREE_ASYNC) !== -1),
      paypal: typeof providers.single !== 'undefined' && providers.single.indexOf(paymentProviders.PAYPAL) !== -1,
      googlepay: typeof providers.single !== 'undefined' && providers.single.indexOf(paymentProviders.BRAINTREE_GOOGLEPAY) !== -1,
      // Used for Payin
      stripe: typeof providers.single !== 'undefined' && providers.single.indexOf(paymentProviders.STRIPE) !== -1,
      // Used for Monthly
      gocardless: typeof providers.monthly !== 'undefined' && providers.monthly.indexOf(paymentProviders.GOCARDLESS) !== -1,
    };
  }

  return (
    <div
      id="data-attribute-wrapper"
      data-cart-id={application.cartId}
      data-campaign-code={application.campaign}
      data-stripe={providerAvailability.stripe}
      data-gocardless={providerAvailability.gocardless}
      data-googlepay={providerAvailability.googlepay}
      data-applepay={providerAvailability.applepay}
      data-paypal={providerAvailability.paypal}
      data-braintree={providerAvailability.braintree}
    >
      { children }
    </div>
  );
};


export default connect(
  ({ application }) => ({ application }),
)(DataAttributeWrapper);
