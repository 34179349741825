export default class ErrorDisplay {
  constructor() {
    // Put the field refs from children into an array to use for scrolling to errors
    this.fieldRefs = [];
    this.timeOutDuration = 300;
  }

  /**
   * Set timeout to scroll to errors
   */
  setScrollToErrorTimeout() {
    this.scrollTimeout = setTimeout(() => { this.scrollToError(); }, this.timeOutDuration);
  }

  /**
   * Set timeout to scroll to errors
   */
  setScrollToErrorTimeoutMarkII(elementToFocus = false) {
    this.scrollTimeout = setTimeout(() => { this.scrollToErrorMarkII(elementToFocus); }, this.timeOutDuration);
  }

  /**
   * Set dom element reference
   * @param {Object} element
   */
  setRef(element) {
    if (element) {
      // multiple input fields component
      if (element.fieldRefs) {
        element.fieldRefs.forEach(item => this.fieldRefs.push(item));
      } else if (element.inputRef) {
        // single input field component
        this.fieldRefs.push(element.inputRef);
      } else {
        // Fallback for radiobutton fieldset
        this.fieldRefs.push(element);
      }
    }
  }

  /**
   * Goes through field refs, gets the first erroring field and focuses on it.
   * If inputelement.labels is not supported: scrolls form into view
   */
  scrollToError() {
    let item;
    for (let i = 0; i < this.fieldRefs.length; i += 1) {
      if (this.fieldRefs[i].labels !== undefined && this.fieldRefs[i].labels.length > 0) {
        const classes = this.fieldRefs[i].labels[0].getAttribute('class');
        if (classes.includes('error')) {
          item = this.fieldRefs[i];
          item.labels[0].scrollIntoView({ behavior: 'smooth' });
          item.focus();
          break;
        }
      } else if (document.querySelector('.error')) {
        // Find error
        document.querySelector('.error').scrollIntoView({ behavior: 'smooth' });

        if (document.querySelector('.error + div > input')) {
          document.querySelector('.error + div > input').focus();
        }
        break;
      }
    }
    clearTimeout(this.scrollTimeout);
  }

  scrollToErrorMarkII(elementToFocus = false) {
    let element = null;

    // Flexibity depending on which component is erroring
    if (document.querySelector('.validation__wrapper')) {
      element = document.querySelector('.validation__wrapper');
    } else if (document.querySelector('.form__field-error-wrapper')) {
      element = document.querySelector('.form__field-error-wrapper');
    } else if (document.querySelector('.error')) {
      element = document.querySelector('.error');
    }

    element.scrollIntoView({ behavior: 'smooth' });

    if (elementToFocus) {
      setTimeout(() => {
        document.querySelector(elementToFocus).focus();
        // Just to ensure enough scroll time has likely passed first
      }, this.timeOutDuration * 3);
    }
    clearTimeout(this.scrollTimeout);
  }
}
