export const RESET_DONATION_FORM_STATE = 'RESET_DONATION_FORM_STATE';
export const SHOW_AMOUNT_ERROR = 'SHOW_AMOUNT_ERROR';
export const UPDATE_AMOUNT = 'UPDATE_AMOUNT';
export const UPDATE_CURRENCY = 'UPDATE_CURRENCY';
export const UPDATE_GIVING_TYPE = 'UPDATE_GIVING_TYPE';
export const DONATION_FORM_LOAD = 'DONATION_FORM_LOAD';
export const UPDATE_POPUP_SHOWN = 'UPDATE_POPUP_SHOWN';
export const UPDATE_HAS_SET_DEFAULT_AMOUNT = 'UPDATE_HAS_SET_DEFAULT_AMOUNT';
export const UPDATE_HAS_CHANGED_GIVING_TYPE = 'UPDATE_HAS_CHANGED_GIVING_TYPE';
export const UPDATE_PREVIOUS_MONEYBUY_ADDED_FOR_GA = 'UPDATE_PREVIOUS_MONEYBUY_ADDED_FOR_GA';

/**
 * Set form load time
 * @return {{type: string, formLoadAt: number}}
 */
export function setFormLoadAt() {
  return {
    type: DONATION_FORM_LOAD,
    formLoadAt: new Date().getTime(),
  };
}

/**
 * Update amount error visibility
 * @param visibility
 * @return {{type: string, showAmountError: *}}
 */
export function showAmountError(visibility) {
  return {
    type: SHOW_AMOUNT_ERROR,
    showAmountError: visibility,
  };
}

/**
 * Resets the donation form state
 * @return {{type: string}}
 */
export function resetDonationFormState() {
  return {
    type: RESET_DONATION_FORM_STATE,
  };
}

/**
 * Update Amount
 *
 * @param amount
 * @param moneyBuyIndex
 * @return {{type: string, amount: *, moneyBuyIndex: *}}
 */
export function updateAmount(amount, moneyBuyIndex = null) {
  return {
    type: UPDATE_AMOUNT,
    amount: isNaN(amount) === true ? ''.toString() : amount,
    moneyBuyIndex,
  };
}

/**
 * Update Giving Type
 *
 * @param givingType string
 * @return {{type: string, givingType: *}}
 */
export function updateGivingType(givingType) {
  return {
    type: UPDATE_GIVING_TYPE,
    givingType,
  };
}

/**
 * Update currency
 *
 * @param name   string
 * @return {{type: string, currency: {name: *, symbol: *}}}
 */
export function updateCurrency(name) {
  let symbol = '£';

  switch (name) {
    case 'USD':
    case 'AUD':
      symbol = '$';
      break;
    case 'EUR':
      symbol = '€';
      break;
    default:
      symbol = '£';
  }

  return {
    type: UPDATE_CURRENCY,
    currency: {
      name,
      symbol,
    },
  };
}

/**
 * Update Popup Shown
 *
 * @param wasPopupShown boolean
 * @return {{type: string, wasPopupShown: *}}
 */
export function updatePopupShown(wasPopupShown) {
  let castValue = null;
  if (typeof wasPopupShown === 'string') {
    castValue = (wasPopupShown === 'true');
  }
  return {
    type: UPDATE_POPUP_SHOWN,
    wasPopupShown: castValue,
  };
}

/**
 * Update Has Set Default Amount
 *
 * @param hasSetDefaultAmount boolean
 * @return {{type: string, hasSetDefaultAmount: boolean}}
 */
export function updateHasSetDefaultAmount(hasSetDefaultAmount) {
  return {
    type: UPDATE_HAS_SET_DEFAULT_AMOUNT,
    hasSetDefaultAmount,
  };
}

/**
 * Update Has Changed Giving Type
 *
 * @param hasChangedGivingType boolean
 * @return {{type: string, hasChangedGivingType: boolean}}
 */
export function updateHasChangedGivingType(hasChangedGivingType) {
  return {
    type: UPDATE_HAS_CHANGED_GIVING_TYPE,
    hasChangedGivingType,
  };
}

/**
 * Update Last Moneybuy Added For GA
 *
 * @param moneybuysForGAForGA object
 * @return {{type: string, lastMoneyBuyAdded: object}}
 */
export function updatemoneybuysForGA(storedCurrentMoneybuy, storedPreviousMoneybuy) {
  return {
    type: UPDATE_PREVIOUS_MONEYBUY_ADDED_FOR_GA,
    moneybuysForGA: {
      storedCurrentMoneybuy,
      storedPreviousMoneybuy,
    },
  };
}
