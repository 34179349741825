import React from 'react';
import { connect } from 'react-redux';

import SocialLinks from '../Success/ThankYou/SocialLinks/SocialLinks';
import ScrollToTopOnMount from '../../Component/App/ScrollToTop/ScrollToTopOnMount';
import CartService from '../../Service/Cart.service';

/**
 * PayinThankYou Component
 */
const PayinThankYou = (props) => {
  const { payment, application } = props;
  const transactionsIdCopy = `Your transaction ID is: ${payment.transactionId}`;
  const cart = new CartService(application.cartId);

  const customSuccessMsg = cart.config.custom_success_message && cart.config.custom_success_message.PAYIN ? cart.config.custom_success_message.PAYIN : null;
  const customButtonURL = cart.config.custom_success_button_url ? cart.config.custom_success_button_url : 'https://www.comicrelief.com/what-your-money-does/';
  const customButtonText = cart.config.custom_success_button_text ? cart.config.custom_success_button_text : 'Where does my money go?';

  const thankYouSubtitle = payment.isAsyncTransaction === true
    ? 'Your payment is being processed, thank you for supporting Comic Relief'
    : 'Your payment was successful, thank you for supporting Comic Relief';

  return (
    <div className="fhsisc__text-wrapper fhsisc__thank-you-text">
      <ScrollToTopOnMount />
      <div className={'inner-content inner-content--success'}>
        <h1 className="success-heading">Thank you!</h1>
        <p className="success-subtitle text-align-center">{thankYouSubtitle}</p>
        <p className="success-details text-align-center">
          A confirmation has been sent to your email account. {transactionsIdCopy}
        </p>
        {customSuccessMsg && <p className="success-details text-align-center">{customSuccessMsg}</p>}
        <p className="success-share text-align-center">
          We love hearing about your fundraising adventures, will you share your story with others too?
        </p>
        <SocialLinks {...props} />
        <p>
          <a
            className="red cta btn"
            href={customButtonURL}
            target="_blank"
            rel="noopener noreferrer"
            aria-label={customButtonText}
          >
            {customButtonText}
          </a>
        </p>
        <p>
          <a
            className="cta-2 btn"
            href="https://www.comicrelief.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Go to homepage
          </a>
        </p>
      </div>
    </div>
  );
};

export default connect(state => ({
  application: state.application,
  donationForm: state.donationForm,
  payment: state.payment,
  payInForm: state.payInForm,
}))(PayinThankYou);
