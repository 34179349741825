export const getApplication = state => state.application;
export const getDonationForm = state => state.donationForm;
export const getGiftaidSection = state => state.giftaidSection;
export const getProviders = state => state.providers;
export const getAddressSection = state => state.addressSection;
export const getPayment = state => state.payment;
export const getSuccessSection = state => state.successSection;
export const getPayInForm = state => state.payInForm;

export default {};
