/* eslint-disable no-control-regex */
const charLimit = 50;
const regex = /^$|^[^-\s][ \w \s ' . \-&_?!@()]{0,49}$/;
const regexError = "This field only accepts up to 50 alpha-numeric characters, and ' . - & _ ? ! ( ) @";
const regexJob = /^[^-\s][ \w \s ' . \-&_?!@()]{0,19}$/;
const regexJobError = "This field only accepts up to 20 alpha-numeric characters, and ' . - & _ ? ! ( ) @";

const nameRegex = /^[ñÑA-Za-zÀ-ÖØ-öø-ÿ,.\- '][ñÑA-Za-zÀ-ÖØ-öø-ÿ,.\- ']{0,24}$/;
const nameErrorText = "This field only accepts alphabetical characters, spaces, and , . - ' (maximum 25 characters)";

// The back end validates emails with validate.js
// Therefore, it makes sense to use the same regex on the front end
// (Our previous regex was more restrictive than the back end and rejected valid emails, which we really dont want.)
// see https://github.com/ansman/validate.js/blob/master/validate.js#L1064
const emailRegex = /^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/i;
const regexEdcoNameError = "This field only accepts up to 100 alpha-numeric characters, and ' . - & _ ? ! ( )";
const regexEdcoName = /^[^-\s][ \w \s ' . \-&_?!@()]{0,99}$/;

module.exports = {
  charLimit,
  regex,
  regexError,
  nameRegex,
  nameErrorText,
  emailRegex,
  regexJob,
  regexJobError,
  regexEdcoName,
  regexEdcoNameError,
};
