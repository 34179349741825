import React, { Component } from 'react';
import CheckboxField from '@comicrelief/storybook/src/components/CheckboxField/CheckboxField';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as applicationActions from '../../Actions/applicationActions';
import * as giftaidActions from '../../Actions/giftAidActions';
import * as donationFormActions from '../../Actions/donationFormActions';

import { GIVING_TYPE_MONTHLY } from '../DonationForm/GivingTypeSelector/GivingTypeSelector';
import PaymentButtons from '../../Component/PaymentButtons/PaymentButtons';
import ProgressBar from '../../Component/ProgressBar/ProgressBar';

import CartService from '../../Service/Cart.service';
import SiteService from '../../Service/Site.service';

import FullHeightSingleImage from '../../Component/FullHeightSingleImage/FullHeightSingleImage';
import { labelText, giftaidInfo, ProgressBarCopy } from './GiftaidCopy';
import { googleAnalyticsMoneyBuys } from '../../Helpers/_Helpers';

/**
 * Giftaid class
 */
class Giftaid extends Component {
  /**
   * Giftaid constructor
   * @param props
   */
  constructor(props) {
    super(props);
    this.site = new SiteService();
    this.state = {
      errorResponse: false,
    };
  }

  /**
   * Giftaid componentDidMount
   */
  componentDidMount() {
    const { application, donationForm, updatemoneybuysForGA, donationForm: { moneybuysForGA: { storedCurrentMoneybuy } } } = this.props;

    document.title = `Gift Aid & Payment Selection${application.page.titlePostfix}`;
    // If the user has not completed the previous form step, then push them back to the homepage
    if (application.completedPageStep === null || this.props.payment.paymentIsComplete === true) {
      return this.props.history.push({ pathname: '/' });
    }

    // Update the current page step
    this.props.updateCurrentPageStep(1);

    googleAnalyticsMoneyBuys(donationForm, updatemoneybuysForGA, storedCurrentMoneybuy, application.siteUrl);

    return null;
  }

  /**
   * Change giftaid state
   * @param event
   */
  changeGiftaid(event) {
    this.props.updateGiftaid(event.target.checked === true ? 1 : 0);
  }

  /**
   * Go back to previous page
   * @param event
   */
  goBack(event = null) {
    if (event) {
      event.preventDefault();
    }
    const siteUrl = this.props.application.siteUrl;

    // Redirect users to where they come from.
    if (siteUrl !== null) {
      sessionStorage.clear();
      window.location.href = siteUrl;
    } else this.props.history.push({ pathname: '/' });
  }

  /**
   * Update error message
   * Used by paypal and apple pay
   * @param message
   */
  updateErrorMessage(message) {
    return this.setState({
      errorResponse: message,
    }, (() => {
        window.scrollTo(0, 0);
      }));
  }

  /**
   * Giftaid render
   * @return {*}
   */
  render() {
    const thisSite = this.site.getSite();
    const claimGiftaidLabelText = labelText(thisSite, this.props.donationForm.givingType);
    const findMoreLink = 'https://www.comicrelief.com/frequently-asked-questions#gift-aid-anchor';

    const additionalText = this.props.donationForm.givingType === GIVING_TYPE_MONTHLY
      ? '* By ticking, I state I am a UK taxpayer making a recurring personal donation and understand that if I pay less Income Tax and/or Capital Gains Tax than the amount of Gift Aid claimed on all my donations, it is my responsibility to pay any difference. Please note that the money that Comic Relief claims back from HMRC as part of the Gift Aid scheme will be treated as unrestricted funds and used to support our general work, even if the original donation was made towards a specific appeal.'
      : '* By ticking, I state I am a UK taxpayer making a personal donation and understand that if I pay less Income Tax and/or Capital Gains Tax than the amount of Gift Aid claimed on all my donations, it is my responsibility to pay any difference. Please note that the money that Comic Relief claims back from HMRC as part of the Gift Aid scheme will be treated as unrestricted funds and used to support our general work, even if the original donation was made towards a specific appeal.';

    const claimGiftAid =
      {
        id: 'giftaid',
        label: claimGiftaidLabelText,
        type: 'checkbox',
        additionalText:
          `${additionalText}
          <a href=${findMoreLink} class="link inline" target="_blank" rel="noopener noreferrer" aria-label="Find out more about Gift Aid (opens in a new window)"> Find out more </a>`,
      };

    if (this.props.application.providers === null) {
      return null;
    }

    const { application, application: { currentPageStep } } = this.props;
    const cart = new CartService(application.cartId);
    const { desktop, mobile, alt } = cart.config.header[this.props.donationForm.givingType];
    const isMonthly = this.props.donationForm.givingType === GIVING_TYPE_MONTHLY;
    const thisProgressBarCopy = ProgressBarCopy(application.cartId, isMonthly);

    return (
      <main role="main">
        <section className={`paragraph--full-height-single-image-single-copy ${isMonthly ? 'njaons' : 'bg--blue-2023'}`}>
          <FullHeightSingleImage
            hideOnMobile
            alt={alt}
            desktop={desktop}
            mobile={mobile}
          />
          <div className="form__step form__step--payment  fhsisc__text-wrapper bg--transparent">
            <form
              name="comicrelief_payinbundle_payment"
              method="post"
              onSubmit={(e) => { e.preventDefault(); }}
              noValidate="novalidate"
              className="has-validation-callback giftaid-form"
            >

              <div className="form__row form__row--giftaid">
                <a
                  className="form__back"
                  href="/"
                  role="button"
                  onClick={(e) => { this.goBack(e); }}
                  aria-label="Back to donation amount"
                >
                  Back
                </a>
                <div className="form__fieldset gift-aid-form">
                  {this.state.errorResponse !== false &&
                  <span className="help-block form-error" style={{ marginBottom: '2em' }}>
                    {this.state.errorResponse}
                  </span>
                  }

                  <ProgressBar currentStep={currentPageStep} isMonthly={isMonthly} text={thisProgressBarCopy.title} />

                  <h3 className="form__subtitle giftaid__subtitle">{thisProgressBarCopy.subtitle}</h3>

                  {giftaidInfo(this.props.donationForm, thisSite)}
                  {
                    <CheckboxField
                      type={claimGiftAid.type}
                      id={claimGiftAid.id}
                      name={claimGiftAid.id}
                      label={claimGiftAid.label}
                      additionalText={claimGiftAid.additionalText}
                      setBackgroundColor
                      checked={this.props.giftaidSection.giftaid === 1}
                      value={this.props.giftaidSection.giftaid}
                      handleCheckboxChange={(e) => { this.changeGiftaid(e); }}
                    />
                  }
                </div>
              </div>
              <PaymentButtons history={this.props.history} updateErrorMessage={(message) => { this.updateErrorMessage(message); }} />
            </form>
          </div>
        </section>
      </main>
    );
  }
}

function mapStateToProps(state) {
  return {
    application: state.application,
    donationForm: state.donationForm,
    giftaidSection: state.giftaidSection,
    payment: state.payment,
  };
}

function mapDispachToProps(dispatch) {
  return bindActionCreators(Object.assign({}, applicationActions, giftaidActions, donationFormActions), dispatch);
}

export default connect(mapStateToProps, mapDispachToProps)(Giftaid);
