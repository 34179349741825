/* eslint no-undef: 0 */
/* eslint react/jsx-no-undef: 0 */

import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import PaymentServiceRouter, { ROUTES } from '../../../Service/PaymentServiceRouter.service';
import { paymentProviders } from '../../../Service/PaymentConfiguration.service';

import * as applicationActions from '../../../Actions/applicationActions';
import * as paymentActions from '../../../Actions/paymentActions';
import { GIVING_TYPE_PAYIN } from '../../../Pages/DonationForm/GivingTypeSelector/GivingTypeSelector';

import SiteService from '../../../Service/Site.service';

/**
 * PaypalButton class
 */
class PaypalButton extends Component {
  /**
   * PaypalButton constructor
   * @param props
   */
  constructor(props) {
    super(props);
    this.siteService = new SiteService();
    this.site = this.siteService.getSite();
    this.state = {
      transaction: {
        providerReference: null,
      },
      showButton: false,
      componentIsMounted: false,
      buttonStyle: {
        label: 'pay',
        size: 'responsive',
        shape: 'pill',
        color: 'white',
        tagline: false,
        height: 50,
      },
      cancelCalled: false,
    };
    this.componentIsMounted = false;
  }

  /**
   * PaypalButton componentDidMount
   */
  componentDidMount() {
    this.componentIsMounted = true;
  }

  componentWillUnmount() {
    this.componentIsMounted = false;
  }

  /**
   * On payment approval
   * @param data
   * @return {Promise<Response>}
   */
  onApprove(data) {
    const { application, onSuccess, setPaymentAsComplete, updateCurrentPageStep, updateErrorMessage } = this.props;
    const clientConfiguration = application.providersConfiguration.single.PAYPAL;
    let responseStatusCode = null;

    return fetch(PaymentServiceRouter.get(ROUTES.provider.paypal.execute), {
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        client: application.client,
        transactionId: this.props.payment.transactionId,
        account_identifier: clientConfiguration.account_identifier,
        paymentId: data.paymentID,
        payerId: data.payerID,
      }),
    })
      .then((res) => {
        responseStatusCode = res.status;

        return res.json();
      })
      .then((response) => {
        if (responseStatusCode === 400 && typeof response.data.type !== 'undefined' && response.data.type === 'cardError') {
          return updateErrorMessage(response.message);
        }

        this.setState({
          showButton: false,
        });

        // Update page steps for GTM
        updateCurrentPageStep(2);
        updateCurrentPageStep(3);

        // Mark the payment as complete
        setPaymentAsComplete();

        return onSuccess({
          hideGooglePay: true,
          hideApplePay: true,
        });
      });
  }

  /**
   * On payment cancellation
   * @return {Promise<Promise<*>|*|void>}
   */
  async onCancel() {
    if (this.state.cancelCalled === false) {
      this.setState({
        cancelCalled: true,
      });

      this.props.cancelTransaction();
      return this.props.onCancel();
    }

    return null;
  }

  /**
   * On order creation, create the paypal order
   * @return {Promise<any>}
   */
  onCreateOrder() {
    const { application, donationForm, giftaidSection } = this.props;
    const clientConfiguration = application.providersConfiguration.single.PAYPAL;

    // As per https://github.com/comicrelief/react-donation/issues/710
    const thisGiftAid = donationForm.givingType === GIVING_TYPE_PAYIN ? null : giftaidSection.giftaid;

    this.setState({
      cancelCalled: false,
    });

    return fetch(PaymentServiceRouter.get(ROUTES.provider.paypal.create), {
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        successUrl: application.callbackUrls.successUrl,
        failureUrl: application.callbackUrls.failureUrl,
        recoverUrl: application.callbackUrls.recoverUrl,
        campaign: application.campaign,
        transSource: application.transSource,
        transSourceUrl: application.callbackUrls.transSourceUrl,
        transType: application.transType,
        affiliate: application.affiliate,
        cartId: application.cartId,
        order_reference: donationForm.orderReference,
        client: application.client,
        amount: donationForm.amount,
        currency: donationForm.currency.name,
        giftaid: thisGiftAid,
        account_identifier: clientConfiguration.account_identifier,
      }),
    })
      .then(res => res.json())
      .then((responseData) => {
        this.props.updatePaymentProvider(paymentProviders.PAYPAL);
        this.props.updateTransactionId(responseData.data.transactionId);

        return responseData.data.provider_reference;
      });
  }

  /**
   * On Payment Error
   * @param error
   * @return {Promise<*>|*|void}
   */
  onError(error) {
    console.log(error);

    const baseUrl = window.location.protocol + '//' + window.location.host;
    // ignore error resulting from clicking back on paypal tab on mobile browser
    if (!error.message.includes(`Unexpected init message from domain ${baseUrl}`)) {
      return this.props.onError();
    }

    return this.props.onCancel();
  }

  /**
   * On button render
   * @return {*}
   */
  render() {
    const { application, donationForm, providers } = this.props;

    if (application.providersConfiguration === null ||
      providers.loadedScriptsProviders.indexOf(paymentProviders.PAYPAL) === -1 ||
      donationForm.currency.name !== 'GBP'
    ) {
      return null;
    }

    if (this.componentIsMounted === true) {
      const Button = paypal.Buttons.driver('react', { React, ReactDOM });

      return (<Button
        style={this.state.buttonStyle}
        createOrder={() => this.onCreateOrder()}
        onApprove={data => this.onApprove(data)}
        onCancel={() => this.onCancel()}
        onError={error => this.onError(error)}
      />);
    }

    return null;
  }
}

/**
 * PaypalButton Default Props
 * @type {{environment: string, onSuccess: function(*), onCancel: function(*), onError: function(*)}}
 */
PaypalButton.defaultProps = {
  environment: 'sandbox',
  onSuccess: () => {},
  onCancel: () => {},
  onError: () => {},
  updateErrorMessage: () => {},
};

export default connect(
  ({ application, donationForm, giftaidSection, payment, providers }) => ({
    application,
    donationForm,
    giftaidSection,
    payment,
    providers,
  }),
  dispatch => bindActionCreators(Object.assign({}, applicationActions, paymentActions), dispatch),
)(PaypalButton);
