import {
  RECAPTCHA_TOKEN_V3,
  RECAPTCHA_TOKEN_V2,
  RECAPTCHA_VERSION,
} from '../Actions/recaptchaActions';

const defaultState = {
  tokenV3: '',
  tokenV2: '',
  version: 3,
};

  /**
   * Recaptcha Reducer
   * @param state
   * @param action
   * @return {*}
   */
export default function recaptcha(state = defaultState, action) {
  switch (action.type) {
    case RECAPTCHA_VERSION:
      return {
        ...state,
        version: action.version,
      };
    case RECAPTCHA_TOKEN_V3:
      return {
        ...state,
        tokenV3: action.tokenV3,
      };
    case RECAPTCHA_TOKEN_V2:
      return {
        ...state,
        tokenV2: action.tokenV2,
      };
    default:
      return state;
  }
}

