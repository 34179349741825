import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as donationFormActions from '../../../Actions/donationFormActions';

/**
 * AmountField Component
 */
const preventTextEntry = (data) => {
  /**
   * Prevent user from entering more that 3 digits after a decimal place.
   * @param data
   */
  const numberEntry = data;
  const decimalPlace = numberEntry.indexOf('.');

  return (decimalPlace > -1) ?
    (numberEntry.substr(0, decimalPlace) + numberEntry.substr(decimalPlace, 3)) : numberEntry;
};

const AmountField = ({ donationForm, elementRef, updateAmount }) => {
  const changeAmount = (event) => {
    event.preventDefault();
    const result = preventTextEntry(event.target.value);
    updateAmount(result);
  };

  const error = donationForm.showAmountError === true ? 'form__field--error-outline' : '';

  return (
    <div className="form__field--wrapper form__money form__field--amount">
      <input
        type="number"
        value={donationForm.amount}
        onChange={changeAmount}
        id="field-input--amount"
        name="text"
        required="required"
        placeholder="Enter your own amount"
        pattern="[^[1-9]+$]"
        className={`form__field form__field--amount ${error}`}
        ref={elementRef}
        aria-describedby="field-label--amount field-error--amount"
        min="1"
        max="25000"
        step="0.01"
      />
    </div>
  );
};

const mapStateToProps = ({ donationForm }) => ({ donationForm });

const mapDispatchToProps = dispatch => bindActionCreators(donationFormActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AmountField);
