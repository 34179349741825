import React, { Component } from 'react';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { sanitizeUrl } from '@braintree/sanitize-url';

import * as applicationActions from '../../Actions/applicationActions';
import * as donationFormActions from '../../Actions/donationFormActions';
import * as payInFormActions from '../../Actions/payInFormActions';
import ErrorDisplayService from '../../Service/ErrorDisplay.service';
import MoneyBuys from './Moneybuys/MoneyBuys';
import MoneyBuyCarousel from './Carousel/Carousel';
import ErrorMessage from '../../Component/ErrorMessage/ErrorMessage';

import GivingTypeSelector, {
  GIVING_TYPE_MONTHLY,
  GIVING_TYPE_SINGLE,
  GIVING_TYPE_PAYIN,
} from './GivingTypeSelector/GivingTypeSelector';
import CurrencySelector from './CurrencySelector/CurrencySelector';
import AmountField from './AmountField/AmountField';
import CartService from '../../Service/Cart.service';
import URLService from '../../Service/Url.service';

import FullHeightSingleImage from '../../Component/FullHeightSingleImage/FullHeightSingleImage';
import { amountFormatter } from '../../Helpers/_Helpers';
import DonationFormCopy from './DonationFormCopy';

/**
 * DonationForm Component
 */
class DonationForm extends Component {
  /**
   * isValid
   *
   * Handy reusable check, static so
   * getDerivedStateFromProps can use it
   * @param input
   * @param input2
   */
  static isValid(input) {
    const outcome = isNaN(input) || input < 1 || input > 25000;
    return !outcome;
  }

  /**
   * getDerivedStateFromProps
   *
   * React's own post-componentDidUpdate
   * function, letting us easily set state
   * without causing infinite loops
   *
   * @param props
   */
  static getDerivedStateFromProps(props) {
    return {
      isFormValid: DonationForm.isValid(props.donationForm.amount),
    };
  }

  /**
   * DonationForm constructor
   */
  constructor(props) {
    super(props);
    this.amountElementRef = React.createRef();
    this.errorDisplay = new ErrorDisplayService();
    props.setFormLoadAt();
    this.state = {
      isFormValid: false,
    };
  }

  /**
   * DonationForm componentDidMount
   * @return {Promise<void>}
   */
  componentDidMount() {
    const {
      application,
      history,
      payment,
      resetApplicationState,
      updateAmount,
      updateCompletedPageStep,
      updateCurrency,
      updateCurrentPageStep,
      updateGivingType,
      updateSiteUrl,
      updateCartId,
      payInForm,
      resetPayInFormState,
      updateCurrentPayInStep,
      updatePopupShown,
    } = this.props;

    // Reset entire application state if there has been a previous payment
    if (payment.paymentIsComplete === true || payment.paymentIsFailed === true) {
      resetApplicationState();
    }

    // Detect donation form parameters being set by url and route to the gift aid page
    const urlAmount = URLService('amount', null);
    const urlCurrency = URLService('currency', 'GBP');
    const urlGivingType = URLService('givingType', GIVING_TYPE_SINGLE).toUpperCase();
    let siteUrl = URLService('siteurl', null);
    const affiliateValue = URLService('affiliate', null);
    const cartid = URLService('cartId', null);
    // Originating from the CR.com Donate 'widget' Paragraph
    const rowID = URLService('rowID', null);
    const popUpShown = URLService('popUpShown', null);

    if (urlAmount !== null && urlCurrency !== null && (urlGivingType === GIVING_TYPE_SINGLE || urlGivingType === GIVING_TYPE_MONTHLY)) {
      // Update siteUrl params for use with Membership row
      if (siteUrl !== application.siteUrl && siteUrl !== null) {
        // Sanitize url
        siteUrl = sanitizeUrl(siteUrl);
        // Append affiliate value if we have it too
        if (affiliateValue !== null) {
          siteUrl = `${siteUrl}?affiliate=${affiliateValue}&amount=${urlAmount}&currency=${urlCurrency}&rowID=${rowID}`;
        }

        if (typeof application !== 'undefined' && application.currentPageStep !== 0) {
          sessionStorage.clear();
          window.location.href = siteUrl;
        }

        updateSiteUrl(siteUrl);
      }

      updateGivingType(urlGivingType);
      updateAmount(urlAmount);
      updateCurrency(urlCurrency);
      updatePopupShown(popUpShown);

      // Update the cart id if set by the url
      if (cartid != null) {
        updateCartId(cartid);
      }

      updateCompletedPageStep(0);

      return history.push({ pathname: '/form/giftaid' });
    } else if (urlGivingType === GIVING_TYPE_PAYIN) {
      // Payin specific stuff: user always needs to supply specific amount fundraised to payin, so no 'amount' logic here
      if (typeof application !== 'undefined' && payInForm.hasPayInStarted === true) {
        // Reset any states from previous attempts
        resetPayInFormState();
        sessionStorage.clear();
      }

      if (cartid != null) {
        updateCartId(cartid);
      }

      updateGivingType(urlGivingType);
    }

    // Reset the completed page step to null when revisiting this page
    updateCompletedPageStep(null);

    // Set the title and current page step
    document.title = `Donate${application.page.titlePostfix}`;

    // Ensure this flag is reset each time
    updateCurrentPayInStep(false);

    return updateCurrentPageStep(0);
  }

  /**
   * On form submission
   * @param event
   * @param isPayIn
   */
  onSubmit(event, isPayIn) {
    event.preventDefault();
    const { donationForm, history, showAmountError, updateCompletedPageStep } = this.props;
    // Validate stored donation amount
    if (DonationForm.isValid(donationForm.amount) === false) {
      showAmountError(true);
      this.errorDisplay.setScrollToErrorTimeoutMarkII('#' + this.amountElementRef.current.id);
      return null;
    }

    // Redirect to the next PayIn page
    if (isPayIn) {
      return history.push({ pathname: '/form/payin/audience' });
    }

    updateCompletedPageStep(0);

    // Else, redirect to the giftaid page
    return history.push({ pathname: '/form/giftaid' });
  }


  /**
   * DonationForm render
   * @return {*}
   */
  render() {
    const { application, donationForm } = this.props;
    const cart = new CartService(application.cartId);
    const isPayIn = donationForm.givingType === GIVING_TYPE_PAYIN;
    const isSingle = donationForm.givingType === GIVING_TYPE_SINGLE;
    const isMonthly = donationForm.givingType === GIVING_TYPE_MONTHLY;
    const moneyBuysTitle = cart.get('moneybuys_title')[donationForm.givingType];
    const { desktop, mobile, alt } = cart.config.header[donationForm.givingType];
    const defaultSelection = cart.config.default_selection;
    const givingTypeCopy = isMonthly ? 'monthly' : 'now';
    const buttonCopy = donationForm.amount !== '' && donationForm.givingType ?
      `Yes, donate ${donationForm.currency.symbol}${amountFormatter(donationForm.amount)} ${givingTypeCopy}`
      : 'Donate now';
    const payInButtonCopy = donationForm.amount !== '' && donationForm.givingType ?
      `Yes, pay in ${donationForm.currency.symbol}${amountFormatter(donationForm.amount)} now`
      : 'Yes, pay in';
    const hasPayin = cart.get('moneybuys')[GIVING_TYPE_PAYIN] !== undefined;
    const showPayinAlert = ((donationForm.amount % 1 !== 0 && isSingle && hasPayin) ? 'payin-alert' : '');
    const amountAlert = 'Please enter a donation amount between £1 and £25,000 and up to two decimal places';
    const payinAlert = "Are you paying in fundraising money? If so, select the 'Pay in' tab, otherwise continue making your donation";
    const isDecimal = donationForm.amount % 1 !== 0;
    document.title = `${isPayIn ? 'Pay-In' : 'Donate'}${application.page.titlePostfix}`;
    const defaultCart = CartService.getDefaultCartId();

    // Crummy temporary fix as Fundraising have asked for default cart customisations
    // beyond what the CMS and application have been designed to do:
    const thisFormSubtitle = (application.cartId === defaultCart && isMonthly) ? DonationFormCopy.subtitle : cart.get('form_subtitle');

    return (
      <main role="main">
        <section className={`paragraph--full-height-single-image-single-copy ${isPayIn ? 'payin' : ''} ${isMonthly ? 'njaons' : 'bg--blue-2023'}`}>
          <FullHeightSingleImage
            alt={alt}
            desktop={desktop}
            mobile={mobile}
          />
          <div className="form__step form__step--payment fhsisc__text-wrapper bg--transparent">
            <form
              name="comicrelief_payinbundle_payment"
              onSubmit={(e) => { this.onSubmit(e, isPayIn); }}
              method="post"
              noValidate="novalidate"
              className="has-validation-callback"
            >
              <div className="form__step form__step--payment">
                <div className="form__row form__row--payment form__row--moneybuy-block">
                  <div
                    id="js-togglebtn-amount"
                    className="form__fieldset form__togglebtn-amount"
                  >
                    <h1 className="form__subtitle select-label moneybuy--heading">
                      {thisFormSubtitle}
                    </h1>

                    <GivingTypeSelector />

                    <p className="moneybuy--title">
                      {moneyBuysTitle}
                    </p>

                    { !isPayIn ? <MoneyBuys defaultIndex={defaultSelection} /> : <MoneyBuyCarousel />}

                  </div>

                  <div className={`form__fieldset ${donationForm.showAmountError === true && 'form__field-error-wrapper'} ${showPayinAlert}`}>
                    <label
                      className={isPayIn
                        ? 'moneybuy--label audience-preload'
                        : 'form__field-label'}
                      htmlFor="field-input--amount"
                    >
                      {isPayIn ? 'How much did you raise?' : 'Enter other amount:'}
                    </label>
                    <CurrencySelector />
                    <AmountField elementRef={this.amountElementRef} />
                  </div>

                  <div>
                    {donationForm.showAmountError === true &&
                      <ErrorMessage id="amount" copy={amountAlert} /> }
                    {(isSingle && isDecimal && showPayinAlert) &&
                      <ErrorMessage id="payin-alert" copy={payinAlert} /> }
                  </div>

                  {application.providers !== null ? (
                    <div className="form__fieldset form__amount-submit submit-btn-wrapper">
                      <button
                        id="comicrelief_payinbundle_payment_amount_submit"
                        type="submit"
                        name="comicrelief_payinbundle_payment[submit]"
                        className={`btn form__next cta ${!this.state.isFormValid ? 'invalid' : ''}`}
                      >
                        {!isPayIn ? buttonCopy : payInButtonCopy }
                      </button>
                    </div>
                  ) : (
                    <div className="loader-container">
                      <span className="loader" />
                    </div>
                  )}
                </div>
              </div>
            </form>
          </div>
        </section>
      </main>
    );
  }
}

export default connect(
  ({ application, donationForm, payment, payInForm }) => ({ application, donationForm, payment, payInForm }),
  dispatch => bindActionCreators(
    Object.assign({}, applicationActions, donationFormActions, payInFormActions),
    dispatch,
  ),
)(DonationForm);
