import { loadReCaptcha } from 'react-recaptcha-v3';
import { select, takeEvery } from 'redux-saga/effects';
import BraintreeClient from 'braintree-web/client';

import SiteService from '../../Service/Site.service';
import PaymentServiceRouter, { ROUTES } from '../../Service/PaymentServiceRouter.service';
import ProviderService, { paymentProviders } from '../../Service/PaymentConfiguration.service';
import store from '../../Store/store';

import {
  loadedProviders,
  addLoadingScript,
  addLoadedScript,
  loadingProviderData,
  loadedProviderData,
  resetProvidersState,
  removeLoadingScript,
} from '../../Actions/providersActions';
import { getApplication, getPayment, getProviders, getDonationForm, getPayInForm } from '../Selectors';

import {
  updateCartId,
  updateProviders,
  updateProvidersConfiguration,
  updateDependencies,
  updateRecentDonors,
  setRecaptchaAsReady,
  RESET_APPLICATION_STATE,
  UPDATE_PROVIDERS,
  APPLICATION_LOAD,
  UPDATE_CURRENT_PAGE_STEP,
  UPDATE_CLIENT,
} from '../../Actions/applicationActions';

import { RESET_DONATION_FORM_STATE, resetDonationFormState, updateGivingType } from '../../Actions/donationFormActions';
import { resetGiftaidState } from '../../Actions/giftAidActions';
import { resetPaymentState } from '../../Actions/paymentActions';
import { resetAddresstate } from '../../Actions/addressActions';
import { resetSuccessState } from '../../Actions/successActions';
import { RESET_PAYIN_FORM_STATE, resetPayInFormState, UPDATE_CURRENT_PAYIN_STEP } from '../../Actions/payInFormActions';

import { GIVING_TYPE_SINGLE, GIVING_TYPE_MONTHLY, GIVING_TYPE_PAYIN } from '../../Pages/DonationForm/GivingTypeSelector/GivingTypeSelector';

import CartService from '../../Service/Cart.service';
import UrlService from '../../Service/Url.service';

const paymentProvidersScripts = {
  [paymentProviders.BRAINTREE_GOOGLEPAY]: 'https://pay.google.com/gp/p/js/pay.js',
  [paymentProviders.PAYPAL]: 'https://www.paypal.com/sdk/js?components=buttons&integration-date=2010-03-21&disable-funding=credit,card,sofort&currency=GBP&commit=true',
  [paymentProviders.STRIPE]: 'https://js.stripe.com/v3/',
};

/**
 * Function to load script asynchronously as a promise
 * @param src
 * @param provider
 * @param application
 */
function asyncLoadScript(src, provider, application) {
  return new Promise((resolve, reject) => {
    const s = document.createElement('script');

    if (provider === paymentProviders.PAYPAL) {
      s.src = src + '&client-id=' + application.providersConfiguration.single[paymentProviders.PAYPAL].client_id;
    } else {
      s.src = src;
    }

    s.onload = resolve;
    s.onerror = reject;
    document.head.appendChild(s);
  });
}

function* abTestCart() {
  const application = yield select(getApplication);

  const cart = new CartService(application.cartId);
  const abTestConfiguration = cart.get('ab_test');
  const abTestDisabled = UrlService('DISABLE_AB_TEST', null);

  if (abTestConfiguration
    && abTestConfiguration.active === true
    && abTestDisabled === null
  ) {
    const newCart = abTestConfiguration.carts[Math.floor(Math.random() * abTestConfiguration.carts.length)];

    if (newCart !== application.cartId) {
      console.log('A/B Test running');
      store.dispatch(updateCartId(newCart));
    }
  }
}

/**
 * Detect invalid giving types for cart and then swap giving type if needed
 * @return {IterableIterator<SelectEffect>}
 */
function* ensureValidGivingTypeForCart() {
  const donationForm = yield select(getDonationForm);
  const application = yield select(getApplication);

  const cart = new CartService(application.cartId);
  const moneyBuys = cart.get('moneybuys');

  if (typeof moneyBuys[donationForm.givingType] === 'undefined') {
    store.dispatch(updateGivingType(donationForm.givingType === GIVING_TYPE_MONTHLY ? GIVING_TYPE_SINGLE : GIVING_TYPE_MONTHLY));
  }
}

function* fetchConfiguration() {
  const application = yield select(getApplication);

  store.dispatch(resetProvidersState());
  store.dispatch(updateProviders(null));
  store.dispatch(updateProvidersConfiguration(null));

  const configuration = yield new ProviderService(application.client).get();

  if (configuration.redirect.active === true) {
    window.location.href = configuration.redirect.url;
  }

  if (typeof configuration.dependencies.reCaptcha !== 'undefined'
    && (configuration.dependencies.reCaptcha === true
    || configuration.dependencies.reCaptcha_frontend === true)
    && typeof window.grecaptcha === 'undefined'
  ) {
    loadReCaptcha(process.env.REACT_APP_RECAPTCHA_V3_SITE_KEY);
  }

  store.dispatch(updateProvidersConfiguration(configuration.providersConfiguration));
  store.dispatch(updateDependencies(configuration.dependencies));
  store.dispatch(updateProviders(configuration.providers));
  store.dispatch(updateRecentDonors(configuration.recentDonors));

  // If only monthly giving is available from the client, then update default giving type to be monthly
  if (typeof configuration.providers.single === 'undefined' && typeof configuration.providers.monthly !== 'undefined') {
    store.dispatch(updateGivingType(GIVING_TYPE_MONTHLY));
  }

  store.dispatch(loadedProviders(configuration.providers.single, configuration.client));
}

/**
 * Load active providers into the store
 */
function* loadProviders() {
  const payment = yield select(getPayment);
  const site = new SiteService();

  // Only update providers when there is not a complete payment in the store
  if (payment.paymentIsComplete === false && site.get('type') !== 'PAYMENT') {
    yield fetchConfiguration();
  }
}

/**
 * Load Provider Scripts
 * @param action
 * @returns {null}
 */
function* loadProviderScripts(action) {
  const application = yield select(getApplication);
  const providers = yield select(getProviders);

  if (action.providers === null) {
    return null;
  }

  const singleProviders = typeof action.providers.single !== 'undefined' ? action.providers.single : [];
  const monthlyProviders = typeof action.providers.monthly !== 'undefined' ? action.providers.monthly : [];
  const availableProviders = [...singleProviders, ...monthlyProviders];

  availableProviders.forEach((provider) => {
    if (typeof paymentProvidersScripts[provider] !== 'undefined' &&
      providers.loadingScriptsProviders.indexOf(provider) === -1 &&
      providers.loadedScriptsProviders.indexOf(provider) === -1
    ) {
      store.dispatch(addLoadingScript(provider));

      asyncLoadScript(paymentProvidersScripts[provider], provider, application)
        .then(() => {
          store.dispatch(addLoadedScript(provider));
        })
        .catch(() => {
          store.dispatch(removeLoadingScript(provider));
        });
    }
  });

  return null;
}

/**
 * Load Data Providers
 * @returns {null}
 */
function* loadDataProviders() {
  const application = yield select(getApplication);
  const donationForm = yield select(getDonationForm);
  const providers = yield select(getProviders);
  const payInForm = yield select(getPayInForm);

  //  Only check current page step where appropriate seeing as the payment page
  //  doesn't have or use 'steps', and now needs this config for GooglePay and ApplePay
  if (application.providers === null ||
      (application.currentPageStep !== 1 && !application.isPaymentPage) ||
      (payInForm.currentPayInStep !== 4 && donationForm.givingType === GIVING_TYPE_PAYIN)
  ) {
    return null;
  }

  const singleProviders = typeof application.providers.single !== 'undefined' ? application.providers.single : [];
  const monthlyProviders = typeof application.providers.monthly !== 'undefined' ? application.providers.monthly : [];
  const availableProviders = [...singleProviders, ...monthlyProviders];

  const needToLoadBraintreeDataFor = [];
  // If googlepay, then load data provider for google pay
  if (availableProviders.indexOf(paymentProviders.BRAINTREE_GOOGLEPAY) !== -1 &&
    typeof providers.loadedDataProviders[paymentProviders.BRAINTREE_GOOGLEPAY] === 'undefined' &&
    typeof providers.loadingDataProvidersStatus[paymentProviders.BRAINTREE_GOOGLEPAY] === 'undefined'
  ) {
    needToLoadBraintreeDataFor.push(paymentProviders.BRAINTREE_GOOGLEPAY);
  }
  // If applepay, then load data provider for apple pay
  if (availableProviders.indexOf(paymentProviders.BRAINTREE_APPLEPAY) !== -1 &&
    typeof providers.loadedDataProviders[paymentProviders.BRAINTREE_APPLEPAY] === 'undefined' &&
    typeof providers.loadingDataProvidersStatus[paymentProviders.BRAINTREE_APPLEPAY] === 'undefined'
  ) {
    needToLoadBraintreeDataFor.push(paymentProviders.BRAINTREE_APPLEPAY);
  }
  if (needToLoadBraintreeDataFor.length > 0) {
    needToLoadBraintreeDataFor.map(paymentProvider => store.dispatch(loadingProviderData(paymentProvider, 'client')));
    return PaymentServiceRouter.postRequest(ROUTES.provider.braintree.generateToken, { client: application.client })
      .then(generateTokenResponse => generateTokenResponse.json())
      .then(tokenData => BraintreeClient.create({ authorization: tokenData.data.token }))
      .then((clientInstance) => {
        needToLoadBraintreeDataFor.map(paymentProvider => store.dispatch(loadedProviderData(paymentProvider, 'client', clientInstance)));
      });
  }
  return null;
}

function* updateConfigurationOnClientUpdate() {
  const payment = yield select(getPayment);

  // Only update providers when there is not a complete payment in the store
  if (payment.paymentIsComplete === false) {
    yield fetchConfiguration();
  }
}

/**
 * Watch the window object for reCaptcha being loaded
 */
function watchForRecaptchaLoaded() {
  store.dispatch(setRecaptchaAsReady(false));

  const checkInterval = setInterval(() => {
    if (typeof window.grecaptcha === 'object' && typeof window.grecaptcha.execute === 'function') {
      store.dispatch(setRecaptchaAsReady());
      clearInterval(checkInterval);
    }
  }, 200);
}


/**
 * Reset the application state
 */
function resetApplicationState() {
  // Application reset is not needed here, as is reset by calling event
  store.dispatch(resetDonationFormState());
  store.dispatch(resetPaymentState());
  store.dispatch(resetGiftaidState());
  store.dispatch(resetAddresstate());
  store.dispatch(resetSuccessState());
  store.dispatch(resetPayInFormState());
}

/**
 * Register application sagas
 */
export default function* sagas() {
  yield takeEvery(RESET_APPLICATION_STATE, resetApplicationState);
  yield takeEvery(RESET_APPLICATION_STATE, watchForRecaptchaLoaded);
  yield takeEvery(APPLICATION_LOAD, loadProviders);
  yield takeEvery(APPLICATION_LOAD, abTestCart);
  yield takeEvery(RESET_DONATION_FORM_STATE, loadProviders);
  yield takeEvery(RESET_PAYIN_FORM_STATE, loadProviders);
  yield takeEvery(APPLICATION_LOAD, watchForRecaptchaLoaded);
  yield takeEvery(APPLICATION_LOAD, ensureValidGivingTypeForCart);
  yield takeEvery(UPDATE_PROVIDERS, loadProviderScripts);
  yield takeEvery(UPDATE_PROVIDERS, loadDataProviders);
  yield takeEvery(UPDATE_CURRENT_PAGE_STEP, loadDataProviders);
  yield takeEvery(UPDATE_CLIENT, updateConfigurationOnClientUpdate);
  yield takeEvery(UPDATE_CURRENT_PAYIN_STEP, loadDataProviders);
}
