import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as paymentActions from '../../../Actions/paymentActions';
import PaymentServiceRouter, { ROUTES } from '../../../Service/PaymentServiceRouter.service';
import { paymentProviders } from '../../../Service/PaymentConfiguration.service';

/**
 * Direct Debit Confirmation component
 */
class AccountDetailsConfirmation extends Component {
  /**
   * AccountDetailsConfirmation constructor
   */
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
    this.componentIsMounted = true;
  }

  /**
   * AccountDetailsConfirmation componentDidMount
   */
  componentDidMount() {
    this.componentIsMounted = true;
  }

  /**
   * AccountDetailsConfirmation componentWillUnmount
   */
  componentWillUnmount() {
    this.componentIsMounted = false;
  }

  /**
   * On button click
   * @param {Object} event
   */
  async onButtonClick(event) {
    event.preventDefault();

    const { application, payment, donationForm, giftaidSection, addressSection } = this.props;

    if (this.componentIsMounted === true) {
      this.setState({
        loading: true,
      });
    }

    const response = await fetch(PaymentServiceRouter.get(ROUTES.provider.goCardless.execute), {
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        successUrl: application.callbackUrls.successUrl,
        failureUrl: application.callbackUrls.failureUrl,
        recoverUrl: application.callbackUrls.recoverUrl,
        campaign: application.campaign,
        transSource: application.transSource,
        transSourceUrl: application.callbackUrls.transSourceUrl,
        transType: application.transType,
        affiliate: application.affiliate,
        cartId: application.cartId,
        order_reference: donationForm.orderReference,
        client: application.client,
        amount: donationForm.amount,
        currency: donationForm.currency.name,
        giftaid: giftaidSection.giftaid,
        firstName: addressSection.firstName.value,
        lastName: addressSection.lastName.value,
        email: addressSection.email.value,
        postcode: addressSection.postcode.value,
        address1: addressSection.address1.value,
        address2: addressSection.address2.value,
        address3: addressSection.address3.value,
        town: addressSection.town.value,
        country: addressSection.country.value,
        accountHolderName: payment.data.accountHolderName || `${payment.data.firstName} ${payment.data.lastName}`,
        accountNumber: payment.data.accountNumber,
        sortCode: payment.data.sortCode,
        billingDayOfMonth: payment.data.billingDayOfMonth,
      }),
    });

    if (response.status !== 200) {
      return this.props.onFailure();
    }

    const execution = await response.json();

    this.props.updatePaymentProvider(paymentProviders.GOCARDLESS);
    this.props.updateSubscriptionId(execution.data.subscriptionId);
    this.props.setPaymentAsComplete();

    return this.props.onSuccess();
  }

  /**
   * Confirmation component render
   * @return {*}
   */
  render() {
    if (this.state.loading === true) {
      return (
        <div className="loader-container">
          <span className="loader" />
        </div>
      );
    }

    const {
      donationForm: { amount, currency: { symbol } },
      addressSection,
      payment: {
        data: {
          accountHolderName,
          accountNumber,
          sortCode,
          bankName,
          billingDayOfMonth,
        },
      },
    } = this.props;

    return (
      <div>
        <div className="form__row form__row--your--account">
          <div className="form__fieldset">
            <h1 className="form__subtitle">Please confirm you would like to set-up a Direct Debit with the details below</h1>
            <div className="form__field-wrapper--checkbox form__field-wrapper--background">
              <div>
                <strong>Account holder name</strong>
                <p>{accountHolderName || `${addressSection.firstName.value} ${addressSection.lastName.value}`}</p>
              </div>
              <div>
                <strong>Bank</strong>
                <p>{bankName}</p>
              </div>
              <div>
                <strong>Sort code</strong>
                <p>{sortCode}</p>
              </div>
              <div>
                <strong>Account number</strong>
                <p>{accountNumber}</p>
              </div>
              <div>
                <strong>Amount of regular gift</strong>
                <p>{`${symbol} ${amount}`}</p>
              </div>
              <div>
                <strong>Direct debit billing date</strong>
                <p>{`${billingDayOfMonth}${this.props.nth(parseInt(billingDayOfMonth, 10))} of every month` }</p>
              </div>
            </div>
          </div>
        </div>
        <div className="form__row">
          <div className="form__fieldset">
            <div className="form__field--wrapper form__submit form__field--submit">
              <button type="submit" className="btn cta" onClick={event => this.onButtonClick(event)} id="comicrelief_payinbundle_payment_submit" name="comicrelief_payinbundle_payment[submit]">
                Confirm Direct Debit
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  state => ({
    addressSection: state.addressSection,
    application: state.application,
    donationForm: state.donationForm,
    giftaidSection: state.giftaidSection,
    payment: state.payment,
  }),
  dispatch => bindActionCreators(Object.assign({}, paymentActions), dispatch),
)(AccountDetailsConfirmation);
