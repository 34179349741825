/**
   * nonCardLoadingStatusUpdate function
   * @param thisProviderName string
   * @param thisProviderStatus string
   * @return {*}
   */
const nonCardLoadingStatusUpdate = (thisProviderName, thisProviderStatus, thisComponent) => {
  // Update the associated state object with the 'loading' string or boolean value
  thisComponent.setState(prevState => ({
    nonCardLoadingStatuses: {
      ...prevState.nonCardLoadingStatuses,
      [thisProviderName]: thisProviderStatus,
    },
  }));
};

/**
   * GetNonCardProvider function
   * @param providers object
   * @param paymentProviders object
   * @param fetchedConfig boolean
   * @return {*}
   */
const getNonCardProviders = (providers, paymentProviders, fetchedConfig = true) => {
  // If the config has not yet been fetched, then return null as we don't want providers from old sessions.
  // nb: not passed for PaymentButtons context, so falls back to the default param value
  if (fetchedConfig === false) {
    return null;
  }

  const theseNonCardProviders = [];

  providers.availableProviders.forEach((provider) => {
    // To let us check specifically for our non-card payment providers used (GooglePay and ApplePay)
    const isAppleOrGooglePay = provider === paymentProviders.BRAINTREE_GOOGLEPAY || provider === paymentProviders.BRAINTREE_APPLEPAY;

    if (typeof paymentProviders[provider] !== 'undefined' && isAppleOrGooglePay) {
      theseNonCardProviders.push(paymentProviders[provider]);
    }
  });

  return theseNonCardProviders;
};

const timeOutDuration = 10000;

export { nonCardLoadingStatusUpdate, getNonCardProviders, timeOutDuration };
