import { combineReducers } from 'redux';

import addressSection from './addressSection';
import application from './application';
import donationForm from './donationForm';
import giftaidSection from './giftaidSection';
import payment from './payment';
import providers from './providers';
import successSection from './successSection';
import payInForm from './payInForm';
import recaptcha from './recaptcha';

const rootReducer = combineReducers({
  addressSection,
  application,
  donationForm,
  giftaidSection,
  payment,
  providers,
  successSection,
  payInForm,
  recaptcha,
});

export default rootReducer;
