import {
  RESET_ADDRESS_STATE,
  UPDATE_FIRST_NAME,
  UPDATE_LAST_NAME,
  UPDATE_EMAIL,
  UPDATE_CONFIRM_EMAIL,
  UPDATE_POSTCODE,
  UPDATE_ADDRESS_1,
  UPDATE_ADDRESS_2,
  UPDATE_ADDRESS_3,
  UPDATE_TOWN,
  UPDATE_COUNTRY,
  UPDATE_VALUE,
} from '../Actions/addressActions';

const defaultState = {
  firstName: { valid: null, message: '', value: '', showErrorMessage: false },
  lastName: { valid: null, message: '', value: '', showErrorMessage: false },
  email: { valid: null, message: '', value: '', showErrorMessage: false },
  confirmEmail: { valid: null, message: '', value: '', showErrorMessage: false },
  postcode: { valid: null, message: '', value: '', showErrorMessage: false },
  address1: { valid: null, message: '', value: '', showErrorMessage: false },
  address2: { valid: true, message: '', value: '', showErrorMessage: false },
  address3: { valid: true, message: '', value: '', showErrorMessage: false },
  town: { valid: null, message: '', value: '', showErrorMessage: false },
  country: { valid: true, message: '', value: 'GB', showErrorMessage: false },
};

/**
 * Address Section Reducer
 * @param state
 * @param action
 * @return {*}
 */
export default function address(state = defaultState, action) {
  switch (action.type) {
    case RESET_ADDRESS_STATE:
      return defaultState;
    case UPDATE_FIRST_NAME:
      return {
        ...state,
        firstName: action.firstName,
      };
    case UPDATE_LAST_NAME:
      return {
        ...state,
        lastName: action.lastName,
      };
    case UPDATE_EMAIL:
      return {
        ...state,
        email: action.email,
      };
    case UPDATE_CONFIRM_EMAIL:
      return {
        ...state,
        confirmEmail: action.confirmEmail,
      };
    case UPDATE_POSTCODE:
      return {
        ...state,
        postcode: action.postcode,
      };
    case UPDATE_ADDRESS_1:
      return {
        ...state,
        address1: action.address1,
      };
    case UPDATE_ADDRESS_2:
      return {
        ...state,
        address2: action.address2,
      };
    case UPDATE_ADDRESS_3:
      return {
        ...state,
        address3: action.address3,
      };
    case UPDATE_TOWN:
      return {
        ...state,
        town: action.town,
      };
    case UPDATE_COUNTRY:
      if (typeof action.country !== 'undefined') {
        return {
          ...state,
          country: action.country,
        };
      }
      return state;
    case UPDATE_VALUE:
      return {
        ...state,
        [action.key]: {
          ...state[action.key],
          value: action.value,
        },
      };
    default:
      return state;
  }
}
