import MyselfFriendsFamilyImg from '../../../../public/images/payin/audience/Payin-Myself-Or-Group-365--compressed.jpg';
import NurserySchoolUniversityImg from '../../../../public/images/payin/audience/Payin-Schools-365--compressed.jpg';
import WorkplaceOrganisationImg from '../../../../public/images/payin/audience/Payin-Workplace-365--compressed.jpg';

const audienceImages = {
  public: MyselfFriendsFamilyImg,
  school: NurserySchoolUniversityImg,
  work: WorkplaceOrganisationImg,
};

export default audienceImages;
