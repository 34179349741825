/* eslint-disable react/no-danger */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import CartService from '../../Service/Cart.service';
import * as applicationActions from '../../Actions/applicationActions';
import CloseCross from './close.svg';

/**
 * CartInfoBanner Component
 */
const CartInfoBanner = ({ application, closeCartInfoBanner }) => {
  const [showBanner, setShowBanner] = useState('');
  const [allMarkup, setAllMarkup] = useState('');

  useEffect(() => {
    const formatCopy = () => {
      const cart = new CartService(application.cartId);
      let localAllMarkup = '';

      // Check for content before doing anything (as it's an optional field)
      const hasBannerCopy = cart.config.banner_copy && cart.config.banner_copy.content;

      if (hasBannerCopy) {
        // Cache all the content
        const allContent = cart.config.banner_copy.content;

        // Iterate through every separate paragraph within our allContent object
        Object.keys(allContent).forEach((index) => {
          // String variable to append to
          let thisParagraphContent = '';
          // Grab all individual parts of this paragraph to iterate through them:
          const thisContent = allContent[index].content;

          // Iterate over every PART of the paragraph that Contentful has *helpfully* broken out into multiple objects
          Object.keys(thisContent).forEach((key) => {
            // If this part is just plain text, add directly to string (wrapping in a strong tag where appropriate)
            if (thisContent[key].nodeType === 'text') {
              const thisText = thisContent[key].marks[0] ? `<strong>${thisContent[key].value}</strong>` : thisContent[key].value;
              thisParagraphContent += thisText;
            } else
            // Otherwise, this must be a link (only text and links are allowed in this Richtext field validation):
            if (thisContent[key].nodeType === 'hyperlink') {
              // Create the markup
              let thisLink = `<a class="link inline" target="_blank" rel="noopener noreferrer" href=${thisContent[key].data.uri}>${thisContent[key].content[0].value}</a>`;
              // Having to recreate this check again as the 'hyperlink' object is constructed differently
              thisLink = thisContent[key].content[0].marks[0] ? `<strong>${thisLink}</strong>` : thisLink;
              thisParagraphContent += thisLink;
            }
          });

          // Wrap all of the combined parts in a paragraph tag, append to overall variable
          localAllMarkup += `<p>${thisParagraphContent}</p>`;
        });
      }
      // Only render the banner when there's something to show, and when the user hasn't already closed it
      setShowBanner(hasBannerCopy && localAllMarkup);
      setAllMarkup(localAllMarkup);
    };

    formatCopy();
  }, [application.cartId]); // Only re-run the effect if cartId changes
  /**
   * handleClose wrapper function
   */
  const handleClose = () => {
    closeCartInfoBanner(true);
  };

  if (showBanner && !application.isCartInfoBannerClosed) {
    return (
      <div id="cart-info-banner">
        <div dangerouslySetInnerHTML={{ __html: allMarkup }} />
        <button type="button" onClick={handleClose}>
          <img src={CloseCross} alt="Close banner" />
        </button>
      </div>
    );
  }

  return null;
};

const mapDispatchToProps = dispatch => bindActionCreators(applicationActions, dispatch);

export default connect(
  ({ application }) => ({ application }),
  mapDispatchToProps,
)(CartInfoBanner);
