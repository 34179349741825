import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as applicationActions from '../../Actions/applicationActions';
import * as payInFormActions from '../../Actions/payInFormActions';
import { ROUTES } from '../../Service/PaymentServiceRouter.service';
import FullHeightSingleImage from '../../Component/FullHeightSingleImage/FullHeightSingleImage';
import ErrorMessage from '../../Component/ErrorMessage/ErrorMessage';
import OtherActivityModal from './OtherActivityModal';
import activityData from './data/activityData';
import ErrorDisplayService from '../../Service/ErrorDisplay.service';
import BackButton from '../../Component/BackButton/BackButton';
import AmountAndGivingTypeHeader from '../../Component/AmountAndGivingTypeHeader/AmountAndGivingTypeHeader';
import audienceImages from './data/audienceImages';
import SiteService from '../../Service/Site.service';
import * as donationFormActions from '../../Actions/donationFormActions';
import { googleAnalyticsMoneyBuys } from '../../Helpers/_Helpers';

/**
 * PayInActivity Component
 */
class PayInActivity extends Component {
  constructor(props) {
    super(props);
    this.errorDisplay = new ErrorDisplayService();
    this.siteService = new SiteService();
    this.activities = activityData;
  }

  /**
   * componentDidMount
   */
  componentDidMount() {
    const { application, payInForm: { currentPayInStep, payInAudienceType }, donationForm, updatemoneybuysForGA, donationForm: { moneybuysForGA: { storedCurrentMoneybuy } } } = this.props;

    // Ensure user is following the journey linearly
    const prevStep = currentPayInStep;
    const thisStep = 1;

    if (prevStep > thisStep + 1 || prevStep < thisStep - 1 || prevStep === null) {
      return this.props.history.push({ pathname: '/' });
    }

    document.title = `Pay-In: Activities${this.props.application.page.titlePostfix}`;

    // Update form progress
    this.props.updateCurrentPayInStep(thisStep);

    // Close the modal on mount if it was left open previously; makes for less confusing UX when moving between steps/refreshing
    this.props.updateIsModalOpen(false);

    // Set the default error message for this step
    const errorMsg = 'Please select at least 1 activity';

    googleAnalyticsMoneyBuys(donationForm, updatemoneybuysForGA, storedCurrentMoneybuy, application.siteUrl, payInAudienceType);

    return this.props.setActivityErrorMessage(errorMsg);
  }

  /**
   * componentDidUpdate
   * @param prevProps
   */
  componentDidUpdate(prevProps) {
    const { payInForm: { payInActivities, isModalOpen } } = this.props;

    // Only run when something's changed AND the modal isn't open, as it overrides this logic
    if (prevProps.payInForm.payInActivities !== payInActivities && !isModalOpen) {
      // Compare current number of selected activities to the limit
      const currentActivityNum = Object.keys(payInActivities).length;
      const isValid = (currentActivityNum > 0 && currentActivityNum <= this.activities.maxActivities);

      // Override any error set by the pop-up modal previously
      const errorMsg = 'Please select at least 1 activity';
      this.props.setActivityErrorMessage(errorMsg);
      this.props.updatePayInActivityValidity(isValid, !isValid); // Flip the validity to act as flag for error-showing
    }
  }

  /**
   * Handle Submission
   * @param e
   */
  onSubmit(e) {
    const { payInForm: { isActivityFormValid } } = this.props;
    e.preventDefault();

    if (isActivityFormValid) {
      return this.props.history.push({ pathname: `/${ROUTES.payIn.details}` });
    }

    // Just update the error state, not validity itself (although we know it's false at this point)
    this.props.updatePayInActivityValidity(isActivityFormValid, true);
    return this.errorDisplay.setScrollToErrorTimeoutMarkII();
  }
  /**
   * goBack function
   * @param e
   */
  goBack(event = null) {
    if (event) event.preventDefault();
    this.props.history.push({ pathname: `/${ROUTES.payIn.audience}` });
  }

  /**
   * Handle Activity Button Changes
   * @param e
   */
  handleClick(e) {
    const value = e.target.value;
    if (value === 'other') {
      return this.props.updateIsModalOpen(true);
    }

    if (!(value in this.props.payInForm.payInActivities)) {
      return this.props.addActivity(value, value); // Using the name as both key AND value for consistency with the 'other' value
    }
    return this.props.removeActivity(value);
  }

  /**
   * On PayInActivity render
   * @return {*}
   */
  render() {
    const {
      payInForm: { activityErrorMessage, payInActivities, isActivityFormValid, showActivityError, isModalOpen, payInAudienceType },
      isMobile,
    } = this.props;

    // Add fallback value to catch error before the redirect in componentDidMount runs
    const thisAudienceType = payInAudienceType || 'public';
    // Style form dynamically based on state
    const selectedActivityNum = Object.keys(payInActivities).length;

    const numberOfActivites = Object.keys(this.activities[thisAudienceType]).length;

    const subtitleCopy = 'Please select at least one activity';

    return (
      <main role="main">
        <section className={'paragraph--full-height-single-image-single-copy bg--blue-2023 paragraph--payin ' + (isModalOpen ? 'js-modal-open' : '')}>

          {!isMobile &&
            <FullHeightSingleImage
              alt={audienceImages[thisAudienceType]}
              desktop={audienceImages[thisAudienceType]}
            />
          }

          <div className="form__step fhsisc__text-wrapper">
            <form
              name="comicrelief_payinbundle_activity"
              onSubmit={(e) => { this.onSubmit(e); }}
              method="post"
              noValidate="novalidate"
              className={'has-validation-callback ' + (selectedActivityNum > 0 ? 'activity-selected' : '')}
            >
              <div className="form__header">
                <BackButton id="activity" handleClick={(e) => { this.goBack(e); }} ariaLabel="Back to audience select" />
                <AmountAndGivingTypeHeader />
              </div>

              <div className="form__step form__step--activity">

                <div id="js-togglebtn-activity" className="form__fieldset">
                  <h1 className="text-align-center">How did you take part?</h1>
                </div>

                <fieldset
                  id="payin_activity_selector"
                  ref={element => this.errorDisplay.setRef(element)}
                  className={'' + (showActivityError ? 'error' : '')}
                >
                  <legend className="text-align-center">{subtitleCopy}</legend>
                  <div className={'form__payin_activity form__payin_activity--' + numberOfActivites}>

                    {Object.keys(this.activities[thisAudienceType]).map(activity => (
                      <div className={'form__field--wrapper activity ' + ((activity in this.props.payInForm.payInActivities) ? 'selected' : 'not-selected')} key={activity} >
                        <label htmlFor={activity + '_selector'}>
                          <button
                            type="button"
                            title={activity}
                            value={activity}
                            id={activity + '_selector'}
                            onClick={(e) => { this.handleClick(e); }}
                            aria-label={this.activities[thisAudienceType][activity]}
                          />
                          {this.activities[thisAudienceType][activity]}</label>
                      </div>
                    ))}
                  </div>
                  {(showActivityError && !isModalOpen) &&
                    <ErrorMessage id="activity" copy={activityErrorMessage} />
                  }
                </fieldset>

                <div className="form__fieldset form__payin-activity-submit submit-btn-wrapper">
                  <button
                    id="comicrelief_payinbundle_activity_submit"
                    type="submit"
                    name="comicrelief_payinbundle_activity[submit]"
                    className={'btn form__next cta ' + (!isActivityFormValid ? 'disabled' : '')}
                  > Continue to your details </button>
                </div>

                {isModalOpen && <OtherActivityModal activities={this.activities} onSubmit={this.onSubmit.bind((this))} label={this.activities[thisAudienceType].other} />}

              </div>
            </form>
          </div>
        </section>
      </main>
    );
  }
}

export default connect(
  ({ application, payInForm, donationForm }) => ({ application, payInForm, donationForm }),
  dispatch => bindActionCreators(
    Object.assign({}, applicationActions, payInFormActions, donationFormActions),
    dispatch,
  ),
)(PayInActivity);
