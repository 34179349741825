import React, { Component } from 'react';
import Favicon from 'react-favicon';
import MetaTags from 'react-meta-tags';

import { Redirect, BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import browser from 'browser-detect';

import Footer from '@comicrelief/storybook/src/components/Footer/Footer';
import BrowserSupportMessage from '@comicrelief/storybook/src/components/BrowserSupportMessage/BrowserSupportMessage';

import store from '../../Store/store';

import Header from '../Header/Header';
import Partners from '../Partners/Partners';

import ScrollToTop from './ScrollToTop/ScrollToTop';
import DonationForm from '../../Pages/DonationForm/DonationForm';
import PaymentForm from '../../Pages/PaymentForm/PaymentForm';
import Success from '../../Pages/Success/Success';
import Failure from '../../Pages/Failure/Failure';
import Giftaid from '../../Pages/Giftaid/Giftaid';
import Payment from '../../Pages/Payment/Payment';
import Address from '../../Pages/Address/Address';
import Sorry from '../../Pages/Sorry/Sorry';
import PayInAudience from '../../Pages/PayIn/PayInAudience';
import PayInActivity from '../../Pages/PayIn/PayInActivity';
import PayInDetails from '../../Pages/PayIn/PayInDetails';
import PayInPostalAddress from '../../Pages/PayIn/PayInPostalAddress';
import PayInPaymentMethod from '../../Pages/PayIn/PayInPaymentMethod';
import PayInBillingAddress from '../../Pages/PayIn/PayInBillingAddress';
import PayInPayment from '../../Pages/PayIn/PayInPayment';
import PayInFailure from '../../Pages/PayIn/PayInFailure';
import PayInSuccess from '../../Pages/PayIn/PayInSuccess';

import DataAttributeWrapper from '../DataAttributeWrapper/DataAttributeWrapper';
import CartInfoBanner from '../CartInfoBanner/CartInfoBanner';

import Sentry from '../Sentry/Sentry';

import FetchInterceptorService from '../../Service/FetchInterceptor.service';
import SiteService, { SITE_TYPES } from '../../Service/Site.service';
import { applicationLoad } from '../../Actions/applicationActions';
import SocialTicker from '../SocialTicker';

import FooterCopy from './FooterCopy';

import './App.scss';

const mobileMediaQuery = window.matchMedia('(max-width: 739px)');

/**
 * App Class
 */
export default class App extends Component {
  /**
   * App Constructor
   */
  constructor(props) {
    super(props);

    this.state = {
      isCompleted: false,
      isCompleting: true,
      isFailed: false,
      isMobile: mobileMediaQuery.matches,
    };

    this.site = new SiteService();
    FetchInterceptorService();
    store.dispatch(applicationLoad());
    this.updateMobileFlag = this.updateMobileFlag.bind(this);
    this.isPaymentPage = this.site.get('type') === SITE_TYPES.PAYMENT;
  }

  componentDidMount() {
    // Add breakpoint listener to act as flag for child components
    mobileMediaQuery.addListener(this.updateMobileFlag);
  }

  /**
   * Fetch fallback menu items
   * @return {*[]}
   */
  getFallbackMenuItems() {
    switch (this.site.getSite()) {
      case 'SRDONATE':
        return [
          {
            url: 'https://lite.sportrelief.com/terms-of-use',
            title: 'Legal',
          },
          {
            url: 'https://lite.sportrelief.com/privacy-notice',
            title: 'Privacy notice',
          },
        ];
      default:
        return [
          {
            url: 'https://lite.comicrelief.com/legal/privacy-notice',
            title: 'Privacy notice',
          },
          {
            url: 'https://lite.comicrelief.com/legal/',
            title: 'Legal',
          },
        ];
    }
  }

  updateMobileFlag() {
    this.setState({
      isMobile: mobileMediaQuery.matches,
    });
  }

  renderApplicationRoutes() {
    const childProps = {
      ...this.state,
    };

    if (process.env.REACT_APP_FORCE_PAYMENT_PAGE === 'true' || this.isPaymentPage) {
      return (
        <Switch>
          <Route exact path="/pay/:transactionId" component={PaymentForm} props={childProps} />
          <Route exact path="/failure" component={Failure} props={childProps} />
          <Route exact path="/sorry" component={Sorry} />
          <Redirect push to="/sorry" />
        </Switch>
      );
    }

    return (
      <Switch>
        <Route exact path="/" component={DonationForm} props={childProps} />
        <Route exact path="/cart/:cartId" component={DonationForm} props={childProps} />

        {/* Using render function to allow passing props/state directly to component */}
        <Route exact path="/form/payin/audience" props={childProps} render={props => <PayInAudience {...props} {...childProps} />} />
        <Route exact path="/form/payin/activity" props={childProps} render={props => <PayInActivity {...props} {...childProps} />} />
        <Route exact path="/form/payin/details" props={childProps} render={props => <PayInDetails {...props} {...childProps} />} />
        <Route exact path="/form/payin/postal-address" props={childProps} render={props => <PayInPostalAddress {...props} {...childProps} />} />
        <Route exact path="/form/payin/billing-address" props={childProps} render={props => <PayInBillingAddress {...props} {...childProps} />} />
        <Route exact path="/form/payin/payment-method" props={childProps} render={props => <PayInPaymentMethod {...props} {...childProps} />} />
        <Route exact path="/form/payin/payment" props={childProps} render={props => <PayInPayment {...props} {...childProps} />} />
        <Route exact path="/form/payin/failure" props={childProps} render={props => <PayInFailure {...props} {...childProps} />} />
        <Route exact path="/form/payin/success" props={childProps} render={props => <PayInSuccess {...props} {...childProps} />} />

        <Route exact path="/form/address" component={Address} props={childProps} />
        <Route exact path="/form/giftaid" component={Giftaid} props={childProps} />
        <Route exact path="/form/payment" component={Payment} props={childProps} />
        <Route exact path="/success" component={Success} props={childProps} />
        <Route exact path="/failure" component={Failure} props={childProps} />
        <Route exact path="/sorry" component={Sorry} />
        <Redirect push to="/" />
      </Switch>
    );
  }

  /**
   * App Render
   * @return {*}
   */
  render() {
    const thisSite = this.site.getSite();
    const browserInfo = browser();
    const fallbackMenu = this.getFallbackMenuItems();
    const footerCopy = (FooterCopy[thisSite] ? FooterCopy[thisSite] : FooterCopy.CRDONATE);
    const footerCopyAdditionalMarkup = FooterCopy.ADDITIONAL;
    const metaTags = (
      <div>
        <Favicon url={this.site.get('favicon')} />
        <MetaTags>
          <title>{this.site.get('title')}{this.site.get('title_postfix')}</title>
          <meta name="description" content={this.site.get('meta').description} />
          <meta property="og:title" content={this.site.get('title') + this.site.get('title_postfix')} />
          <meta property="og:image" content={this.site.get('logo')} />
          <meta property="og:site_name" content={this.site.get('title') + this.site.get('title_postfix')} />
          <meta property="og:url" content={window.location.protocol + '//' + window.location.host + '/'} />
          <meta property="og:description" content={this.site.get('meta').description} />
          <meta name="keywords" content={this.site.get('meta').keywords} />
        </MetaTags>
      </div>
    );

    if (browserInfo && browserInfo.name === 'ie' && browserInfo.versionNumber < 11) {
      return (
        <div>
          {metaTags}

          <BrowserSupportMessage />
        </div>
      );
    }

    const isPaymentPage = process.env.REACT_APP_FORCE_PAYMENT_PAGE === 'true' || this.isPaymentPage;

    return (
      <div className={`site-${thisSite.toLowerCase()} site-donate`}>
        {metaTags}

        <Provider store={store}>
          <DataAttributeWrapper>
            <CartInfoBanner />
            <Router>
              <div className="outer-wrapper">
                <SocialTicker />
                <Header isPaymentPage={isPaymentPage} />
                <Sentry />
                <ScrollToTop />
                { this.renderApplicationRoutes() }
                <Partners />
              </div>
            </Router>
          </DataAttributeWrapper>
        </Provider>

        <Footer
          copy={footerCopy}
          source={this.props.baseUrl}
          campaign="comicrelief"
          noLinks={this.isPaymentPage}
          noSocial={this.isPaymentPage}
          fallbackMenu={fallbackMenu}
          additionalMarkup={footerCopyAdditionalMarkup}
        />
      </div>
    );
  }
}
