import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import FRSBLogo from './frsb.png';
import SiteService, { SITE_TYPES } from '../../Service/Site.service';

/**
 * Partners class
 */
const Partners = () => {
  const siteService = new SiteService();
  const isPaymentPage = siteService.get('type') === SITE_TYPES.PAYMENT;
  return (
    <div className="partners">
      <section className="donate__partners">
        <div className="donate__partners-fr">
          <img src={FRSBLogo} alt="Registered with Fundraising Regulator - Logo" />
        </div>
      </section>
      <div className="donate__partners-copy">
        <p className="font--xsmall">By working with the best partners like Swiftaid, Braintree, GoCardless, Paypal and Stripe,<br /> we&apos;ll keep your data safe. {!isPaymentPage && <a className="hyperlink" href="https://www.cafonline.org/" aria-label="Click here to go to Charities Aid Foundation account">Donate using your Charities Aid Foundation account</a>}</p>
      </div>
    </div>
  );
};

export default connect(
  state => state,
  dispatch => bindActionCreators({}, dispatch),
)(Partners);
