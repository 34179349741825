import { select, takeEvery } from 'redux-saga/effects';
import TagManager from 'react-gtm-module';

import { getAddressSection, getApplication, getDonationForm, getGiftaidSection, getPayment, getPayInForm } from '../Selectors';
import CartService from '../../Service/Cart.service';
import SiteService from '../../Service/Site.service';
import { GIVING_TYPE_SINGLE, GIVING_TYPE_MONTHLY, GIVING_TYPE_PAYIN } from '../../Pages/DonationForm/GivingTypeSelector/GivingTypeSelector';
import store from '../../Store/store';
import { setGTMAsInstantiated, UPDATE_CURRENT_PAGE_STEP, UPDATE_DEPENDENCIES } from '../../Actions/applicationActions';
import { SET_PAYMENT_COMPLETE } from '../../Actions/paymentActions';
import { DONATION_FORM_LOAD, UPDATE_AMOUNT, UPDATE_GIVING_TYPE, UPDATE_PREVIOUS_MONEYBUY_ADDED_FOR_GA } from '../../Actions/donationFormActions';
import { UPDATE_CURRENT_PAYIN_STEP } from '../../Actions/payInFormActions';

let tagManagerInitialised = false;

// Helper function to create lowercase, pipe-delimited string for all activity values
function getActivities(activities) {
  let theseActivities = Object.values(activities);
  theseActivities = theseActivities.join('|').replace(/\s+/g, '-').toLowerCase();
  return theseActivities;
}

// Helper function to extract the exact 'page' (not the
// full path) from the widget-set siteURL state variable
function getSiteURLPage(url) {
  // Set the default, in case this ISN'T a widget
  let output = 'donate_homepage';

  if (url && url.includes('rowID')) {
    // Strip out any URL params first:
    output = url.split('?')[0];

    // Grab the last character of the string
    const lastChar = output.substring(output.length - 1);

    // If the path ended with a trailing slash,
    // it's a subpage, so handle accordingly:
    if (lastChar === '/') {
      // Split up the subfolders
      output = output.split('/');
      // Get the highest-level subpage, ignoring that trailing slash
      output = output[output.length - 2];
    } else {
      // Otherwise, it was the homepage:
      output = 'homepage';
    }
  }

  // Add requested prefix
  return 'crcom_' + output;
}

// Helper function to determine if this jouney originated from
// a Donate row/widget from CRcom or not
function isWidgetJourney(url) {
  // Only ever set by the Donate row, so a reliable marker
  return url && url.includes('rowID');
}

// Helper function to map both homepage and payment types to siteprefixed version
function autoNamer(givingType, isHomePage) {
  if (isHomePage) {
    // Homepage naming, defaults to PayIn
    switch (givingType) {
      case GIVING_TYPE_SINGLE:
        return 'cr-donate-homepage-single';
      case GIVING_TYPE_MONTHLY:
        return 'cr-donate-homepage-monthly';
      default:
        return 'cr-donate-homepage-payin';
    }
  } else {
    // Payment type naming, defaults to PayIn
    switch (givingType) {
      case GIVING_TYPE_SINGLE:
        return 'cr-single-payment';
      case GIVING_TYPE_MONTHLY:
        return 'cr-regular-payment';
      default:
        return 'cr-fundraising-payment';
    }
  }
}

/**
 * Complete a transaction or subscription
 */
function* completeTransaction() {
  const application = yield select(getApplication);
  const donationForm = yield select(getDonationForm);
  const giftaidSection = yield select(getGiftaidSection);
  const addressSection = yield select(getAddressSection);
  const payment = yield select(getPayment);
  const payInForm = yield select(getPayInForm);
  const isPayin = donationForm.givingType === GIVING_TYPE_PAYIN;
  const isSingle = donationForm.givingType === GIVING_TYPE_SINGLE;
  let allActivities = '';
  let userEmail = '';
  const isWidget = isWidgetJourney(application.siteUrl);
  const { storedCurrentMoneybuy } = donationForm.moneybuysForGA;
  const thisAffiliation = storedCurrentMoneybuy.affiliation;
  const thisItemListPageURL = getSiteURLPage(application.siteUrl);
  // This acts as default, for non-widgetty journeys
  let dimension16 = 'donate_landing-page';

  // We can use this value as a 'user came via the widget' flag, since it's only set in that scenario
  if (isWidget) {
    dimension16 = 'widget_';

    // Add on giving type:
    dimension16 = isSingle ? dimension16.concat('single_') : dimension16.concat('regular_');

    // Add on popup status:
    dimension16 = !donationForm.wasPopupShown ? dimension16.concat('na') : dimension16.concat('banner-shown');
  }

  // Override this var for membership
  const dimension10 = (application.clientOverride === 'the_fix' ? 'cr-membership-payment' : autoNamer(donationForm.givingType));
  const moneyBuyName = application.moneybuy !== null && application.moneybuy !== '' ? application.moneybuy : `cr-${donationForm.moneyBuy.name}`;

  // PayIn-specific formating and tweaks
  if (isPayin) {
    userEmail = payInForm.payInDetailsValidation.email && payInForm.payInDetailsValidation.email.value ? payInForm.payInDetailsValidation.email.value : 'N';
    allActivities = getActivities(payInForm.payInActivities);
  } else {
    userEmail = addressSection.email && addressSection.email.value ? addressSection.email.value : 'N';
  }

  TagManager.dataLayer({
    dataLayer: {
      ecommerce: {
        currencyCode: donationForm.currency.name,
        purchase: {
          actionField: {
            id: payment.transactionId === null ? payment.subscriptionId : payment.transactionId,
            affiliation: payment.paymentProvider,
            revenue: donationForm.amount,
            shipping: 0.00,
            tax: 0.00,
          },
          products: [{
            id: moneyBuyName,
            name: moneyBuyName,
            price: donationForm.amount,
            brand: dimension10,
            category: application.cartId,
            quantity: 1,
            dimension10,
            dimension11: giftaidSection.giftaid === 1 ? 'yes' : 'no',
            metric1: giftaidSection.giftaid === 1 ? parseFloat(donationForm.amount * 0.25).toFixed(2) : 0,
            ...isPayin && { dimension13: payInForm.payInAudienceType },
            ...isPayin && { dimension14: allActivities },
            dimension16,
          }],
        },
      },
      event: 'EEtransaction',
    },
  });

  TagManager.dataLayer({
    dataLayer: {
      user: {
        userEmail,
      },
      event: 'custUserEmail',
    },
  });

  // Success/Thank You - GA4:
  const thisMoneybuyName = application.moneybuy !== null && application.moneybuy !== '' ? application.moneybuy : `${donationForm.moneyBuy.name}`;
  const thisCategory = isSingle ? 'single-donations' : 'regular-donations';
  let thisCartID = application.cartId;
  thisCartID = thisCartID.toLowerCase();

  if (!isPayin) {
    TagManager.dataLayer({ dataLayer: { ecommerce: null } });
    TagManager.dataLayer({
      dataLayer: {
        event: 'add_payment_info',
        ecommerce: {
          value: donationForm.amount,
          currency: donationForm.currency.name,
          payment_type: payment.paymentProvider,
          items: [{
            item_id: thisMoneybuyName,
            item_name: thisMoneybuyName,
            price: donationForm.amount,
            quantity: 1,
            item_brand: 'comicrelief',
            affiliation: thisAffiliation,
            item_category: thisCartID,
            item_category2: thisCategory,
            item_category3: giftaidSection.giftaid === 1 ? 'giftaid-yes' : 'giftaid-no',
            // Convert the zero-index to one-index, as zero is reserved for manual input
            index: (thisMoneybuyName === 'manual-entry' ? 0 : parseInt(donationForm.moneyBuy.index, 10) + 1),
            item_list_id: thisItemListPageURL,
            item_list_name: thisItemListPageURL,
          }],
        },
      },
    });

    TagManager.dataLayer({ dataLayer: { ecommerce: null } });
    TagManager.dataLayer({
      dataLayer: {
        event: 'purchase',
        ecommerce: {
          transaction_id: payment.transactionId === null ? payment.subscriptionId : payment.transactionId,
          currency: donationForm.currency.name,
          tax: 0,
          shipping: 0,
          value: donationForm.amount,
          items: [{
            item_id: thisMoneybuyName,
            item_name: thisMoneybuyName,
            price: donationForm.amount,
            quantity: 1,
            item_brand: 'comicrelief',
            affiliation: thisAffiliation,
            item_category: thisCartID,
            item_category2: thisCategory,
            item_category3: giftaidSection.giftaid === 1 ? 'giftaid-yes' : 'giftaid-no',
            // Convert the zero-index to one-index, as zero is reserved for manual input
            index: (thisMoneybuyName === 'manual-entry' ? 0 : parseInt(donationForm.moneyBuy.index, 10) + 1),
            item_list_id: thisItemListPageURL,
            item_list_name: thisItemListPageURL,
          }],
        },
      },
    });
  } else {
    TagManager.dataLayer({ dataLayer: { ecommerce: null } });
    TagManager.dataLayer({
      dataLayer: {
        event: 'add_payment_info',
        ecommerce: {
          value: donationForm.amount,
          currency: donationForm.currency.name,
          payment_type: payment.paymentProvider,
          items: [{
            item_id: payInForm.payInAudienceType,
            item_name: payInForm.payInAudienceType,
            affiliation: 'cr-payin',
            price: donationForm.amount,
            quantity: 1,
            item_brand: 'comicrelief',
            item_category: thisCartID,
            item_category2: 'fundraising',
            item_fundraising_activity: allActivities,
            index: 0,
            item_list_id: thisItemListPageURL,
            item_list_name: thisItemListPageURL,
          }],
        },
      },
    });

    TagManager.dataLayer({ dataLayer: { ecommerce: null } });
    TagManager.dataLayer({
      dataLayer: {
        event: 'purchase',
        ecommerce: {
          transaction_id: payment.transactionId === null ? payment.subscriptionId : payment.transactionId,
          currency: donationForm.currency.name,
          tax: 0,
          shipping: 0,
          value: donationForm.amount,
          items: [{
            item_id: payInForm.payInAudienceType,
            item_name: payInForm.payInAudienceType,
            affiliation: 'cr-payin',
            price: donationForm.amount,
            quantity: 1,
            item_brand: 'comicrelief',
            item_category: thisCartID,
            item_category2: 'fundraising',
            item_fundraising_activity: allActivities,
            index: 0,
            item_list_id: thisItemListPageURL,
            item_list_name: thisItemListPageURL,
          }],
        },
      },
    });
  }
}

/**
 * Create the default data layer.
 * Two datalayers are needed on pageload to capture both single and monthly impressions.
 */
function* createDefaultDataLayer() {
  const application = yield select(getApplication);
  const donationForm = yield select(getDonationForm);
  const cart = new CartService(application.cartId);
  const singleMoneyBuys = cart.get('moneybuys')[GIVING_TYPE_SINGLE];
  const regularMoneyBuys = cart.get('moneybuys')[GIVING_TYPE_MONTHLY];
  const isSingle = donationForm.givingType === GIVING_TYPE_SINGLE;
  const isMonthly = donationForm.givingType === GIVING_TYPE_MONTHLY;
  const isPayin = donationForm.givingType === GIVING_TYPE_PAYIN;
  const payinMoneyBuys = cart.get('moneybuys')[GIVING_TYPE_PAYIN];
  const isWidget = isWidgetJourney(application.siteUrl);
  const thisAffiliation = isWidget ? 'cr-website' : 'cr-donation';
  const thisItemListPageURL = getSiteURLPage(application.siteUrl);
  const thisCartID = application.cartId.toLowerCase();

  if (application.clientOverride === 'the_fix') {
    return;
  }

  if (typeof singleMoneyBuys !== 'undefined') {
    const singleImpressionsArray = Object.keys(singleMoneyBuys).map((element, index) => ({
      id: `moneybuy-${singleMoneyBuys[index].amount}`,
      name: `moneybuy-${singleMoneyBuys[index].amount}`,
      price: parseFloat(singleMoneyBuys[index].amount),
      brand: 'cr-single-payment',
      category: application.cartId,
      position: index + 1,
      list: 'cr-donate-homepage-single',
      dimension10: 'cr-single-payment',
    }));

    singleImpressionsArray.push({
      id: 'manual-entry',
      name: 'manual-entry',
      price: 0,
      brand: 'cr-single-payment',
      category: application.cartId,
      position: 0,
      list: 'cr-donate-homepage-single',
      dimension10: 'cr-single-payment',
    });

    TagManager.dataLayer({
      dataLayer: {
        ecommerce: {
          currencyCode: donationForm.currency.name,
          impressions: singleImpressionsArray,
        },
      },
    });

    // GA4: Trigger the following dataLayer when single donation option is in view:
    if (isSingle) {
      const singleImpressionsItems = Object.keys(singleMoneyBuys).map((element, index) => ({
        item_id: `moneybuy-${singleMoneyBuys[index].amount}`,
        item_name: `moneybuy-${singleMoneyBuys[index].amount}`,
        price: singleMoneyBuys[index].amount,
        item_brand: 'comicrelief',
        affiliation: thisAffiliation,
        item_category: thisCartID,
        item_category2: 'single-donations',
        index: index + 1, // 0 should always represent the manual entry
        item_list_id: thisItemListPageURL,
        item_list_name: thisItemListPageURL,
      }));

        // Add manual-entry by hand, keeping the index as zero consistently
      singleImpressionsItems.push({
        item_id: 'manual-entry',
        item_name: 'manual-entry',
        price: 0,
        item_brand: 'comicrelief',
        affiliation: thisAffiliation,
        item_category: thisCartID,
        item_category2: 'single-donations',
        index: 0,
        item_list_id: thisItemListPageURL,
        item_list_name: thisItemListPageURL,
      });

      TagManager.dataLayer({ dataLayer: { ecommerce: null } });
      TagManager.dataLayer({ dataLayer: {
        event: 'view_item_list',
        currency: donationForm.currency.name,
        ecommerce: {
          items: singleImpressionsItems,
        },
      },
      });
    }
  }

  if (typeof regularMoneyBuys !== 'undefined') {
    const regularImpressionsArray = Object.keys(regularMoneyBuys).map((element, index) => ({
      id: `moneybuy-${regularMoneyBuys[index].amount}`,
      name: `moneybuy-${regularMoneyBuys[index].amount}`,
      price: parseFloat(regularMoneyBuys[index].amount),
      brand: 'cr-regular-payment',
      category: application.cartId,
      position: index + 1,
      list: 'cr-donate-homepage-regular',
      dimension10: 'cr-regular-payment',
    }));

    regularImpressionsArray.push({
      id: 'manual-entry',
      name: 'manual-entry',
      price: 0,
      brand: 'cr-regular-payment',
      category: application.cartId,
      position: 0,
      list: 'cr-donate-homepage-regular',
      dimension10: 'cr-regular-payment',
    });

    TagManager.dataLayer({
      dataLayer: {
        ecommerce: {
          currencyCode: donationForm.currency.name,
          impressions: regularImpressionsArray,
        },
      },
    });

    // GA4: Trigger the following dataLayer when regular donation option is selected:
    if (isMonthly) {
      const regularImpressionsItems = Object.keys(regularMoneyBuys).map((element, index) => ({
        item_id: `moneybuy-${regularMoneyBuys[index].amount}`,
        item_name: `moneybuy-${regularMoneyBuys[index].amount}`,
        price: regularMoneyBuys[index].amount,
        item_brand: 'comicrelief',
        affiliation: thisAffiliation,
        item_category: thisCartID,
        item_category2: 'regular-donations',
        index: index + 1, // non-zero indexed, as zero will always represent the manual entry
        item_list_id: thisItemListPageURL,
        item_list_name: thisItemListPageURL,
      }));

        // Add manual-entry by hand, keeping the index as zero consistently
      regularImpressionsItems.push({
        item_id: 'manual-entry',
        item_name: 'manual-entry',
        price: 0,
        item_brand: 'comicrelief',
        affiliation: thisAffiliation,
        item_category: thisCartID,
        item_category2: 'regular-donations',
        index: 0,
        item_list_id: thisItemListPageURL,
        item_list_name: thisItemListPageURL,
      });

      TagManager.dataLayer({ dataLayer: { ecommerce: null } });
      TagManager.dataLayer({ dataLayer: {
        event: 'view_item_list',
        currency: donationForm.currency.name,
        ecommerce: {
          items: regularImpressionsItems,
        },
      },
      });
    }
  }

  // GA4: Trigger the following dataLayer when payin option is selected:
  if (typeof payinMoneyBuys !== 'undefined' && isPayin) {
    TagManager.dataLayer({ dataLayer: { ecommerce: null } });
    TagManager.dataLayer({ dataLayer: {
      event: 'view_item_list',
      currency: donationForm.currency.name,
      ecommerce: {
        items: [{
          item_id: 'no_category', // As Audience not yet set
          item_name: 'no_category', // As Audience not yet set
          affiliation: 'cr-payin',
          price: 0,
          item_brand: 'comicrelief',
          item_category: thisCartID,
          item_category2: 'fundraising',
          item_list_name: thisItemListPageURL,
          item_list_id: thisItemListPageURL,
          index: 0,
        }],
        currency: 'GBP',
      },
    },
    });
  }
}

/**
 * Instantiate GTM
 */
function* instantiateGTM(state) {
  const application = yield select(getApplication);
  const site = new SiteService();

  let finAttr = application.financialAttribution;
  const activity = finAttr.activity ? finAttr.activity : 'null';
  const project = finAttr.project ? finAttr.project : 'null';
  const fund = finAttr.fund ? finAttr.fund : 'null';
  const campaignName = application.campaign;

  // Create a custom pipe-delimited string for GTM (checking for old carts with incomplete values)
  finAttr = `${activity}|${project}|${fund}|${campaignName}`;

  if (typeof window.google_tag_manager === 'undefined' && tagManagerInitialised === false && state.dependencies.gtm === true) {
    TagManager.initialize({
      gtmId: site.get('GTM').id,
      dataLayer: {
        site: [{
          category: 'cr-donation',
          pageCategory: site.get('GTM').application,
          cartID: application.cartId,
          environment: process.env.REACT_APP_ENVIRONMENT,
          financialAttribution: finAttr,
        }],
      },
    });

    tagManagerInitialised = true;
    store.dispatch(setGTMAsInstantiated());
  }
}

/**
 * Register GTM Page Step Changes
 */
function* pageStepChange() {
  const application = yield select(getApplication);
  const donationForm = yield select(getDonationForm);
  const giftaidSection = yield select(getGiftaidSection);
  const addressSection = yield select(getAddressSection);
  const payment = yield select(getPayment);

  const email = addressSection.email && addressSection.email.value ? addressSection.email.value : 'N';
  const isSingle = donationForm.givingType === GIVING_TYPE_SINGLE;

  const isWidget = isWidgetJourney(application.siteUrl);
  const thisAffiliation = isWidget ? 'cr-website' : 'cr-donation';
  const thisItemListPageURL = getSiteURLPage(application.siteUrl);
  let thisCartID = application.cartId;
  thisCartID = thisCartID.toLowerCase();

  TagManager.dataLayer({
    dataLayer: {
      user: {
        userEmail: email,
      },
      event: 'custUserEmail',
    },
  });

  let step = null;
  let thisEvent = null;
  switch (application.currentPageStep) {
    case 1:
      step = 'Giftaid Page';
      break;
    case 2:
      step = 'Your Details';
      thisEvent = 'add_shipping_info';
      break;
    case 3:
      step = 'Payment Details';
      break;
    default:
      step = null;
      thisEvent = null;
  }

  if (step != null && payment.paymentIsComplete === false) {
    // get moneybuy id and name
    const moneyBuyName = application.moneybuy !== null && application.moneybuy !== '' ? application.moneybuy : `cr-${donationForm.moneyBuy.name}`;
    let dimension10 = isSingle ? 'cr-single-payment' : 'cr-regular-payment';
    // This acts as default, for non-widgetty journeys
    let dimension16 = 'donate_landing-page';

    // We can use this value as a 'user came via the widget' flag, since it's only set in that scenario
    if (isWidget) {
      dimension16 = 'widget_';

      // Add on giving type:
      dimension16 = donationForm.givingType === GIVING_TYPE_SINGLE ? dimension16.concat('single_') : dimension16.concat('regular_');

      // Add on popup status:
      dimension16 = !donationForm.wasPopupShown ? dimension16.concat('na') : dimension16.concat('banner-shown');
    }

    // Assign dimension10 for Membership journey, exclude Donation page
    if (application.clientOverride === 'the_fix' && application.currentPageStep !== 0) {
      dimension10 = 'membership-payment';
    }

    TagManager.dataLayer({
      dataLayer: {
        ecommerce: {
          currencyCode: donationForm.currency.name,
          checkout: {
            actionField: {
              step: application.currentPageStep,
              option: step,
            },
            products: [{
              id: moneyBuyName,
              name: moneyBuyName,
              price: donationForm.amount,
              brand: dimension10,
              category: application.cartId,
              quantity: 1,
              dimension10,
              dimension11: giftaidSection.giftaid === 1 ? 'yes' : 'no',
              metric1: giftaidSection.giftaid === 1 ? parseFloat(donationForm.amount * 0.25).toFixed(2) : 0,
              dimension16,
            }],
          },
        },
        event: 'EEcheckout',
      },
    });


    // 04: Payment Details, 03: Your Details GA4 events:
    const thisMoneybuyName = application.moneybuy !== null && application.moneybuy !== '' ? application.moneybuy : `${donationForm.moneyBuy.name}`;
    const thisCategory = isSingle ? 'single-donations' : 'regular-donations';

    if (thisEvent !== null) {
      TagManager.dataLayer({ dataLayer: { ecommerce: null } });
      TagManager.dataLayer({
        dataLayer: {
          event: thisEvent,
          ecommerce: {
            value: donationForm.amount,
            currency: donationForm.currency.name,
            items: [{
              item_id: thisMoneybuyName,
              item_name: thisMoneybuyName,
              price: donationForm.amount,
              quantity: 1,
              item_brand: 'comicrelief',
              affiliation: thisAffiliation,
              item_category: thisCartID,
              item_category2: thisCategory,
              ...(step !== 'Giftaid Page' && { item_category3: giftaidSection.giftaid === 1 ? 'giftaid-yes' : 'giftaid-no' }),
              // Convert the zero-index to one-index, as zero is reserved for manual input
              index: (thisMoneybuyName === 'manual-entry' ? 0 : parseInt(donationForm.moneyBuy.index, 10) + 1),
              item_list_id: thisItemListPageURL,
              item_list_name: thisItemListPageURL,
            }],
          },
        },
      });
    }
  }
}

function* payinStepChange() {
  const application = yield select(getApplication);
  const donationForm = yield select(getDonationForm);
  const payInForm = yield select(getPayInForm);
  const payment = yield select(getPayment);
  const email = payInForm.payInDetailsValidation.email && payInForm.payInDetailsValidation.email.value ? payInForm.payInDetailsValidation.email.value : 'N';
  let thisCartID = application.cartId;
  thisCartID = thisCartID.toLowerCase();
  // Create lowercase, pipe-delimited string for all activity values
  const allActivities = getActivities(payInForm.payInActivities);
  const thisItemListPageURL = getSiteURLPage(application.siteUrl);

  /**
  * null: (/)
  * 0: Audience: (form/payin/audience)
  * 1: Activity: (form/payin/activity)
  * 2: Your details: (form/payin/details)
  * 3: Postal address: (form/payin/postal-address)
  * 4: Payment method: (form/payin/payment-method)
  * 5: Billing address: (form/payin/billing-address)
  * 6: Enter card details: (form/payin/payment)
  * 7: Marketing prefs & success message (same step, different state)
  * */

  // Only run on proper PayIn steps; completeTransaction function already has its own custUserEmail event already
  if (payInForm.currentPayInStep !== false && payInForm.currentPayInStep < 7) {
    TagManager.dataLayer({
      dataLayer: {
        user: {
          userEmail: email,
        },
        event: 'custUserEmail',
      },
    });
  }

  let stepName = null;
  let mappedStepNumber = null; // As we still need a 1/2/3 value for GTM 'shopping cart' model
  let thisEvent = null;

  // As per the OG function, this code runs on the *subsequent* page/step load, passing the previous pagename and its associated data
  switch (payInForm.currentPayInStep) {
    case 1:
      stepName = 'Fundraising Type'; // GTM version of 'Audience', runs on Activity pageload
      mappedStepNumber = 1;
      break;
    case 3:
      stepName = 'Your Details'; // GTM version of 'Postal Address', runs on Postal Address pageload
      mappedStepNumber = 2;
      break;
    case 5:
      // GA4 event for 'form/payin/billing-address' pageload
      thisEvent = 'add_shipping_info';
      break;
    case 6:
      stepName = 'Payment Details'; // GTM version of 'Billing Address', runs on Payment Method pageload
      mappedStepNumber = 3;
      break;
    default:
      stepName = null;
  }


  if (stepName != null && payment.paymentIsComplete === false) {
    const moneyBuyName = `${donationForm.moneyBuy.name}`; // Always 'manual-entry', the only PayIn option
    const dimension10 = 'cr-fundraising-payment'; // As this funcion is Payin-specific, we needn't assign this dyanmically

    TagManager.dataLayer({
      dataLayer: {
        ecommerce: {
          currencyCode: donationForm.currency.name,
          checkout: {
            actionField: {
              step: mappedStepNumber,
              option: stepName,
            },
            products: [{
              id: moneyBuyName,
              name: moneyBuyName,
              price: donationForm.amount,
              brand: dimension10,
              category: application.cartId,
              quantity: 1,
              dimension10,
              dimension11: 'no', // No GA on Payin
              metric1: 0, // No GA on Payin
            }],
          },
        },
        event: 'EEcheckout',
      },
    });
  }

  // Runs on:
  // form/payin/billing-address
  if (thisEvent !== null) {
    TagManager.dataLayer({ dataLayer: { ecommerce: null } });
    TagManager.dataLayer({
      dataLayer: {
        event: thisEvent,
        ecommerce: {
          value: donationForm.amount,
          currency: donationForm.currency.name,
          items: [{
            item_id: payInForm.payInAudienceType,
            item_name: payInForm.payInAudienceType,
            affiliation: 'cr-payin',
            price: donationForm.amount,
            quantity: 1,
            item_brand: 'comicrelief',
            item_category: thisCartID,
            item_category2: 'fundraising',
            item_fundraising_activity: allActivities,
            index: 0,
            item_list_id: thisItemListPageURL,
            item_list_name: thisItemListPageURL,
          }],
        },
      },
    });
  }
}

/**
 * Change the moneybuy / donation amount
 */
function* productChange() {
  const application = yield select(getApplication);
  const donationForm = yield select(getDonationForm);
  const paymentType = autoNamer(donationForm.givingType);
  const previousPaymentType = autoNamer(donationForm.moneyBuy.previousGivingTypeGA);

  // Convert the zero-index to one-index, as zero is reserved for manual input
  // (which is stored as null in this context, so convert accordingly)
  const moneyBuyPosition = donationForm.moneyBuy.index ? parseInt(donationForm.moneyBuy.index, 10) + 1 : 0;

  const homepageType = autoNamer(donationForm.givingType, true); // pass in 'isHomePage' flag

  // Only trigger GTM client for non-membership journey
  if (application.clientOverride !== 'the_fix' && application.siteUrl === null) {
    // Only trigger GTM client if the amount is more than zero
    if (donationForm.amount > 0) {
      // Register the product click
      if (donationForm.moneyBuy.name !== 'manual-entry') {
        TagManager.dataLayer({
          dataLayer: {
            ecommerce: {
              currencyCode: donationForm.currency.name,
              click: {
                actionField: {
                  list: homepageType,
                },
              },
              products: [{
                id: donationForm.moneyBuy.name,
                name: donationForm.moneyBuy.name,
                price: donationForm.amount,
                brand: paymentType,
                category: application.cartId,
                position: moneyBuyPosition,
                dimension10: paymentType,
              }],
            },
            event: 'productClick',
          },
        });
      }

      // Remove previous products from the basket, but only if this is a *different* moneybuy
      if (donationForm.moneyBuy.previousName !== null
        && donationForm.previousAmount !== null
        && donationForm.moneyBuy.previousName.length !== 0) {
        TagManager.dataLayer({
          dataLayer: {
            ecommerce: {
              currencyCode: donationForm.currency.previousName,
              remove: {
                products: [{
                  id: donationForm.moneyBuy.previousName,
                  name: donationForm.moneyBuy.previousName,
                  price: donationForm.previousAmount,
                  brand: previousPaymentType,
                  category: application.cartId,
                  quantity: 1,
                  dimension10: previousPaymentType,
                }],
              },
            },
            event: 'removeFromBasket',
          },
        });
      }


      // Add the new product to the basket
      TagManager.dataLayer({
        dataLayer: {
          ecommerce: {
            currencyCode: donationForm.currency.name,
            add: {
              products: [{
                id: donationForm.moneyBuy.name,
                name: donationForm.moneyBuy.name,
                price: donationForm.amount,
                brand: paymentType,
                category: application.cartId,
                quantity: 1,
                dimension10: paymentType,
              }],
            },
          },
          event: 'addToBasket',
        },
      });
    }
  }
}

/**
 * Triggered if givingType has changed, triggering the relevant GA events
 */
function* givingTypeChange() {
  const donationForm = yield select(getDonationForm);
  const application = yield select(getApplication);
  const cart = new CartService(application.cartId);
  const isSingle = donationForm.givingType === GIVING_TYPE_SINGLE;
  const isMonthly = donationForm.givingType === GIVING_TYPE_MONTHLY;
  const isPayin = donationForm.givingType === GIVING_TYPE_PAYIN;
  const singleMoneyBuys = cart.get('moneybuys')[GIVING_TYPE_SINGLE];
  const regularMoneyBuys = cart.get('moneybuys')[GIVING_TYPE_MONTHLY];
  const payinMoneyBuys = cart.get('moneybuys')[GIVING_TYPE_PAYIN];
  const isWidget = isWidgetJourney(application.siteUrl);
  const thisAffiliation = isWidget ? 'cr-website' : 'cr-donation';
  const thisItemListPageURL = getSiteURLPage(application.siteUrl);
  let thisCartID = application.cartId;
  thisCartID = thisCartID.toLowerCase();

  // GA4: Trigger the following dataLayer when single donation option is in view:
  if (isSingle && typeof singleMoneyBuys !== 'undefined') {
    const singleImpressionsItems = Object.keys(singleMoneyBuys).map((element, index) => ({
      item_id: `moneybuy-${singleMoneyBuys[index].amount}`,
      item_name: `moneybuy-${singleMoneyBuys[index].amount}`,
      price: singleMoneyBuys[index].amount,
      item_brand: 'comicrelief',
      affiliation: thisAffiliation,
      item_category: thisCartID,
      item_category2: 'single-donations',
      index: index + 1, // 0 should always represent the manual entry
      item_list_id: thisItemListPageURL,
      item_list_name: thisItemListPageURL,
    }));

    // Add manual-entry by hand, keeping the index as zero consistently
    singleImpressionsItems.push({
      item_id: 'manual-entry',
      item_name: 'manual-entry',
      price: 0,
      item_brand: 'comicrelief',
      item_category: thisCartID,
      item_category2: 'single-donations',
      item_list_name: 'homepage',
      index: 0,
    });

    TagManager.dataLayer({ dataLayer: { ecommerce: null } });
    TagManager.dataLayer({ dataLayer: {
      event: 'view_item_list',
      currency: donationForm.currency.name,
      ecommerce: {
        items: singleImpressionsItems,
      },
    },
    });
  } else if (isMonthly && typeof regularMoneyBuys !== 'undefined') {
    // GA4: Trigger the following dataLayer when regular donation option is selected:
    const regularImpressionsItems = Object.keys(regularMoneyBuys).map((element, index) => ({
      item_id: `moneybuy-${regularMoneyBuys[index].amount}`,
      item_name: `moneybuy-${regularMoneyBuys[index].amount}`,
      price: parseFloat(regularMoneyBuys[index].amount),
      item_brand: 'comicrelief',
      affiliation: thisAffiliation,
      item_category: thisCartID,
      item_category2: 'regular-donations',
      index: index + 1, // non-zero indexed, as zero will always represent the manual entry
      item_list_id: thisItemListPageURL,
      item_list_name: thisItemListPageURL,
    }));

    // Add manual-entry by hand, keeping the index as zero consistently
    regularImpressionsItems.push({
      item_id: 'manual-entry',
      item_name: 'manual-entry',
      price: 0,
      item_brand: 'comicrelief',
      affiliation: thisAffiliation,
      item_category: thisCartID,
      item_category2: 'regular-donations',
      item_list_id: thisItemListPageURL,
      item_list_name: thisItemListPageURL,
      index: 0,
    });

    TagManager.dataLayer({ dataLayer: { ecommerce: null } });
    TagManager.dataLayer({ dataLayer: {
      event: 'view_item_list',
      currency: donationForm.currency.name,
      ecommerce: {
        items: regularImpressionsItems,
      },
    },
    });
  } else if (isPayin && typeof payinMoneyBuys !== 'undefined') {
    TagManager.dataLayer({ dataLayer: { ecommerce: null } });
    TagManager.dataLayer({ dataLayer: {
      event: 'view_item_list',
      currency: donationForm.currency.name,
      ecommerce: {
        items: [{
          item_id: 'no_category', // As Audience not yet set
          item_name: 'no_category',
          affiliation: 'cr-payin',
          price: 0,
          item_brand: 'comicrelief',
          item_category: thisCartID,
          item_category2: 'fundraising',
          item_list_id: thisItemListPageURL,
          item_list_name: thisItemListPageURL,
          index: 0,
        }],
      },
    },
    });
  }
}

/**
 * Triggered by the GA page on Single/Monthly journeys and Activity page on Payin,
 * it's tracking changes to selected MBs
 */
function* moneybuysForGAChanged() {
  const donationForm = yield select(getDonationForm);
  const application = yield select(getApplication);
  let thisCartID = application.cartId;
  thisCartID = thisCartID.toLowerCase();
  const thisItemListPageURL = getSiteURLPage(application.siteUrl);
  const { storedCurrentMoneybuy, storedPreviousMoneybuy } = donationForm.moneybuysForGA;

  let thisCurrentCategory;
  let thisPreviousCategory;

  // Handle current moneybuy
  if (storedCurrentMoneybuy.givingType === GIVING_TYPE_PAYIN) {
    thisCurrentCategory = 'fundraising';
  } else {
    thisCurrentCategory = storedCurrentMoneybuy.givingType === GIVING_TYPE_SINGLE ? 'single-donations' : 'regular-donations';
  }

  // Handle previous moneybuy if it exists
  if (storedPreviousMoneybuy) {
    if (storedPreviousMoneybuy.givingType === GIVING_TYPE_PAYIN) {
      thisPreviousCategory = 'fundraising';
    } else {
      thisPreviousCategory = storedPreviousMoneybuy.givingType === GIVING_TYPE_SINGLE ? 'single-donations' : 'regular-donations';
    }
  }

  TagManager.dataLayer({ dataLayer: { ecommerce: null } });
  TagManager.dataLayer({
    dataLayer: {
      event: 'select_item',
      ecommerce: {
        items: [{
          item_id: storedCurrentMoneybuy.name,
          item_name: storedCurrentMoneybuy.name,
          affiliation: storedCurrentMoneybuy.affiliation,
          price: storedCurrentMoneybuy.amount,
          item_brand: 'comicrelief',
          item_category: thisCartID,
          item_category2: thisCurrentCategory,
          index: storedCurrentMoneybuy.index,
          item_list_id: thisItemListPageURL,
          item_list_name: thisItemListPageURL,
        }],
        currency: donationForm.currency.name,
      },
    },
  });

  TagManager.dataLayer({ dataLayer: { ecommerce: null } });
  TagManager.dataLayer({
    dataLayer: {
      event: 'view_item',
      ecommerce: {
        items: [{
          item_id: storedCurrentMoneybuy.name,
          item_name: storedCurrentMoneybuy.name,
          price: storedCurrentMoneybuy.amount,
          quantity: 1,
          item_brand: 'comicrelief',
          affiliation: storedCurrentMoneybuy.affiliation,
          item_category: thisCartID,
          item_category2: thisCurrentCategory,
          index: storedCurrentMoneybuy.index,
          item_list_id: thisItemListPageURL,
          item_list_name: thisItemListPageURL,
        }],
        currency: donationForm.currency.name,
      },
    },
  });


  // Remove the previously 'add_to_cart'-d moneybuy
  if (storedPreviousMoneybuy) {
    TagManager.dataLayer({ dataLayer: { ecommerce: null } });
    TagManager.dataLayer({
      dataLayer: {
        event: 'remove_from_cart',
        ecommerce: {
          currency: donationForm.currency.name,
          value: storedPreviousMoneybuy.amount,
          items: [{
            item_id: storedPreviousMoneybuy.name,
            item_name: storedPreviousMoneybuy.name,
            price: storedPreviousMoneybuy.amount,
            quantity: 1,
            item_brand: 'comicrelief',
            affiliation: storedPreviousMoneybuy.affiliation,
            item_category: thisCartID,
            item_category2: thisPreviousCategory,
            index: parseFloat(storedPreviousMoneybuy.index),
            item_list_id: thisItemListPageURL,
            item_list_name: thisItemListPageURL,
          }],
        },
      },
    });
  }

  if (storedCurrentMoneybuy) {
    TagManager.dataLayer({ dataLayer: { ecommerce: null } });
    TagManager.dataLayer({
      dataLayer: {
        event: 'add_to_cart',
        ecommerce: {
          currency: donationForm.currency.name,
          value: storedCurrentMoneybuy.amount,
          items: [{
            item_id: storedCurrentMoneybuy.name,
            item_name: storedCurrentMoneybuy.name,
            price: storedCurrentMoneybuy.amount,
            quantity: 1,
            item_brand: 'comicrelief',
            affiliation: storedCurrentMoneybuy.affiliation,
            item_category: thisCartID,
            item_category2: thisCurrentCategory,
            index: parseFloat(storedCurrentMoneybuy.index),
            item_list_id: thisItemListPageURL,
            item_list_name: thisItemListPageURL,
          }],
        },
      },
    });
  }

  TagManager.dataLayer({ dataLayer: { ecommerce: null } });
  TagManager.dataLayer({
    dataLayer: {
      event: 'begin_checkout',
      ecommerce: {
        value: donationForm.amount,
        currency: donationForm.currency.name,
        items: [{
          item_id: storedCurrentMoneybuy.name,
          item_name: storedCurrentMoneybuy.name,
          price: donationForm.amount,
          quantity: 1,
          item_brand: 'comicrelief',
          affiliation: storedCurrentMoneybuy.affiliation,
          item_category: thisCartID,
          item_category2: thisCurrentCategory,
          index: parseFloat(storedCurrentMoneybuy.index),
          item_list_id: thisItemListPageURL,
          item_list_name: thisItemListPageURL,
        }],
      },
    },
  });
}

/**
 * Register GTM Sagas
 */
export default function* sagas() {
  // Load GTM when providers are loaded
  yield takeEvery(UPDATE_DEPENDENCIES, instantiateGTM);

  // GTM donation form load triggering data layer create
  yield takeEvery(DONATION_FORM_LOAD, createDefaultDataLayer);

  // GTM trigger on journey success
  yield takeEvery(SET_PAYMENT_COMPLETE, completeTransaction);
  // TO-DO: udpdate flag only on success page being rendered

  // GTM Page Step Change
  yield takeEvery(UPDATE_CURRENT_PAGE_STEP, pageStepChange);

  // GTM product changes
  yield takeEvery(UPDATE_AMOUNT, productChange);

  // PayIn: GTM PayIn Step Change
  yield takeEvery(UPDATE_CURRENT_PAYIN_STEP, payinStepChange);

  // GivingType change on homepage
  yield takeEvery(UPDATE_GIVING_TYPE, givingTypeChange);

  // GivingType change on homepage
  yield takeEvery(UPDATE_PREVIOUS_MONEYBUY_ADDED_FOR_GA, moneybuysForGAChanged);
}
