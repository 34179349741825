import SiteConfig from '../Config/site.json';

const DEFAULT_SITE = 'CRDONATE';

export const SITE_TYPES = {
  DONATION: 'DONATION',
  PAYMENT: 'PAYMENT',
};

/**
 * SiteService class
 */
export default class SiteService {
  /**
   * SiteService Constructor
   */
  constructor() {
    this.site = process.env.REACT_APP_SITE;
    this.setConfig();
  }

  /**
   * Get a config variable
   * @param param
   * @return {null}
   */
  get(param) {
    return typeof this.config[param] !== 'undefined' ? this.config[param] : null;
  }

  /**
   * Get Site
   * @return {*}
   */
  getSite() {
    return this.site;
  }

  /**
   * Set configuration
   * @return {*}
   * @private
   */
  setConfig() {
    if (typeof this.site === 'undefined' && typeof SiteConfig[this.site] === 'undefined') {
      this.site = DEFAULT_SITE;
    }

    return this.config = SiteConfig[this.site];
  }
}
