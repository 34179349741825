import React from 'react';
import { connect } from 'react-redux';
import Raven from 'react-raven';

/**
 * MoneyBuys Component
 */
const Sentry = ({ application }) => {
  /**
   * MoneyBuys render
   * @return {*}
   */
  if (application.dependencies.sentry === false) {
    return null;
  }

  return (
    <Raven
      dsn="https://779580845f964be1b7abcaca5576f365@sentry.io/1226481"
      config={{
        environment: process.env.REACT_APP_ENVIRONMENT,
      }}
    />
  );
};

export default connect(({ application }) => ({ application }))(Sentry);
