import React, { Component } from 'react';
import { connect } from 'react-redux';

import MarketingConsent from '@comicrelief/storybook/src/components/MarketingConsent/MarketingConsent';
import { bindActionCreators } from 'redux';
import * as payInFormActions from '../../Actions/payInFormActions';
import marketingConsentData from './data/marketingConsentData.json';

/**
 * MarketingPreferences Component
 */
class PayInMarketingPreferences extends Component {
  constructor(props) {
    super(props);
    this.scrollTimeout = null;
    this.state = {
      isSubmitting: false,
    };
  }
  /**
   * Deals with component update after pressing submit button
   */
  componentDidUpdate() {
    const { payInForm: { marketingPrefs } } = this.props;
    if (marketingPrefs.showErrorMessages && !marketingPrefs.formValidity && marketingPrefs.validating) {
      // timeout needed for error class names to appear
      this.scrollTimeout = setTimeout(() => { this.scrollToError(); }, 500);
    }
  }

  /**
   * Check if any permission is set
   * @returns {boolean}
   */
  isPermissionSet() {
    const { payInForm: { marketingPrefs } } = this.props;
    return (marketingPrefs.permissionEmail.value !== null
      && marketingPrefs.permissionEmail.value !== '')
    || (marketingPrefs.permissionPost.value !== null
        && marketingPrefs.permissionPost.value !== '')
      || (marketingPrefs.permissionPhone.value !== null
        && marketingPrefs.permissionPhone.value !== '')
      || (marketingPrefs.permissionSMS.value !== null
        && marketingPrefs.permissionSMS.value !== '');
  }

  /**
   * Checks if any field is invalid.
   * If invalid fields: shows error sets state to show errorMessages.
   * If all fields valid: sets form validity to true
   * @param e
   */
  validateForm(e) {
    e.preventDefault();
    const { payInForm: { marketingPrefs }, payInMarketingConsentSubmitData } = this.props;
    // Post form values only if permission is set
    if (this.isPermissionSet()) {
      let validity = true;
      const validation = {
        permissionEmail: marketingPrefs.permissionEmail,
        permissionPost: marketingPrefs.permissionPost,
        permissionPhone: marketingPrefs.permissionPhone,
        permissionSMS: marketingPrefs.permissionSMS,
      };

      Object.keys(validation).map((key) => {
        const item = validation[key];

        if (item.valid !== true) {
          validity = false;
        }
        return true;
      });

      // update state accordingly
      if (validity !== true) {
        this.props.updatePayInMarketingValidity(false);
      } else {
        // Stop any more submissions being made
        this.setState({ isSubmitting: true });
        this.props.updatePayInMarketingValidity(true);
        // Update flag used by saga to submit data
        payInMarketingConsentSubmitData();
      }
    } else {
      /**
      * Submit form data (regardless of marketing prefs interaction)
      */
      payInMarketingConsentSubmitData();
      // Stop any more submissions being made
      this.setState({ isSubmitting: true });
    }
  }

  /**
   * Goes through field refs, gets the first erroring field and focuses on it,
   * uses additional checks to suit specific components
   */
  scrollToError() {
    // Scroll to the first erroring field and focus on its input field
    const errorWrapper = document.querySelectorAll('.form__field--erroring')[0];
    const errorField = document.querySelectorAll('.form__field--erroring > div input')[0];

    if (typeof errorWrapper !== 'undefined') {
      errorWrapper.scrollIntoView('smooth');
      errorField.focus();
    }
    clearTimeout(this.scrollTimeout);
  }

  render() {
    const { payInForm: { marketingPrefs } } = this.props;

    return (
      <div className="form__step form__step--payment fhsisc__text-wrapper bg--transparent payin--marketing-consent">
        <form
          name="comicrelief_payinbundle_marketing-pref"
          method="post"
          onSubmit={(e) => { e.preventDefault(); }}
          noValidate="novalidate"
          className="has-validation-callback marketing-pref-form"
        >
          <div>
            <div className="form__row form__row--marketing-consent">
              <h1 className="thankyou-heading">Thank you so much!</h1>
              <p className="thankyou-subheading">
                We’ve successfully received your wonderful fundraising payment, but just before we show you the details, would you like to...
              </p>

              <h2 className="text-align-center marketing-prefs-heading">Stay up to date with Comic Relief?</h2>
            </div>
            <MarketingConsent
              getValidation={(validation) => {
                Object.keys(validation).forEach(key => this.props.changePayInPermissions(key, validation[key]));
              }}
              itemData={marketingConsentData}
              valueFromParent={
                {
                  permissionEmail: marketingPrefs.permissionEmail.validation,
                  permissionPost: marketingPrefs.permissionPost.validation,
                  permissionPhone: marketingPrefs.permissionPhone.validation,
                  permissionSMS: marketingPrefs.permissionSMS.validation,
                }
              }
              showErrorMessages={marketingPrefs.showErrorMessages}
            />

            <div className="form__step--marketing-pref--bottom">
              <button
                className="btn btn--red cta"
                type="submit"
                aria-label="Submit"
                onClick={e => this.validateForm(e)}
                disabled={this.state.isSubmitting}
              > Show me my payment details
              </button>

            </div>
          </div>

        </form>
      </div>
    );
  }
}

export default connect(
  ({ application, payment, payInForm }) => ({ application, payment, payInForm }),
  dispatch => bindActionCreators(Object.assign({}, payInFormActions), dispatch),
)(PayInMarketingPreferences);
