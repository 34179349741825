import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Braintree from '../../Component/Provider/Braintree/Braintree';
import Stripe from '../../Component/Provider/Stripe/Stripe';
import * as applicationActions from '../../Actions/applicationActions';
import * as paymentActions from '../../Actions/paymentActions';

import * as payInFormActions from '../../Actions/payInFormActions';
import { GIVING_TYPE_PAYIN } from '../DonationForm/GivingTypeSelector/GivingTypeSelector';
import { paymentProviders } from '../../Service/PaymentConfiguration.service';
import FullHeightSingleImage from '../../Component/FullHeightSingleImage/FullHeightSingleImage';
import { ROUTES } from '../../Service/PaymentServiceRouter.service';
import BackButton from '../../Component/BackButton/BackButton';
import AmountAndGivingTypeHeader from '../../Component/AmountAndGivingTypeHeader/AmountAndGivingTypeHeader';
import audienceImages from './data/audienceImages';

/**
 * PayInDetails Component
 */
class PayInPayment extends Component {
  constructor(props) {
    super(props);
    this.componentIsMounted = false;
  }

  /**
   * Payment componentWillMount
   */
  componentDidMount() {
    // Ensure user is following the journey linearly
    const prevStep = this.props.payInForm.currentPayInStep;
    const thisStep = 6;
    const isInvalidJourney = prevStep >= thisStep || prevStep < thisStep - 1 || prevStep === null;

    if (isInvalidJourney) {
      return this.props.history.push({ pathname: '/' });
    }

    document.title = `Pay-In: Payment${this.props.application.page.titlePostfix}`;
    this.componentIsMounted = true;

    // Update form progress
    this.props.updateCurrentPayInStep(thisStep);

    return null;
  }

  /**
   * Payment componentWillUnmount
   */
  componentWillUnmount() {
    this.componentIsMounted = false;
  }

  /**
   * Payment onSuccess
   */
  onSuccess() {
    this.props.history.push({ pathname: `/${ROUTES.payIn.success}` });
  }

  /**
   * Payment onFailure
   */
  onFailure() {
    this.props.setPaymentAsFailed();
    this.props.history.push({ pathname: `/${ROUTES.payIn.failure}` });
  }

  /**
   * Go back to previous form stage with state
   * @param event
   * @return {Promise<void>}
   */
  goBack(event) {
    event.preventDefault();

    const { payment } = this.props;

    if (payment.transactionId) {
      this.props.cancelTransaction();
    }

    return this.props.history.push({ pathname: `/${ROUTES.payIn.billingAddress}` });
  }

  /**
   * Render the payment component
   * @return {*}
   */
  renderPaymentComponent() {
    const { application: { providers }, donationForm, history } = this.props;
    const props = {
      onSuccess: transaction => this.onSuccess(transaction),
      onFailure: transaction => this.onFailure(transaction),
    };

    if (donationForm.givingType === GIVING_TYPE_PAYIN) {
      if (providers.single.indexOf(paymentProviders.STRIPE) !== -1) {
        return (
          <div>
            <h1 className="form__subtitle">Enter card details</h1>
            <Stripe {...props} history={history} />
          </div>
        );
      }

      if (providers.single.indexOf(paymentProviders.BRAINTREE) !== -1 || providers.single.indexOf(paymentProviders.BRAINTREE_ASYNC) !== -1) {
        return (
          <div>
            <h1 className="form__subtitle">Enter card details</h1>
            <Braintree {...props} history={history} />
          </div>
        );
      }
    }

    return null;
  }


  /**
   * PayInDetails render
   * @return {*}
   */
  render() {
    const {
      application,
      payment,
      isMobile,
      payInForm: { payInAudienceType },
    } = this.props;

    if (application.providers === null ||
      payment.paymentIsComplete === true ||
      this.componentIsMounted === false
    ) {
      return null;
    }

    return (
      <main role="main">

        <section className="paragraph--full-height-single-image-single-copy bg--blue-2023 paragraph--payin">
          {!isMobile && <FullHeightSingleImage
            alt={audienceImages[payInAudienceType]}
            desktop={audienceImages[payInAudienceType]}
          />}

          <div className="form__step fhdisc__text-wrapper fhsisc__text-wrapper bg--transparent">
            <form
              name="comicrelief_payinbundle_payment"
              method="post"
              onSubmit={(e) => { this.handleSubmit(e); }}
              noValidate="novalidate"
              className="has-validation-callback fhdisc__text-3"
            >
              <div className="form__header">
                <BackButton id="payment" handleClick={(e) => { this.goBack(e); }} ariaLabel="Back to payment details" />
                <AmountAndGivingTypeHeader />
              </div>

              {this.renderPaymentComponent()}

            </form>
          </div>
        </section>
      </main>
    );
  }
}

function mapStateToProps(state) {
  return {
    application: state.application,
    donationForm: state.donationForm,
    payment: state.payment,
    payInForm: state.payInForm,
  };
}

function mapDispachToProps(dispatch) {
  return bindActionCreators(Object.assign({}, applicationActions, paymentActions, applicationActions, payInFormActions), dispatch);
}

export default connect(mapStateToProps, mapDispachToProps)(PayInPayment);
