const establishmentData = {

  establishmentType: [
    { label: 'Please select' },
    { label: 'Nursery/Early Years Setting', value: 'nursery' },
    { label: 'Primary School', value: 'primary_school' },
    { label: 'Secondary School', value: 'secondary_school' },
    { label: 'College or Sixth Form', value: 'college_or_sixth_form' },
    { label: 'Youth Group', value: 'youth_group' },

  ],
};

export default establishmentData;

