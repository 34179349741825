import React, { Component } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Braintree from '../../Component/Provider/Braintree/Braintree';
import Stripe from '../../Component/Provider/Stripe/Stripe';
import Gocardless, { steps } from '../../Component/Provider/Gocardless/Gocardless';

import { GIVING_TYPE_SINGLE, GIVING_TYPE_MONTHLY } from '../DonationForm/GivingTypeSelector/GivingTypeSelector';
import { paymentProviders } from '../../Service/PaymentConfiguration.service';

import * as applicationActions from '../../Actions/applicationActions';
import * as paymentActions from '../../Actions/paymentActions';
import CartService from '../../Service/Cart.service';
import ProgressBar from '../../Component/ProgressBar/ProgressBar';

import FullHeightSingleImage from '../../Component/FullHeightSingleImage/FullHeightSingleImage';

/**
 * Payment class
 */
class Payment extends Component {
  /**
   * Payment constructor
   */
  constructor(props) {
    super(props);

    this.componentIsMounted = false;
  }

  /**
   * Payment componentWillMount
   */
  componentDidMount() {
    document.title = `Payment${this.props.application.page.titlePostfix}`;
    this.componentIsMounted = true;

    // If the user has not completed the previous form step, then push them back to the homepage
    if (this.props.application.completedPageStep === null ||
      this.props.application.completedPageStep < 2 ||
      this.props.payment.paymentIsComplete === true) {
      return this.props.history.push({ pathname: '/' });
    }

    this.props.updateCurrentPageStep(3);

    return null;
  }

  /**
   * Payment componentWillUnmount
   */
  componentWillUnmount() {
    this.componentIsMounted = false;
  }

  /**
   * Payment onSuccess
   */
  onSuccess() {
    this.props.history.push({ pathname: '/success' });
  }

  /**
   * Payment onFailure
   */
  onFailure() {
    this.props.setPaymentAsFailed();
    this.props.history.push({ pathname: '/failure' });
  }

  /**
   * Go back to previous form stage with state
   * @param event
   * @return {Promise<void>}
   */
  goBack(event) {
    event.preventDefault();

    const { payment, updateStep } = this.props;

    if (payment.transactionId) {
      this.props.cancelTransaction();
    }

    if (payment.step === steps.ACCOUNT_DETAILS_CONFIRMATION) {
      return updateStep('');
    }

    return this.props.history.push({ pathname: '/form/address' });
  }

  /**
   * Render the payment component
   * @return {*}
   */
  renderPaymentComponent() {
    const { application: { providers }, donationForm, payment, history } = this.props;
    const props = {
      onSuccess: transaction => this.onSuccess(transaction),
      onFailure: transaction => this.onFailure(transaction),
    };

    if (donationForm.givingType === GIVING_TYPE_SINGLE) {
      if (providers.single.indexOf(paymentProviders.STRIPE) !== -1) {
        return (
          <div>
            <h1 className="form__subtitle">Please enter your payment details</h1>
            <Stripe {...props} history={history} />
          </div>
        );
      }

      if (providers.single.indexOf(paymentProviders.BRAINTREE) !== -1 || providers.single.indexOf(paymentProviders.BRAINTREE_ASYNC) !== -1) {
        return (
          <div>
            <h1 className="form__subtitle">Please enter your payment details</h1>
            <Braintree {...props} history={history} />
          </div>
        );
      }
    } else {
      if (providers.monthly.indexOf(paymentProviders.GOCARDLESS) !== -1 && payment.paymentProvider === paymentProviders.GOCARDLESS) {
        return (
          <div>
            <Gocardless {...props} />
          </div>
        );
      }

      if (providers.monthly.indexOf(paymentProviders.BRAINTREE) !== -1 && payment.paymentProvider === paymentProviders.BRAINTREE) {
        return (
          <div>
            <h1 className="form__subtitle">Setup monthly donation</h1>
            <Braintree {...props} />
          </div>
        );
      }
    }

    return null;
  }

  /**
   * Payment render
   * @return {*}
   */
  render() {
    const { application, donationForm, payment, application: { currentPageStep } } = this.props;

    if (application.providers === null ||
      payment.paymentIsComplete === true ||
      this.componentIsMounted === false
    ) {
      return null;
    }

    const cart = new CartService(application.cartId);
    const { desktop, mobile, alt } = cart.config.header[donationForm.givingType];
    const isMonthly = donationForm.givingType === GIVING_TYPE_MONTHLY;
    // As monthly payment involves sub steps, having to update the overall counter for the Progress bar
    const isSecondPaymentStep = isMonthly && payment.step === steps.ACCOUNT_DETAILS_CONFIRMATION;
    const updatedCurrentPageStep = isSecondPaymentStep ? currentPageStep + 1 : currentPageStep;
    let progressBarText;

    if (isSecondPaymentStep) {
      progressBarText = isMonthly ? 'Almost there… it feels like love is just on the horizon…' : 'Almost there...';
    } else {
      progressBarText = isMonthly ? "Great, you're nearly there! Just a few more steps until we're ready to join forces, to do good whilst having a great time!" : "Great, we're nearly done!";
    }

    return (
      <main role="main">
        <section className={`paragraph--full-height-single-image-single-copy ${isMonthly ? 'njaons' : 'bg--blue-2023'}`}>
          <FullHeightSingleImage
            hideOnMobile
            alt={alt}
            desktop={desktop}
            mobile={mobile}
          />
          <div className="form__step form__step--payment fhsisc__text-wrapper bg--transparent">
            <form
              method="post"
              onSubmit={(event) => { event.preventDefault(); }}
            >
              <div className="form__row form__row--payment">
                <a
                  id="js-back-details"
                  className="form__back"
                  href="/"
                  role="button"
                  onClick={(e) => { this.goBack(e); }}
                  aria-label="Back to Giftaid claim"
                >
                  Back
                </a>

                <ProgressBar currentStep={updatedCurrentPageStep} isMonthly={isMonthly} text={progressBarText} />

                {this.renderPaymentComponent()}
              </div>
            </form>

            <div className="payment-info-wrapper">
              <p className="payment-icons-heading">We&apos;ve partnered with the following payment providers to process your donation securely:</p>
              <div className="payment-icons-wrapper">
                <div className="payment-icon stripe">
                  <img src="/images/payment-option-svgs/stripe.svg" alt="Stripe icon" />
                </div>
                <div className="payment-icon braintree">
                  <img src="/images/payment-option-svgs/braintree.svg" alt="Braintree icon" />
                </div>
                <div className="payment-icon go-cardless">
                  <img src="/images/payment-option-svgs/go-cardless.svg" alt="Go-Cardless icon" />
                </div>
              </div>
            </div>

          </div>
        </section>
      </main>
    );
  }
}

function mapStateToProps(state) {
  return {
    application: state.application,
    donationForm: state.donationForm,
    giftaidSection: state.giftaidSection,
    addressSection: state.addressSection,
    payment: state.payment,
  };
}

function mapDispachToProps(dispatch) {
  return bindActionCreators(Object.assign({}, applicationActions, paymentActions), dispatch);
}

export default connect(mapStateToProps, mapDispachToProps)(Payment);
