// import store from '../Store/store';

export const RESET_SUCCESS_STATE = 'RESET_SUCCESS_STATE';
export const UPDATE_PERMISSION_EMAIL = 'UPDATE_PERMISSION_EMAIL';
export const UPDATE_PERMISSION_POST = 'UPDATE_PERMISSION_POST';
export const UPDATE_PERMISSION_PHONE = 'UPDATE_PERMISSION_PHONE';
export const UPDATE_PERMISSION_SMS = 'UPDATE_PERMISSION_SMS';
export const RESET_PERMISSION_SMS = 'RESET_PERMISSION_SMS';
export const UPDATE_ERROR_MESSAGE = 'UPDATE_ERROR_MESSAGE';
export const UPDATE_VALIDITY = 'UPDATE_VALIDITY';
export const SUBMIT_MARKETING_CONSENT_FORM = 'SUBMIT_MARKETING_CONSENT_FORM';

/**
 * Resets the state of the success section
 * @return {{type: string}}
 */
export function resetSuccessState() {
  return {
    type: RESET_SUCCESS_STATE,
  };
}

/**
 * Resets the state of the permission SMS
 * @return {{type: string}}
 */
export function resetPermissionSMS() {
  return {
    type: RESET_PERMISSION_SMS,
  };
}

/**
 * Update Marketing preferences
 * Form state
 * @param status boolean
 * @return {{type: string, formSubmitted: *}}
 */
export function submitMarketingConsentForm(status) {
  return {
    type: SUBMIT_MARKETING_CONSENT_FORM,
    formSubmitted: status,
  };
}
/**
 * Changes field permission states
 * @param checkbox
 * @param validation
 */
export function changePermissions(checkbox, validation) {
  let value = validation.value;
  let checked = false;

  if (value !== null) {
    value = value === 'yes' ? 1 : 0;
    checked = true;
  }

  // set validity for fields
  const fieldValidity = (checked === true && validation.valid === true)
    || (checked === false && validation.value === null);

  const phoneValidity = (checked === false
    && typeof validation.fieldValidation.phone !== 'undefined'
    && validation.fieldValidation.phone.value === '')
    || (checked === true && typeof validation.fieldValidation.phone !== 'undefined' && validation.fieldValidation.phone.valid === true);

  const mobileValidity = (checked === false
    && typeof validation.fieldValidation.mobile !== 'undefined'
    && validation.fieldValidation.mobile.value === '')
    || (checked === false && typeof validation.fieldValidation.mobile !== 'undefined'
      && validation.fieldValidation.mobile.valid === true)
    || (checked === true && typeof validation.fieldValidation.mobile !== 'undefined' && validation.fieldValidation.mobile.valid === true);

  switch (checkbox) {
    case 'permissionEmail':
      return {
        type: UPDATE_PERMISSION_EMAIL,
        permissionEmail: {
          checked,
          value,
          valid: fieldValidity,
          validation,
        },
      };
    case 'permissionPost':
      return {
        type: UPDATE_PERMISSION_POST,
        permissionPost: {
          checked,
          value,
          valid: fieldValidity,
          validation,
        },
      };
    case 'permissionPhone':
      return {
        type: UPDATE_PERMISSION_PHONE,
        permissionPhone: {
          checked,
          value,
          valid: phoneValidity,
          validation,
        },
      };
    case 'permissionSMS':
      return {
        type: UPDATE_PERMISSION_SMS,
        permissionSMS: {
          checked,
          value,
          valid: mobileValidity,
          validation,
        },
      };
    default:
      return null;
  }
}


export function updateErrorMessage(error) {
  return {
    type: UPDATE_ERROR_MESSAGE,
    error,
  };
}

export function updateValidity(status) {
  return {
    type: UPDATE_VALIDITY,
    status,
  };
}
