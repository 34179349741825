import React from 'react';
import PropTypes from 'prop-types';
import browser from 'browser-detect';

/**
 * ErrorMessage component
 */
const ErrorMessage = ({ copy, id }) => {
  const isBrowser = browser();
  const supportedAriaAttributes = isBrowser.name === 'firefox' && isBrowser.os.match('Windows') ? { role: 'alert', 'aria-relevant': 'all' } : { role: 'status' };

  return (
    <div
      id={`field-error--${id}`}
      className={`form__field-error-container form__field-error-container--${id}`}
      aria-live="assertive"
      {...supportedAriaAttributes}
    >
      <span className={id === 'payin-alert' ? 'form-error form-error-decimal' : 'form-error'}>
        {copy}
      </span>
    </div>
  );
};

ErrorMessage.propTypes = {
  copy: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
};

export default ErrorMessage;
