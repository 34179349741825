import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as applicationActions from '../../Actions/applicationActions';
import * as paymentActions from '../../Actions/paymentActions';
import * as giftaidActions from '../../Actions/giftAidActions';

import PaypalButton from '../Provider/Paypal/Paypal';
import ApplePayButton from '../Provider/ApplePay/ApplePay';
import GooglePayButton from '../Provider/GooglePay/GooglePay';

import { GIVING_TYPE_MONTHLY, GIVING_TYPE_SINGLE, GIVING_TYPE_PAYIN } from '../../Pages/DonationForm/GivingTypeSelector/GivingTypeSelector';
import { paymentProviders } from '../../Service/PaymentConfiguration.service';
import { getNonCardProviders, nonCardLoadingStatusUpdate } from '../Provider/_utils/nonCardProviderHelpers';

import { ROUTES } from '../../Service/PaymentServiceRouter.service';

import './PaymentButtons.scss';

/**
 * PaymentButtons Component
 */
class PaymentButtons extends Component {
  constructor() {
    super();
    this.state = {
      nonCardLoadingStatuses: {},
    };
  }

  /**
   * Create card payment
   * @param {Object} event
   */
  onSubmitCard(event) {
    event.preventDefault();

    const { history, updateCompletedPageStep, donationForm } = this.props;

    // Update the page step sto be completed
    updateCompletedPageStep(1);

    // Redirect to next page, based on Giving Type
    const newPath = (donationForm.givingType === GIVING_TYPE_PAYIN ? `/${ROUTES.payIn.billingAddress}` : '/form/address');

    history.push({ pathname: newPath });
  }

  /**
   * nonCardLoadingStatusUpdateWrapper
   *
   * Allow us to pass 'this' to our shared function
   * @param thisProviderName string
   * @param thisProviderStatus string
   */
  nonCardLoadingStatusUpdateWrapper(thisProviderName, thisProviderStatus) {
    nonCardLoadingStatusUpdate(thisProviderName, thisProviderStatus, this);
  }

  /**
   * PaymentButtons render
   * @return {*}
   */
  render() {
    const {
      application,
      donationForm,
      history,
      setPaymentAsFailed,
      updateCurrentPageStep,
      updateErrorMessage,
      updatePaymentProvider,
      updatePaymentType,
    } = this.props;
    const providers = application.providers;

    const onSuccess = () => {
      const baseUrl = window.location.protocol + '//' + window.location.host;
      const newSuccessUrl = application.callbackUrls.successUrl.replace(baseUrl, '');
      history.push({ pathname: newSuccessUrl });
    };

    const onError = (error) => {
      updateCurrentPageStep(2);

      if (error && typeof error.message !== 'undefined' && error.message.indexOf('Correlation id: unknown') !== -1) {
        try {
          const jsError = JSON.parse(error.message.split('Correlation id: unknown')[1]);

          if (typeof jsError.data !== 'undefined' && typeof jsError.data.type !== 'undefined') {
            return updateErrorMessage(jsError.message);
          }
        } catch (e) {
          return updateErrorMessage('The payment did not go through and you haven\'t been charged.');
        }
      }

      setPaymentAsFailed();
      const baseUrl = window.location.protocol + '//' + window.location.host;
      const newFailureUrl = application.callbackUrls.failureUrl.replace(baseUrl, '');
      return history.push({ pathname: newFailureUrl });
    };

    const onCancel = () => {
      const cancelPath = (donationForm.givingType === GIVING_TYPE_PAYIN ? ROUTES.payIn.cancel : ROUTES.global.cancel);
      history.push({ pathname: `/${cancelPath}` });
    };

    const { nonCardLoadingStatuses } = this.state;

    // Make sure we've got at least one non-card provider available to use
    const currentNonCardProviders = getNonCardProviders(this.props.providers, paymentProviders);
    const nonCardProvidersAreAvailable = currentNonCardProviders !== null && currentNonCardProviders.length > 0;

    // Cache all of the non-card loading statuses
    const anyLoading = Object.values(nonCardLoadingStatuses).indexOf('loading') > -1;
    const providerStatusesAreEmpty = Object.values(nonCardLoadingStatuses).length < 1;

    // Only show the loader if we've definitely got SOME noncard providers enabled and this is a NON monthly payment,
    // but also when we have a 'loading' flag OR we're still waiting on any status updates from the child components
    // don't show loader in case of Monthly payment
    const showLoader = donationForm.givingType !== GIVING_TYPE_MONTHLY &&
                       nonCardProvidersAreAvailable &&
                       (anyLoading || providerStatusesAreEmpty);

    return (
      <div className="form__row form__row--payment-buttons gift-aid-form">
        <div className="form__fieldset">

          { (donationForm.givingType === GIVING_TYPE_SINGLE || donationForm.givingType === GIVING_TYPE_PAYIN)
           && typeof providers.single !== 'undefined' &&
          (providers.single.indexOf(paymentProviders.BRAINTREE) !== -1 ||
            providers.single.indexOf(paymentProviders.BRAINTREE_ASYNC) !== -1 ||
            providers.single.indexOf(paymentProviders.STRIPE) !== -1) ?
            (
              <button
                id="comicrelief_payinbundle_payment_card"
                className="payment-button form__next"
                type="submit"
                aria-label="Pay by card"
                onClick={e => this.onSubmitCard(e)}
              >Pay by card
              </button>
            ) : ''}

          {(donationForm.givingType === GIVING_TYPE_SINGLE || donationForm.givingType === GIVING_TYPE_PAYIN) &&
          typeof providers.single !== 'undefined' &&
          providers.single.indexOf(paymentProviders.BRAINTREE_APPLEPAY) !== -1 ?
            (
              <ApplePayButton
                onError={onError}
                onSuccess={onSuccess}
                onCancel={onCancel}
                nonCardLoadingStatusUpdate={(name, status) => this.nonCardLoadingStatusUpdateWrapper(name, status)}
              />
            ) : ''}

          {(donationForm.givingType === GIVING_TYPE_SINGLE || donationForm.givingType === GIVING_TYPE_PAYIN)
           && providers.single.indexOf(paymentProviders.BRAINTREE_GOOGLEPAY) !== -1 ?
            (
              <GooglePayButton
                onError={onError}
                onSuccess={onSuccess}
                onCancel={onCancel}
                nonCardLoadingStatusUpdate={(name, status) => this.nonCardLoadingStatusUpdateWrapper(name, status)}
              />
            ) : ''}

          {/* Use the state (set via nonCardLoadingStatusUpdate callback in provider components) to determine if anything's loading */}
          { showLoader &&
          <div className="loader-container">
            <span className="loader" />
          </div>
          }

          { donationForm.givingType === GIVING_TYPE_MONTHLY && typeof providers.monthly !== 'undefined' &&
          providers.monthly.indexOf(paymentProviders.GOCARDLESS) !== -1
            ? (
              <div>
                <button
                  id="comicrelief_payinbundle_payment_directdebit"
                  className="payment-button form__next cta"
                  type="button"
                  aria-label="pay with direct debit"
                  onClick={(e) => {
                    updatePaymentType(paymentProviders.GOCARDLESS);
                    updatePaymentProvider(paymentProviders.GOCARDLESS);
                    this.onSubmitCard(e);
                  }}
                >Pay with Direct debit
                </button>
              </div>
            ) : ''}

          { donationForm.givingType === GIVING_TYPE_MONTHLY && typeof providers.monthly !== 'undefined' &&
          providers.monthly.indexOf(paymentProviders.BRAINTREE) !== -1
            ? (
              <button
                id="comicrelief_payinbundle_payment_card"
                className="payment-button form__next"
                type="submit"
                onClick={(e) => {
                  updatePaymentType(paymentProviders.BRAINTREE);
                  updatePaymentProvider(paymentProviders.BRAINTREE);
                  this.onSubmitCard(e);
                }}
                aria-label="Pay by card"
              >pay with card
              </button>
            ) : ''}

          {(donationForm.givingType === GIVING_TYPE_SINGLE || donationForm.givingType === GIVING_TYPE_PAYIN) &&
          typeof providers.single !== 'undefined' &&
          providers.single.indexOf(paymentProviders.PAYPAL) !== -1 ?
            (
              <PaypalButton
                onError={onError}
                onSuccess={onSuccess}
                onCancel={onCancel}
                updateErrorMessage={(message) => { this.props.updateErrorMessage(message); }}
              />
            ) : ''}

        </div>
      </div>
    );
  }
}

export default connect(
  ({ application, donationForm, providers }) => ({ application, donationForm, providers }),
  dispatch => bindActionCreators(
    Object.assign({}, applicationActions, giftaidActions, paymentActions),
    dispatch,
  ),
)(PaymentButtons);
