import fetchIntercept from 'fetch-intercept';

const requestedUrls = {};

/**
 * Fetch request interceptor
 */
export default () => {
  fetchIntercept.register({
    request: ((url, config) => {
      requestedUrls[url] = false;
      return [url, config];
    }),
    response: ((response) => {
      requestedUrls[response.url] = true;

      if (response.status.toString().startsWith(5) &&
        response.url.indexOf(process.env.REACT_APP_PAYMENTSURL) !== -1 &&
        window.location.href.indexOf('/sorry') === -1
      ) {
        window.location = '/sorry';
      }
      return response;
    }),
    responseError: (() => {
      setTimeout(() => {
        let paymentsServiceHasFailed = false;

        Object.keys(requestedUrls).forEach((url) => {
          if (url.indexOf(process.env.REACT_APP_PAYMENTSURL) !== -1
            && requestedUrls[url] === false) {
            paymentsServiceHasFailed = true;
          }
        });

        if (window.location.href.indexOf('/sorry') === -1 && paymentsServiceHasFailed === true) {
          window.location = '/sorry';
        }
      }, 5000);
    }),
  });
};
