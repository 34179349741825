import { setRecaptchaTokenV3, setRecaptchaVersion } from '../Actions/recaptchaActions';
import store from '../Store/store';
/**
 * function to return the active token and version
 */
export const getRecaptchaTokenAndVersion = (application, recaptcha) => {
  if ((application.dependencies.reCaptcha_frontend === false
    && application.dependencies.reCaptcha === false)
    || application.reCaptchaReady === false) {
    return { version: null, token: null };
  }

  // check if the token V2 / V3
  let tokenCheck = '';
  if (recaptcha.version === 2) {
    tokenCheck = recaptcha.tokenV2;
  } else {
    tokenCheck = recaptcha.tokenV3;
  }

  return { version: recaptcha.version, token: tokenCheck };
};

/**
 * function to prevent user from submit if tokenV2 or tokenV2 is not set
 */
export const canSubmitRecaptcha = (recaptcha, application) => {
  if (application.dependencies.reCaptcha === false && application.dependencies.reCaptcha_frontend === false) {
    return true;
  }
  if (recaptcha.version === 3 && recaptcha.tokenV3) {
    return true;
  } else if (recaptcha.version === 2 && recaptcha.tokenV2) {
    return true;
  }
  return false;
};

/**
 * Recaptcha Service
 * @param response string
 * @return {*}
 */
export const handleRecaptchaResponse = (responseStatus, responseData) => {
  // check for the responseStatus and responseData if Recaptcha V3 has failed
  // to add version to the state
  if (responseStatus === 401 && responseData.data.challenge) {
    store.dispatch(setRecaptchaVersion(2));
    return true;
  }
  return false;
};

/**
 * Promisify grecaptcha.execute()
 */
const refreshToken = async action => new Promise((resolve, reject) => {
  try {
    if (!window.grecaptcha) {
      resolve(null);
    }
    window.grecaptcha.execute(process.env.REACT_APP_RECAPTCHA_V3_SITE_KEY, { action })
      .then(token => (resolve(token)));
  } catch (err) {
    reject(err);
  }
});

/**
 * Refresh reCaptcha token before form submit to avoid reCaptcha 2min timeout error
 */
export const refreshRecaptchaToken = async (application, action) => {
  if (application.dependencies.reCaptcha === false && application.dependencies.reCaptcha_frontend === false) {
    return true;
  }

  const token = await refreshToken(action);
  store.dispatch(setRecaptchaTokenV3(token));

  return true;
};
