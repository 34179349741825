import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import CartService from '../../Service/Cart.service';
import FullHeightSingleImage from '../../Component/FullHeightSingleImage/FullHeightSingleImage';

/**
 * Failure Component
 */
const Failure = ({ application, donationForm, payment, history }) => {
  useEffect(() => {
    // If the payment is not complete then return the user to the homepage
    if (payment.paymentIsFailed === false) {
      history.push({ pathname: '/' });
    }

    // Sets the document title
    document.title = `Failure${application.page.titlePostfix}`;
  }, [application.page.titlePostfix, history, payment.paymentIsFailed]);

  const cart = new CartService(application.cartId);
  const { desktop, mobile, alt } = cart.config.header[donationForm.givingType];

  return (
    <main role="main">
      <section className="paragraph--full-height-single-image-single-copy bg--blue-2023">
        <FullHeightSingleImage
          alt={alt}
          desktop={desktop}
          mobile={mobile}
        />
        <div className="inner-content failure fhsisc__text-wrapper bg--transparent">
          <h1>Oops, your payment <br /> didn&apos;t go through</h1>
          <h2>Your transaction failed and you&apos;ve not been charged</h2>
          <a className="btn cta" href="/form/payment">Try again</a>
          <p>
            If you continue to encounter this problem please <a style={{ color: 'white' }} className="hyperlink" href="https://www.comicrelief.com/contact-us"><strong>contact the support team</strong></a>.
          </p>
        </div>
      </section>
    </main>
  );
};

export default connect(
  ({ application, donationForm, payment }) => ({ application, donationForm, payment }),
)(Failure);
