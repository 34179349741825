export const RESET_GIFTAID_STATE = 'RESET_GIFTAID_STATE';
export const UPDATE_GIFTAID = 'UPDATE_GIFTAID';
export const UPDATE_PAYMENT_TYPE = 'UPDATE_PAYMENT_TYPE';

/**
 * Resets the state of the giftaid section
 * @return {{type: string}}
 */
export function resetGiftaidState() {
  return {
    type: RESET_GIFTAID_STATE,
  };
}

/**
 * Update whether giftaid is added or not
 * @param giftaid
 * @return {{type: string, giftaid: *}}
 */
export function updateGiftaid(giftaid) {
  return {
    type: UPDATE_GIFTAID,
    giftaid,
  };
}

/**
 * Update the payment type
 * @param paymentType
 * @return {{type: string, paymentType: *}}
 */
export function updatePaymentType(paymentType) {
  return {
    type: UPDATE_PAYMENT_TYPE,
    paymentType,
  };
}
