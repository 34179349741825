import React from 'react';
import PropTypes from 'prop-types';
/**
 * ProgressBar component
 */
const ProgressBar = ({ currentStep, isMonthly, text }) => {
  const totalSteps = isMonthly ? 6 : 5; // Monthly features an extra step
  let percentage = (100 * currentStep) / totalSteps;
  percentage = Math.round(percentage / 1) * 1; // round to nearest whole number

  return (
    <div className="progress-bar__wrapper">
      <p className="progress-bar__text">{text}</p>
      <div className={`progress-bar progress-bar--${percentage}-done`}>
        <span className={`progress-bar__indicator progress-bar__indicator__${percentage}-percent-done`} />
      </div>
    </div>
  );
};

ProgressBar.propTypes = {
  currentStep: PropTypes.number.isRequired,
  isMonthly: PropTypes.bool.isRequired,
  text: PropTypes.string.isRequired,
};

export default ProgressBar;
