import React from 'react';
import { connect } from 'react-redux';
import { ReCAPTCHA as ReCaptchaV2 } from 'react-google-recaptcha';
import { ReCaptcha as ReCaptchaV3 } from 'react-recaptcha-v3';
import store from '../../Store/store';
import { setRecaptchaTokenV2, setRecaptchaTokenV3 } from '../../Actions/recaptchaActions';

/**
 * Recaptcha Component
 */
const Recaptcha = ({ recaptcha, application, action }) => {
  /**
  * function to get the generated token from V3
  */
  const verifyV3Token = (recaptchaV3Token) => {
    store.dispatch(setRecaptchaTokenV3(recaptchaV3Token));
  };

  const verifyV2Token = (recaptchaV2Token) => {
    store.dispatch(setRecaptchaTokenV2(recaptchaV2Token));
  };

  return (
    application.dependencies.reCaptcha === true || application.dependencies.reCaptcha_frontend === true ?
      <React.Fragment>
        <ReCaptchaV3
          sitekey={process.env.REACT_APP_RECAPTCHA_V3_SITE_KEY}
          verifyCallback={verifyV3Token}
          action={action}
        />
        {recaptcha.version === 2 &&
          <React.Fragment>
            <p className="secure-quote-for-recaptcha">
              For your security, please solve the following challenge
            </p>
            <ReCaptchaV2
              onChange={verifyV2Token}
              className="recaptcha-v2-element"
              grecaptcha={window.grecaptcha}
              sitekey={process.env.REACT_APP_RECAPTCHA_V2_SITE_KEY}
            />
          </React.Fragment>}
      </React.Fragment>
      : null
  );
};

export default connect(({ recaptcha, application }) => ({ recaptcha, application }))(Recaptcha);
