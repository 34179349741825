import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ReactSVG from 'react-svg';
import SvgSprite from '@comicrelief/storybook/src/components/SVG/spritesheet.svg';

import PayInThankYou from './PayInThankYou';
import PayInMarketingPreferences from './PayInMarketingPreferences';
import * as payInFormActions from '../../Actions/payInFormActions';
import FullHeightSingleImage from '../../Component/FullHeightSingleImage/FullHeightSingleImage';
import audienceImages from './data/audienceImages';
import Confetti from '../../Component/Confetti/Confetti';
import SuccessImg from '../../../public/images/payin/Payin-Success-365--compressed.jpg';
import PayinSpritesheet from '../../../public/images/payin/payin-spritesheet.svg';
/**
 * Success Component
 */
const PayInSuccess = ({ application, history, payment, payInForm, updateCurrentPayInStep, isMobile }) => {
  useEffect(() => {
    // Ensure user is following the journey linearly
    const prevStep = payInForm.currentPayInStep;
    const thisStep = 7;

    // If the payment is not complete then return the user to the homepage
    if (prevStep === null || payment.paymentIsComplete === false || (payment.transactionId === null && payment.subscriptionId === null)) {
      history.push({ pathname: '/' });
    }
    // Set the page title
    document.title = `Pay-In: Success${application.page.titlePostfix}`;
    updateCurrentPayInStep(thisStep);
  }, [application.page.titlePostfix, history, payInForm.currentPayInStep, payment.paymentIsComplete, payment.subscriptionId, payment.transactionId, updateCurrentPayInStep]);

  return (
    <main role="main">
      <ReactSVG
        path={SvgSprite}
        className="visually-hidden"
      />
      <ReactSVG
        path={PayinSpritesheet}
        className="visually-hidden"
      />
      <section className="paragraph--full-height-single-image-single-copy bg--blue-2023 paragraph--payin">
        {!isMobile && payInForm.marketingPrefs.formComplete !== true && <FullHeightSingleImage
          alt={audienceImages[payInForm.payInAudienceType]}
          desktop={audienceImages[payInForm.payInAudienceType]}
        />}
        {!isMobile && payInForm.marketingPrefs.formComplete === true && <FullHeightSingleImage
          alt={SuccessImg}
          desktop={SuccessImg}
        />}
        {payInForm.marketingPrefs.formComplete === true ?
          <PayInThankYou /> :
          <PayInMarketingPreferences />
        }
      </section>
      {payInForm.marketingPrefs.formComplete === true &&
      <Confetti />
      }
    </main>
  );
};

const mapStateToProps = ({ application, donationForm, payment, payInForm }) => ({
  application,
  donationForm,
  payment,
  payInForm,
});

const mapDispatchToProps = dispatch => bindActionCreators(payInFormActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(PayInSuccess);
