import { isEqual } from 'lodash';
import { GIVING_TYPE_PAYIN } from '../Pages/DonationForm/GivingTypeSelector/GivingTypeSelector';

export const amountFormatter = amount => (amount && !Number.isInteger(amount) ? amount.toFixed(2) : amount);

// Helper function to determine if this jouney originated from
// a Donate row/widget from CRcom or not
function isWidgetJourney(url) {
  // Only ever set by the Donate row, so a reliable marker
  return url && url.includes('rowID');
}

export const googleAnalyticsMoneyBuys = (donationForm, updatemoneybuysForGA, storedCurrentMoneybuy, url, payInAudienceType = null) => {
  const isCurrentlyPayin = donationForm.givingType === GIVING_TYPE_PAYIN;
  let thisAffiliation;
  let thisIndex;
  let thisName;

  // Use this Payin-only value as a flag to represent Payin or not
  if (isCurrentlyPayin) {
    // Payin only has manual entry, so always zero
    thisIndex = 0;
    thisName = payInAudienceType;
    thisAffiliation = isWidgetJourney(url) ? 'cr-website-payin' : 'cr-payin';
  } else {
    // Transform values to represent manual entry when applicable
    thisIndex = donationForm.moneyBuy.index !== null ? (parseInt(donationForm.moneyBuy.index, 10) + 1) : 0;
    thisName = donationForm.moneyBuy.name;
    thisAffiliation = isWidgetJourney(url) ? 'cr-website-donation' : 'cr-donation';
  }

  // Construct object from user's most recent interaction to compare with our GA stored values
  const mostRecentMoneybuy = {
    name: thisName,
    amount: donationForm.amount,
    index: thisIndex,
    givingType: donationForm.givingType,
    affiliation: thisAffiliation,
  };

  // Only trigger on a change:
  if (!(isEqual(mostRecentMoneybuy, storedCurrentMoneybuy))) {
    // If we don't have this stored already:
    if (storedCurrentMoneybuy === null) {
      // Pass in this brand new moneybuy only
      updatemoneybuysForGA(mostRecentMoneybuy, null);
    } else {
      // Else we've already got a value, so bring the brand new value and swap the stored 'current' to become 'previous'
      updatemoneybuysForGA(mostRecentMoneybuy, storedCurrentMoneybuy);
    }
  }
};

export default { amountFormatter, googleAnalyticsMoneyBuys };
