import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ErrorDisplayService from '../../Service/ErrorDisplay.service';

import * as payInFormActions from '../../Actions/payInFormActions';
import ErrorMessage from '../../Component/ErrorMessage/ErrorMessage';

import { regex, regexError, charLimit } from '../../data/regex';

/**
 * OtherActivityModal Component
 */
class OtherActivityModal extends Component {
  constructor(props) {
    super(props);
    this.errorDisplay = new ErrorDisplayService();
  }
  /**
   * componentDidMount
   * */
  componentDidMount() {
    const { payInForm: { payInActivities, isActivityFormValid }, activities } = this.props;
    const hasReachedLimit = (Object.keys(payInActivities).length > activities.maxActivities);

    // If we're already at the limit, show the error (set in the constructor) right off the bat
    this.props.updatePayInActivityValidity(isActivityFormValid, hasReachedLimit);
    if (hasReachedLimit) this.props.setActivityErrorMessage(`Please select up to ${activities.maxActivities} activities in total`);
    // Set focus to input upon mounting of modal
    this.nameInput.focus();
  }
  /**
   * componentDidUpdate
   * */
  componentDidUpdate(prevProps) {
    // Handle validation only once state has updated
    if (prevProps.payInForm.payInActivities !== this.props.payInForm.payInActivities) {
      this.validateForm();
    }
  }

  /**
   * validateForm
   * Handles validation of the small 'modal' form only
   * */
  validateForm() {
    const { payInForm: { payInActivities }, activities } = this.props;
    let isValid = true;
    let errorMsg = '';

    const currentActivityNum = Object.keys(payInActivities).length;
    const overLimit = (currentActivityNum > activities.maxActivities);
    const currentValue = payInActivities.other;
    const fieldIsEmpty = currentValue === undefined;

    // Check regex and length only if the field isn't empty
    const isRegexMatch = !fieldIsEmpty ? (currentValue.match(regex) !== null) : false;
    const isAtCharLimit = !fieldIsEmpty ? (currentValue.length >= charLimit) : false;

    /**
      * Update flag, and reassign error message based on the most important error:
      * there's no point in telling the user their 'Other Activity' input is too many chars long
      * if they've already hit the limit of how many total activities they can choose.
      * */
    if (!isRegexMatch || fieldIsEmpty || overLimit || isAtCharLimit) {
      isValid = false;
      if (isAtCharLimit) errorMsg = `Please only enter a maximum of ${charLimit} characters`;
      if (!isRegexMatch) errorMsg = `${regexError}`;
      if (fieldIsEmpty) errorMsg = 'Please enter an activity or close the pop-up';
      if (overLimit) errorMsg = `Please select up to ${activities.maxActivities} activities in total`;
    }

    // Update form and error states based on the above
    this.props.setActivityErrorMessage(errorMsg);
    this.props.updatePayInActivityValidity(isValid, !isValid);
  }

  /**
   * Handle submission from the modal
   * @param e
   * */
  handleOtherSubmit(e) {
    const { payInForm: { isActivityFormValid } } = this.props;

    e.preventDefault();

    if (isActivityFormValid) {
      this.props.onSubmit(e);
    } else {
      // Run the validation function to show any errors not yet shown
      this.validateForm();
      this.errorDisplay.setScrollToErrorTimeoutMarkII();
    }
  }

  /**
   * Handle input field updates
   * @param e
   * */
  handleTyping(e) {
    const thisValue = e.target.value;

    // Update the state accordingly
    if (thisValue === '') {
      this.props.removeActivity('other');
    } else {
      this.props.addActivity('other', thisValue);
    }
  }

  /**
   * Handle modal close
   */
  handleClose() {
    const { payInForm: { isActivityFormValid, payInActivities }, activities } = this.props;
    const overLimit = (Object.keys(payInActivities).length > activities.maxActivities);
    // If the user hasn't actually entered a value and we're still within the limit, remove any error state from the entire form
    if (!overLimit) this.props.updatePayInActivityValidity(isActivityFormValid, false);
    this.props.updateIsModalOpen(false);
  }

  /**
   * otherActivityModal render
   * @return {*}
   */
  render() {
    const {
      payInForm: { isActivityFormValid, activityErrorMessage, showActivityError, payInActivities: { other } },
    } = this.props;

    return (
      <div className="activity-modal__wrapper">
        <div className="activity-modal__content">

          <button
            id="comicrelief_payinbundle_audience_close"
            type="button"
            name="comicrelief_payinbundle_audience[close]"
            title="Close modal"
            onClick={(e) => { this.handleClose(e); }}
          >X</button>

          <div className="form__payin_other-activity">
            <p>What activity or activities did you take part in?</p>
            <div id="field-wrapper--otherActivity">
              <div className="form__fieldset form__field--wrapper form__field-wrapper--text">
                <label id="field-label--otherActivity" htmlFor="field-input--otherActivity" className="form__field-label required">
                  {this.props.label}
                </label>
                <div className="form__field--otherActivity">
                  <input
                    type="text"
                    id="field-input--otherActivity"
                    name="otherActivity"
                    className="form__field form__field--text "
                    required
                    placeholder="Other activity"
                    aria-describedby="field-label--otherActivity field-error--otherActivity"
                    value={other || ''}
                    onChange={(e) => { this.handleTyping(e); }}
                    ref={(input) => { this.nameInput = input; }}
                    maxLength={charLimit}
                  />
                  {showActivityError &&
                    <ErrorMessage copy={activityErrorMessage} id={'other-activity'} />
                  }
                </div>
              </div>
            </div>
          </div>

          <div className="form__fieldset form__other-activity-submit">
            <button
              id="comicrelief_payinbundle_other-activity-submit"
              type="submit"
              name="comicrelief_payinbundle_other-activity[submit]"
              className={'btn form__next cta ' + (!isActivityFormValid ? 'disabled' : '')}
              onClick={(e) => { this.handleOtherSubmit(e); }}
            > Continue to my details </button>
          </div>
        </div>
      </div>
    );
  }
}

/**
  * PaypalButton Default Props
  * @type {{ isFormValid: Boolean }}
  */
OtherActivityModal.defaultProps = {
  activities: null,
  onSubmit: null,
  history: {
    push: {},
  },
};

export default connect(
  ({ application, payInForm }) => ({ application, payInForm }),
  dispatch => bindActionCreators(
    Object.assign({}, payInFormActions),
    dispatch,
  ),
)(OtherActivityModal);
