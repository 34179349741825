import { all } from 'redux-saga/effects';

import ApplicationSagas from './Application';
import GTMSagas from './GTM';
import PermissionSagas from './Permissions';
import TransactionSagas from './Transaction';
import PayInSagas from './PayIn';
import FBCAPISagas from './FacebookCAPI';


/**
 * Saga Reducer Hooks
 */
export default function* sagas() {
  yield all([
    ApplicationSagas(),
    GTMSagas(),
    PermissionSagas(),
    TransactionSagas(),
    PayInSagas(),
    FBCAPISagas(),
  ]);
}
