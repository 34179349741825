import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import SchoolsLookUp from '@comicrelief/storybook/src/components/SchoolsLookUp/SchoolsLookUp';
import SelectField from '@comicrelief/storybook/src/components/SelectField/SelectField';
import InputField from '@comicrelief/storybook/src/components/InputField/InputField';

import * as applicationActions from '../../../Actions/applicationActions';
import * as payInFormActions from '../../../Actions/payInFormActions';
import ErrorDisplayService from '../../../Service/ErrorDisplay.service';
import establishmentData from '../data/establishmentData';
import { regex, regexError, regexEdcoName, regexEdcoNameError } from '../../../data/regex';

/**
 * PayInDetails Component
 */
class PayInSchoolsLookup extends Component {
  constructor(props) {
    super(props);
    this.errorDisplay = new ErrorDisplayService();
    this.endpoint = process.env.REACT_APP_SCHOOLS_LOOKUP_URL + 'schools/lookup?query=';
    this.min = 3;
    this.randomSelectKey = Math.random();
    this.handleFieldUpdate = this.handleFieldUpdate.bind(this);
    this.checkSchoolValues = this.checkSchoolValues.bind(this);
    this.handleSchoolsChange = this.handleSchoolsChange.bind(this);
  }

  componentDidUpdate(prevProps) {
    const {
      payInForm,
      payInForm: {
        allAddressValidation: { payInSchoolAddressValidation },
        allAddressValidity: { isPayInSchoolAddressValid },
        forceFormErrors },
      updatePayInForceFormErrors,
    } = this.props;

    // If the 'Address' parent component has changed this flag via an attempted submission:
    if (forceFormErrors) {
      Object.keys(payInSchoolAddressValidation).forEach((fieldName) => {
        this.handleSchoolsChange(payInSchoolAddressValidation[fieldName], fieldName);
      });

      this.errorDisplay.setScrollToErrorTimeoutMarkII();
      updatePayInForceFormErrors(false);
    }

    // Only check the address validity only once the redux state has
    if (payInForm.allAddressValidation.payInSchoolAddressValidation !== prevProps.payInForm.allAddressValidation.payInSchoolAddressValidation) {
      const areRequiredFieldsValid = this.checkSchoolValues();

      // Switch form validity accordingly
      if (areRequiredFieldsValid && isPayInSchoolAddressValid === false) {
        this.props.updatePayInAllAddressValidity('isPayInSchoolAddressValid', true);
      } else if (!areRequiredFieldsValid && isPayInSchoolAddressValid === true) {
        this.props.updatePayInAllAddressValidity('isPayInSchoolAddressValid', false);
      }
    }
  }

  handleFieldUpdate(fieldState, fieldName) {
    const { payInForm: { allAddressValidation: { payInSchoolAddressValidation } } } = this.props;

    // To prevent black holes
    if (fieldState.value !== payInSchoolAddressValidation[fieldName].value) {
      this.handleSchoolsChange(fieldState, fieldName);
    }
  }

  /**
   * checkValues helper function
   * @return {{isValid: Boolean: *}}
   */
  checkSchoolValues() {
    const { payInForm: { allAddressValidation: { payInSchoolAddressValidation } } } = this.props;
    let isValid = true;

    // Check each field validity
    Object.keys(payInSchoolAddressValidation).forEach((fieldName) => {
      const thisField = payInSchoolAddressValidation[fieldName];
      if (thisField.valid === false || (thisField.value === '' && thisField.required)) {
        isValid = false;
      }
    });

    return isValid;
  }

  /**
   * handleSchoolsChange function:
   * Manages both updating and validation of fields, as SchoolsLookUp's
   * own 'validateField' func only runs on blur event for some reason
   * @param fieldName string
   * @param event object
   */
  handleSchoolsChange(fieldState, fieldName) {
    const {
      updatePayInAllAddressValidity,
      updateAllPayInAddresses, payInForm: { allAddressValidation: { payInSchoolAddressValidation }, allAddressValidity: { isPayInSchoolAddressValid } },
    } = this.props;

    const thisFieldValidationObj = Object.assign({}, payInSchoolAddressValidation[fieldName]);
    thisFieldValidationObj.value = fieldState.value ? fieldState.value : '';

    // Skip hidden, programmatically-filled fields (which have no 'required' obj property at all)
    if (('required' in payInSchoolAddressValidation[fieldName])) {
    // Switch the regex config depending on the field
      const thisRegex = (fieldName !== 'establishmentName' ? regex : regexEdcoName);
      const thisRegexErrorMsg = (fieldName !== 'establishmentName' ? regexError : regexEdcoNameError);

      const isRequired = thisFieldValidationObj.required;
      const isNotEmpty = thisFieldValidationObj.value !== '';
      const isRegexMatch = thisFieldValidationObj.value.match(thisRegex) !== null;

      thisFieldValidationObj.message = !isNotEmpty && isRequired ? 'This field is required' : '';
      thisFieldValidationObj.message = isNotEmpty && !isRegexMatch ? thisRegexErrorMsg : thisFieldValidationObj.message; // Additional regex check

      // Require non-empty regex matches for required fields, but
      thisFieldValidationObj.valid = (isRequired && isNotEmpty && isRegexMatch) || (!isRequired && isRegexMatch);
      thisFieldValidationObj.showErrorMessage = !thisFieldValidationObj.valid;
    }

    // To force re-render to show error
    if (fieldName === 'establishmentType') this.randomSelectKey = Math.random();

    updateAllPayInAddresses('payInSchoolAddressValidation', fieldName, thisFieldValidationObj);

    const areAllFieldsValid = this.checkSchoolValues();

    // Update the relevant validity flag
    if (areAllFieldsValid !== isPayInSchoolAddressValid) {
      updatePayInAllAddressValidity('isPayInSchoolAddressValid', areAllFieldsValid);
    }
  }

  /**
   * Render SchoolsLookUp
   */
  render() {
    const { payInForm: { allAddressValidation: { payInSchoolAddressValidation }, allAddressValidity: { isPayInSchoolAddressValid } } } = this.props;

    const validObj = payInSchoolAddressValidation; // Just to streamline code

    return (
      <div className={isPayInSchoolAddressValid && 'lookup--valid'}>
        <SelectField
          id="establishmentType"
          name="establishmentType"
          label="Select establishment type"
          showErrorMessage={validObj.establishmentType.showErrorMessage}
          required
          options={establishmentData.establishmentType}
          isValid={(state, name) => { this.handleFieldUpdate(state, name); }}
          key={this.randomSelectKey}
          defaultValue={validObj.establishmentType.value}
          ref={element => this.errorDisplay.setRef(element)}
          extraClass={(validObj.establishmentType.showErrorMessage ? 'error validation__wrapper' : '')}
        />

        <InputField
          type="text"
          label="Job title"
          id="field-input--job-title"
          name="jobTitle"
          className="form__field form__field--text field-input--job-title"
          placeholder="Job title"
          aria-describedby="field-label--payin-job-title field-error--payin-job-title"
          isValid={(state, name) => this.handleFieldUpdate(state, name)}
          showErrorMessage={validObj.jobTitle.showErrorMessage}
          required
          fieldValue={validObj.jobTitle}
          autoComplete="off"
          pattern={regex}
          invalidErrorText={regexError}
        />

        <SchoolsLookUp
          data={this.endpoint}
          min={this.min}
          ref={element => this.errorDisplay.setRef(element)}
          onChange={(fieldName, event) => { this.handleSchoolsChange(event.target, fieldName); }}
          // Field prop stuff
          selectedEstablishment={validObj.selectedEstablishment.value}
          establishmentIdValue={validObj.establishmentId.value}
          establishmentNameValue={validObj.establishmentName.value}
          address1Value={validObj.address1.value}
          address2Value={validObj.address2.value}
          address3Value={validObj.address3.value}
          townValue={validObj.town.value}
          postcodeValue={validObj.postcode.value}
          // error msgs
          establishmentNameErrorMessage={validObj.establishmentName.message}
          address1ErrorMessage={validObj.address1.message}
          address2ErrorMessage={validObj.address2.message}
          address3ErrorMessage={validObj.address3.message}
          townErrorMessage={validObj.town.message}
          postcodeErrorMessage={validObj.postcode.message}
          // required flags used for form validation
          establishmentNameRequired={validObj.establishmentName.required}
          address1Required={validObj.address1.required}
          address2Required={validObj.address2.required}
          address3Required={validObj.address1.required}
          townRequired={validObj.town.required}
          postcodeRequired={validObj.postcode.required}
          // Labels to reflect the above
          establishmentNameLabelText={`Establishment name ${validObj.establishmentName.required ? '' : '(optional)'}`}
          address1LabelText={`Address 1 ${validObj.address1.required ? '' : '(optional)'}`}
          address2LabelText={`Address 2 ${validObj.address2.required ? '' : '(optional)'}`}
          address3LabelText={`Address 3 ${validObj.address3.required ? '' : '(optional)'}`}
          townLabelText={`Town ${validObj.town.required ? '' : '(optional)'}`}
          postcodeLabelText={`Postcode ${validObj.postcode.required ? '' : '(optional)'}`}
          placeholder="Postcode"
          extraClasses={(!isPayInSchoolAddressValid ? 'error' : '')}
          titleCopy="Enter the name or postcode of your establishment"
        />
      </div>
    );
  }
}

export default connect(
  ({ application, payInForm }) => ({ application, payInForm }),
  dispatch => bindActionCreators(
    Object.assign({}, applicationActions, payInFormActions),
    dispatch,
  ),
)(PayInSchoolsLookup);
